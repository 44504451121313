import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import SpinnerAtom from "@atoms/Spinner";
import IInspectionDetailOrganism from "./interface";
import { t, Trans } from "@lingui/macro";
import ChipAtom from "@atoms/Chip";
import TabsMolecule from "@molecules/Tabs";
import { toast } from "react-toastify";
import JobsService from "@services/JobsService";
import { JobModel } from "@models/JobModel";
import JobDetailsFormOrganism from "./Details";
import JobDetailsInspectionOrganism from "./Inspections";
import { useConfirm } from "material-ui-confirm";
import DataBalloonAtom from "@atoms/DataBalloon";
import { useDispatch } from "react-redux";
import { resetFormsWarnings } from "@stores/reducers/saverReducer";

const JobDetailOrganism = ({
  jobIn,
  onUpdate,
  onDelete,
  onCopy,
}: IInspectionDetailOrganism) => {
  const [job, setResults] = useState<JobModel | undefined>(undefined);
  const confirm = useConfirm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFormsWarnings());
  }, []);

  const { isLoading, data, refetch } = useQuery(
    `job-detail${jobIn.id}`,
    () => JobsService.get(jobIn.id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
      if (onUpdate) {
        onUpdate({ ...data.data, ...{ instanceOf: "Job" } });
      }
    }
  }, [isLoading, data]);

  const copy = () => {
    JobsService.copyJob(jobIn.id).then((res: any) => {
      if (!res.hasErrors()) {
        if (onCopy) {
          // @ts-ignore
          onCopy({ ...res.getData(), ...{ instanceOf: "Job" } });
        }
        toast(res.getMsgString(), {
          type: "success",
        });
      } else {
        toast(res.getMsgString(), {
          type: "error",
        });
      }
    });
  };

  const deleteJob = () => {
    confirm({
      title: "Are you sure you want to delete this element?",
      description: "The action is irreversible!",
      cancellationText: "Go back",
      confirmationText: "Delete",
    }).then(() => {
      JobsService.delete(jobIn.id).then((res: any) => {
        if (!res.hasErrors()) {
          if (onDelete) {
            // @ts-ignore
            onDelete(res.getData().id);
          }
          toast(res.getMsgString(), {
            type: "success",
          });
        } else {
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      });
    });
  };

  const handleOnModifcations = () => {
    refetch();
    if (onUpdate) {
      onUpdate();
    }
  };

  const jobDetailPanel = () => {
    return (
      <>
        {job && (
          <Box mb={1}>
            <Typography variant="h2">
              <Trans>Job details</Trans>
            </Typography>
            <Card variant="outlined" elevation={0}>
              <CardContent>
                <Grid container spacing={2} sx={{ marginTop: "0px" }}>
                  <Grid
                    item
                    md={10}
                    sx={{
                      borderRight: "1px solid #DDDDDD",
                    }}
                  >
                    <Grid container>
                      <Grid item md={1}>
                        <DataBalloonAtom
                          variant="chip"
                          title={t`Total inspections`}
                          severity={"warning"}
                          data={job.total_inspections}
                        />
                      </Grid>
                      <Grid item md={10} className="align-center">
                        <Box>
                          <Typography variant="h5" gutterBottom>
                            {job.client.name}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <Grid container>
                        <Grid item md={1}></Grid>
                        <Grid item md={11}>
                          <Typography variant="h6">
                            {job.label ?? "--"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={2}>
                    <Box pb={1}>
                      <Button onClick={copy} fullWidth variant="outlined">
                        <Trans>Copy</Trans>
                      </Button>
                    </Box>
                    <Box pb={1}>
                      <Button onClick={deleteJob} fullWidth variant="outlined">
                        <Trans>Delete</Trans>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
        {job && (
          <TabsMolecule
            tabsLabels={[
              {
                label: "Job details",
                child: (
                  <JobDetailsFormOrganism
                    job={job}
                    onSave={handleOnModifcations}
                  />
                ),
              },
              {
                label: (
                  <Box>
                    <Trans>Inspections</Trans>&nbsp;
                    <ChipAtom size="small" label={job.total_inspections} />
                  </Box>
                ),
                child: (
                  <JobDetailsInspectionOrganism
                    onMod={handleOnModifcations}
                    job={job}
                  />
                ),
              },
            ]}
          />
        )}
      </>
    );
  };

  return <>{job ? jobDetailPanel() : <SpinnerAtom />}</>;
};

export default JobDetailOrganism;
