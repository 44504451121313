import { Fragment, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useUser } from "@hooks";
import Modal from "@molecules/Modal";
import LayoutModal from "@layouts/modal";
import { Box } from "@mui/material";
import { useAppDispatch } from "@stores/hooks";
import {
  addRTCMemberOnline,
  removeRTCMemberOnline,
} from "@stores/reducers/chatReducer";
import callSound from "@resources/sounds/call_sound.wav";
import { useDevice } from "@hooks";
import VideoChat from "@organisms/VideoChat";

const VideoChatBubblesOrganism = () => {
  const pusher = useSelector((state: any) => state.app.pusherInstance);
  const channel = useSelector((state: any) => state.app.presenceChannel);
  const serviceWorkerRegistration = useSelector(
    (state: any) => state.app.serviceWorkerRegistration,
  );
  const ringingSound = document.createElement("audio");
  const auth = useUser();
  const device = useDevice();
  const [calling, setCalling] = useState<any>(null);
  const [accepted, setAccepted] = useState<any>(false);
  const dispatch = useAppDispatch();

  const closeCallModal = (data: any) => {
    playRingingSound(false);
    ringingSound.pause();
    ringingSound.currentTime = 0;
    channel.trigger(`client-signal-${calling.userId}`, {
      type: "message",
      action: "modal-closed",
      userId: auth.user.id,
    });
    setCalling(null);
  };

  const playRingingSound = (play = true) => {
    /*
        ringingSound.pause();
        ringingSound.currentTime = 0;
        ringingSound.volume = 0.3;
        ringingSound.src = callSound;
        ringingSound.oncanplaythrough = () => {
            if (play) {
                ringingSound.play();
            }
        }
        */
  };

  useEffect(() => {
    if (auth && channel && serviceWorkerRegistration && pusher) {
      const context = { serviceWorkerRegistration };
      channel
        .bind("pusher:subscription_succeeded", (members: any) => {
          const get = Object.keys(members.members).map((memberId: any) => {
            return {
              id: Number(memberId), // casting, here I've got a string ??
              info: members.members[memberId],
            };
          });
          get.forEach((member: any) => dispatch(addRTCMemberOnline(member)));
        })
        .bind("pusher:member_added", (member: any) => {
          dispatch(addRTCMemberOnline(member));
        })
        .bind(
          `client-signal-${auth.user.id}`,
          (signal: any) => {
            console.log("Signal", signal);
            if (!signal.isAnswer && signal.type !== "message") {
              if (context.serviceWorkerRegistration) {
                context.serviceWorkerRegistration.showNotification(
                  `Incoming call`,
                  {
                    body: `From ${signal.user_info}`,
                    requireInteraction: true,
                    vibrate: [300, 100, 400],
                    data: {
                      link: window.location.href,
                    },
                  },
                );
              }
              setCalling(signal);
              playRingingSound();
            }
          },
          context,
        );
    }
  }, [auth, pusher, serviceWorkerRegistration]);

  const onAcceptCall = () => {
    setAccepted(true);
  };

  useEffect(() => {
    // thanks to https://stackoverflow.com/questions/65454773/outdated-react-state-in-pusher-bind-method
    if (channel) {
      channel.unbind("pusher:member_removed");
      channel.bind("pusher:member_removed", (member: any) => {
        if (calling) {
          if (Number(member.id) === Number(calling.userId)) {
            setCalling(null);
            playRingingSound(false);
          }
        }
        dispatch(removeRTCMemberOnline(member));
      });
      channel.bind(`client-signal-${auth.user.id}`, (signal: any) => {
        if (signal.type === "message") {
          if (signal.action === "modal-closed") {
            setCalling(null);
            playRingingSound(false);
          }
        }
      });
    }
  }, [channel, calling]);

  return (
    <Fragment>
      <Modal open={calling ? true : false} set_open={closeCallModal}>
        <LayoutModal
          title={
            <Box>Incoming call from {calling ? calling.user_info : ""}</Box>
          }
        >
          <Box>
            <VideoChat
              device={device}
              pusher={pusher}
              auth={auth}
              signal={calling}
              onAcceptCall={onAcceptCall}
              onHangUp={closeCallModal}
              activity={null}
              onSharing={() => console.log("sharing")}
              receiverIsOnline={true}
              presenceChannel={channel}
            />
          </Box>
        </LayoutModal>
      </Modal>
    </Fragment>
  );
};

export default memo(VideoChatBubblesOrganism);
