import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { AttributeModel } from "@models/AttributeModel";

class AttributesService extends CoreBaseService {
  constructor() {
    super(AttributeModel);
  }

  save(model_id: number, model_type: string, values: Array<any>) {
    return this.create({ model_id, model_type, values });
  }
}

const inst = new AttributesService();

export default inst;
