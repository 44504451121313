import { Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import CircleIcon from "@mui/icons-material/Circle";

const OnlineOfflineMemberIconMolecule = ({ memberId }: any) => {
  const webRTCMembersOnline = useSelector(
    (state: any) => state.chat.webrtcUsersOnline,
  );
  const receiverIsOnline = webRTCMembersOnline.find(
    (member: any) => member.id === memberId,
  )
    ? true
    : false;

  return (
    <Tooltip
      placement="top"
      title={"User is " + (receiverIsOnline ? "online" : "offline")}
    >
      <CircleIcon
        style={{
          width: '.7em',
          height: '.7em',
          color: receiverIsOnline ? "#5395D1" : "#d1d1d1"
        }}
        fontSize="small"
      />
    </Tooltip>
  );
};

export default OnlineOfflineMemberIconMolecule;
