import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { RateModel } from "@models/RateModel";

class RatesService extends CoreBaseService {
  constructor() {
    super(RateModel);
  }
}

const inst = new RatesService();

export default inst;
