import { isArray } from "lodash";
import * as Yup from "yup";
import { ObjectShape } from "yup/lib/object";

export const MakeResolver = (
  collection: any,
  attributesSample?: Array<any>,
) => {
  let configuration: ObjectShape = {};

  if (isArray(collection)) {
    collection.forEach((group: any) => {
      makeConfiguration(group, group.attributes, configuration);
    });
  } else {
    makeConfiguration(collection, attributesSample, configuration);
  }
  return Yup.object().shape(configuration);
};

const makeConfiguration = (
  group: any,
  attributesList: any,
  configuration: any,
): void => {
  const formSchema: any = {};

  attributesList.forEach((attribute: any) => {
    let yupSchema = null;
    if (attribute.type.alias === "checkbox") {
      yupSchema = Yup.array().min(1);
    } else {
      yupSchema = Yup.string();
    }
    if (attribute.is_required) {
      yupSchema = yupSchema.required("form.required_message");
    }
    formSchema[attribute.id] = yupSchema;
  });

  configuration[group.alias] = Yup.array().of(Yup.object().shape(formSchema));
  /*
        .required("Must have fields")
        .min(1, "Minimum of 1 field")
        */
};

/*

import { isArray } from 'lodash';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';


export const MakeResolver = (collection: any, attributesSample?: Array<any>) => {
    
    let configuration: ObjectShape = {};

    if(isArray(collection)) {
        collection.forEach((group: any) => {
            makeConfiguration(group, group.attributes, configuration);
        });
    } else {
        makeConfiguration(collection, attributesSample, configuration);
    }
    return Yup.object().shape(configuration);
}


const makeConfiguration = (group: any, attributesList: any, configuration: any): void => {
    const formSchema: any = {};
    
    attributesList.forEach((attribute: any) => {
        console.log('attr', attribute);

        let yupSchema = null;
        if(attribute.type.alias === 'checkbox') {
            yupSchema = Yup.array();
            if(attribute.is_required) {
                yupSchema = yupSchema.min(1);
            }
        } else if(attribute.type.alias === 'text') {
            yupSchema = Yup.string().nullable();
        } else if(attribute.type.alias === 'select') {
            yupSchema = Yup.string().nullable();
        } else {
            yupSchema = Yup.mixed().nullable();
        }


        if(attribute.is_required) {
            yupSchema = yupSchema.required("form.required_message");
        }
        formSchema[attribute.id] = yupSchema;
    });

    configuration[group.alias] = Yup.array()
        .of(Yup.object()
        .shape(formSchema))
    }
    */
