import { createTheme } from "@mui/material/styles";
import { COLORS } from "@theme/Colors";

const rawTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      defaultProps: {
        size: "small",
      },
    },
    MuiInputLabel: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px",
          "&:last-child": {
            paddingBottom: "8px",
          },
        },
      },
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 14,
    h1: {
      fontWeight: 400,
      color: COLORS.blue.primary,
      fontSize: "2.4rem",
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 400,
      color: COLORS.gray.primary,
      paddingBottom: "1rem",
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: 1.334,
    },
    h5: {},
    h6: {
      fontSize: "0.9rem",
      fontWeight: "400",
    },
    body2: {
      color: "black",
      fontSize: ".9rem",
      fontWeight: 400,
    },
    caption: {
      color: "black",
    },
    fontWeightLight: 300, // Work Sans
    fontWeightRegular: 400, // Work Sans
    fontWeightMedium: 700, // Roboto Condensed
  },
});

export default rawTheme;
