import { styled } from "@mui/system";
import { COLORS } from "@theme/Colors";

interface ILogoProps {}

export const StyledActivityRow = styled("div")((props: ILogoProps) => ({
  padding: "16px 24px",
  fontSize: "13px",
  ".date-time": {
    fontSize: "19px",
    color: COLORS.blue.primary,
    time: {
      fontSize: "22px",
      display: "block",
    },
  },
  h6: {
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  "& svg": {
    color: "rgb(204, 204, 204)",
  },
  "& p": {
    fontSize: "13px",
  },
  "& button": {
    fontSize: "13px",
  },
  "& .date *": {
    fontWeight: "bold",
    fontSize: "15px",
  },
  // marginBottom: ({ theme }: ILogoProps) => theme.spacing(3),
}));
