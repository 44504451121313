import { Grid, Paper, Typography, Box } from "@mui/material";
import { StyledActivityRow } from "./styled";
import IPersonRow from "./interface";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const PeopleRowMolecule = ({ person, onActionSelected }: IPersonRow) => {
  const actionSelected = (item: any) => {
    if (onActionSelected) {
      onActionSelected(item, person);
    }
  };

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledActivityRow>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center" }}
              md={1}
              xs={12}
            >
              <Box sx={{ margin: "auto" }}>
                <Typography>
                  {person.verified ? (
                    <CheckCircleOutlinedIcon color="primary" />
                  ) : (
                    <ErrorOutlinedIcon color="warning" />
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center" }}
              md={1}
              xs={12}
            >
              <Box sx={{ margin: "auto" }}>
                <Typography>
                  {person.internal ? (
                    <CheckCircleOutlinedIcon color="primary" />
                  ) : (
                    <ErrorOutlinedIcon color="warning" />
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={2} xs={12}>
              {person.full_name ? person.full_name : "--"}
            </Grid>
            <Grid item md={2} xs={12}>
              <ul>
                {person.partners &&
                  person.partners.map((partner: any, index: number) => {
                    return (
                      <li key={index}>{`${partner.category.name}${
                        partner.category.alias === "provider" ? ":" : ""
                      } ${partner.partner ? partner.partner.name : ""}`}</li>
                    );
                  })}
              </ul>
            </Grid>
            <Grid item md={2} xs={12}>
              {person.country ? person.country.name : "--"}
            </Grid>
            <Grid item md={2} xs={12}>
              {person.city ? person.city : "--"}
            </Grid>
            <Grid sx={{ textAlign: "right" }} item md={2} xs={12}>
              <MultiButtonMolecule
                onSelected={actionSelected}
                options={[
                  {
                    label: "Details",
                    id: 1,
                    data: { personId: person.id },
                  },
                  {
                    label: "Delete",
                    id: 4,
                    data: { personId: person.id },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </StyledActivityRow>
      </Paper>
    </>
  );
};

export default PeopleRowMolecule;
