import { Controller } from "react-hook-form";
import ConnectForm from "@utils/ConnectForm";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { Radio } from "@mui/material";
import IRadioGroupMolecule, { IRadio } from "./interface";
import { StyledRadioGroup, StyledFormControlLabel } from "./styled";
import { GetSubObjectByString } from "@utils/helpers";
import { useSelector } from "react-redux";

const RadioGroupMolecule = ({
  radios,
  controlName,
  control,
  storeCollection,
}: IRadioGroupMolecule) => {
  const options = useSelector((state: any) =>
    storeCollection
      ? GetSubObjectByString(state.filters.filters, storeCollection)
      : radios,
  );

  return (
    <>
      <ConnectForm>
        {({ getValues }: UseFormReturn<FieldValues, any>) => (
          <Controller
            name={controlName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <StyledRadioGroup
                style={{ flex: 1 }}
                value={value !== undefined ? value : ""}
                onChange={onChange}
              >
                {options &&
                  options.map((radio: IRadio, index: number) => {
                    return (
                      <StyledFormControlLabel
                        active={
                          Number(radio.id) === Number(getValues(controlName))
                            ? 1
                            : 0
                        }
                        key={index}
                        value={radio ? radio.id : ""}
                        label={radio.name}
                        control={<Radio />}
                      />
                    );
                  })}
              </StyledRadioGroup>
            )}
          />
        )}
      </ConnectForm>
    </>
  );
};

export default RadioGroupMolecule;
