import { StyledIconWrapper } from "./styled";
import { IMIcon } from "./interfaces";
import "light-icons/dist/light-icon.css";
import { Tooltip } from "@mui/material";

const MIcon = ({ icon, tooltip, on_click }: IMIcon) => {
  return (
    <StyledIconWrapper>
      {tooltip ? <Tooltip title={tooltip}>{icon}</Tooltip> : icon}
    </StyledIconWrapper>
  );
};

MIcon.defaultProps = {
  variant: "sunrise",
};

export default MIcon;
