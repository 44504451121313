import { CoreBaseModel } from "@core/models/Base.model";

export class MenuModel extends CoreBaseModel {
  children: Array<any>;
  icon: any;
  id: number;
  is_loggable: boolean;
  is_menu: boolean;
  name: string;
  parent_id: number;
  route: string;
  slug: string;
  sort: number;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "menu";
  }
}
