import { styled } from "@mui/system";
import { Box, Grid } from "@mui/material";

export const StyledAttributesBox = styled(Box)(() => ({
  marginBottom: "10px",
}));

export const StyledGeneralDetailBox = styled(Box)(() => ({}));

export const StyledGenearalContGrid = styled(Grid)(() => ({
  marginBottom: "20px",
}));
