import IOrganism from "./interface";
import { ActivityModel } from "@models/ActivityModel";
import ActivitiesService from "@services/ActivitiesService";
import ActivityAccordion from "./Accordion";
import PaginatedListMolecule from "@molecules/PaginatedList";
import { Box, Card } from "@mui/material";
import ButtonAtom from "@atoms/Button";
import { t, Trans } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import { Fragment, useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import InspectionActivitiesForm from "@organisms/InspectionCreate/Activities";
import { toast } from "react-toastify";
import { messageService } from "@helpers/messagesService";
import { useQuery } from "react-query";

const InspectionDetailActTabOrganism = ({
  inspection,
  activityId,
}: IOrganism) => {
  const [extensorVisible, setExtensorVisible] = useState<boolean>(false);
  const [filter] = useState<any>({
    inspection_id: inspection.id,
    activity_id: activityId,
  });
  const [refetchKey, setRefetchKey] = useState<number>(0);
  const [updatedActivity, setUpdatedActivity] = useState<ActivityModel | null>(
    null,
  );
  const [exceptDates, setExceptDates] = useState<any>([]);

  const {
    isLoading,
    data,
    refetch: refetchExceptDays,
  } = useQuery(
    `except-dates-for-${inspection.id}`,
    () => ActivitiesService.filters(inspection.id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setExceptDates(
        data.data.activities.map((activity: ActivityModel) => activity.date),
      );
    }
  }, [isLoading, data]);

  const getInView = () => {
    /*
        if(activityId) {
            var myElement = document.getElementById(`${activityId}`);
            if(myElement) {
                // @ts-ignore
                const offset = myElement.getBoundingClientRect().top - document.querySelector('.MuiDrawer-paperAnchorRight').getBoundingClientRect().top;
                // @ts-ignore
                document.querySelector('.MuiDrawer-paperAnchorRight').scrollTop = offset;
            }
        }
        */
  };

  const onActivitiesCreated = () => {
    toast(t`Activities created successfully`, { type: "success" });
    messageService.sendMessage("inspectiondetail/refetch");
    setExtensorVisible(false);
    setRefetchKey(refetchKey + 1);
    refetchExceptDays();
  };

  const handleDelete = () => {
    messageService.sendMessage("inspectiondetail/refetch");
    setRefetchKey(refetchKey + 1);
    refetchExceptDays();
  };

  const handleMod = (activity: ActivityModel) => {
    setUpdatedActivity(activity);
    refetchExceptDays();
  };

  return (
    <>
      <Box mb={1} className="text-center">
        <ButtonAtom
          onClick={() =>
            isLoading
              ? null
              : setExtensorVisible(extensorVisible ? false : true)
          }
          label={
            !extensorVisible ? (
              <Fragment>
                <AddIcon />
                &nbsp;<Trans>Add activity</Trans>
              </Fragment>
            ) : (
              <Fragment>
                <ClearIcon fontSize="small" />
                &nbsp;<Trans>Cancel</Trans>
              </Fragment>
            )
          }
        />
      </Box>
      {extensorVisible && (
        <Box mb={2}>
          <Card>
            <Box p={2}>
              <InspectionActivitiesForm
                isAdd={true}
                exceptDates={exceptDates}
                inspection={inspection}
                onNext={() => onActivitiesCreated()}
                onNextText={t`Create`}
              />
            </Box>
          </Card>
        </Box>
      )}
      <PaginatedListMolecule
        renderFunc={(activity: ActivityModel, index: number) => {
          return (
            <ActivityAccordion
              inspection={inspection}
              onSave={handleMod}
              hideReportStatus={inspection.isUnique() && !activity.isLast()}
              exceptDates={exceptDates}
              onDelete={handleDelete}
              isExpanded={activityId === activity.id}
              activityMinimal={activity}
            />
          );
        }}
        service={ActivitiesService}
        avoidRerender={true}
        listId={"inspection-detail-activities-list"}
        updated={updatedActivity}
        onLoad={refetchKey > 0 ? () => {} : getInView}
        scrollPagination={document.querySelector(".MuiDrawer-paperAnchorRight")}
        refetchKey={refetchKey}
        perPage={15}
        filter={filter}
      />
    </>
  );
};

export default InspectionDetailActTabOrganism;
