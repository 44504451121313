import { CoreVerbsService } from "./Verbs.service";
import { CoreServiceErrorModel } from "../models/ServiceError.model";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { CoreResponseModel } from "@core/models/Response.model";
import { AxiosRequestConfig } from "axios";
const BASE_ENDPOINT = process.env.REACT_APP_PUBLIC_API_ENDPOINT;
/**
 * CoreBaseService
 * please note that:
 * API Response is expected in the format:
 *
 * {
 *  data: [] | {} | string | number
 *  msg: string (a string containing a success message or a generic error message)
 *  error: {label, msg} (an object key-value for each warned field - ex. a form's field)
 * }
 *
 */
export class CoreBaseService {
  apiService: CoreVerbsService;
  T: any;
  path = "";
  tempModel: any;
  reactState: any;
  cacheEnabled = [];
  cacheData = [];
  configuration = {
    errors: {
      unhautorized: {
        // Clear auth data when 401 or 403 (UNAUTHORIZED or FORBIDDEN)
        clear: true,
        // Redirect to login when 401 or 403 (UNAUTHORIZED or FORBIDDEN)
        redirectToLogin: true,
      },
      // Callbacks
      "403": null,
    },
  };

  constructor(T: any, subSlug: any | null = "") {
    this.apiService = new CoreVerbsService();
    if (T === null) {
      this.T = null;
      this.path = subSlug;
    } else {
      if (T && T.getResourceName()) {
        this.T = T;
        if (!subSlug) {
          this.path = T.getResourceName();
        } else {
          this.path = subSlug;
        }
      }
    }
  }

  /**
   * Change temporary output model
   * ex: usersService->as(AnimalModel)->get();
   * @param {*} outputModel
   */
  as(outputModel: any) {
    this.tempModel = this.T;
    this.T = outputModel;
    return this;
  }

  /**
   * Sub
   * INFO: T should be NULL if you dont want to instantiate the response's data
   * otherwise the responso will be instantiate using the default model
   */
  sub(subSlug: string, T: any = null) {
    return new CoreBaseService(
      typeof T !== "undefined" ? T : this.T,
      `${this.path}/${subSlug}`,
    );
  }

  /**
   * A custom call with free path/route
   * @param {*} url
   * @param {*} T
   */
  customCall(url: string, T = null) {
    return new CoreBaseService(typeof T !== "undefined" ? T : this.T, url);
  }

  /**
   * Get action
   * @param {*} data
   */
  get(
    code?: string | number | undefined,
    query?: object,
    raw = false,
    deleteEmpty = false,
    configs?: AxiosRequestConfig,
    headers?: any
  ) {
    const getParams: { params: any } = { params: null };
    if (query) {
      getParams.params = query;
    }
    if (deleteEmpty) {
      Object.keys(getParams.params).forEach((key) => {
        if (!getParams.params[key]) {
          delete getParams.params[key];
        }
      });
    }
    return this.apiService
      .get(this.getGetterPath(code), getParams.params, undefined, configs, headers)
      .then((item: CoreResponseModel) => {
        if (raw) {
          return item;
        }
        let dataRes = item.data;
        if (this.T) {
          if (Array.isArray(dataRes)) {
            const list: Array<any> = [];
            for (const record of dataRes) {
              let model = null;
              if (this.T) {
                model = new this.T(record);
                this.instantiateSubObjects(this.T, model);
              }
              list.push(model);
            }
            item.data = list;
          } else {
            // If paginated
            if (Array.isArray(item.data.data)) {
              const list: Array<any> = [];
              for (const record of item.data.data) {
                let model = null;
                if (this.T) {
                  model = new this.T(record);
                  this.instantiateSubObjects(this.T, model);
                }
                list.push(model);
              }
              item.data.data = list;
            } else {
              item.data = new this.T(dataRes);
            }
            this.instantiateSubObjects(this.T, item.data);
          }
        }
        this.afterCall(item.getData());
        return item;
      })
      .catch(catchError(this.errorHandl));
  }

  /**
   * Patch action
   * @param {*} data
   * @param {*} code
   */
  update(data: any, code: number | string | -1, headers?: any) {
    return this.apiService
      .patch(this.getUpdatePath(data, code), data, BASE_ENDPOINT, headers)
      .then((item: CoreResponseModel) => {
        if (this.T) {
          item.data = new this.T(item.data);
          this.instantiateSubObjects(this.T, item.data);
        }
        this.afterCall(item.getData());
        return item;
      })
      .catch((e: any) => catchError(e));
  }

  /**
   * Delete action
   * @param {*} data
   */
  delete(id: string | number) {
    return this.apiService
      .delete(this.getDeletePath(id))
      .then((item: CoreResponseModel) => {
        return item;
      })
      .catch(catchError(this.errorHandl));
  }

  /**
   * Delete action
   * @param {*} data
   */
  deleteById(path: string, id: string | number) {
    return this.apiService
      .delete(path + id)
      .then((item: CoreResponseModel) => {
        return item;
      })
      .catch(catchError(this.errorHandl));
  }

  /**
   * Create action
   * @param {*} data
   */
  create(data: any, raw = false, headers?: any) {
    return this.apiService
      .post(this.getCreatePath(), data, undefined, headers)
      .then((item: CoreResponseModel) => {
        if (raw) {
          return item;
        }
        if (this.T) {
          if (Array.isArray(item.data)) {
            const list: Array<any> = [];
            for (const record of item.data) {
              let model = null;
              if (this.T) {
                model = new this.T(record);
                this.instantiateSubObjects(this.T, model);
              }
              list.push(model);
            }
            item.data = list;
          } else {
            item.data = new this.T(item.data);
            this.instantiateSubObjects(this.T, item.data);
          }
        }
        this.afterCall(item.getData());
        return item;
      })
      .catch(catchError(this.errorHandl));
  }

  /**
   * Put action
   * @param {*} data
   */
  put(data: any, code: number | string, raw = false) {
    return this.apiService
      .put(this.getUpdatePath(data, code), data)
      .then((item: CoreResponseModel) => {
        if (raw) {
          return item;
        }
        if (this.T) {
          if (Array.isArray(item.data)) {
            const list: Array<any> = [];
            for (const record of item.data) {
              let model = null;
              if (this.T) {
                model = new this.T(record);
                this.instantiateSubObjects(this.T, model);
              }
              list.push(model);
            }
            item.data = list;
          } else {
            item.data = new this.T(item.data);
            this.instantiateSubObjects(this.T, item.data);
          }
        }
        this.afterCall(item.getData());
        return item;
      })
      .catch(catchError(this.errorHandl));
  }

  /**
   * Instantiate response objects based on their models
   * @param {*} T
   * @param {*} modelInstance
   */
  instantiateSubObjects(T: any, modelInstance: any) {
    if (T.getSubTypesList) {
      if (T.getSubTypesList()) {
        T.getSubTypesList().forEach((item: any) => {
          if (modelInstance[item.object]) {
            if (Array.isArray(modelInstance[item.object])) {
              modelInstance[item.object].forEach(
                (z_item: any, z_index: any) => {
                  if (item.test) {
                    if (!item.test(modelInstance[item.object][z_index])) {
                      console.error(
                        `${this.T.getResourceName()}: failed test for response property "${
                          item.object
                        }" at index ${z_index}`,
                      );
                    }
                  }
                  modelInstance[item.object][z_index] = new item.model(z_item);
                  try {
                    this.instantiateSubObjects(
                      item.model,
                      modelInstance[item.object][z_index],
                    );
                  } catch (e) {
                    console.error(
                      "BaseService.instantiateSubObjects: possible redundancy. Ex: user<userModel>.data<userModel>",
                    );
                  }
                },
              );
            } else {
              if (item.test) {
                if (!item.test(modelInstance[item.object])) {
                  console.error(
                    `${this.T.getResourceName()}: failed test for response property "${
                      item.object
                    }"`,
                  );
                }
              }
              if (item.object) {
                modelInstance[item.object] = new item.model(
                  modelInstance[item.object],
                );
              }
              try {
                this.instantiateSubObjects(
                  item.model,
                  modelInstance[item.object],
                );
              } catch (e) {
                console.error(
                  "BaseService.instantiateSubObjects: possible redundancy. Ex: user<userModel>.data<userModel>",
                );
              }
            }
          }
        });
      }
    }
  }

  /**
   * Format create path url
   */
  getCreatePath() {
    return `${this.path}`;
  }

  /**
   * Format get path url
   */
  getGetterPath(code: string | number | undefined) {
    return `${this.path}${code ? "/" + code : ""}`;
  }

  /**
   * Format find path url (pagination)
   */
  getFindPath() {
    return `${this.path}`;
  }

  /**
   * Format update path url
   */
  getUpdatePath(data: any, code: any | null = null) {
    return `${this.path}${code === -1 ? "" : code ? "/" + code : data.id}`;
  }

  /**
   * Format delete path url
   */
  getDeletePath(id: any) {
    return `${this.path}/${id}`;
  }

  /**
   * Error handler
   * todo da finire
   * @param {*} error
   */
  errorHandl = (error: any) => {
    // todo qui  bisogna standardizzare: vd. error.error
    this.afterCall();
    console.log(error.response.data);
    console.log(error.error.response.data);
    const errorData: any = {};
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorData.message = error.error.message;
    } else {
      // Get server-side error
      console.error(error);
      // UNAUTHORIZED or FORBIDDEN ?
      if (error.status === 401 /*|| error.status === 403*/) {
        // ? clear SESSION?
      }
    }
    // todo qui  bisogna standardizzare: vd. error.error
    return throwError(
      new CoreServiceErrorModel(
        error.message,
        error.error ? error.error.errors : error.errors,
        error.status,
        error.headers,
        error.error.data,
      ),
    );
  };

  /**
   * After call
   * It is performed at each end of the call
   */
  afterCall(data?: any) {
    // Back to default model
    if (this.tempModel) {
      this.T = this.tempModel;
      this.tempModel = null;
    }
    if (this.reactState) {
      this.reactState(data);
    }
  }
}
