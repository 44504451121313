import { styled } from "@mui/system";
import { COLORS } from "@theme/Colors";

interface ILogoProps {}

export const StyledIconWrapper = styled("div")((props: ILogoProps) => ({
  background: COLORS.blue.primary,
  display: "inline-flex",
  borderRadius: "20px",
  margin: "2px",
  fontSize: "12px",
  padding: "6px",
  width: "fit-content",
  "& svg": {
    fill: "white",
  },
  // marginBottom: ({ theme }: ILogoProps) => theme.spacing(3),
}));
