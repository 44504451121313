import * as React from "react";
import { Box, Button, Typography } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import IInspectionActivityCreateOrganism, { Steps } from "./interface";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinkJobForm from "./LinkJob";
import InspectionDataForm from "./Inspection";
import InspectionActivitiesForm from "./Activities";
import InspectionRatesForm from "./Rates";
import { InspectionModel } from "@models/InspectionModel";
import RadioGroupMolecule from "@molecules/RadioGroup";
import { FormProvider, useForm } from "react-hook-form";
import InspectionNotesForm from "./Notes";
import { create } from "lodash";

const InspectionCreateOrganism = ({
  mode,
  job,
  onNewInspection,
}: IInspectionActivityCreateOrganism) => {
  const [jobId, setJobId] = React.useState(job ? job.id : 0);
  const [createdInspection, setInspection] = React.useState<
    InspectionModel | undefined
  >(undefined);
  const [success /*, setSuccess*/] = React.useState<boolean>(false);
  const [allSteps, setAllSteps] = React.useState<Array<string>>([...Steps]);
  const [activeStep, setActiveStep] = React.useState(0);
  const firstUpdate = React.useRef(true);

  const formInstance = useForm<any>({
    mode: "onChange",
    defaultValues: {
      job_type: 1,
    },
    reValidateMode: "onSubmit",
  });

  React.useEffect(() => {
    if (mode === "fast") {
      allSteps.shift();
    }
    setAllSteps([...allSteps]);
  }, []);

  const { watch } = formInstance;
  const jobType = Number(watch("job_type"));

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setJobId(0);
  }, [jobType]);

  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return allSteps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? allSteps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    if (onNewInspection && createdInspection) {
      onNewInspection(createdInspection);
    }
    // handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: "100%" }}>
      {success ? (
        <React.Fragment></React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {allSteps.map((label, index) => (
                <Step key={label} completed={completed[index]}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - inspection created
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                }}
              >
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <Box mt={3}>
              <Box>
                {mode !== "fast" && (
                  <Box
                    sx={{
                      display: activeStep === 0 ? "block" : "none",
                    }}
                  >
                    <form noValidate>
                      <FormProvider {...formInstance}>
                        <RadioGroupMolecule
                          controlName={"job_type"}
                          control={formInstance.control}
                          radios={[
                            {
                              name: t`Link to existing job`,
                              id: 1,
                            },
                            {
                              name: t`Create new job`,
                              id: 2,
                            },
                          ]}
                        />
                      </FormProvider>
                    </form>
                    <LinkJobForm
                      onNext={handleNext}
                      selectedJob={jobId}
                      inspection={createdInspection}
                      onSelectedJobChange={(jobId: number | 0) =>
                        !job ? setJobId(jobId) : null
                      }
                      action={jobType === 1 ? "existing" : "new"}
                    />
                  </Box>
                )}
                <Box
                  sx={{
                    display:
                      activeStep === (mode === "fast" ? 0 : 1) && jobId
                        ? "block"
                        : "none",
                  }}
                >
                  <InspectionDataForm
                    mode={mode}
                    onInspection={(inspection: InspectionModel) =>
                      setInspection(inspection)
                    }
                    onNext={handleNext}
                    onBack={handleBack}
                    jobId={jobId}
                  />
                </Box>
                <Box
                  sx={{
                    display:
                      activeStep === (mode === "fast" ? 1 : 2) && jobId
                        ? "block"
                        : "none",
                  }}
                >
                  { createdInspection && <InspectionNotesForm
                    inspection={createdInspection}
                    onNext={handleNext}
                    onBack={handleBack}
                  /> }
                </Box>
                <Box
                  sx={{
                    display:
                      activeStep === (mode === "fast" ? 2 : 3) &&
                      createdInspection
                        ? "block"
                        : "none",
                  }}
                >
                  <InspectionActivitiesForm
                    inspection={
                      createdInspection
                        ? createdInspection
                        : new InspectionModel({})
                    }
                    onNext={handleNext}
                    onBack={handleBack}
                  />
                </Box>
                <Box
                  sx={{
                    display:
                      activeStep === (mode === "fast" ? 3 : 4) &&
                      createdInspection
                        ? "block"
                        : "none",
                  }}
                >
                  <InspectionRatesForm
                    inspection={
                      createdInspection
                        ? createdInspection
                        : new InspectionModel({})
                    }
                    onNext={handleComplete}
                    onBack={handleBack}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {activeStep !== allSteps.length &&
              (completed[activeStep] ? (
                <Typography variant="caption" sx={{ display: "inline-block" }}>
                  Step {activeStep + 1} already completed
                </Typography>
              ) : (
                <Box>
                  {completedSteps() === totalSteps() - 1 && (
                    <React.Fragment>
                      <Button onClick={handleComplete}>
                        <Trans>Complete and create</Trans>
                      </Button>
                    </React.Fragment>
                  )}
                </Box>
              ))}
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default InspectionCreateOrganism;
