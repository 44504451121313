import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const StyledIconsHolder = styled(Box)(() => ({
  display: "flex",
  width: "fit-content",
  marginLeft: "5px",
  gap: "5px",
  alignItems: "centers",
  background: "white",
  border: "1px solid #DDDDDD",
  borderRadius: "5px",
}));
