import { StyledButton } from "./styled";
import IButton from "./interfaces";
import SpinnerAtom from "@atoms/Spinner";

const ButtonAtom = ({
  primary = false,
  size = "medium",
  backgroundColor,
  label,
  color,
  loading,
  disabled,
  fullWidth,
  onClick,
  sx,
  className,
  variant,
  type,
  ...props
}: IButton) => {
  return (
    <StyledButton
      disabled={loading ? true : disabled}
      color={color}
      className={className}
      sx={sx}
      type={type}
      fullWidth={fullWidth}
      onClick={loading ? () => {} : onClick ? onClick : () => {}}
      {...props}
      variant={variant ? variant : "contained"}
    >
      {loading ? <SpinnerAtom /> : <>{label}</>}
    </StyledButton>
  );
};

export default ButtonAtom;
