import { memo, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { /*useDevice,*/ useUser } from "@hooks";
import { ToastContainer } from "react-toastify";
import PrivateMiddleLayer from "@layouts/private-middlelayer";
import Pusher from "pusher-js";
import ChatBubblesOrganism from "@organisms/ChatBubbles";
import { messageService } from "@helpers/messagesService";
// Chat system
import {
  addMessage,
  deleteMessage,
  editMessage,
} from "@stores/reducers/chatReducer";
import { useAppDispatch } from "@stores/hooks";
import {
  setPusherInstance,
  setPresenceChannel,
  setNotificationChannel,
  setServiceWorkerRegistration,
} from "@stores/reducers/appReducer";
import VideoChatBubblesOrganism from "@organisms/VideoChatBubbles";
import MediaHandler from "@organisms/VideoChat/MediaHandler";

const PrivateRoutes = () => {
  const user = useUser();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const APP_ENV = process.env.REACT_APP_PUSHER_ENV;
  const APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY
    ? process.env.REACT_APP_PUSHER_APP_KEY
    : "";

  useEffect(() => {
    dispatch(setPusherInstance("Pusher instance here!"));

    // app key
    if (!user.user) {
      return;
    }

    Pusher.logToConsole = true;
    // @ts-ignore
    const tokenAuth = JSON.parse(localStorage.getItem("user")).token;

    const pusher = new Pusher(APP_KEY, {
      auth: {
        headers: {
          Authorization: `Bearer ${tokenAuth}`,
        },
      },
      cluster: "eu",
      forceTLS: true,
      // @ts-ignore
      encrypted: true,
      // @ts-ignore
      authEndpoint: `${process.env.REACT_APP_PUBLIC_API_ENDPOINT}pusher/auth`,
    });

    const channel = pusher.subscribe(
      "private-notifications-" + APP_ENV + "." + user.user.id,
    );
    channel
      .bind("new-notification", (data: any) => {
        console.log(data, "socket");
        messageService.sendMessage(data);
        if (data.type === "chat_message") {
          const message = {
            activity_id: data.msg.activity_id,
            sender_id: data.msg.sender_id,
            sender_name: data.msg.sender_name,
            message: data.msg,
          };
          if (data.msg.action === "add") {
            dispatch(addMessage(JSON.parse(JSON.stringify(message))));
          } else if (data.msg.action === "delete") {
            dispatch(deleteMessage(JSON.parse(JSON.stringify(message))));
          } else if (data.msg.action === "edit") {
            dispatch(editMessage(JSON.parse(JSON.stringify(message))));
          }
        }
      })
      .bind("pusher:subscription_succeeded", function (data: any) {
        console.log(data, "subscription_succeeded");
      })
      .bind("pusher:subscription_count", function (data: any) {
        console.log(data, "subscription_count");
      })
      .bind("pusher:subscription_error", function (data: any) {
        console.log(data, "subscription_error");
      });
    // Presence channel
    const presenceChannel = pusher.subscribe("presence-video-channel");
    dispatch(setPusherInstance(pusher));
    // ai (admin/inspector) Notifications channel
    const notificationChannel = pusher.subscribe(
      "private-ai-notifications-" + APP_ENV,
    );
    dispatch(setNotificationChannel(notificationChannel));
    dispatch(setPresenceChannel(presenceChannel));
  }, []);

  // Mem serviceWorkerRegistration object
  window.navigator.serviceWorker.ready.then(
    (serviceWorkerRegistration: ServiceWorkerRegistration) => {
      dispatch(setServiceWorkerRegistration(serviceWorkerRegistration));
    },
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices()
    .then(devices => {
        const microphones = devices.filter(d => d.kind === 'audioinput');
        const cameras = devices.filter(d => d.kind === 'videoinput');
        if(microphones.length >= 1 && cameras.length >= 1) {
          return;
        } else {
          // Ask audio-video permissions
          new MediaHandler().getPermissions().then((stream) => {});
        }
    });
  }, []);


  return user?.hasToken() ? (
    <PrivateMiddleLayer>
      <ToastContainer position="bottom-right" />
      <ChatBubblesOrganism />
      <VideoChatBubblesOrganism />
      <Outlet />
    </PrivateMiddleLayer>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default memo(PrivateRoutes);
