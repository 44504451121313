import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import IPeopleQualificationsOrganism from "./interface";
import AttributesService from "@services/AttributesService";
import { useQuery } from "react-query";
import { AttributeModel } from "@models/AttributeModel";
import SpinnerAtom from "@atoms/Spinner";
import List from "./list";
import Group from "./Group";
import { PeopleModel } from "@models/PeopleModel";
import { AssetsModel } from "@models/AssetsModel";
import { InspectionModel } from "@models/InspectionModel";

const AttributesOrganism = ({
  model,
  model_type,
  editModeIndex,
}: IPeopleQualificationsOrganism) => {
  const [attributes, setAttributes] = useState<any>([]);
  const [modelData, setPersonData] = useState<
    PeopleModel | undefined | AssetsModel | InspectionModel
  >(undefined);

  useEffect(() => {
    setPersonData(model);
  }, [model]);

  const { data, isLoading } = useQuery(
    `attributes-${model_type}`,
    () => AttributesService.get("", { model_type }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  /**
   * On Save update the interface
   * @param newGroupData
   */
  const onGroupModified = (newGroupData: AttributeModel) => {
    if (modelData) {
      modelData.attributes = newGroupData.attributes;
      setPersonData(new PeopleModel(modelData));
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      if (model) {
        // @ts-ignore
        setAttributes(data.data);
      }
    }
  }, [data, isLoading, model]);

  return (
    <>
      {!isLoading && modelData && attributes ? (
        attributes.map((group: any, groupIndex: number) => {
          if (group.attributes.length) {
            return (
              <List
                key={groupIndex}
                group={group}
                attributes={attributes}
                onGroupModified={onGroupModified}
                groupIndex={groupIndex}
                modelData={modelData}
                model_type={model_type}
              />
            );
          } else if (group.children.length) {
            return (
              <Group
                key={groupIndex}
                defaultToMod={
                  editModeIndex !== undefined
                    ? groupIndex === editModeIndex
                      ? true
                      : false
                    : false
                }
                attributes={attributes}
                onGroupModifiedCb={onGroupModified}
                group={group}
                model_type={model_type}
                modelData={modelData}
              ></Group>
            );
          } else {
            return <></>;
          }
        })
      ) : (
        <Box>
          <SpinnerAtom></SpinnerAtom>
        </Box>
      )}
    </>
  );
};

export default AttributesOrganism;
