import * as React from "react";
import { Box, Alert, Grid } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ButtonAtom from "@atoms/Button";
import { useSelector } from "react-redux";
import HCMCreateFormOrganism from "@organisms/HCMCreate";
import { JobModel } from "@models/JobModel";
import ClienSupplierRatesForm from "@organisms/HCMDetail/Activities/ActivityRow/Rates/Form";
import DataBalloonAtom from "@atoms/DataBalloon";
import HelperErrorMolecule from "@molecules/HelperError";
import RadioGroupMoleculeA from "@molecules/RadioGroup";
import { FormProvider, useForm } from "react-hook-form";
import { IType } from "@interfaces/IType";
import HCMService from "@services/HCMService";
import { toast } from "react-toastify";

const steps = [t`HCM`, t`Rates`];

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode
 * @returns
 */
const HCMCreateWizardOrganism = ({
  mode,
  onFinalize,
  onNewJob,
  onCancel,
  onSave,
}: any) => {
  const [sErrors /*, setSErrors*/] = React.useState<any | null>({});
  const [globalLoading /*, setGlobaLoading*/] = React.useState<boolean>(false);
  const [msg /*, setMsg*/] = React.useState("");
  const [success /*, setSuccess*/] = React.useState<boolean | null>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [clientData, setClientData] = React.useState<any>({});
  const [supplierData, setSupplierData] = React.useState<any>({});
  const ratesTypesList = useSelector(
    (state: any) => state.filters.filters.types?.rates,
  );
  // const autoSave = useSelector((state: any) => state.saver.saveAll);
  const [jobCreated, setJobCreated] = React.useState<any | null>(null);
  const [errors /*, setErrors*/] = React.useState<any>({});

  const formInstance = useForm<any>({
    mode: "onChange",
    defaultValues: {
      type_id: ratesTypesList ? ratesTypesList[0].id : "",
    },
    reValidateMode: "onSubmit",
  });

  const { watch } = formInstance;

  const typeId = Number(watch("type_id"));

  const handleOnCreated = (job: JobModel) => {
    if (onNewJob) {
      onNewJob(job);
    }
    setJobCreated(job);
    setActiveStep(activeStep + 1);
  };

  const handleFinalize = () => {
    HCMService.setRates(jobCreated.id, [clientData, supplierData])
      .then((res: any) => {
        console.log(res);
        if (!res.hasErrors()) {
          toast(res.getMsgString(), { type: "success" });
        } else {
          toast(res.getMsgString(), { type: "error" });
        }
        if (onFinalize) {
          onFinalize();
        }
      })
      .catch((e: any) => {});
  };

  const clientChanged = (data: any) => {
    const inData: any = {};
    inData.id = data.id;
    inData.info = JSON.stringify({
      days: data.days,
      hours: data.hours,
      rate: data.rate,
      allowance_quantity: data.allowance_quantity,
      allowance: data.allowance,
      extra: data.extra,
      is_calculated: data.is_calculated,
      time_unit: data.time_unit,
    });
    inData.value = data.value;
    inData.category_id = data.category_id;
    inData.type_id = data.type_id;
    inData.model_type = "hcms";
    setClientData({ ...clientData, ...inData });
  };

  const supplierChanged = (data: any) => {
    const inData: any = {};
    inData.id = data.id;
    inData.info = JSON.stringify({
      days: data.days,
      hours: data.hours,
      rate: data.rate,
      allowance_quantity: data.allowance_quantity,
      allowance: data.allowance,
      extra: data.extra,
      is_calculated: data.is_calculated,
      time_unit: data.time_unit,
    });
    inData.value = data.value;
    inData.category_id = data.category_id;
    inData.type_id = data.type_id;
    inData.model_type = "hcms";
    setSupplierData({ ...supplierData, ...inData });
  };

  return (
    <Box sx={{ width: "100%" }}>
      {success ? (
        <React.Fragment>
          {msg ? (
            <Box mt={2}>
              <Alert severity="success">{msg}</Alert>
            </Box>
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Box mt={4}>
            {activeStep === 0 && (
              <HCMCreateFormOrganism onSave={handleOnCreated} />
            )}
            {activeStep === 1 && jobCreated && ratesTypesList && (
              <Box mt={3}>
                <Box mb={2}>
                  <Grid container spacing={1}>
                    <Grid item md={7} xs={12}>
                      <form noValidate>
                        <FormProvider {...formInstance}>
                          <RadioGroupMoleculeA
                            control={formInstance.control}
                            controlName="type_id"
                            radios={
                              ratesTypesList
                                ? ratesTypesList.map((type: IType) => {
                                    return {
                                      name: (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          {type.name}
                                          &nbsp;
                                          {((type.name.toLowerCase() ===
                                            "client" &&
                                            errors.clientErrors) ||
                                            (type.name.toLowerCase() ===
                                              "supplier" &&
                                              errors.supplierErrors)) && (
                                            <DataBalloonAtom
                                              size={"small"}
                                              severity="error"
                                              data={
                                                type.name.toLowerCase() ===
                                                  "client" &&
                                                errors.clientErrors
                                                  ? Object.keys(
                                                      errors.clientErrors,
                                                    ).length
                                                  : type.name.toLowerCase() ===
                                                      "supplier" &&
                                                    errors.supplierErrors
                                                  ? Object.keys(
                                                      errors.supplierErrors,
                                                    ).length
                                                  : 0
                                              }
                                            />
                                          )}
                                        </div>
                                      ),
                                      id: Number(type.id),
                                    };
                                  })
                                : null
                            }
                          />
                          <HelperErrorMolecule message={sErrors["type_id"]} />
                        </FormProvider>
                      </form>
                    </Grid>
                  </Grid>
                </Box>
                <React.Fragment>
                  {
                    <Box
                      sx={{
                        display:
                          typeId === ratesTypesList[0].id ? "block" : "none",
                      }}
                    >
                      <ClienSupplierRatesForm
                        onChange={clientChanged}
                        model={jobCreated}
                        typeId={ratesTypesList[0].id}
                      />
                    </Box>
                  }
                  {
                    <Box
                      sx={{
                        display:
                          typeId === ratesTypesList[1].id ? "block" : "none",
                      }}
                    >
                      <ClienSupplierRatesForm
                        onChange={supplierChanged}
                        model={jobCreated}
                        typeId={ratesTypesList[1].id}
                      />
                    </Box>
                  }
                </React.Fragment>
              </Box>
            )}
          </Box>
          <Box mt={2}>
            {sErrors && msg ? <Alert severity="error">{msg}</Alert> : null}
            <Box mt={2} sx={{ textAlign: "right" }}>
              {onCancel ? (
                <ButtonAtom
                  sx={{ marginRight: "5px" }}
                  color={"error"}
                  onClick={onCancel}
                  disabled={true}
                  label={<Trans>Cancel</Trans>}
                />
              ) : null}
              {(activeStep === 1 || mode === "patch") && (
                <ButtonAtom
                  label={<Trans>Save and close</Trans>}
                  loading={globalLoading}
                  variant="contained"
                  onClick={handleFinalize}
                  disabled={false}
                />
              )}
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default HCMCreateWizardOrganism;
