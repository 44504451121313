import { useState, Fragment, useEffect } from "react";
import { Box, Button, Typography, Drawer } from "@mui/material";
import { Trans } from "@lingui/macro";
import ContainerAtom from "@atoms/Container";
import LayoutBase from "@layouts/base";
import { PageHeader } from "./styled";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@molecules/Modal";
import NewInspectionActivityOrganism from "@organisms/InspectionCreate";
import LayoutModal from "@layouts/modal";
import PaginatedListMolecule from "@molecules/PaginatedList";
import { InspectionModel } from "@models/InspectionModel";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import InspectionActivitiesFilterOrganism from "@organisms/InspectionActivitiesFilter";
import { toast } from "react-toastify";
import DrawerLayout from "@layouts/drawer";
import { useConfirm } from "material-ui-confirm";
import { useUser } from "@hooks";
import { useDispatch } from "react-redux";
import { set } from "@stores/reducers/titleReducer";
import ActivityChatOrganism from "@organisms/ActivityChat";
import { closeChat } from "@stores/reducers/chatReducer";
import { useSearchParams } from "react-router-dom";
import { startChat } from "@stores/reducers/chatReducer";
import VideoCallOrganism from "@organisms/VideoCall";
import { useSelector } from "react-redux";
import InspectionDetailOrganism from "@organisms/InspectionDetail";
import moment from "moment";
import InspectionActivityRowMolecule from "@molecules/InspectionActivityRow";
import ActivitiesService from "@services/ActivitiesService";
import { ActivityModel } from "@models/ActivityModel";
import { messageService } from "@utils/messagesService";
import DrawerMolecule from "@molecules/Drawer";
import { updateForm } from "@stores/reducers/saverReducer";

const ActivitiesPage = () => {
  const presenceChannel = useSelector(
    (state: any) => state.app.presenceChannel,
  );
  const [filter, setFilter] = useState<Object | undefined>({
    date_range: "all",
  });
  const [refetchKey, setRefetchKey] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [videoCallFullscreen, setVideoCallFullscreen] = useState(false);
  const [videoCall, setVideoCall] = useState<any | undefined>(undefined);
  const [chat, setChat] = useState<any>(null);
  const [drawerDetailID, setDrawerDetailID] = useState<any>({});
  const [activityDetailId, setActivityDetailID] = useState<any>(null);
  const [state, setState] = useState({ right: false });
  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [newRow, setNewRows] = useState<
    ActivityModel | undefined | Array<ActivityModel>
  >(undefined);
  const [updatedItem, setUpdated] = useState<InspectionModel | undefined>(
    undefined,
  );
  const [toDelete, setToDeleteFromList] = useState<number | undefined>(
    undefined,
  );
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const user = useUser();
  const [searchParams /*, setSearchParams*/] = useSearchParams();

  useEffect(() => {
    dispatch(set("Activities"));
  }, [dispatch]);

  useEffect(() => {
    if (searchParams.get("chat")) {
      dispatch(startChat({ activity_id: searchParams.get("chat") }));
    }
    if (searchParams.get("insp")) {
      setState({ ...state, right: true });
      setDrawerDetailID(searchParams.get("insp"));
    }
  }, [searchParams]);

  useEffect(() => {
    const subS = messageService.getMessage().subscribe((message) => {
      if (message && message.text === "refetch-act-totals") {
        setRefetchKey(Math.random() * 100);
      }
    });
    return () => {
      subS.unsubscribe();
    };
  }, []);

  const toggleDrawer =
    (anchor: any, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      if (chat) {
        setChat(null);
        // @ts-ignore
        dispatch(closeChat({ activity_id: selectedInspection.id }));
      }
      setState({ ...state, [anchor]: open });
    };

  const itemUpdated = (inspection: InspectionModel | undefined) => {
    setUpdated(inspection);
    messageService.sendMessage("refetch-act-totals");
  };

  const onNewInspection = (inspection: InspectionModel) => {
    dispatch(updateForm({
      slug: `inspection-detail`,
      dirty: false,
      saved: true,
    }));
    dispatch(updateForm({
      slug: `inspection-detail-rates`,
      dirty: false,
      saved: true,
    }));
    ActivitiesService.get("", { inspection_id: inspection.id }).then(
      (res: any) => {
        setNewRows(res.getData());
      },
    );
    setModalOpen(false);
  };

  const handleFilterChange = (values: any) => {
    if (values.date_start) {
      values.date_start = moment(values.date_start).format("yyyy-MM-DD");
    }
    if (values.date_end) {
      values.date_end = moment(values.date_end).format("yyyy-MM-DD");
    }
    setFilter(values);
  };

  const handleActionSelect = (param: IMBOption) => {
    if (param.label === "Open chat") {
      setChat({}); // TODO call dispatch(startChat()) and remove this action
      setSelectedActivity(param.data.activity);
      setState({ ...state, right: true });
    } else {
      if (chat) {
        setChat(null);
      }
    }
    if (param.label === "Video call") {
      setVideoCall(param.data);
      setState({ ...state, right: true });
      setSelectedActivity(null);
    } else if (param.label === "Details") {
      setDrawerDetailID(param.data.inspectionId);
      setActivityDetailID(param.data.activityId);
      setState({ ...state, right: true });
    } else if (param.label === "Copy") {
      ActivitiesService.copyActivity(param.data.activityId).then((res: any) => {
        if (!res.hasErrors()) {
          setNewRows(res.getData());
          toast(res.getMsgString(), {
            type: "success",
          });
        } else {
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      });
    } else if (param.label === "Delete") {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        ActivitiesService.delete(param.data.activityId).then((res: any) => {
          if (!res.hasErrors()) {
            setToDeleteFromList(param.data.activityId);
          }
          toast(res.getMsgString(), {
            type: res.hasErrors() ? "error" : "success",
          });
        });
      });
    }
  };

  const dirtyNotSavedForms = useSelector((state: any) =>
    state.saver.forms.filter((form: any) => form.dirty && !form.saved),
  );

  const closeVideChatDrawer = () => {
    presenceChannel.trigger(
      `client-signal-${videoCall.activity?.getUser().id}`,
      {
        type: "message",
        action: "modal-closed",
      },
    );
    toggleDrawer("right", false);
    setVideoCall(null);
    setState({ right: false });
  };

  useEffect(() => {
    if (presenceChannel) {
      presenceChannel.bind(`client-signal-${user.user.id}`, (signal: any) => {
        if (signal.type === "message") {
          if (signal.action === "modal-closed") {
            setState({ right: false });
          }
        }
      });
    }
  }, [presenceChannel]);

  const ispectionCopied = (inspection: InspectionModel) => {
    ActivitiesService.get("", { inspection_id: inspection.id }).then(
      (res: any) => {
        setNewRows(res.getData());
      },
    );
  };

  const handleCloseDetailDrawer = () => {
    setState({ right: false });
  };

  return (
    <>
      <Fragment key={"right"}>
        <DrawerMolecule
          open={state.right && !chat && !videoCall}
          onClose={handleCloseDetailDrawer}
        >
          <InspectionDetailOrganism
            onDelete={() => {
              setState({ right: false });
              setRefetchKey(refetchKey + 1);
            }}
            onCopy={ispectionCopied}
            onUpdate={itemUpdated}
            activityId={activityDetailId}
            id={drawerDetailID}
          />
        </DrawerMolecule>
        <Drawer
          anchor={"right"}
          open={state.right && chat && selectedActivity ? true : false}
          onClose={() => setState({ right: false })}
        >
          <DrawerLayout onCloseClick={() => setState({ right: false })}>
            <Box>
              <Box>
                <Trans>Inspector</Trans>:{" "}
                {selectedActivity?.inspection?.user.full_name}
              </Box>
            </Box>
            <ActivityChatOrganism activity={selectedActivity} />
          </DrawerLayout>
        </Drawer>
        <Modal
          fullscreen={videoCallFullscreen}
          open={state.right && videoCall && !selectedActivity ? true : false}
          set_open={closeVideChatDrawer}
        >
          <LayoutModal
            title={
              <Box>
                Call {videoCall ? videoCall.activity?.getUser().full_name : ""}
              </Box>
            }
          >
            {videoCall && (
              <Box>
                <VideoCallOrganism
                  onHangUp={closeVideChatDrawer}
                  isFullScreen={videoCallFullscreen}
                  onRequestFullscreen={() =>
                    setVideoCallFullscreen(videoCallFullscreen ? false : true)
                  }
                  activity={videoCall.activity}
                />
              </Box>
            )}
          </LayoutModal>
        </Modal>
      </Fragment>
      <LayoutBase>
        {!user.isInspector() ? (
          <Fragment>
            <ContainerAtom>
              <PageHeader>
                <Typography variant="h1">
                  <Trans>Inspection activities</Trans>
                </Typography>
                <Button onClick={() => setModalOpen(true)} variant="outlined">
                  <AddIcon></AddIcon> <Trans>New inspection</Trans>
                </Button>
              </PageHeader>
              <Modal
                open={modalOpen}
                class_name="modal-lg"
                set_open={(bool: boolean) => {
                  dispatch(updateForm({
                    slug: `inspection-detail`,
                    dirty: false,
                    saved: true,
                  }));
                  dispatch(updateForm({
                    slug: `inspection-detail-rates`,
                    dirty: false,
                    saved: true,
                  }));
                  setModalOpen(bool)
                }}
              >
                <LayoutModal title={<Trans>Create new inspection</Trans>}>
                  <NewInspectionActivityOrganism
                    onNewInspection={onNewInspection}
                  ></NewInspectionActivityOrganism>
                </LayoutModal>
              </Modal>
              <Box mb={2}>
                <InspectionActivitiesFilterOrganism
                  onFormChange={handleFilterChange}
                ></InspectionActivitiesFilterOrganism>
              </Box>
              <Box mt={1}>
                {filter ? (
                  <PaginatedListMolecule
                    idToDelete={toDelete}
                    scrollPagination={true}
                    refetchKey={refetchKey}
                    updated={updatedItem}
                    renderFunc={(item: ActivityModel) => {
                      return (
                        <InspectionActivityRowMolecule
                          activity={item}
                          onActionSelected={handleActionSelect}
                        />
                      );
                    }}
                    service={ActivitiesService}
                    perPage={15}
                    newRow={newRow}
                    filter={filter}
                  />
                ) : (
                  <></>
                )}
              </Box>
            </ContainerAtom>
          </Fragment>
        ) : (
          <Box mt={1}>
            <PaginatedListMolecule
              refetchKey={refetchKey}
              idToDelete={toDelete}
              updated={updatedItem}
              renderFunc={(item: ActivityModel) => {
                return (
                  <InspectionActivityRowMolecule
                    activity={item}
                    onActionSelected={handleActionSelect}
                  />
                );
              }}
              service={ActivitiesService}
              perPage={15}
              newRow={newRow}
              filter={filter}
            />
          </Box>
        )}
      </LayoutBase>
    </>
  );
};

export default ActivitiesPage;
