import { Fragment } from "react";
import {
  StyledChatBubble,
  StyledChatBubbleContainer,
  StyledCloseButton,
} from "./styled";
import { IconButton } from "@mui/material";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import Badge from "@mui/material/Badge";
// import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "@stores/hooks";
import { destroyChat } from "@stores/reducers/chatReducer";
import { useConfirm } from "material-ui-confirm";

const ChatBubble = ({ chat, index, onChatSelect }: any) => {
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const handleCloseChat = () => {
    if (chat) {
      confirm({
        description: "Are you sure you want to close this chat?",
      }).then(() => {
        dispatch(destroyChat({ activity_id: chat.activity_id }));
      });
    }
  };

  return (
    <Fragment>
      <StyledChatBubbleContainer>
        <StyledChatBubble className={`dragHandle-${index}`}>
          <StyledCloseButton onClick={handleCloseChat}>
            <CloseIcon />
          </StyledCloseButton>
          <IconButton onClick={() => onChatSelect(chat)}>
            <Badge badgeContent={chat.toRead} color="warning">
              <MarkChatUnreadIcon color="action" />
            </Badge>
          </IconButton>
        </StyledChatBubble>
        {/*
                    <Draggable 
                        
                        position={{ x: positionX, y: positionY }}
                        onDrag={(_e: DraggableEvent, data: DraggableData) => {
                            if(data.deltaX || data.deltaY) {
                                setWasDragging(true);
                            }
                        }}
                        onStart={(_e: DraggableEvent, data: DraggableData) => {
                            setLastPositionX(data.x);
                            setLastPositionY(data.y);
                        }}
                        onStop={(e: DraggableEvent, data: DraggableData) => {
                            e.stopPropagation();
                            if (data.x === lastPositionX && data.y === lastPositionY) {
                                // drag did not change anything. Consider this to be a click
                                // onChatSelect(chat);
                            }
                            setPositionX(data.x);
                            setPositionY(data.y);
                            if(!wasDragging) {
                                onChatSelect(chat);
                            }
                            setWasDragging(false);
                        }}>
                         <StyledChatBubble className={`dragHandle-${index}`}>
                            <StyledCloseButton onClick={(e: any) => { e.stopPropagation(); handleCloseChat() }}>
                                <CloseIcon />
                            </StyledCloseButton>
                            <IconButton>
                                <Badge badgeContent={chat.toRead} color="warning"><MarkChatUnreadIcon color="action" /></Badge>
                            </IconButton>
                     </StyledChatBubble>
                     </Draggable>
                */}
      </StyledChatBubbleContainer>
    </Fragment>
  );
};

export default ChatBubble;
