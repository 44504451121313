import { memo, Fragment } from "react";
import { Trans, t } from "@lingui/macro";
import type { ISelectedFiltersOrganismProps } from "./interface";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ChipAtom from "@atoms/Chip";

const SelectedFilterMolecule = ({
  items,
  handleDelete,
}: ISelectedFiltersOrganismProps) => {
  const changedItems = items.filter((item) => item.isChanged);
  const isFilterChanged = !!changedItems.length;
  const changedItemsCount = changedItems.length;

  return (
    <>
      <Stack direction="row" spacing={3} alignItems="center">
        <Typography>
          {isFilterChanged ? (
            <>
              <Trans>Filters applied</Trans> ({changedItemsCount})
            </>
          ) : (
            <Trans>No filters applied.</Trans>
          )}
        </Typography>
        <ChipAtom
          sx={{
            visibility: isFilterChanged ? "visible" : "hidden",
            pointerEvents: isFilterChanged ? "all" : "none",
          }}
          color="error"
          label={t`Remove all`}
          onDelete={() => handleDelete("all")}
        />
      </Stack>
      {changedItems.map(({ value, label, isChanged }) => (
        <ChipAtom
          key={value}
          label={label}
          onDelete={() => handleDelete(value)}
        />
      ))}
    </>
  );
};

export default memo(SelectedFilterMolecule);
