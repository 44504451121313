import { CoreBaseModel } from "@core/models/Base.model";
import { ICategory } from "@interfaces/ICategory";
import { IType } from "@interfaces/IType";
import { ActivityModel } from "./ActivityModel";
import { UserModel } from "./UserModel";

export class HCMModel extends CoreBaseModel {
  instanceOf: "HCM";
  id: number;
  code_id: string;
  label: string;
  category: ICategory;
  user: UserModel;
  vendor: any;
  category_id: number;
  type_id: number;
  client: any;
  client_id: number;
  client_code_id?: string;
  type: IType;
  activities: Array<ActivityModel>;
  total_activities: number;
  first_activity?: any;
  last_activity?: any;

  constructor(data: any) {
    super();
    this.instanceOf = "HCM";
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "hcms";
  }

  /*
    static getSubTypesList() {
        return [
            { object: 'status_logs', model: StatusLogModel },
        ];
    }
    */
}
