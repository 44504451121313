import IDateFieldMolecule from "./interface";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import FormHelperText from "@mui/material/FormHelperText";
import { Controller } from "react-hook-form";
import ConnectForm from "@utils/ConnectForm";
import { UseFormReturn, FieldValues } from "react-hook-form";
import HelperErrorMolecule from "@molecules/HelperError";

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <DateFieldMolecule />
 * </FormProvider>
 * @param IAsyncSelectMolecule
 * @returns
 */
const TimeFieldMolecule = ({
  controlName,
  label,
  control,
  inputFormat,
  sError,
}: IDateFieldMolecule) => {
  return (
    <ConnectForm>
      {({ register, setValue, formState }: UseFormReturn<FieldValues, any>) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <TimePicker
                  label={label}
                  onChange={(e: any) =>
                    setValue(controlName, e, {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  inputFormat={inputFormat}
                  value={value ? value : null}
                  renderInput={(params) => (
                    <TextField
                      disabled={true}
                      {...register(controlName)}
                      {...params}
                      fullWidth
                    />
                  )}
                />
                {!!formState.errors &&
                formState.errors[controlName] &&
                formState.errors[controlName]?.message ? (
                  <FormHelperText>
                    {!!formState.errors &&
                      formState.errors[controlName] &&
                      (formState.errors[controlName]?.message as String)}
                  </FormHelperText>
                ) : null}
                {sError ? <HelperErrorMolecule message={sError} /> : null}
              </>
            )}
            name={controlName}
            control={control}
          />
        </LocalizationProvider>
      )}
    </ConnectForm>
  );
};

TimeFieldMolecule.defaultProps = {
  inputFormat: "hh:mm",
  controlName: null,
  label: "",
  control: null,
};

export default TimeFieldMolecule;
