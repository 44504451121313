import { Grid, Paper, Box } from "@mui/material";
import { StyledNotificationRow } from "./styled";
import INotificationRow from "./interface";
import DatePrintAtom from "@atoms/DatePrint";
import ForumIcon from "@mui/icons-material/Forum";
import MIconAtom from "@atoms/MIcon";
import { t } from "@lingui/macro";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationAccordionText from "./NotificationAccordionText";

const NotificationRowMolecule = ({
  notification,
  onNotificationSelected,
}: INotificationRow) => {
  const actionSelected = () => {
    if (onNotificationSelected) {
      onNotificationSelected(notification);
    }
  };

  return (
    <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
      <StyledNotificationRow>
        <Grid onClick={notification.isChat() ? actionSelected : undefined} container sx={{ alignItems: "center" }}>
          <Grid sx={{ display: "flex" }} item md={10} xs={10}>
            <Box className="date" mr={1}>
              {notification.isChat() ? (
                <MIconAtom tooltip={t`Chat message`} icon={<ForumIcon />} />
              ) : (
                <MIconAtom tooltip={t`Chat message`} icon={<NotificationsNoneIcon />} />
              )}
            </Box>
            <Box>
              <Box>
                {notification.isChat() ? (
                  <Box>
                    {notification.notification.user.full_name}: { notification.notification.type.name }
                    <Box>
                      <b>"{notification.text}"</b>
                    </Box>
                  </Box>
                ) : (
                  <NotificationAccordionText notification={notification}/>
                )}
              </Box>
              <Box mt={2}>
                <DatePrintAtom utc={false} tz={null} format="DD/MM/YYYY HH:mm">
                  {notification.notification.created_at}
                </DatePrintAtom>
              </Box>
            </Box>
          </Grid>
          <Grid sx={{ textAlign: "center" }} item md={2} xs={2}></Grid>
        </Grid>
      </StyledNotificationRow>
    </Paper>
  );
};

export default NotificationRowMolecule;
