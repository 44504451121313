import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

export const StyledMenuContainer = styled(Box)(({ theme }) => ({
  position: "sticky",
  top: "80px",
  left: "5px",
  "& .active li": {
    fontWeight: "bold",
  },
}));
