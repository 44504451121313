export const ENVIRONMENT = {
  local: process.env.NODE_ENV === "development",
  production: process.env.NODE_ENV === "production",
};

export const LOCAL_STORAGE_KEYS = {
  LOCALE: "lang",
  USER: "user",
};

export const ADMIN_ID = 3;

export const PAGES = [
  { label: "Activities", href: "/activities" },
  { label: "Assets", href: "/assets" },
  { label: "Inspectors", href: "/people" },
  { label: "Settings", href: "/settings/users" },
];

export const GOOGLE_MAPS_API_KEY = "AIzaSyDmsBHbBelV8WudVmeoZK8la-SP6t8CfU8";

export const IDS = {
  ROLES: {
    USER: 1,
    ADMIN: 1,
    INSPECTOR: 2,
    CLIENT: 7,
    VENDOR: 8,
    SUBCONTRACTOR: 9,
  },
  CUSTOMERS: {
    CLIENT: 7,
    PROVIDER: 8,
  },
  STATUSES: {
    REPORTS: {
      PENDING: 20,
      ISSUED: 21,
      SENT: 22,
    },
  },
};
