import { CoreBaseModel } from "@core/models/Base.model";

export class PartnerModel extends CoreBaseModel {
  id: number;
  name: string;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "partners";
  }
}
