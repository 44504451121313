import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { authLoginRequest } from "@stores/reducers/authReducer";
import { useAppDispatch } from "@stores/hooks";
import { useAuth } from "@hooks";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import loginSchema from "@validation/login";
import ButtonAtom from "@atoms/Button";
import { StyledFormBody } from "./styled";
import { ToastContainer, toast } from "react-toastify";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
interface IFormValues {
  email: string;
  password: string;
  pwa: number | string;
}

const LoginFormHolder = ({ pwa }: any) => {
  const dispatch = useAppDispatch();
  const { error, isLoading } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit: handleHookSubmit,
  } = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      pwa: pwa ? 1 : "",
    },
    reValidateMode: "onSubmit",
    resolver: yupResolver(loginSchema),
  });

  const handleSubmit = (data: IFormValues) => {
    dispatch(authLoginRequest(data));
  };

  useEffect(() => {
    toast(error, {
      type: "info",
    });
  }, [error]);

  return (
    <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
      <StyledFormBody>
        <TextField
          {...register("email", { required: true })}
          fullWidth
          type="text"
          label={t`Email`}
          margin="normal"
          autoComplete="email"
          helperText={!!isSubmitted && errors.email && errors.email?.message}
          size="medium"
        />
        <TextField
          {...register("password", { required: true })}
          fullWidth
          label={t`Password`}
          margin="normal"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          helperText={
            !!isSubmitted && errors.password && errors.password?.message
          }
          size="medium"
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  aria-label="toggle password visibility"
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </StyledFormBody>
      <Box my={1}>
        <ToastContainer />
        {error ? <Alert severity="error">{error}</Alert> : null}
      </Box>
      <ButtonAtom
        type="submit"
        label={<Trans>Login</Trans>}
        disabled={isLoading}
        loading={isLoading}
        fullWidth
        variant="contained"
      />
    </form>
  );
};

export default LoginFormHolder;
