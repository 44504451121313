import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { ICategory } from "@interfaces/ICategory";
import type { IType } from "@interfaces/IType";
import { CoreResponseModel } from "@core/models/Response.model";
import FiltersService from "@services/FiltersService";

export interface IFilterState {
  isLoading: boolean;
  filters: {
    categories?: {
      assets?: ICategory[];
      locations?: ICategory[];
      users?: ICategory[];
      inspections?: Array<any>;
    };
    assets?: [];
    countries?: [];
    customers?: [];
    locations?: [];
    statuses?: [];
    types?: {
      inspections?: IType[];
      users?: IType[];
      assets?: IType[];
      rates?: IType[];
      documents?: IType[];
    };
    roles?: [];
    activities?: [];
    users?: [];
  };
  error: any;
}

const initialState: IFilterState = {
  isLoading: false,
  filters: {},
  error: null,
};

export const fetchFilters = createAsyncThunk(
  "filters/get",
  async (filter: object, { rejectWithValue }): Promise<any> => {
    try {
      const res = (await FiltersService.get(
        undefined,
        filter,
        true,
      )) as CoreResponseModel;
      if (res.hasErrors()) {
        // @ts-ignore
        throw new Error({
          msg: res.getMsgString ? res.getMsgString() : "Errore",
          fieldErrors: res.getErrors(),
        });
      }
      return res.getData();
    } catch (error: any) {
      console.log("error.message", error.msg);
      throw rejectWithValue(error);
    }
  },
);

export const fetchFilter = createAsyncThunk(
  "filters/get/key",
  async (key: string, { rejectWithValue }): Promise<any> => {
    try {
      const res = (await FiltersService.get(
        undefined,
        { resources: [key] },
        true,
      )) as CoreResponseModel;
      if (res.hasErrors()) {
        // @ts-ignore
        throw new Error({
          msg: res.getMsgString ? res.getMsgString() : "Errore",
          fieldErrors: res.getErrors(),
        });
      }
      return res.getData();
    } catch (error: any) {
      console.log("error.message", error.msg);
      throw rejectWithValue(error);
    }
  },
);

export const filtersReducer = createSlice({
  name: "filters",
  initialState,
  reducers: {
    deleteItem: (state, action) => {
      // @ts-ignore
      const index = state.filters[action.payload.what].findIndex(
        (item: any) => item.id === action.payload.id,
      );
      if (index !== -1) {
        // @ts-ignore
        state.filters[action.payload.what].splice(index, 1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilters.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchFilters.fulfilled, (state, { payload }) => {
        state.filters = payload;
      })
      .addCase(fetchFilters.rejected, (state, { payload }) => {
        state.error = payload; // msg, fieldErrors
        state.isLoading = false;
      })
      .addCase(fetchFilter.fulfilled, (state, { payload }) => {
        Object.keys(payload).forEach((key: string) => {
          // ignore because 'key' anyway will be under our control
          // @ts-ignore
          state.filters[key] = payload[key];
        });
      });
  },
});

export const { deleteItem } = filtersReducer.actions;

export default filtersReducer.reducer;
