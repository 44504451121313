export const COLORS = {
  white: "#FFFFFF",
  blue: {
    primary: "#2058e8",
    shade1: "#1565c0",
  },
  gray: {
    primary: "#585858",
    shade1: "#f4f4f4",
    shade2: "#272838",
  },
};

export const primary = {
  base: "#000FF5",
  light: "#00A0FF",
  dark: "#000FBE",
  glass: "#091153",
};

export const defaultColorIcon = "#2f2f2f";

export const gray = {
  black: "#000000",
  shade1: "#333333",
  shade2: "#4D4D4D",
  shade3: "#909090",
  shade4: "#999999",
  shade5: "#CCCCCC",
  shade6: "#E6E6E6",
  shade7: "#F2F2F2",
  shade8: "#FAFAFA",
  white: "#FFFFFF",
};
