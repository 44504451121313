import { styled } from "@mui/material/styles";
import type { IGroupActionButtonProps } from "./interfaces";
import Button from "@mui/material/Button";

export const GroupActionButton = styled(
  ({ isVisible, ...restProps }: IGroupActionButtonProps) => (
    <Button {...restProps} />
  ),
)<IGroupActionButtonProps>(({ isVisible = true }) => ({
  flexGrow: 1,
  visibility: isVisible ? "visible" : "hidden",
  pointerEvents: isVisible ? "all" : "none",
}));

GroupActionButton.defaultProps = {
  variant: "contained",
};

export const Wrapper = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));
