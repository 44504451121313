import * as React from "react";
import { Box, Button } from "@mui/material";
import IComponentProps from "./interface";
import { FormProvider, useForm } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import Grid from "@mui/material/Grid";
import "flatpickr/dist/themes/material_green.css";
import ButtonAtom from "@atoms/Button";
import RadioGroupMoleculeA from "@molecules/RadioGroup";
import { useSelector } from "react-redux";
import { IType } from "@interfaces/IType";
import RatesService from "@services/RatesService";
import HelperErrorMolecule from "@molecules/HelperError";
import ClienSupplierRatesForm from "./Form";
import DataBalloonAtom from "@atoms/DataBalloon";
import SpinnerAtom from "@atoms/Spinner";
import { useAppDispatch } from "@stores/hooks";
import { addForm, updateForm } from "@stores/reducers/saverReducer";

const InspectionRatesForm = ({
  inspection,
  clientRecord,
  isInd,
  supplierRecord,
  onBack,
  onNext,
  onError,
}: IComponentProps) => {
  const [sErrors /*, setSErrors*/] = React.useState<any>({}); // inutile???
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [clientDirty, setClientDirty] = React.useState<boolean>(false);
  const [supplierDirty, setSupplierDirty] = React.useState<boolean>(false);
  const [clientData, setClientData] = React.useState<any>(clientRecord ?? {});
  const [errors, setErrors] = React.useState<any>({});
  const [supplierData, setSupplierData] = React.useState<any>(
    supplierRecord ?? {},
  );
  const ratesTypesList = useSelector(
    (state: any) => state.filters.filters.types?.rates,
  );
  const currencyList = useSelector(
    (state: any) => state.filters.filters.categories?.rates,
  );
  const autoSave = useSelector((state: any) => state.saver.saveAll);
  const dispatch = useAppDispatch();

  const formInstance = useForm<any>({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const {
    handleSubmit: handleHookSubmit,
    formState,
    watch,
    setValue,
    reset,
  } = formInstance;

  const isCalculated = watch("is_calculated");
  const type = watch("type_id");
  const isHours = watch("time_unit") === "hours";
  const isDays = watch("time_unit") === "days";

  React.useEffect(() => {
    if (autoSave.status && (supplierDirty || clientDirty)) {
      handleSubmit();
    }
  }, [autoSave]);

  React.useEffect(() => {
    reset({
      category_id: currencyList ? currencyList[0].id : "",
      type_id: ratesTypesList ? ratesTypesList[0].id : "",
      is_calculated: false,
      time_unit: "hours",
      allowance_quantity: "",
      allowance: "",
      value: "",
      rate: "",
      days: "",
      extra: "",
    });
  }, [ratesTypesList, currencyList]);

  React.useEffect(() => {
    if (!isDays) {
      setValue("days", "");
      setValue("allowance_quantity", "");
      setValue("allowance", "");
    }
    if (!isHours) {
      setValue("hours", "");
    }
    if (!isCalculated) {
      setValue("days", "");
      setValue("hours", "");
      setValue("rate", "");
      setValue("allowance_quantity", "");
      setValue("allowance", "");
    }
  }, [isHours, isDays, isCalculated, setValue]);

  const mutate = (data: any) => {
    return data.id
      ? RatesService.put({ ...data, ...{ model_id: inspection?.id } }, data.id)
      : RatesService.create({ ...data, ...{ model_id: inspection?.id } });
  };

  const handleSubmit = async () => {
    setErrors({});
    let hasErrors = false;
    setIsLoading(true);
    let tmpErrors = {};
    // Save client
    if (/*Number(type) === */ratesTypesList[0].id) {
      const responseA: any = await mutate(clientData);
      if (!responseA.hasErrors()) {
        setClientData({
          ...clientData,
          ...{ id: responseA.getData().id },
        });
      } else {
        tmpErrors = { clientErrors: responseA.getErrors() };
        hasErrors = true;
      }
    }
    // Save supplier
    if (/*Number(type) === */ratesTypesList[1].id) {
      const responseB: any = await mutate(supplierData);
      if (!responseB.hasErrors()) {
        setSupplierData({
          ...supplierData,
          ...{ id: responseB.getData().id },
        });
      } else {
        tmpErrors = {
          ...tmpErrors,
          ...{ supplierErrors: responseB.getErrors() },
        };
        hasErrors = true;
      }
    }
    setErrors(tmpErrors);
    setIsLoading(false);
    if (!hasErrors) {
      dispatch(
        updateForm({
          slug: "inspection-detail-rates",
          dirty: false,
          saved: true,
        }),
      );
      if (onNext) {
        onNext();
      }
    } else {
      if (onError) {
        onError();
      }
    }
  };

  const clientChanged = (data: any) => {
    const inData: any = {};
    inData.id = data.id;
    inData.info = JSON.stringify({
      days: data.days,
      hours: data.hours,
      rate: data.rate,
      allowance_quantity: data.allowance_quantity,
      allowance: data.allowance,
      extra: data.extra,
      is_calculated: data.is_calculated,
      time_unit: data.time_unit,
    });
    inData.value = data.value;
    inData.category_id = data.category_id;
    inData.type_id = data.type_id;
    inData.model_type = "inspections";
    setClientData({ ...clientData, ...inData });
  };

  const supplierChanged = (data: any) => {
    const inData: any = {};
    inData.id = data.id;
    inData.info = JSON.stringify({
      days: data.days,
      hours: data.hours,
      rate: data.rate,
      allowance_quantity: data.allowance_quantity,
      allowance: data.allowance,
      extra: data.extra,
      is_calculated: data.is_calculated,
      time_unit: data.time_unit,
    });
    inData.value = data.value;
    inData.category_id = data.category_id;
    inData.type_id = data.type_id;
    inData.model_type = "inspections";
    setSupplierData({ ...supplierData, ...inData });
  };

  const handleOnDirtyChangeClient = (isDirty: boolean) => {
    setClientDirty(isDirty);
  };

  const handleOnDirtyChangeSupplier = (isDirty: boolean) => {
    setSupplierDirty(isDirty);
  };

  React.useEffect(() => {
    if (supplierDirty || clientDirty) {
      dispatch(
        addForm({
          slug: "inspection-detail-rates",
          formState: formInstance.formState,
        }),
      );
    }
  }, [supplierDirty, clientDirty]);

  return (
    <Box>
      {!ratesTypesList || !currencyList ? (
        <SpinnerAtom />
      ) : (
        <>
          <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
            <FormProvider {...formInstance}>
              <Box mb={2}>
                <Grid container spacing={1}>
                  <Grid item md={7} xs={12}>
                    <RadioGroupMoleculeA
                      control={formInstance.control}
                      controlName="type_id"
                      radios={
                        ratesTypesList
                          ? ratesTypesList.map((type: IType) => {
                              return {
                                name: (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      width: "100%",
                                      alignItems: "center",
                                    }}
                                  >
                                    {type.name}
                                    &nbsp;
                                    {((type.name.toLowerCase() === "client" &&
                                      errors.clientErrors) ||
                                      (type.name.toLowerCase() === "supplier" &&
                                        errors.supplierErrors)) && (
                                      <DataBalloonAtom
                                        size={"small"}
                                        severity="error"
                                        data={
                                          type.name.toLowerCase() ===
                                            "client" && errors.clientErrors
                                            ? Object.keys(errors.clientErrors)
                                                .length
                                            : type.name.toLowerCase() ===
                                                "supplier" &&
                                              errors.supplierErrors
                                            ? Object.keys(errors.supplierErrors)
                                                .length
                                            : 0
                                        }
                                      />
                                    )}
                                  </div>
                                ),
                                id: Number(type.id),
                              };
                            })
                          : null
                      }
                    />
                    <HelperErrorMolecule message={sErrors["type_id"]} />
                  </Grid>
                </Grid>
              </Box>
            </FormProvider>
          </form>

          <Box>
            {ratesTypesList && (
              <React.Fragment>
                {
                  <Box
                    sx={{
                      display:
                        Number(type) === ratesTypesList[0].id
                          ? "block"
                          : "none",
                    }}
                  >
                    <ClienSupplierRatesForm
                      onDirtyChange={handleOnDirtyChangeClient}
                      record={clientRecord}
                      onChange={clientChanged}
                      errors={errors.clientErrors}
                      model={inspection}
                      typeId={ratesTypesList[0].id}
                    />
                  </Box>
                }
                {
                  <Box
                    sx={{
                      display:
                        Number(type) === ratesTypesList[1].id
                          ? "block"
                          : "none",
                    }}
                  >
                    <ClienSupplierRatesForm
                      onDirtyChange={handleOnDirtyChangeSupplier}
                      record={supplierRecord}
                      onChange={supplierChanged}
                      errors={errors.supplierErrors}
                      model={inspection}
                      typeId={ratesTypesList[1].id}
                    />
                  </Box>
                }
              </React.Fragment>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {!isInd && (
              <Button color="inherit" onClick={onBack} sx={{ mr: 1 }}>
                <Trans>Back</Trans>
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            <ButtonAtom
              variant="contained"
              loading={isLoading}
              label={!isInd ? t`Complete` : t`Save`}
              onClick={() => handleSubmit()}
              disabled={!formState.isValid}
              sx={{ mr: 1 }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default InspectionRatesForm;
