import { FormHelperText } from "@mui/material";
import { Fragment } from "react";
import IProps from "./interface";

const HelperErrorMolecule = ({ message }: IProps) => {
  return (
    <Fragment>{message && <FormHelperText>{message}</FormHelperText>}</Fragment>
  );
};

export default HelperErrorMolecule;
