import { styled } from "@mui/system";
import { Menu } from "@mui/material";

import { Button } from "@mui/material";

export const StyledNotificationButton = styled(Button)({
  "& svg": {
    color: "white",
  },
});

export const StyledMenu = styled(Menu)({
  width: "300px",
  paddingBottom: "0px !important",
});
