import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import IPeopleQualificationsOrganism from "./interface";
import AttributesService from "@services/AttributesService";
import { useQuery } from "react-query";
import SpinnerAtom from "@atoms/Spinner";
import { PeopleModel } from "@models/PeopleModel";
import { AssetsModel } from "@models/AssetsModel";
import { InspectionModel } from "@models/InspectionModel";
import AccordionOrganism from "./Accordion";

const AttributesOrganism = ({
  model,
  model_type,
}: IPeopleQualificationsOrganism) => {
  const [attributes, setAttributes] = useState<any>([]);
  const [renderKey, setRenderKey] = useState<any>(0);
  const [dataChanged, setDataChanged] = useState<
    PeopleModel | InspectionModel | undefined | AssetsModel
  >(undefined);
  const [modelData, setPersonData] = useState<
    PeopleModel | InspectionModel | undefined | AssetsModel
  >(undefined);

  useEffect(() => {
    setPersonData(model);
  }, [model]);

  const onGroupChange = (newGroupData: PeopleModel) => {
    // This is made to update the data after changing tab/destroying without closing form
    if (modelData) {
      modelData.attributes = newGroupData.attributes;
    }
    setDataChanged(modelData);
  };

  useEffect(() => {
    return () => {
      // on destroy
      setPersonData(dataChanged);
    };
  }, []);

  const { data, isLoading } = useQuery(
    `attributes-${model_type}`,
    () => AttributesService.get("", { model_type }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const onGroupModified = (newGroupData: PeopleModel) => {
    if (modelData) {
      modelData.attributes = newGroupData.attributes;
      setPersonData(modelData);
    }
    setRenderKey(renderKey + 1);
  };

  useEffect(() => {
    if (!isLoading && data) {
      if (model) {
        // @ts-ignore
        setAttributes(data.data);
      }
    }
  }, [data, isLoading, model]);

  return (
    <Box key={renderKey}>
      {!isLoading && modelData && attributes ? (
        attributes.map((group: any, groupIndex: number) => {
          const personAssignedGroup = modelData.getAttributes(group.alias);
          return (
            <AccordionOrganism
              key={groupIndex}
              onGroupChange={onGroupChange}
              model_type={model_type}
              onGroupModified={onGroupModified}
              modelData={modelData}
              personAssignedGroup={personAssignedGroup}
              group={group}
              groupIndex={groupIndex}
            />
          );
        })
      ) : (
        <Box>
          <SpinnerAtom></SpinnerAtom>
        </Box>
      )}
    </Box>
  );
};

export default AttributesOrganism;
