import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { CoreResponseModel } from "@core/models/Response.model";
import IMessage from "@interfaces/IMessage";
import ChatService from "@services/ChatService";
import ActivitiesService from "@services/ActivitiesService";

export interface IChat {
  messages: Array<IMessage>;
}

function prepend(value: any, array: Array<any>) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

const chatObject = (payload: any) => {
  return {
    messages: payload.messages ? payload.messages : [],
    activity_id: payload.activity_id,
    isOpen: false,
    toRead: 0,
  };
};

export interface IChatState {
  chats: any;
  chatToShow: number | null;
  webrtcUsersOnline: Array<number>;
  isLoading: boolean;
  error: any;
}

const initialState: IChatState = {
  chats: {},
  chatToShow: null,
  webrtcUsersOnline: [],
  isLoading: false,
  error: null,
};

export const getChat = createAsyncThunk(
  "chat/get",
  async (filter: any, { rejectWithValue }): Promise<any> => {
    try {
      const res = (await ChatService.get(
        "",
        filter,
        true,
      )) as CoreResponseModel;
      const resActivity = (await ActivitiesService.get(
        filter.activity_id,
        undefined,
        true,
      )) as CoreResponseModel;
      if (res.hasErrors()) {
        // @ts-ignore
        throw new Error(res.getMsgString ? res.getMsgString() : "Errore");
      } else {
        return {
          activity_id: filter.activity_id,
          activity: resActivity.getData(),
          messages: res.getData(),
        };
      }
    } catch (error: any) {
      console.log("error.message", error.msg);
      throw rejectWithValue(error);
    }
  },
);

export const chatReducer = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addRTCMemberOnline: (state, action) => {
      const found = state.webrtcUsersOnline.find(
        (member: any) => member.id === Number(action.payload.id),
      );
      if (!found) {
        state.webrtcUsersOnline.push(action.payload);
      }
    },
    removeRTCMemberOnline: (state, action) => {
      const foundIndex = state.webrtcUsersOnline.findIndex(
        (member: any) => member.id === Number(action.payload.id),
      );
      if (foundIndex !== -1) {
        state.webrtcUsersOnline.splice(foundIndex, 1);
      }
    },
    addMessage: (state, action) => {
      if (!state.chats[action.payload.activity_id]) {
        state.chats[action.payload.activity_id] = chatObject({
          messages: [action.payload.message],
          activity_id: action.payload.activity_id,
        });
      }
      if (state.chats[action.payload.activity_id].isOpen) {
        if (
          !state.chats[action.payload.activity_id].messages.find(
            (message: any) => message.id === action.payload.message.id,
          )
        ) {
          state.chats[action.payload.activity_id].messages = prepend(
            JSON.parse(JSON.stringify(action.payload.message)),
            state.chats[action.payload.activity_id].messages,
          );
        }
      } else {
        state.chats[action.payload.activity_id].toRead += 1;
      }
      state.chats = { ...state.chats };
    },
    deleteMessage: (state, action) => {
      if (state.chats[action.payload.activity_id]) {
        const index = state.chats[
          action.payload.activity_id
        ].messages.findIndex(
          (message: any) => message.id === action.payload.message.id,
        );
        if (index !== -1) {
          state.chats[action.payload.activity_id].messages.splice(index, 1);
        }
      }
    },
    editMessage: (state, action) => {
      if (state.chats[action.payload.activity_id]) {
        console.log(current(state.chats));
        const index = state.chats[
          action.payload.activity_id
        ].messages.findIndex(
          (message: any) => message.id === action.payload.message.id,
        );
        const newA = [...state.chats[action.payload.activity_id].messages];
        if (index !== -1) {
          newA[index].message = action.payload.message.text;
        }
        state.chats[action.payload.activity_id].messages = newA;
      }
    },
    hideActiveChat: (state) => {
      state.chatToShow = null;
    },
    openChat: (state, action) => {
      if (state.chats[action.payload.activity_id]) {
        state.chats[action.payload.activity_id].isOpen = true;
        state.chats[action.payload.activity_id].toRead = 0;
      }
    },
    startChat: (state, action) => {
      state.chatToShow = action.payload;
    },
    closeChat: (state, action) => {
      if (state.chats[action.payload.activity_id]) {
        state.chats[action.payload.activity_id].isOpen = false;
        state.chats[action.payload.activity_id].toRead = 0;
        state.chatToShow = null;
      }
    },
    destroyChat: (state, action) => {
      delete state.chats[action.payload.activity_id];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChat.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(getChat.fulfilled, (state, { payload }) => {
        if (payload) {
          state.chats[payload.activity_id] = payload;
          state.chats[payload.activity_id].isOpen = true;
          state.isLoading = false;
          state.error = null;
        }
      })
      .addCase(getChat.rejected, (state, { payload }) => {
        state.error = payload; // msg, fieldErrors
        state.isLoading = false;
      });
  },
});

export const {
  addMessage,
  deleteMessage,
  editMessage,
  openChat,
  closeChat,
  destroyChat,
  startChat,
  hideActiveChat,
  addRTCMemberOnline,
  removeRTCMemberOnline,
} = chatReducer.actions;

export default chatReducer.reducer;
