import * as Yup from "yup";
import { t } from "@lingui/macro";

const createClientSchema = Yup.object().shape({
  // type_id: Yup.string().required(t`Necessaria`),
  email: Yup.string()
    .email()
    .required(t`Necessaria`),
  name: Yup.string().required(t`Necessaria`),
  phone: Yup.string(),
});

export default createClientSchema;
