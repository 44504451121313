import { useState, useEffect } from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import AttributesGroupFormOrganism from "@organisms/AttributesGroupForm";

// Accepted models
import { PeopleModel } from "@models/PeopleModel";
import { AssetsModel } from "@models/AssetsModel";
import DatePrintAtom from "@atoms/DatePrint";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import DoneIcon from "@mui/icons-material/Done";
import moment from "moment";

const GroupedList = ({
  group,
  editMode,
  groupIndex,
  modelData,
  model_type,
  onGroupModified,
  attributes,
}: any) => {
  const [attrToEdit, setAttrToEdit] = useState<
    PeopleModel | AssetsModel | null
  >(null);
  // const [ /*accStatuses,*/ setAccStatuses ] = useState<any>({}); // never used ?????
  const [personAssignedGroup, setPersonAssignedGroup] =
    useState<any /**attributes-list */>([]);

  useEffect(() => {
    setPersonAssignedGroup(modelData.getAttributes(group.alias));
  }, [modelData]);

  const groupModified = (data: PeopleModel) => {
    // setAttrToEdit(null);
    if (onGroupModified) {
      onGroupModified(data);
    }
    setPersonAssignedGroup(
      data.attributes.find((groupA: any) => groupA.alias === group.alias)
        .attributes,
    );
  };

  useEffect(() => {
    setAttrToEdit(editMode ? group : null);
  }, [editMode, group]);

  useEffect(() => {
    if (modelData) {
      const statuses: any = {};
      attributes.forEach((group: any) => {
        let personAssignedGroup = [];
        if (group.children.length) {
          group.children.forEach((groupB: any) => {
            personAssignedGroup = modelData.getAttributes(groupB.alias);
            statuses[groupB.id] = {
              expanded: personAssignedGroup.length ? true : false,
            };
          });
        } else {
          personAssignedGroup = modelData.getAttributes(group.alias);
          statuses[group.id] = {
            expanded: personAssignedGroup.length ? true : false,
          };
        }
      });
      // setAccStatuses(statuses);
    }
  }, [attributes, modelData]);

  const getSelectLabelByValue = (
    personAttribute: any,
    groupConfiguration: any,
  ) => {
    const attributesFound = groupConfiguration.attributes.find(
      (attribute: any) => attribute.id === personAttribute.attribute_id,
    );
    if (attributesFound) {
      if (Array.isArray(personAttribute.value)) {
        const selectedOptions: Array<string> = [];
        personAttribute.value.forEach((value: any) => {
          const found = attributesFound.options.find(
            (option: any) => Number(option.value) === Number(value),
          );
          if (found) {
            selectedOptions.push(found.label);
          }
        });
        if (selectedOptions.length) {
          return selectedOptions.join(", ");
        } else {
          return "";
        }
      } else {
        const selectedOption = attributesFound.options.find(
          (option: any) =>
            Number(option.value) === Number(personAttribute.value),
        );
        if (selectedOption) {
          return selectedOption.label;
        } else return "";
      }
    }
    return "";
  };

  return (
    <>
      {
        <Grid item md={3} xs={3} mb={1}>
          {!attrToEdit || attrToEdit.id !== group.id ? (
            personAssignedGroup.length ? (
              personAssignedGroup.map((attributesGroup: any, index: number) => {
                return (
                  <Box key={index}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={
                          attributesGroup.find(
                            (att: any) => att.type.alias === "date",
                          )
                            ? 2
                            : 0
                        } // TODO controlla, crash
                        xs={
                          attributesGroup.find(
                            (att: any) => att.type.alias === "date",
                          )
                            ? 1
                            : 0
                        }
                      >
                        {attributesGroup.find(
                          (att: any) => att.type.alias === "date",
                        ) &&
                          moment(
                            attributesGroup.find(
                              (att: any) => att.type.alias === "date",
                            ).value,
                          ).isBefore(moment()) && (
                            <PriorityHighIcon color="warning"></PriorityHighIcon>
                          )}
                        {(!attributesGroup.find(
                          (att: any) => att.type.alias === "date",
                        ) ||
                          !moment(
                            attributesGroup.find(
                              (att: any) => att.type.alias === "date",
                            ).value,
                          ).isBefore(moment())) && (
                          <DoneIcon color="success"></DoneIcon>
                        )}
                      </Grid>
                      <Grid item md={10} xs={10}>
                        <Typography variant="body1">
                          <strong>{group.name}</strong>
                        </Typography>
                        {attributesGroup.map(
                          (attribute: any, itemIndex: number) => {
                            return (
                              <Box
                                key={itemIndex}
                                sx={{
                                  display:
                                    attribute.type.alias === "date"
                                      ? "block"
                                      : attribute.type.alias === "boolean" ? attribute.value ? "block" : "none" : "inline",
                                  marginRight: "3px",
                                }}
                              >
                                {attribute.type.alias === "select" && (
                                  <Typography variant="caption">
                                    {getSelectLabelByValue(attribute, group)}
                                  </Typography>
                                )}
                                {attribute.type.alias === "checkbox" && (
                                  <Typography>
                                    {getSelectLabelByValue(attribute, group)}
                                  </Typography>
                                )}
                                {attribute.type.alias === "text" && (
                                  <Typography variant="caption">
                                    {attribute.value ?? ""}
                                  </Typography>
                                )}
                                {attribute.type.alias === "boolean" && attribute.value && attribute.name !== 'enabled' && (
                                  <Typography>{ attribute.label }</Typography>
                                )}
                                {attribute.type.alias === "date" && (
                                  <Typography variant="caption">
                                    {attribute.value ? (
                                      <DatePrintAtom format="DD/MM/yyyy">
                                        {attribute.value}
                                      </DatePrintAtom>
                                    ) : (
                                      ""
                                    )}
                                  </Typography>
                                )}
                              </Box>
                            );
                          },
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                );
              })
            ) : (
              <></>
            )
          ) : null}
        </Grid>
      }
      {attrToEdit && attrToEdit.id === group.id ? (
        <Grid item md={12} xs={12}>
          <Grid container>
            <Grid item md={2} className="align-center">
              {group.name}
            </Grid>
            <Grid item md={10}>
              <AttributesGroupFormOrganism
                showTitle={false}
                model_id={modelData.id}
                model_type={model_type}
                onSave={groupModified}
                model={modelData}
                personAssignedGroup={personAssignedGroup}
                group={group}
              />
            </Grid>
          </Grid>
          <Divider />
        </Grid>
      ) : null}
    </>
  );
};

export default GroupedList;
