import { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import IActivitiesFilterOrganism from "./interface";
import { FormProvider, useForm } from "react-hook-form";
import TabsMolecule from "@molecules/Tabs";
import { IDS } from "@helpers/constants";

interface IFormValues {
  report_status_id: string;
  date_range: string;
}

const InspectorActivitiesFilterOrganism = ({
  onFormChange,
}: IActivitiesFilterOrganism) => {
  const [key, setKey] = useState<number>(0);

  const formMethods = useForm<any>({
    mode: "onChange",
    defaultValues: {
      report_status_id: "",
      date_range: "",
    },
    reValidateMode: "onSubmit",
  });

  const { handleSubmit, getValues, watch, setValue } = formMethods;

  const setFilterTab = (data: any) => {
    setValue("report_status_id", data.value);
    if (!data.value) {
      setPeriod(null);
    } else {
      setPeriod({ value: "24" });
    }
    setKey(key + 1);
  };

  const setPeriod = (data: any) => {
    if (data) {
      setValue("date_range", data.value);
    } else {
      setValue("date_range", null);
    }
    setKey(key + 1);
  };

  let timeout: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    const watchAll = formMethods.watch((value, { name, type }) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        handleFormSubmit(getValues());
      }, 0);
    });
    return () => watchAll.unsubscribe();
  }, [watch, formMethods]);

  const handleFormSubmit = (data: IFormValues) => {
    onFormChange(data);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormProvider {...formMethods}>
          <Grid container>
            <Grid sx={{ margin: "0 auto" }} item sm={12} md={12} lg={12}>
              <TabsMolecule
                onChange={setFilterTab}
                delayOnChange={true}
                preselected={0}
                tabsLabels={[
                  { label: "Upcoming", value: null },
                  { label: "Reports", value: 20 },
                ]}
              />
            </Grid>
          </Grid>
          <Box sx={{ overflow: "hidden" }}>
            {getValues().report_status_id === IDS.STATUSES.REPORTS.PENDING && (
              <Box mt={2} sx={{ width: "100%" }}>
                <Grid container>
                  <Grid sx={{ margin: "0 auto" }} item sm={12} md={12} lg={12}>
                    <TabsMolecule
                      delayOnChange={true}
                      onChange={setPeriod}
                      tabsLabels={[
                        { label: "24 H", value: "24" },
                        { label: "36 H", value: "36" },
                        {
                          label: "3 DAYS",
                          value: "72",
                        },
                        {
                          label: "OTHERS",
                          value: "others",
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </FormProvider>
      </form>
    </Box>
  );
};

export default InspectorActivitiesFilterOrganism;
