import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { FilterModel } from "@models/FilterModel";

class FiltersService extends CoreBaseService {
  constructor() {
    super(FilterModel);
  }
}

const inst = new FiltersService();

export default inst;
