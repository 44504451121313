import axios, { AxiosRequestConfig } from "axios";
import { CoreResponseModel } from "../models/Response.model";

const BASE_ENDPOINT = process.env.REACT_APP_PUBLIC_API_ENDPOINT;

export class CoreVerbsService {
  // constructor() {}

  formatErrors(error: any) {
    return error.error;
  }

  url(path: string) {
    return `${BASE_ENDPOINT}${path}`;
  }

  get(
    path: string,
    params = null,
    serviceUrl = BASE_ENDPOINT,
    config?: AxiosRequestConfig,
    headers?: any
  ) {
    return axios
      .request({
        method: "get",
        params,
        headers: headers ? headers : undefined,
        url: `${serviceUrl}${path}`,
        responseType: "stream",
        ...(config || {}),
      })
      .then((response) => {
        return new CoreResponseModel(response);
      })
      .catch((err) => new CoreResponseModel({ data: err.response }));
  }

  post(path: string, body = {}, serviceUrl = BASE_ENDPOINT, headers?: any) {
    if(!serviceUrl) {
      serviceUrl = BASE_ENDPOINT
    }
    return axios({
      method: "post",
      url: `${serviceUrl}${path}`,
      responseType: "stream",
      data: body,
      headers: headers ? headers : undefined,
    })
      .then((response) => {
        return new CoreResponseModel(response);
      })
      .catch((err) => new CoreResponseModel(err.response));
  }

  put(path: string, body = {}, serviceUrl = BASE_ENDPOINT) {
    return axios({
      method: "put",
      url: `${serviceUrl}${path}`,
      data: body,
    })
      .then((response) => {
        return new CoreResponseModel(response);
      })
      .catch((err) => new CoreResponseModel(err.response));
  }

  delete(path: string, data?: any | null, serviceUrl: any = BASE_ENDPOINT) {
    return axios({
      method: "delete",
      url: `${serviceUrl}${path}`,
      data,
    })
      .then((response) => {
        return new CoreResponseModel(response);
      })
      .catch((err) => {
        return new CoreResponseModel(err.response);
      });
  }

  patch(path: string, body = {}, serviceUrl = BASE_ENDPOINT, headers?: any) {
    return axios({
      method: "patch",
      headers: headers ? headers : undefined,
      url: `${serviceUrl}${path}`,
      responseType: "stream",
      data: body,
    })
      .then((response) => {
        return new CoreResponseModel(response);
      })
      .catch((err) => new CoreResponseModel(err.response));
  }
}
