import { useState, useEffect } from "react";
import { Alert, Box, Divider } from "@mui/material";
import { Trans } from "@lingui/macro";
import { StyledTitle } from "./styled";
import { ActivityModel } from "@models/ActivityModel";
import { useQuery } from "react-query";
import ActivitiesService from "@services/ActivitiesService";
import { useDispatch } from "react-redux";
import { set } from "@stores/reducers/titleReducer";
import ActivityRowInspectorMolecule from "@molecules/ActivityRow/InspectorVersion";
import { messageService } from "@utils/messagesService";
import SpinnerAtom from "@atoms/Spinner";
import { useSelector } from "react-redux";
import { useUser } from "@hooks";
import InspectorActivitiesFilterOrganism from "./filter";

interface IFormValues {
  report_status_id: string | null;
  date_range: string | null;
}

const InspectorActivitiesPage = () => {
  const auth = useUser();
  const [today, setToday] = useState([]);
  const [filter, setFilter] = useState<IFormValues>({
    date_range: null,
    report_status_id: null,
  });
  const [week, setWeek] = useState([]);
  const dispatch = useDispatch();
  const notificationChannel: any = useSelector(
    (state: any) => state.app.notificationChannel,
  );

  useEffect(() => {
    messageService.sendMessage("hide-back-action");
    messageService.sendMessage("footer-fixed");
  }, []);

  useEffect(() => {
    dispatch(set("Activities"));
  }, [dispatch]);

  const {
    isRefetching,
    isLoading,
    refetch: refetchDaily,
  } = useQuery(
    "activities-today",
    () =>
      ActivitiesService.get("", { date_range: "today" }).then((res: any) => {
        setToday(res.getData());
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const {
    isRefetching: isFetchingWeek,
    isLoading: isLoadingWeek,
    refetch: refetchWeekly,
  } = useQuery(
    "activities-thisweek",
    () =>
      ActivitiesService.get("", {
        date_range: filter.date_range ? filter.date_range : "week",
        report_status_id: filter.report_status_id
          ? filter.report_status_id
          : null,
      }).then((res: any) => {
        setWeek(res.getData());
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (notificationChannel) {
      notificationChannel.bind("client-inspector-notification", (data: any) => {
        if (data.type === "message") {
          if (data.toId === auth.user.id) {
            if (data.action === "new-activity") {
              refetchDaily();
              refetchWeekly();
            }
          }
        }
      });
    }
  }, [notificationChannel]);

  useEffect(() => {
    refetchWeekly();
    if (!filter.date_range) {
      refetchDaily();
    }
  }, [filter]);

  const filterChanged = (data: any) => {
    setFilter(data);
  };

  return (
    <>
      <Box>
        <InspectorActivitiesFilterOrganism onFormChange={filterChanged} />
        {(isFetchingWeek || isRefetching || isLoadingWeek || isLoading) && (
          <SpinnerAtom size={"50px"} />
        )}
        {today.length && !filter.date_range && !isRefetching ? (
          <Box>
            <StyledTitle variant="body2">
              <b>
                <Trans>Today</Trans>
              </b>
            </StyledTitle>
            <Divider sx={{ marginBottom: "10px" }} />
            {today.map((activity: ActivityModel, index: number) => {
              return (
                <ActivityRowInspectorMolecule
                  key={index}
                  activity={activity}
                ></ActivityRowInspectorMolecule>
              );
            })}
          </Box>
        ) : null}
        {!today.length && !filter.date_range && !isRefetching && !isLoading && (
          <Alert severity="warning">
            <Trans>No activities scheduled for today</Trans>
          </Alert>
        )}
      </Box>
      <Box sx={{ marginBottom: week.length ? "60px" : "0px" }}>
        {week.length && !isFetchingWeek ? (
          <Box>
            <StyledTitle>
              <b>
                {filter.date_range ? (
                  filter.date_range
                ) : (
                  <Trans>This week</Trans>
                )}
              </b>
            </StyledTitle>
            <Divider sx={{ marginBottom: "10px" }} />
            {week.map((activity: ActivityModel, index: number) => {
              return (
                <ActivityRowInspectorMolecule
                  key={index}
                  activity={activity}
                ></ActivityRowInspectorMolecule>
              );
            })}
          </Box>
        ) : null}
      </Box>
      {!week.length && !isFetchingWeek && !isLoadingWeek && (
        <Alert severity="warning">
          <Trans>No activities scheduled</Trans>
        </Alert>
      )}
    </>
  );
};

export default InspectorActivitiesPage;
