import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Trans } from "@lingui/macro";
import AttributesGroupFormOrganism from "@organisms/AttributesGroupForm";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { PeopleModel } from "@models/PeopleModel";
import { AssetsModel } from "@models/AssetsModel";
import DatePrintAtom from "@atoms/DatePrint";

const List = ({
  group,
  groupIndex,
  modelData,
  model_type,
  onGroupModified,
  attributes,
}: any) => {
  const [attrToEdit, setAttrToEdit] = useState<
    PeopleModel | AssetsModel | null
  >(null);
  const [accStatuses, setAccStatuses] = useState<any>({
    [group.id]: { expanded: true },
  });
  const [personAssignedGroup, setPersonAssignedGroup] =
    useState<any /**attributes-list */>(modelData.getAttributes(group.alias));

  const groupModified = (data: PeopleModel) => {
    // setAttrToEdit(null);
    if (onGroupModified) {
      onGroupModified(data);
    }
    setPersonAssignedGroup(
      data.attributes.find((groupA: any) => groupA.alias === group.alias)
        .attributes,
    );
  };

  useEffect(() => {
    if (modelData) {
      const statuses: any = {};
      attributes.forEach((group: any) => {
        let personAssignedGroup = [];
        if (group.children.length) {
          group.children.forEach((groupB: any) => {
            personAssignedGroup = modelData.getAttributes(groupB.alias);
            statuses[groupB.id] = {
              expanded: personAssignedGroup.length ? true : false,
            };
          });
        } else {
          // personAssignedGroup = modelData.getAttributes(group.alias);
          statuses[group.id] = { expanded: true };
        }
      });
      setAccStatuses(statuses);
    }
  }, [attributes, modelData]);

  const accordionChangeStatus = (
    event: React.MouseEvent<HTMLButtonElement>,
    expanded: boolean,
    groupId: number,
  ) => {
    accStatuses[groupId].expanded = expanded;
    event.stopPropagation();
  };

  const editGroup = (e: any, group: any) => {
    setAttrToEdit(group);
    if (group && !accStatuses[group.id].expanded) {
      return 0;
    } else {
      // any case stop propagation
      return e.stopPropagation();
    }
  };

  const getSelectLabelByValue = (
    personAttribute: any,
    groupConfiguration: any,
  ) => {
    const attributesFound = groupConfiguration.attributes.find(
      (attribute: any) => attribute.id === personAttribute.attribute_id,
    );
    if (attributesFound) {
      if (Array.isArray(personAttribute.value)) {
        const selectedOptions: Array<string> = [];
        personAttribute.value.forEach((value: any) => {
          const found = attributesFound.options.find(
            (option: any) => Number(option.value) === Number(value),
          );
          if (found) {
            selectedOptions.push(found.label);
          }
        });
        if (selectedOptions.length) {
          return selectedOptions.join(", ");
        } else {
          return "--";
        }
      } else {
        const selectedOption = attributesFound.options.find(
          (option: any) =>
            Number(option.value) === Number(personAttribute.value),
        );
        if (selectedOption) {
          return selectedOption.label;
        } else return "--";
      }
    }
    return "--";
  };

  return (
    <>
      <Box mb={2} key={groupIndex}>
        <Accordion
          onChange={(event: any, expanded: boolean) =>
            accordionChangeStatus(event, expanded, group.id)
          }
          defaultExpanded={accStatuses[group.id].expanded}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${groupIndex}a-content`}
            id={`panel${groupIndex}a-header`}
          >
            <Typography variant="h5">
              <b>{group.name}</b>
            </Typography>
            <Box sx={{ textAlign: "right", fontSize: "12px" }}>
              {attrToEdit && attrToEdit.id === group.id ? (
                <Tooltip title={<Trans>Cancel</Trans>} placement="top">
                  <IconButton onClick={(e: any) => editGroup(e, null)}>
                    <CancelIcon></CancelIcon>
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <React.Fragment>
                      <Trans>Modify the</Trans> {group.name}
                    </React.Fragment>
                  }
                  placement="top"
                >
                  <IconButton onClick={(e: any) => editGroup(e, group)}>
                    <EditIcon color="success"></EditIcon>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {!attrToEdit || attrToEdit.id !== group.id ? (
                personAssignedGroup.length ? (
                  personAssignedGroup.map((attributesGroup: any) => {
                    return (
                      <Box sx={{ display: "flex" }}>
                        {attributesGroup.map(
                          (attribute: any, itemIndex: number) => {
                            return (
                              <Box
                                mt={1}
                                sx={{
                                  marginRight: "40px",
                                }}
                                key={itemIndex}
                              >
                                <Typography>
                                  <b>{attribute.label}</b>
                                </Typography>
                                {attribute.type.alias === "select" && (
                                  <Typography variant="caption">
                                    {getSelectLabelByValue(attribute, group)}
                                  </Typography>
                                )}
                                {attribute.type.alias === "checkbox" && (
                                  <Typography variant="caption">
                                    {getSelectLabelByValue(attribute, group)}
                                  </Typography>
                                )}
                                {attribute.type.alias === "text" && (
                                  <Typography variant="caption">
                                    {attribute.value ?? "--"}
                                  </Typography>
                                )}
                                {attribute.type.alias === "date" && (
                                  <Typography variant="caption">
                                    {attribute.value ? (
                                      <DatePrintAtom format="DD/MM/yyyy">
                                        {attribute.value}
                                      </DatePrintAtom>
                                    ) : (
                                      "--"
                                    )}
                                  </Typography>
                                )}
                              </Box>
                            );
                          },
                        )}
                      </Box>
                    );
                  })
                ) : (
                  <Box mb={1}>
                    <Alert severity="warning">
                      <Trans>There are no attributes set for this group</Trans>
                    </Alert>
                  </Box>
                )
              ) : null}
            </Box>
            {attrToEdit && attrToEdit.id === group.id ? (
              <AttributesGroupFormOrganism
                showTitle={false}
                model_id={modelData.id}
                model_type={model_type}
                onSave={groupModified}
                model={modelData}
                personAssignedGroup={personAssignedGroup}
                group={group}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default List;
