import * as Yup from "yup";
import { t } from "@lingui/macro";

const createPersonSchema = (idProvider: number) =>
  Yup.object().shape({
    role_id: Yup.number().required(t`Necessaria`),
    name: Yup.string().required(t`Necessaria`),
    surname: Yup.string().required(t`Necessaria`),
    email: Yup.string()
      .email()
      .required(t`Necessaria`),
    country_id: Yup.number().required(),
    phone: Yup.string().nullable(),
    city: Yup.string().required(),
    sources: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          category_id: Yup.number().required(),
          new_provider: Yup.boolean().nullable().notRequired(),
          partner_id: Yup.mixed()
            .nullable(true)
            .notRequired()
            .when("category_id", {
              is: idProvider,
              then: Yup.number().required().min(1),
            })
            .when("new_provider", {
              is: true,
              then: Yup.number().min(-1).notRequired().nullable(),
            }),
          new_provider_name: Yup.string().when("new_provider", {
            is: true,
            then: Yup.string().required("Must enter name"),
          }),
          new_provider_email: Yup.string()
            .email()
            .when("new_provider", {
              is: true,
              then: Yup.string().email().required("Must enter email address"),
            }),
        }),
      ),
  });

export default createPersonSchema;
