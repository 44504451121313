import { Fragment, useEffect, useState } from "react";
import { Box, Typography, Grid, Alert } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AttributeModel } from "@models/AttributeModel";
import GroupedList from "./../GroupedList";
import IconButton from "@mui/material/IconButton";
import { Trans } from "@lingui/macro";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";

const Group = ({
  group,
  attributes,
  modelData,
  onGroupModifiedCb,
  model_type,
  defaultToMod,
}: any) => {
  const [attrToEdit, setAttrToEdit] = useState<any>(
    defaultToMod ? group : null,
  );
  const [expanded, setExpanded] = useState<boolean>(true);
  const [thereAreGroupsSetted, setThereAreGroupsSetted] =
    useState<boolean>(false);

  const onGroupModified = (newGroupData: AttributeModel) => {
    onGroupModifiedCb(newGroupData);
  };

  const editGroup = (e: any, group: any) => {
    setAttrToEdit(group);
    if (group && !expanded) {
      return 0;
    } else {
      // any case stop propagation
      return e.stopPropagation();
    }
  };

  const accordionChangeStatus = (
    event: React.MouseEvent<HTMLButtonElement>,
    stauts: boolean,
  ) => {
    setExpanded(stauts);
    event.stopPropagation();
  };

  const filterEmptyRecords = (attributesGroup: Array<any>) => {
    const valOnes = attributesGroup.filter((attribute: any) => {
      if (attribute.value) {
        return true;
      } else {
        return false;
      }
    });
    if (valOnes.length) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    group.children.forEach((childGroup: any) => {
      if (
        modelData.getAttributes(childGroup.alias).filter(filterEmptyRecords)
          .length &&
        !thereAreGroupsSetted
      ) {
        setThereAreGroupsSetted(true);
      }
    });
  }, [group, modelData]);

  return (
    <>
      {
        (!group.bhge) || (group.bhge && modelData.bhge) ? (
          <Box mb={2} key={group.id}>
            <Accordion
              onChange={(event: any, expanded: boolean) =>
                accordionChangeStatus(event, expanded)
              }
              defaultExpanded={expanded}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${group.id}a-content`}
                id={`panel${group.id}a-header`}
              >
                <Typography variant="h5">
                  <b>{group.name}</b>
                </Typography>
                <Box sx={{ textAlign: "right", fontSize: "12px" }}>
                  {attrToEdit && attrToEdit.id === group.id ? (
                    <Tooltip title={<Trans>Cancel</Trans>} placement="top">
                      <IconButton onClick={(e: any) => editGroup(e, null)}>
                        <CancelIcon></CancelIcon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <Fragment>
                          <Trans>Modify the</Trans> {group.name}
                        </Fragment>
                      }
                      placement="top"
                    >
                      <IconButton onClick={(e: any) => editGroup(e, group)}>
                        <EditIcon color="success"></EditIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {thereAreGroupsSetted || attrToEdit ? (
                  <Grid container>
                    {group.children.map((childGroup: any, groupIndex: number) => {
                      if (
                        modelData
                          .getAttributes(childGroup.alias)
                          .filter(filterEmptyRecords).length ||
                        attrToEdit
                      ) {
                        return (
                          <GroupedList
                            key={groupIndex}
                            editMode={attrToEdit ? true : false}
                            group={childGroup}
                            attributes={attributes}
                            groupIndex={groupIndex}
                            modelData={modelData}
                            onGroupModified={onGroupModified}
                            model_type={model_type}
                          />
                        );
                      } else return <></>;
                    })}
                  </Grid>
                ) : (
                  <Box mb={1}>
                    <Alert severity="warning">
                      <Trans>There are no attributes set for this group</Trans>
                    </Alert>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Box>
        ) : (<></>)
      }
    </>
  );
};

export default Group;
