import { createSlice } from "@reduxjs/toolkit";

interface ISaverState {
  forms: Array<{
    slug: string;
    saving: boolean;
    formState: any;
    saved: boolean;
    dirty: boolean;
    warned: boolean;
  }>;
  saveAll: { status: boolean | null; initiator: string | null };
}

const initialState: ISaverState = {
  forms: [],
  saveAll: { status: null, initiator: null },
};

export const saverSlice = createSlice({
  name: "title",
  initialState,
  reducers: {
    addForm: (state, action) => {
      const data = { ...action.payload };
      if (state.forms) {
        const exists = state.forms.find((form: any) => form.slug === data.slug);
        if (!exists) {
          state.forms.push({
            slug: data.slug,
            formState: data.formState,
            saving: false,
            saved: false,
            dirty: true,
            warned: false,
          });
        } else {
          data.saved = false;
          data.saving = false;
          data.dirty = true;
          state.forms = state.forms.map((form: any) =>
            form.slug === data.slug ? { ...form, ...data } : form,
          );
        }
      }
    },
    updateForm: (state, action) => {
      state.forms = state.forms.map((form: any) =>
        form.slug === action.payload.slug
          ? { ...form, ...action.payload }
          : form,
      );
      console.log(state.forms);
    },
    removeForm: (state, action) => {
      state.forms = state.forms.filter(
        (form: any) => form.slug !== action.payload.slug,
      );
    },
    resetFormsWarnings: (state) => {
      state.forms = [];
      state.saveAll = { status: null, initiator: null };
    },
    warnForm: (state) => {
      state.forms.filter((form: any) => form.isDirty && !form.saved);
    },
    saveAll: (state, action) => {
      state.saveAll = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addForm,
  resetFormsWarnings,
  warnForm,
  saveAll,
  updateForm,
  removeForm,
} = saverSlice.actions;

export default saverSlice.reducer;
