import IRatingFieldMolecule from "./interface";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Rating from "@mui/material/Rating";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from "@mui/material/Alert";
import { Controller } from "react-hook-form";
import ConnectForm from "@utils/ConnectForm";
import { UseFormReturn, FieldValues } from "react-hook-form";

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <RatingMolecule />
 * </FormProvider>
 * @param IRatingFieldMolecule
 * @returns
 */
const RatingMolecule = ({
  controlName,
  label,
  control,
  size,
  sError,
}: IRatingFieldMolecule) => {
  return (
    <ConnectForm>
      {({ register, setValue, formState }: UseFormReturn<FieldValues, any>) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Controller
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <Rating
                  sx={{
                    fontSize: size,
                  }}
                  value={value}
                  onChange={(e: any, newValue: any) =>
                    setValue(controlName, newValue, {
                      shouldValidate: true,
                    })
                  }
                />
                {!!formState.errors &&
                formState.errors[controlName] &&
                formState.errors[controlName]?.message ? (
                  <FormHelperText>
                    {!!formState.errors &&
                      formState.errors[controlName] &&
                      (formState.errors[controlName]?.message as String)}
                  </FormHelperText>
                ) : null}
                {sError ? (
                  <Alert severity="error" icon={false}>
                    {sError}
                  </Alert>
                ) : null}
              </>
            )}
            name={controlName}
            control={control}
          />
        </LocalizationProvider>
      )}
    </ConnectForm>
  );
};

RatingMolecule.defaultProps = {
  controlName: null,
  size: "3rem",
  label: "",
  control: null,
};

export default RatingMolecule;
