import { styled } from "@mui/system";
import { FormControlLabel, RadioGroup } from "@mui/material";

/*
interface IRadioGroupProps {
  size?: "big" | "medium" | "small" | undefined
}

export const StyledImg = styled('img')((props: ILogoProps) => ({
  maxWidth: remCalc(50)
}));
*/

interface IRadioGroupProps {
  size?: "big" | "medium" | "small" | undefined;
}

interface IFormControlLabeProps {
  active: number;
}

export const StyledRadioGroup = styled(RadioGroup)(
  (props: IRadioGroupProps) => ({
    display: "flex",
    flexDirection: "row",
    "& label": {
      overflow: "hidden",
      "&:nth-of-type(1)": {
        borderRadius: "6px 0px 0px 6px",
      },
      "&:nth-of-type(2)": {
        borderRadius: "0px 6px 6px 0px",
      },
      "& span": {
        "& svg:not(.warn)": {
          display: "none",
        },
        "& .icon-button": {
          display: "block",
        },
        "&:nth-of-type(2)": {
          margin: "0 auto",
        },
        padding: "1px",
      },
      display: "flex",
      height: "32px",
      textAlign: "center",
      textTransform: "uppercase",
      background: "#d7d7d7",
      color: "#4f4f4f57",
      width: "50%",
      margin: 0,
    },
  }),
);

export const StyledFormControlLabel = styled(FormControlLabel)(
  (props: IFormControlLabeProps) => ({
    background: props.active ? "#2059e8 !important" : "#DDDDDD",
    color: props.active ? "#FFFFFF !important" : "#333333 ",
  }),
);
