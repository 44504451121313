import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { POModel } from "@models/POModel";

class POsService extends CoreBaseService {
  constructor() {
    super(POModel);
  }
}

const inst = new POsService();

export default inst;
