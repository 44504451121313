import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import SpinnerAtom from "@atoms/Spinner";
import IInspectionDetailOrganism from "./interface";
import { t, Trans } from "@lingui/macro";
import ChipAtom from "@atoms/Chip";
import TabsMolecule from "@molecules/Tabs";
import { toast } from "react-toastify";
import { HCMModel } from "@models/HCMModel";
import HCMService from "@services/HCMService";
import HCMDetailsFormOrganism from "./Details";
import HCMDetailsActivitiesOrganism from "./Activities";
import { useConfirm } from "material-ui-confirm";
import DataBalloonAtom from "@atoms/DataBalloon";
import { StyledInspectionInfoGrid } from "./styled";

const HCMDetailOrganism = ({
  hcmIn,
  onUpdate,
  onDelete,
  onCopy,
}: IInspectionDetailOrganism) => {
  const [hcm, setResults] = useState<HCMModel | undefined>(undefined);
  const confirm = useConfirm();

  const { isLoading, data, refetch } = useQuery(
    `hcm-detail${hcmIn.id}`,
    () => HCMService.get(hcmIn.id).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: true,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
      if (onUpdate) {
        onUpdate(data.data);
      }
    }
  }, [isLoading, data]);

  const copy = () => {
    HCMService.copy(hcmIn.id).then((res: any) => {
      if (!res.hasErrors()) {
        if (onCopy) {
          // @ts-ignore
          onCopy({ ...res.getData(), ...{ instanceOf: "HCM" } });
        }
        toast(res.getMsgString(), {
          type: "success",
        });
      } else {
        toast(res.getMsgString(), {
          type: "error",
        });
      }
    });
  };

  const deleteHCM = () => {
    confirm({
      title: "Are you sure you want to delete this element?",
      description: "The action is irreversible!",
      cancellationText: "Go back",
      confirmationText: "Delete",
    }).then(() => {
      HCMService.delete(hcmIn.id).then((res: any) => {
        if (!res.hasErrors()) {
          if (onDelete) {
            // @ts-ignore
            onDelete(res.getData().id);
          }
          toast(res.getMsgString(), {
            type: "success",
          });
        } else {
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      });
    });
  };

  const handleOnModifcations = () => {
    refetch();
    if (onUpdate) {
      onUpdate();
    }
  };

  const hcmDetailPanel = () => {
    return (
      <>
        {hcm && (
          <Box mb={1}>
            <Typography variant="h2">
              <Trans>HCM details</Trans>
            </Typography>
            <Card variant="outlined" elevation={0}>
              <CardContent>
                <Grid container spacing={1} sx={{ marginTop: "0px" }}>
                  <Grid
                    item
                    md={10}
                    sx={{
                      borderRight: "1px solid #DDDDDD",
                    }}
                  >
                    <Grid container>
                      <Grid item md={1}>
                        <DataBalloonAtom
                          variant="chip"
                          title={t`Total activities`}
                          severity={"warning"}
                          data={hcm.total_activities}
                        />
                      </Grid>
                      <Grid item md={10} className="align-center">
                        <Box>
                          <Typography variant="h5" gutterBottom>
                            {hcm.client.name}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <Grid container spacing={2}>
                        <Grid item md={12}>
                          <Grid container>
                            <Grid item md={1}></Grid>
                            <Grid item md={10}>
                              <StyledInspectionInfoGrid container>
                                <Grid item md={4}>
                                  <Typography>
                                    <b>Inspector:</b>{" "}
                                    {hcm.user?.full_name ?? "--"}
                                  </Typography>
                                </Grid>
                                <Grid item md={4}>
                                  <Typography>
                                    <b>Vendor:</b>{" "}
                                    {hcm.vendor ? hcm.vendor.name : "--"}
                                  </Typography>
                                </Grid>
                              </StyledInspectionInfoGrid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item md={2}>
                    <Box pb={1}>
                      <Button onClick={copy} fullWidth variant="outlined">
                        <Trans>Copy</Trans>
                      </Button>
                    </Box>
                    <Box pb={1}>
                      <Button
                        disabled={hcm.total_activities > 0}
                        onClick={deleteHCM}
                        fullWidth
                        variant="outlined"
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}
        {hcm && (
          <TabsMolecule
            tabsLabels={[
              {
                label: "HCM details",
                child: (
                  <HCMDetailsFormOrganism
                    hcm={hcm}
                    onSave={handleOnModifcations}
                  />
                ),
              },
              {
                label: (
                  <Box>
                    <Trans>Activities</Trans>&nbsp;
                    <ChipAtom size="small" label={hcm.total_activities} />
                  </Box>
                ),
                child: (
                  <HCMDetailsActivitiesOrganism
                    onMod={handleOnModifcations}
                    hcm={hcm}
                  />
                ),
              },
            ]}
          />
        )}
      </>
    );
  };

  return <>{hcm ? hcmDetailPanel() : <SpinnerAtom />}</>;
};

export default HCMDetailOrganism;
