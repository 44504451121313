// Global styles
import "./styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";

import { useMount } from "@hooks";

// Lingui
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

import { defaultLocale, dynamicActivate } from "@helpers/i18n";
// Pages
import IndexPage from "@pages/index/";
import FetchPage from "@pages/fetch/";
import LoginPage from "@pages/login/";
import LogoutPage from "@pages/logout";
import InspectionActivitiesPage from "@pages/inspection-activities";
import AssetsPage from "@pages/assets";
import JobsPage from "@pages/jobs";
import NotificationsPage from "@pages/notifications";
import PeoplePage from "@pages/people";
import SettingsPage from "@pages/settings";
import VendorsPage from "@pages/vendors";
import ClientsPage from "@pages/clients";
import UsersPage from "@pages/users";
import DownloadPage from "@pages/download";
import UploadPage from "@pages/upload";
// Inspector PWA
import InspectorPage from "@pages/inspector";
import InspectorActivityPage from "@pages/inspector/activity";

import PartnersPage from "@pages/partners";
import SubcontractorsPage from "@pages/subcontractors";

import PrivateRoutes from "@layouts/private";
import PublicRoutes from "@layouts/public";

import { useAppDispatch } from "@stores/hooks";
import { LOCAL_STORAGE_KEYS } from "@utils/constants";
import LocalStorageManager from "@utils/LocalStorageManager";

import { checkAuth } from "@stores/reducers/authReducer";

// Axios interceptors
import { setupInterceptors } from "@utils/interceptors";

// Router
import { Routes, Route } from "react-router-dom";

// Theme
import { ThemeProvider } from "@mui/material";
import theme from "@theme/MuiTheme";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ConfirmProvider } from "material-ui-confirm";
import InspectorActivitiesPage from "@pages/inspector/activities";
import ConfirmPage from "@pages/confirm";

const queryClient = new QueryClient();

/**
 * App
 * All ROUTES of the app are here
 * Using react-router v6
 * https://reactrouter.com/docs/en/v6/getting-started/tutorial
 */

function App() {
  const dispatch: any = useAppDispatch();

  useMount(() => {
    const locale = LocalStorageManager.getItem(LOCAL_STORAGE_KEYS.LOCALE);

    // With this method we dynamically load the catalogs
    dynamicActivate(locale || defaultLocale, !locale);
    dispatch(checkAuth());
  });

  useEffect(() => {
    setupInterceptors();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ConfirmProvider>
        <ThemeProvider theme={theme}>
          <I18nProvider i18n={i18n}>
            <div className="App">
              <Routes>
                <Route element={<PublicRoutes />}>
                  <Route path="/login" element={<LoginPage />} />
                  <Route
                    path="/inspections/:id/confirm"
                    element={<ConfirmPage />}
                  ></Route>
                  <Route
                    path="/inspections/:id/upload"
                    element={<UploadPage />}
                  ></Route>
                  <Route
                    path="/inspections/:id/download"
                    element={<DownloadPage />}
                  ></Route>
                </Route>
                <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<IndexPage />} />
                  <Route path="/fetch" element={<FetchPage />}></Route>
                  <Route path="/settings" element={<SettingsPage />}>
                    <Route
                      path="/settings/users"
                      element={<UsersPage />}
                    ></Route>
                    <Route
                      path="/settings/vendors"
                      element={<VendorsPage />}
                    ></Route>
                    <Route
                      path="/settings/clients"
                      element={<ClientsPage />}
                    ></Route>
                    <Route
                      path="/settings/partners"
                      element={<PartnersPage />}
                    ></Route>
                    <Route
                      path="/settings/subcontractors"
                      element={<SubcontractorsPage />}
                    ></Route>
                  </Route>
                  <Route path="/inspector" element={<InspectorPage />}>
                    <Route
                      path="/inspector/activities"
                      element={<InspectorActivitiesPage />}
                    ></Route>
                    <Route
                      path="/inspector/activity/:id/details"
                      element={<InspectorActivityPage />}
                    ></Route>
                  </Route>
                  <Route path="/logout" element={<LogoutPage />}></Route>
                  <Route
                    path="/activities"
                    element={<InspectionActivitiesPage />}
                  ></Route>
                  <Route path="/jobs" element={<JobsPage />}></Route>
                  <Route
                    path="/notifications"
                    element={<NotificationsPage />}
                  ></Route>
                  <Route path="/assets" element={<AssetsPage />}></Route>
                  <Route path="/inspectors" element={<PeoplePage />}></Route>
                </Route>
              </Routes>
            </div>
          </I18nProvider>
        </ThemeProvider>
      </ConfirmProvider>
    </QueryClientProvider>
  );
}

export default App;
