import { styled } from "@mui/system";
import { Box, Grid } from "@mui/material";

export const StyledFuncWrapper = styled(Box)(() => ({
  marginBottom: "3px",
  border: "1px solid #DDDDDD",
}));

export const StyledInspectionInfoGrid = styled(Grid)(() => ({
  "& h5": {
    color: "#888888",
    fontSize: "19px",
    fontWeight: "300",
  },
}));

export const StyledInspectionStatusesGrid = styled(Grid)(() => ({
  marginBottom: "5px",
  "& .MuiGrid-item": {
    textAlign: "right",
    paddingRight: "10px",
  },
  "& p": {
    fontSize: "12px",
    fontWeight: "bold",
  },
}));
