import { CoreBaseService } from "@core/services/Base.service";
import { JobModel } from "@models/JobModel";

class JobsService extends CoreBaseService {
  constructor() {
    super(JobModel);
  }

  copyJob(id: number) {
    return this.sub(`${id}/copy`).create({});
  }
}

const inst = new JobsService();

export default inst;
