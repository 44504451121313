import { CoreBaseService } from "@core/services/Base.service";
import { ActivityModel } from "@models/ActivityModel";
import { InspectionModel } from "@models/InspectionModel";

class InspectionsService extends CoreBaseService {
  constructor() {
    super(InspectionModel);
  }

  getTotals() {
    return this.sub("totals").get();
  }

  copyInspection(id: number) {
    return this.sub(`${id}/copy`, InspectionModel).create({});
  }

  getActivities(id: number) {
    return this.sub(`${id}/activities`, ActivityModel).get();
  }

  setActivities(data: any, id: number) {
    return this.sub(`${id}/set_activities`).update(data, -1);
  }

  setJob(data: any, id: number) {
    return this.sub(`${id}/set_job`).update(data, -1);
  }

  sendConfirmationRequestToInspector(inspection_id: number) {
    return this.sub(`${inspection_id}/send_confirmation`).update({}, -1);
  }

  sendConfirmationRequestToVendor(
    inspection_id: number,
    destinations: { email_client: string; email_vendor: string },
  ) {
    return this.sub(`${inspection_id}/send_customerconfirmation`).update(
      destinations,
      -1,
    );
  }

  download(inspection_id: number, headers?: any) {
    return this.sub(`${inspection_id}/download`).get("", {}, false, false, {
      responseType: "blob",
    }, headers);
  }

  confirm(inspection_id: number, headers?: any) {
    return this.sub(`${inspection_id}/confirmation`).update({}, -1, headers);
  }
}

const inst = new InspectionsService();

export default inst;
