import CircularProgress from "@mui/material/CircularProgress";
import { StyledContainer } from "./styled";

const SpinnerAtom = ({ size }: any) => {
  return (
    <StyledContainer>
      <CircularProgress size={size ? size : "20px"} />
    </StyledContainer>
  );
};

export default SpinnerAtom;
