import { Box, Typography } from "@mui/material";
import { NotificationModel } from "@models/NotificationModel";
import { Trans } from "@lingui/macro";
import { useState } from "react";


interface INotificationAccordion {
    notification: NotificationModel
}

const NotificationAccordionText = ({
  notification
}: INotificationAccordion) => {
 
  const [ messageVisible, setMessageVisible ] = useState(false);

  const toggleMessage = () => {
    setMessageVisible(!messageVisible);
  }

  return (
    <Box>
        {notification.notification.user.full_name}: {notification.notification.type.name}
        <Box sx={{textDecoration:'underline'}}>
          <Typography onClick={toggleMessage}>
            {
              messageVisible ? <Trans>Hide message</Trans> : <Trans>Show message</Trans>
            }
          </Typography>
        </Box>
        { messageVisible && <Box mt={2} dangerouslySetInnerHTML={{__html: notification.text}}>
        </Box> }
    </Box>
  );
};

export default NotificationAccordionText;
