import { styled as MuiStyled } from "@mui/system";
import { FormControlLabel } from "@mui/material";

export const StyledFormControlLabel = MuiStyled(FormControlLabel)((props) => ({
  [props.theme.breakpoints.down("md")]: {
    ".MuiCheckbox-root": {
      padding: "4px 2px 4px 9px",
    },
    span: {
      fontSize: "13px !important",
    },
  },
}));
