import { Grid, Paper, Box, Divider, Tooltip } from "@mui/material";
import IProps from "./interface";
import { StyledRow } from "./styled";
import { t } from "@lingui/macro";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const JobRowMolecule = ({ job, selected, onSelected }: IProps) => {
  const selectThis = (id: number) => {
    if (onSelected) {
      onSelected(id);
    }
  };

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledRow selected={selected} onClick={() => selectThis(job.id)}>
          <Tooltip title={t`Select` + " " + job.client.name}>
            <Grid container spacing={2} sx={{ alignItems: "center" }}>
              <Grid item md={3} xs={12}>
                <Box>
                  <b>{job.client.name}</b>
                </Box>
                <Box>{`${job.type.name} - ${job.category.name}`}</Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box>{job.label}</Box>
                <Divider />
                <Box>{job.code_id}</Box>
              </Grid>
              <Grid sx={{ textAlign: "right" }} item md={4} xs={12}>
                <CheckCircleIcon color="success" className="selected-icon" />
              </Grid>
            </Grid>
          </Tooltip>
        </StyledRow>
      </Paper>
    </>
  );
};

export default JobRowMolecule;
