import React, { useState, useEffect, memo } from "react";
import IAsyncAutocompleteMolecule from "./interface";
import {
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useQuery } from "react-query";
import { Controller } from "react-hook-form";
import ConnectForm from "@utils/ConnectForm";
import { UseFormReturn, FieldValues } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { GetSubObjectByString } from "@utils/helpers";
import { useSelector } from "react-redux";

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <AsyncAutocompleteMolecule controlName="name" label='Pick a name' />
 * </FormProvider>
 * @param IAsyncSelectMolecule
 * @returns
 */
const AsyncAutocompleteMolecule = ({
  service,
  serviceMethod,
  getOptionLabel,
  freeSolo,
  label,
  filters,
  controlName,
  sError,
  except,
  storeName,
  multiple,
  storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
  cacheTime,
  lazy,
  variant,
  emptyValue,
  listId,
  optionLabel,
  optionValue,
  control,
}: IAsyncAutocompleteMolecule) => {
  const [result, setResult] = useState<Array<any>>([]);
  const [rendered, setRendered] = useState<boolean>(false);
  let timeout: any = null;

  const { isLoading, data } = useQuery(
    listId,
    () =>
      service && !lazy
        ? !serviceMethod
          ? service.get()
          : service[serviceMethod]()
        : filters,
    {
      refetchOnWindowFocus: false,
      cacheTime: cacheTime,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      if (except) {
        if (typeof except === "function") {
          data.data = data.data.filter((item: any) => {
            return !except(item);
          });
        }
        if (Array.isArray(except)) {
          data.data = data.data.filter((item: any) => {
            return !except.includes(optionValue ? item[optionValue] : item);
          });
        }
      }
      // @ts-ignore
      const dataList = data.data.filter(function (el: any) {
        return el != null;
      });
      setResult(dataList);
      if (dataList && dataList.length) {
        setRendered(true);
      }
    }
  }, [isLoading, data]);

  const loadList = async (segment: string) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (!segment || segment === "") {
      return setResult([]);
    }
    timeout = setTimeout(() => {
      if (service) {
        service.getAutocomplete(segment).then((res: any) => {
          // @ts-ignore
          setResult(
            res.getData().filter(function (el: any) {
              return el != null;
            }),
          );
        });
      }
    }, 300);
  };

  return (
    <FormControl fullWidth>
      {!rendered && isLoading && !result.length ? (
        <Skeleton height={"2.8125rem"} />
      ) : (
        <React.Fragment>
          <ConnectForm key={result.length}>
            {({
              register,
              setValue,
              formState,
            }: UseFormReturn<FieldValues, any>) => (
              <Controller
                render={({
                  field: { onChange, onBlur, value, ref, ...props },
                }) => (
                  <React.Fragment>
                    <Autocomplete
                      freeSolo={freeSolo}
                      disablePortal
                      multiple={multiple}
                      onChange={(event: any, newValue: any) => {
                        if (newValue) {
                          if (
                            typeof newValue[newValue.length - 1] !== "string"
                          ) {
                            // console.log("SETTING PREDEFINED", newValue);
                            setValue(
                              controlName,
                              optionValue
                                ? newValue
                                  ? newValue[optionValue]
                                  : null
                                : newValue,
                              {
                                shouldValidate: true,
                                shouldDirty: true,
                              },
                            );
                          } else {
                            // get
                            if (service && service.createAutocomplete) {
                              service
                                .createAutocomplete(
                                  newValue[newValue.length - 1],
                                )
                                .then((res: any) => {
                                  setValue(
                                    controlName,
                                    optionValue
                                      ? res.getData()
                                        ? res.getData()[optionValue]
                                        : null
                                      : res.getData(),
                                    {
                                      shouldValidate: true,
                                      shouldDirty: true,
                                    },
                                  );
                                });
                            } else {
                              setValue(controlName, newValue, {
                                shouldValidate: true,
                                shouldDirty: true,
                              });
                            }
                          }
                        } else {
                          setValue(
                            controlName,
                            emptyValue
                              ? emptyValue.hasOwnProperty(optionValue)
                                ? emptyValue.id
                                : emptyValue
                              : null,
                            {
                              shouldValidate: true,
                              shouldDirty: true,
                            },
                          );
                        }
                      }}
                      value={
                        typeof value === "string" && value
                          ? value
                          : value
                          ? result.find(
                              (item: any) => item.id === Number(value),
                            )
                          : emptyValue
                          ? emptyValue.hasOwnProperty(optionValue)
                            ? emptyValue.id
                            : emptyValue
                          : null
                      }
                      options={result}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {getOptionLabel
                              ? getOptionLabel(option)
                              : (item: any) => (item.name ? item.name : item)}
                          </li>
                        );
                      }}
                      getOptionLabel={
                        getOptionLabel
                          ? getOptionLabel
                          : (item: any) => (item.name ? item.name : item)
                      }
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          onChange={(event: any) =>
                            service && lazy
                              ? loadList(event.target.value)
                              : null
                          }
                          label={label}
                          {...params}
                        />
                      )}
                      {...props}
                    />
                    {!!formState.errors &&
                    formState.errors[controlName] &&
                    formState.errors[controlName]?.message ? (
                      <FormHelperText>
                        {!!formState.errors &&
                          formState.errors[controlName] &&
                          (formState.errors[controlName]?.message as String)}
                      </FormHelperText>
                    ) : null}
                  </React.Fragment>
                )}
                name={controlName}
                control={control}
              />
            )}
          </ConnectForm>
          {sError ? <FormHelperText>{sError}</FormHelperText> : null}
        </React.Fragment>
      )}
    </FormControl>
  );
};

AsyncAutocompleteMolecule.defaultProps = {
  cacheTime: 0,
  listId: String(Math.random()),
  variant: "filled",
  optionLabel: "name",
  optionValue: null,
};

export default memo(AsyncAutocompleteMolecule);

const AutocompleteMoleculeB = ({
  service,
  serviceMethod,
  getOptionLabel,
  label,
  lazy,
  filters,
  emptyValue,
  controlName,
  sError,
  storeName,
  storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
  cacheTime,
  except,
  variant,
  listId,
  freeSolo,
  multiple,
  optionLabel,
  optionValue,
  control,
}: IAsyncAutocompleteMolecule) => {
  const [key, setKey] = useState<number>(0);
  const [rendered, setRendered] = useState<boolean>(false);

  const list = useSelector((state: any) =>
    storeCollection
      ? GetSubObjectByString(state.filters.filters, storeCollection)
      : filters
      ? filters
      : null,
  );

  useEffect(() => {
    setKey(key + 1);
    if (list && list.length) {
      setRendered(true);
    }
  }, [list]);

  return list ? (
    <AsyncAutocompleteMolecule
      key={key}
      filters={{ data: list }}
      service={service}
      serviceMethod={serviceMethod}
      getOptionLabel={getOptionLabel}
      label={label}
      freeSolo={freeSolo}
      lazy={lazy}
      except={except}
      controlName={controlName}
      emptyValue={emptyValue}
      multiple={multiple}
      sError={sError}
      storeName={storeName}
      storeCollection={storeCollection}
      cacheTime={cacheTime}
      variant={variant}
      listId={listId}
      optionLabel={optionLabel}
      optionValue={optionValue}
      control={control}
    />
  ) : !rendered ? (
    <Skeleton height={"2.8125rem"} />
  ) : (
    <></>
  );
};

AutocompleteMoleculeB.defaultProps = {
  cacheTime: 0,
  listId: String(Math.random()),
  variant: "filled",
  optionLabel: "name",
  optionValue: null,
};

export const AutocompleteMolecule = memo(AutocompleteMoleculeB);
