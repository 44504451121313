import { CoreBaseModel } from "@core/models/Base.model";
import { IUser } from "@interfaces/IUser";

/**
 * AuthModel class
 * IUser interface
 */
export class AuthModel extends CoreBaseModel implements IUser {
  token?: string;
  user?: any;

  /**
   * This is used by the authentication system.
   * You can change it according to your properties, but it is not recommended to change it.
   * @returns boolean
   */
  /*
  get token(): string | undefined {
    return this.token;
  }
  */

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  /**
   * Service resource name (api endpoint)
   * process.env.REACT_APP_PUBLIC_API_ENDPOINT + '/auth'
   */
  static getResourceName() {
    return "auth/login";
  }

  /**
   * This is used by the authentication system.
   * You can change it according to your properties, but it is not recommended to change it.
   * @returns boolean
   */
  hasToken(): boolean {
    return this.token != null;
  }

  getInitials() {
    return this.user.name.charAt(0) + this.user.surname.charAt(0);
  }

  isAdmin() {
    return this.user.role.name === "admin";
  }

  isInspector() {
    return this.user?.role.name === "inspector";
  }

  can(action: string) {
    return this.user?.role.permissions.find(
      (permission: any) => permission.name === action || permission.name === "admin",
    );
  }
}
