import { CoreBaseModel } from "@core/models/Base.model";
import { IType } from "@interfaces/IType";
import { ICategory } from "@interfaces/ICategory";

export class PeopleModel extends CoreBaseModel {
  date: string;
  attributes: Array<any>;
  asset: any;
  active: boolean;
  internal: boolean;
  verified: boolean;
  role: any;
  role_id: number;
  location: any;
  location_id: number;
  phone: string;
  type: IType;
  country: any;
  country_id: number;
  city: string;
  partners: any;
  type_id: number;
  created_at: string;
  category: ICategory;
  category_id: number;
  id: number;
  deleted_at: null;
  email: string;
  surname: string;
  name: string;
  full_name: string;
  updated_at: string;
  bhge: boolean;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
    if (!this.attributes) {
      this.attributes = [];
    }
  }

  static getResourceName() {
    return "users";
  }

  getAttributes(alias?: string) {
    if (alias && Array.isArray(this.attributes)) {
      const group = this.attributes.find((group: any) => group.alias === alias);
      if (group) return group.attributes;
      return [];
    } else {
      return this.attributes ? this.attributes : [];
    }
  }
}
