import { memo, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { t, Trans } from "@lingui/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import isEqual from "lodash/isEqual";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import TextFieldAtom from "@atoms/TextField";
import Typography from "@mui/material/Typography";
import { SelectMolecule } from "@molecules/AsyncSelect";
import AsyncSelectMolecule from "@molecules/AsyncSelect";
import type { IPeopleFilterProps } from "./interface";
import type { IPeopleFilterOrganismProps } from "./interface";
import { peopleFilterValidationSchema } from "@validation/peopleFilter";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import { IDS } from "@helpers/constants";
import DateFieldMolecule from "@molecules/DateField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@molecules/Switch";
import UserService from "@services/UserService";
import { AutocompleteMolecule } from "@molecules/AsyncAutocomplete";
import { useQuery } from "react-query";

const PeopleFilterOrganism = ({
  filter,
  setFilter,
  attributes,
}: IPeopleFilterOrganismProps) => {
  // const [ attributes, setAttributes ] = useState<any>([]);
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const [cities, setCities] = useState<[]>([]);
  console.log(attributes);
  const formMethods = useForm<IPeopleFilterProps>({
    mode: "onChange",
    defaultValues: filter,
    reValidateMode: "onSubmit",
    resolver: yupResolver(peopleFilterValidationSchema),
  });

  const { watch, handleSubmit, reset, getValues, setValue } = formMethods;
  const values = watch();
  const isAvailableForSearch = !isEqual(filter, values);

  const isRadius = watch("isRadius");

  const { isLoading: isLoadingCities, data: citiesList } = useQuery(
    `inspector-filter-cities`,
    () => UserService.getCities().then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!isLoadingCities && citiesList) {
      setCities(citiesList.getData());
    }
  }, [isLoadingCities, citiesList]);

  useEffect(() => {
    if (!isRadius) {
      setValue("radius", undefined);
    }
  }, [isRadius]);

  useEffect(() => {
    reset(filter);
  }, [filter, reset]);

  const handleFormSubmit = (data: IPeopleFilterProps) => {
    data.role_id = IDS.ROLES.INSPECTOR;
    setFilter(data);
  };

  const formField = (attribute: any, index: number) => {
    if (attribute.type.alias === "select") {
      return (
        <AsyncSelectMolecule
          listId={`${attribute.label}.${index}.${attribute.id}`}
          control={formMethods.control}
          controlName={`attributes.${attribute.id}`}
          optionValue={"value"}
          variant={"outlined"}
          label={attribute.label}
          filters={{ data: attribute.options }}
          optionLabel={"label"}
        ></AsyncSelectMolecule>
      );
    }
    if (attribute.type.alias === "text") {
      return (
        <TextFieldAtom
          controlName={`attributes.${attribute.id}`}
          variant={"outlined"}
          label={attribute.label}
        ></TextFieldAtom>
      );
    }
    if (attribute.type.alias === "date") {
      return (
        <DateFieldMolecule
          control={formMethods.control}
          controlName={`attributes.${attribute.id}`}
          inputFormat={"dd/MM/yyyy"}
          label={attribute.label}
        ></DateFieldMolecule>
      );
    }
    if (attribute.type.alias === "checkbox") {
      return (
        <AsyncSelectMolecule
          listId={`${attribute.name}`}
          control={formMethods.control}
          emptyValue={[]}
          nullable={false}
          multiple={true}
          controlName={`attributes.${attribute.id}`}
          optionValue={"value"}
          variant={"outlined"}
          label={attribute.label}
          filters={{ data: attribute.options }}
          optionLabel={"label"}
        ></AsyncSelectMolecule>
      );
    }
    if (attribute.type.alias === "boolean") {
      return (
        <Switch
          controlName={`attributes.${attribute.id}`}
          control={formMethods.control}
          label={attribute.label}
        ></Switch>
      );
    }
  };

  return (
    <Paper
      variant="outlined"
      elevation={0}
      sx={{ paddingY: 2, maxHeight: "91vh", overflowY: "auto" }}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormProvider {...formMethods}>
          <Stack spacing={2}>
            <Stack px={2}>
              <Button
                sx={{ height: "48px" }}
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isAvailableForSearch}
                type="submit"
              >
                <Trans>Apply filters</Trans>
              </Button>
            </Stack>
            <Divider />
            <Stack px={2}>
              <TextFieldAtom
                controlName="search"
                label={t`Name`}
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
            <Divider />
            <Stack px={2}>
              <b>
                <Trans>General</Trans>
              </b>
            </Stack>
            {
              <Stack sx={{ paddingX: 2 }}>
                <AutocompleteMolecule
                  control={formMethods.control}
                  controlName="country_id"
                  optionValue={"id"}
                  variant={"outlined"}
                  emptyValue={null}
                  label={"Country"}
                  storeCollection="countries"
                  optionLabel={"name"}
                  getOptionLabel={(country: any) => country.name}
                ></AutocompleteMolecule>
              </Stack>
            }
            <Stack sx={{ paddingX: 2 }}>
              <SelectMolecule
                control={formMethods.control}
                controlName="type_id"
                optionValue={"id"}
                variant={"outlined"}
                emptyValue={null}
                label={"Source"}
                storeCollection="types.inspections"
                optionLabel={"name"}
              ></SelectMolecule>
            </Stack>
            <Divider />
            {isAdvanced && (
              <Box>
                <Box px={2}>
                  <b>
                    <Trans>Advanced filters</Trans>
                  </b>
                </Box>
                <Box p={2}>
                  <Stack>
                    <Grid container spacing={1}>
                      <Grid item md={7}>
                        <AutocompleteMolecule
                          control={formMethods.control}
                          listId={"filter-users-cities"}
                          controlName="city"
                          variant={"outlined"}
                          emptyValue={null}
                          getOptionLabel={(cityName: any) => cityName}
                          label={"City"}
                          filters={cities}
                        ></AutocompleteMolecule>
                      </Grid>
                      <Grid item md={5}>
                        <Switch
                          controlName={"isRadius"}
                          control={formMethods.control}
                          label={t`Radius`}
                        ></Switch>
                      </Grid>
                    </Grid>
                    {getValues().isRadius && (
                      <Box mt={2}>
                        <Grid container>
                          <Grid item md={12}>
                            <AsyncSelectMolecule
                              control={formMethods.control}
                              controlName="radius"
                              optionValue={"value"}
                              listId={"radius-lists"}
                              variant={"outlined"}
                              emptyValue={null}
                              label={"Radius"}
                              filters={{
                                data: [
                                  {
                                    label: "20km",
                                    value: 20,
                                  },
                                  {
                                    label: "40km",
                                    value: 40,
                                  },
                                  {
                                    label: "60km",
                                    value: 60,
                                  },
                                  {
                                    label: "80km",
                                    value: 80,
                                  },
                                  {
                                    label: "100km",
                                    value: 100,
                                  },
                                  {
                                    label: "120km",
                                    value: 120,
                                  },
                                  {
                                    label: "140km",
                                    value: 140,
                                  },
                                  {
                                    label: "160km",
                                    value: 160,
                                  },
                                  {
                                    label: "180km",
                                    value: 180,
                                  },
                                  {
                                    label: "200km",
                                    value: 200,
                                  },
                                ],
                              }}
                              optionLabel={"label"}
                            ></AsyncSelectMolecule>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </Stack>
                </Box>
                <Divider />
                {attributes.length &&
                  attributes.map((attributesGroup: any, index: number) => {
                    if (!attributesGroup.children.length) {
                      return (
                        <Stack key={index}>
                          <Accordion elevation={0} className="mini">
                            <AccordionSummary
                              sx={{
                                padding: "15px",
                                borderBottom: "1px solid #DDDDDD",
                              }}
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Typography>
                                <b>{attributesGroup.name}</b>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {attributesGroup.attributes.map(
                                (attribute: any, index: number) => {
                                  return attributesGroup.attributes[index]
                                    .is_filter ? (
                                    <Grid
                                      key={"a-" + index}
                                      container
                                      spacing={1}
                                      mt={1}
                                    >
                                      <Grid item md={12} xs={12}>
                                        {attributesGroup.attributes[index] &&
                                          formField(
                                            attributesGroup.attributes[index],
                                            index,
                                          )}
                                      </Grid>
                                    </Grid>
                                  ) : (
                                    ""
                                  );
                                },
                              )}
                            </AccordionDetails>
                          </Accordion>
                        </Stack>
                      );
                    } else {
                      return (
                        <Stack key={index}>
                          <Accordion elevation={0} className="mini">
                            <AccordionSummary
                              sx={{
                                padding: "15px",
                                borderBottom: "1px solid #DDDDDD",
                              }}
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <Typography>
                                <b>{attributesGroup.name}</b>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {
                                <Box mt={0}>
                                  <Grid container spacing={2} mt={1}>
                                    {attributesGroup.children.map(
                                      (childGroup: any) => {
                                        return childGroup.attributes.map(
                                          (attribute: any, index: number) => {
                                            return childGroup.attributes[index]
                                              .is_filter ? (
                                              <Grid
                                                key={"c-" + index}
                                                item
                                                md={6}
                                                xs={12}
                                              >
                                                {childGroup.attributes[index] &&
                                                  formField(
                                                    childGroup.attributes[
                                                      index
                                                    ],
                                                    index,
                                                  )}
                                              </Grid>
                                            ) : (
                                              ""
                                            );
                                          },
                                        );
                                      },
                                    )}
                                  </Grid>
                                </Box>
                              }
                            </AccordionDetails>
                          </Accordion>
                        </Stack>
                      );
                    }
                  })}
              </Box>
            )}
            <Stack sx={{ paddingX: 2 }}>
              <Button
                sx={{ height: "28px" }}
                fullWidth
                onClick={() => setIsAdvanced(isAdvanced ? false : true)}
                variant="outlined"
                color="primary"
              >
                {!isAdvanced ? (
                  <Trans>Advanced filters</Trans>
                ) : (
                  <Trans>Basic filters</Trans>
                )}
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </form>
    </Paper>
  );
};

export default memo(PeopleFilterOrganism);
