import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const PageHeader = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const StyledTitle = styled(Typography)`
  font-size: 14px;
  text-transform: uppercase;
  color: grey;
  margin-top: 20px;
  padding-bottom: 5px;
`;
