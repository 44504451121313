import { CoreBaseService } from "@core/services/Base.service";
import { HCMActivityModel } from "@models/HCMActivityModel";

class HCMActivitiesService extends CoreBaseService {
  constructor() {
    super(HCMActivityModel);
  }
}

const inst = new HCMActivitiesService();

export default inst;
