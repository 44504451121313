import { CoreBaseModel } from "@core/models/Base.model";

export class ClientModel extends CoreBaseModel {
  id: number;
  name: string;
  last_name: string;
  email: string;
  type: any;
  country_id: number;
  country: any;
  type_id: number;
  phone: string;
  division: string;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "customers";
  }
}
