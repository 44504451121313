import * as Yup from "yup";
import { t } from "@lingui/macro";

const createAdminSchema = Yup.object().shape({
  role_id: Yup.string().required(t`Necessaria`),
  email: Yup.string().required(t`Necessaria`),
  name: Yup.string().required(t`Necessaria`),
  surname: Yup.string().required(t`Necessaria`),
  phone: Yup.string(),
});

export default createAdminSchema;
