import * as React from "react";
import { Box, Typography, Alert } from "@mui/material";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ButtonAtom from "@atoms/Button";
import { Trans, t } from "@lingui/macro";
import InspectionsService from "@services/InspectionsService";
import DatePrintAtom from "@atoms/DatePrint";
import Popover from "@mui/material/Popover";
import { FormProvider, useForm } from "react-hook-form";
import TextFieldAtom from "@atoms/TextField";
import { yupResolver } from "@hookform/resolvers/yup";
import SwitchMolecule from "@molecules/Switch";
import * as Yup from "yup";

export const YupSchema = Yup.object().shape({
  email_client: Yup.string().email().nullable(),
  email_vendor: Yup.string().email().nullable(),
});

const ActionsEventsOrganism = ({ inspection, disableButtons }: any) => {
  const [cfIDate, setCfIDate] = React.useState<string>(
    inspection.confirmation_sent_at ?? "",
  );
  const [cfVCDate, setCfVCDate] = React.useState<string>(
    inspection.customer_confirmation_sent_at ?? "",
  );
  const [sErrors /*, setSErrors*/] = React.useState(null);

  const formInstance = useForm<any>({
    defaultValues: {
      email_client: "",
      email_vendor: "",
      make_email_default: false,
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(YupSchema),
  });

  const {
    getValues,
    formState: { isValid },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const sendCToVendorClient = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate: crInspector, isLoading: sendingCrInspector } = useMutation(
    () =>
      InspectionsService.sendConfirmationRequestToInspector(inspection.id).then(
        (res: any) => {
          if (!res.hasErrors()) {
            setCfIDate(res.getData().confirmation_sent_at);
            return toast(res.getMsgString(), {
              type: res.hasErrors() ? "error" : "success",
            });
          } else {
            toast(res.getMsgString(), {
              type: "error",
            });
          }
        },
      ),
  );

  const { mutate: crVendor, isLoading: sendingCrVendor } = useMutation(() =>
    InspectionsService.sendConfirmationRequestToVendor(
      inspection.id,
      getValues(),
    ).then((res: any) => {
      if (!res.hasErrors()) {
        setCfVCDate(res.getData().customer_confirmation_sent_at);
        toast(res.getMsgString(), {
          type: res.hasErrors() ? "error" : "success",
        });
        return handleClosePopover();
      } else {
        toast(res.getMsgString(), {
          type: "error",
        });
      }
    }),
  );

  const handleSubmit = (data: any) => {
    crVendor();
  };

  return (
    <Box sx={{ width: "100%" }}>
      {disableButtons && (
        <Alert severity="warning">
          <Trans>The inspection was edited. Save to enable.</Trans>
        </Alert>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        PaperProps={{
          style: { width: "30%" },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={3}>
          <h4>
            <Trans>Send confirmation request to client/vendor</Trans>
          </h4>
          <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
            <FormProvider {...formInstance}>
              <Box mb={2}>
                <TextFieldAtom
                  controlName={"email_vendor"}
                  variant={"outlined"}
                  InputLabelProps={{ shrink: true }}
                  placeholder={inspection.subvendor ? inspection.subvendor.email : inspection.vendor ? inspection.vendor.email : ""}
                  label={inspection.subvendor ? <Trans>Subvendor email</Trans> : <Trans>Vendor email</Trans>}
                />
                {sErrors && sErrors["label"] ? (
                  <Alert severity="error" icon={false}>
                    {sErrors["label"]}
                  </Alert>
                ) : null}
              </Box>
              <Box>
                <TextFieldAtom
                  controlName={"email_client"}
                  variant={"outlined"}
                  placeholder={
                    inspection.job
                      ? inspection.job.client
                        ? inspection.job.client.email
                        : ""
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                  label={<Trans>Client email</Trans>}
                />
                <SwitchMolecule
                    controlName={"make_email_default"}
                    control={formInstance.control}
                    label={"Make default"}
                  />
                {sErrors && sErrors["label"] ? (
                  <Alert severity="error" icon={false}>
                    {sErrors["label"]}
                  </Alert>
                ) : null}
              </Box>
              <Box mt={1} className="text-right">
                <ButtonAtom
                  disabled={!isValid}
                  loading={sendingCrVendor}
                  type="submit"
                  label={t`Send confirmation`}
                />
              </Box>
            </FormProvider>
          </form>
        </Box>
      </Popover>
      {cfIDate && (
        <Box className="d-flex align-center gap-5">
          <Typography>
            <b>
              <Trans>Sent request to inspector at:</Trans>
            </b>
          </Typography>
          <DatePrintAtom format="DD/MM/yyyy">{cfIDate}</DatePrintAtom>
        </Box>
      )}
      {cfVCDate && (
        <Box className="d-flex align-center gap-5">
          <Typography>
            <b>
              <Trans>Sent request to client/vendor at:</Trans>
            </b>
          </Typography>
          <DatePrintAtom format="DD/MM/yyyy">{cfVCDate}</DatePrintAtom>
        </Box>
      )}
      {inspection.confirmation && (
        <Box mb={1}>
          <Box className="d-flex align-center gap-5">
            <Typography>
              <b>
                <Trans>Confirmed at</Trans>:
              </b>
            </Typography>
            <DatePrintAtom format="DD/MM/yyyy">
              {inspection.confirmation}
            </DatePrintAtom>
          </Box>
        </Box>
      )}
      {inspection.download && (
        <Box mt={1} mb={1}>
          <Box className="d-flex align-center gap-5">
            <Typography>
              <b>
                <Trans>Downloaded at</Trans>:
              </b>
            </Typography>
            <DatePrintAtom format="DD/MM/yyyy">
              {inspection.download}
            </DatePrintAtom>
          </Box>
        </Box>
      )}
      <Box mt={2} mb={1}>
        <ButtonAtom
          disabled={disableButtons}
          loading={sendingCrInspector}
          onClick={() => crInspector()}
          color="success"
          label={t`Send confirmation request to inspector`}
        />
      </Box>
      <Box mt={1} mb={1}>
        <ButtonAtom
          disabled={disableButtons}
          loading={sendingCrVendor}
          onClick={sendCToVendorClient}
          color="info"
          label={t`Send confirmation request to client/vendor`}
        />
      </Box>
    </Box>
  );
};

export default ActionsEventsOrganism;
