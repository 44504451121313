import { ClientModel } from "@models/ClientModel";
import * as Yup from "yup";
import { t } from "@lingui/macro";

export interface IFormValues {
  name: string | null;
  type_id: number;
  email: string | null;
  phone: string | null;
  country_id: number | null;
  division: string | undefined;
}

export default interface IUserCreateOrganism {
  mode?: string;
  label: string;
  typeId: number;
  hideAttributes?: boolean;
  user?: ClientModel;
  onUpdate?: (person: ClientModel) => void;
  onNew?: (person: ClientModel) => void;
}

export const createVendorSchema = Yup.object().shape({
  email: Yup.string().required(t`Necessaria`),
  name: Yup.string().required(t`Necessaria`),
  country_id: Yup.number().required(t`Necessaria`),
  division: Yup.string(),
});

export const createSubcontractorSchema = Yup.object().shape({
  email: Yup.string().required(t`Necessaria`),
  name: Yup.string().required(t`Necessaria`),
  country_id: Yup.number().required(t`Necessaria`),
  phone: Yup.string(),
});
