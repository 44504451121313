import { useState, useEffect } from "react";
import { Box } from "@mui/material";
import IAttributesList from "./interface";
import { useQuery } from "react-query";
import AttributesService from "@services/AttributesService";
import SpinnerAtom from "@atoms/Spinner";
import AttributesFormLayer from "./Form";

const AttributesListMolecule = ({
  modelType,
  setValue,
  notifyValidation,
  onFormChange,
}: IAttributesList) => {
  const [list, setList] = useState([]);

  const { data, isLoading } = useQuery(
    `attribues-${modelType}`,
    () => AttributesService.get("", { model_type: modelType }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const formHasChanged = (values: any) => {
    if (setValue) {
      setValue(values);
    }
    if (onFormChange) {
      onFormChange(values);
    }
  };

  useEffect(() => {
    if (!isLoading && data) {
      // @ts-ignore
      setList(data.getData());
    }
  }, [data, isLoading]);

  return (
    <>
      <Box>
        {isLoading ? (
          <SpinnerAtom />
        ) : (
          <Box>
            <AttributesFormLayer
              list={list}
              onFormChange={formHasChanged}
              notifyValidation={notifyValidation}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default AttributesListMolecule;
