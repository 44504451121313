import { styled } from "@mui/system";
import { Paper } from "@mui/material";

export const StyledPaper = styled(Paper)((props: any) => ({
  position: "fixed",
  zIndex: 9,
  bottom: 0,
  display: "flex",
  justifyContent: "center",
  left: 0,
  right: 0,
}));
