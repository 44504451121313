import React, { useState, useEffect, memo } from "react";
import IAsyncAutocompleteMolecule from "./interface";
import {
  FormControl,
  FormHelperText,
  Autocomplete,
  TextField,
  Box,
} from "@mui/material";
import { useQuery } from "react-query";
import { Controller } from "react-hook-form";
import ConnectForm from "@utils/ConnectForm";
import { UseFormReturn, FieldValues } from "react-hook-form";
import Alert from "@mui/material/Alert";
import { GetSubObjectByString } from "@utils/helpers";
import { useSelector } from "react-redux";
import SpinnerAtom from "@atoms/Spinner";

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <AsyncAutocompleteMolecule controlName="name" label='Pick a name' />
 * </FormProvider>
 * @param IAsyncSelectMolecule
 * @returns
 */
const AsyncAutocompleteMolecule = ({
  service,
  serviceMethod,
  getOptionLabel,
  freeSolo,
  label,
  filters,
  controlName,
  onCreate,
  onWrite,
  sError,
  typeId,
  except,
  storeName,
  multiple,
  storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
  cacheTime,
  lazy,
  defaultValue,
  variant,
  emptyValue,
  listId,
  optionLabel,
  optionValue,
  control,
}: IAsyncAutocompleteMolecule) => {
  const [result, setResult] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  let timeout: any = null;

  const { isLoading, data } = useQuery(
    listId,
    () =>
      service && !lazy && !filters
        ? !serviceMethod
          ? service.get()
          : service[serviceMethod]()
        : filters,
    {
      refetchOnWindowFocus: false,
      cacheTime: cacheTime,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      if (except) {
        if (typeof except === "function") {
          data.data = data.data.filter((item: any) => {
            return !except(item);
          });
        }
        if (Array.isArray(except)) {
          data.data = data.data.filter((item: any) => {
            return !except.includes(optionValue ? item[optionValue] : item);
          });
        }
      }
      // @ts-ignore
      setResult(
        data.data.filter(function (el: any) {
          return el != null;
        }),
      );
    }
  }, [isLoading, data]);

  const loadList = async (segment: string) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    if (!segment || segment === "") {
      return setResult([]);
    }
    timeout = setTimeout(() => {
      if (service) {
        service.getAutocomplete(segment).then((res: any) => {
          // @ts-ignore
          setResult(
            res.getData().filter(function (el: any) {
              return el != null;
            }),
          );
        });
      }
    }, 300);
  };

  return (
    <FormControl fullWidth>
      {
        /*isLoading ? 
                    <Skeleton height={'2.8125rem'}/>
                : */
        <React.Fragment>
          <ConnectForm>
            {({
              register,
              getValues,
              setValue,
              formState,
            }: UseFormReturn<FieldValues, any>) => (
              <Controller
                render={({
                  field: { onChange, onBlur, value, ref, ...props },
                }) => (
                  <React.Fragment>
                    {loading && (
                      <Box className="overlay-universal">
                        <SpinnerAtom size="18px" />
                      </Box>
                    )}
                    <Autocomplete
                      freeSolo={freeSolo}
                      disablePortal
                      defaultValue={defaultValue}
                      multiple={multiple}
                      value={value}
                      onChange={(event: any, newValue: any) => {
                        // New string
                        if (typeof newValue[newValue.length - 1] === "string") {
                          setLoading(true);
                          service
                            .createAutocomplete(
                              newValue[newValue.length - 1],
                              typeId,
                            )
                            .then((res: any) => {
                              const data = [...value, ...[res.getData()]];
                              setLoading(false);
                              if (onCreate) {
                                onCreate();
                              }
                              filters = {
                                ...{
                                  data: filters.data.push(res.getData()),
                                },
                              };
                              setValue(controlName, data, {
                                shouldValidate: true,
                              });
                            });
                        } else {
                          setValue(controlName, newValue, {
                            shouldValidate: true,
                          });
                        }
                      }}
                      options={result}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {getOptionLabel
                              ? getOptionLabel(option)
                              : (item: any) => (item.name ? item.name : item)}
                          </li>
                        );
                      }}
                      getOptionLabel={
                        getOptionLabel
                          ? getOptionLabel
                          : (item: any) => (item.name ? item.name : item)
                      }
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          onChange={(event: any) => {
                            if (service && lazy) loadList(event.target.value);
                            if (onWrite) {
                              onWrite(event.target.value);
                            }
                          }}
                          label={label}
                          {...params}
                        />
                      )}
                      {...props}
                    />
                    {!!formState.errors &&
                    formState.errors[controlName] &&
                    formState.errors[controlName]?.message ? (
                      <FormHelperText>
                        {!!formState.errors &&
                          formState.errors[controlName] &&
                          (formState.errors[controlName]?.message as String)}
                      </FormHelperText>
                    ) : null}
                  </React.Fragment>
                )}
                name={controlName}
                control={control}
              />
            )}
          </ConnectForm>
          {sError ? (
            <Alert severity="error" icon={false}>
              {sError}
            </Alert>
          ) : null}
        </React.Fragment>
      }
    </FormControl>
  );
};

AsyncAutocompleteMolecule.defaultProps = {
  cacheTime: 0,
  listId: String(Math.random()),
  variant: "filled",
  optionLabel: "name",
  optionValue: null,
};

export default memo(AsyncAutocompleteMolecule);

const AutocompleteMoleculeB = ({
  service,
  serviceMethod,
  getOptionLabel,
  label,
  lazy,
  filters,
  emptyValue,
  controlName,
  sError,
  storeName,
  storeCollection, // can be for instance -> 'types' or 'types.activites' to get a sub list
  cacheTime,
  typeId,
  defaultValue,
  except,
  variant,
  listId,
  onWrite,
  freeSolo,
  multiple,
  optionLabel,
  onCreate,
  optionValue,
  control,
}: IAsyncAutocompleteMolecule) => {
  const list = useSelector((state: any) =>
    storeCollection
      ? GetSubObjectByString(state.filters.filters, storeCollection)
      : null,
  );

  return list ? (
    <AsyncAutocompleteMolecule
      filters={{ data: list }}
      service={service}
      onCreate={onCreate}
      onWrite={onWrite}
      serviceMethod={serviceMethod}
      getOptionLabel={getOptionLabel}
      label={label}
      freeSolo={freeSolo}
      typeId={typeId}
      defaultValue={defaultValue}
      lazy={lazy}
      except={except}
      controlName={controlName}
      emptyValue={emptyValue}
      multiple={multiple}
      sError={sError}
      storeName={storeName}
      storeCollection={storeCollection}
      cacheTime={cacheTime}
      variant={variant}
      listId={listId}
      optionLabel={optionLabel}
      optionValue={optionValue}
      control={control}
    />
  ) : null;
};

AutocompleteMoleculeB.defaultProps = {
  cacheTime: 0,
  listId: String(Math.random()),
  variant: "filled",
  optionLabel: "name",
  optionValue: null,
};

export const AutocompleteMolecule = memo(AutocompleteMoleculeB);
