import { Controller } from "react-hook-form";
import ConnectForm from "@utils/ConnectForm";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { Radio } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IRadioGroupMolecule, { IRadio } from "./interface";

const RadioGroupMolecule = ({
  radios,
  controlName,
  control,
}: IRadioGroupMolecule) => {
  return (
    <>
      <ConnectForm>
        {({ register, getValues }: UseFormReturn<FieldValues, any>) => (
          <Controller
            name={controlName}
            control={control}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                style={{ flex: 1 }}
                row
                value={value}
                onChange={onChange}
              >
                {radios.map((radio: IRadio, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={radio.value}
                      label={radio.label}
                      control={<Radio />}
                    />
                  );
                })}
              </RadioGroup>
            )}
          />
        )}
      </ConnectForm>
    </>
  );
};

export default RadioGroupMolecule;
