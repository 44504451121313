import { createSlice } from "@reduxjs/toolkit";

export const titleSlice = createSlice({
  name: "title",
  initialState: {
    title: "",
  },
  reducers: {
    set: (state, action) => {
      state.title = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { set } = titleSlice.actions;

export default titleSlice.reducer;
