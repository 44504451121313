import { useEffect, useState } from "react";
import { Grid, Box, Typography, Alert } from "@mui/material";
import { Trans } from "@lingui/macro";
import IEvaluateOrganism from "./interface";
import { useQuery } from "react-query";
import AnswersService from "@services/AnswersService";
import SpinnerAtom from "@atoms/Spinner";
import ChipAtom from "@atoms/Chip";

const EvaluationOrganism = ({ activity }: IEvaluateOrganism) => {
  const [results, setResults] = useState<any>([]);

  const { isLoading, data } = useQuery(
    `evaluation-${activity.id}`,
    () =>
      AnswersService.get("", {
        model_id: activity.id,
        model_type: "activities",
        user_id: activity.user?.id,
      }).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setResults(data.data);
    }
  }, [isLoading, data]);

  return (
    <Box mt={2}>
      <b>
        <Trans>Evaluation</Trans>
      </b>
      {isLoading && <SpinnerAtom />}
      <Box mt={2}>
        {!isLoading && results.length ? (
          results.map((answer: any, index: number) => {
            return (
              <Grid container mb={1} key={index}>
                <Grid item md={10} xs={10}>
                  <Typography sx={{ fontSize: "13px" }}>
                    {answer.question.title}
                  </Typography>
                </Grid>
                <Grid sx={{ textAlign: "right" }} item md={2} xs={2}>
                  <ChipAtom
                    variant="filled"
                    color={
                      [0, 1].includes(answer.vote)
                        ? "error"
                        : [0, 1, 2, 3].includes(answer.vote)
                        ? "warning"
                        : "success"
                    }
                    label={answer.vote}
                  />
                </Grid>
              </Grid>
            );
          })
        ) : (
          <></>
        )}
        {!isLoading && !results.length ? (
          <Alert severity="info">
            <Trans>Not yet evaluated</Trans>
          </Alert>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default EvaluationOrganism;
