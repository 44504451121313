import { CoreBaseModel } from "@core/models/Base.model";

export class DocumentModel extends CoreBaseModel {
  file: string;
  type: string;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "documents";
  }

  isImage() {
    const ext = this.file.split(".").pop();
    if (ext) {
      return ["jpeg", "jpg", "bmp", "gif", "png"].includes(ext);
    } else {
      return false;
    }
  }

  isPdf() {
    const ext = this.file.split(".").pop();
    if (ext) {
      return ["pdf"].includes(ext);
    } else {
      return false;
    }
  }
}
