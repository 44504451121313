import { useEffect, useState } from "react";
import IOrganism, { defaultData } from "./interface";
import { Box, Grid, Typography } from "@mui/material";
import { Trans, t } from "@lingui/macro";
import ButtonAtom from "@atoms/Button";
import { FormProvider, useForm } from "react-hook-form";
import { SelectMolecule } from "@molecules/AsyncSelect";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StatusesMolecule from "@molecules/Statuses";
import DateFieldMolecule from "@molecules/DateField";
import TimeFieldMolecule from "@molecules/TimePicker";
import ActivitiesService from "@services/ActivitiesService";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import moment from "moment";
import Notes from "@organisms/Notes";
import GoogleAutocomplete from "@molecules/GoogleAutocomplete";
import { messageService } from "@utils/messagesService";
import { useAppDispatch } from "@stores/hooks";
import { addForm, updateForm } from "@stores/reducers/saverReducer";
import { useSelector } from "react-redux";
import SpinnerAtom from "@atoms/Spinner";
import ReportUpload from "@organisms/ReportUpload";
import DatePrintAtom from "@atoms/DatePrint";
import { ActivityModel } from "@models/ActivityModel";

const InspActivityFormOrganism = ({
  activity,
  inspection,
  exceptDates,
  onSave,
  hideReportStatus,
  onDirty,
  saveFormKey,
}: IOrganism) => {
  const [sErrors, setSErrors] = useState(null);
  const dispatch = useAppDispatch();
  const autoSave = useSelector((state: any) => state.saver.saveAll);
  const [sendingReminder, setSendingReminder] = useState(false);

  const formInstance = useForm({
    mode: "onChange",
    defaultValues: defaultData(activity),
    reValidateMode: "onSubmit",
  });

  const {
    control,
    reset,
    getValues,
    formState: { isValid, isDirty },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const handleReset = (updSaver = true, inputActivity?: ActivityModel) => {
    reset(defaultData(inputActivity ? inputActivity : activity));
    if (updSaver) {
      dispatch(
        updateForm({
          slug: `inspection-activity-detail-${activity.id}`,
          saved: true,
        }),
      );
    }
  };

  useEffect(() => {
    handleReset(false);
  }, [activity]);

  useEffect(() => {
    if (onDirty) {
      onDirty(isDirty);
    }
    if (isDirty) {
      dispatch(
        addForm({
          slug: `inspection-activity-detail-${activity.id}`,
          formState: formInstance.formState,
        }),
      );
    } else {
      dispatch(
        updateForm({
          slug: `inspection-activity-detail-${activity.id}`,
          saved: true,
        }),
      );
    }
  }, [formInstance, isDirty]);

  useEffect(() => {
    if (autoSave.status && isDirty) {
      handleSubmit(getValues());
    }
  }, [autoSave, getValues]);

  useEffect(() => {
    if (saveFormKey && isDirty) {
      handleSubmit(getValues());
    }
  }, [saveFormKey]);

  const { mutate: createActivityMutation, isLoading } = useMutation(
    (formValues: any) =>
      ActivitiesService.put(formValues, activity.id).then((res: any) => {
        if (!res.hasErrors()) {
          toast(res.getMsgString(), {
            type: "success",
          });
          messageService.sendMessage("refetch-act-totals");
          if (onSave) {
            onSave(res.getData());
          }
          handleReset(true , res.getData());
        } else {
          setSErrors(res.getErrors());
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      }),
  );

  const handleSubmit = (data: any) => {
    const values = { ...data };
    values.date = values.date ? moment(values.date).format("yyyy-MM-DD") : null;
    values.start_time = values.start_time
      ? moment(values.start_time).format("HH:mm")
      : null;
    values.end_time = values.end_time
      ? moment(values.end_time).format("HH:mm")
      : null;
    values.inspection_id = activity.inspection_id;
    setSErrors(null);
    createActivityMutation(values);
  };

  const handleSendReminder = () => {
    if (sendingReminder) {
      return;
    }
    setSendingReminder(true);
    ActivitiesService.sendReminder(activity.id)
      .then((res: any) => {
        toast(res.getMsgString(), {
          type: res.status === 200 ? "success" : "error",
        });
        setSendingReminder(false);
      })
      .catch((e: any) => {
        setSendingReminder(false);
        toast("Generic error", {
          type: "error",
        });
      });
  };

  return (
    <>
      <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
        <FormProvider {...formInstance}>
          <Grid container spacing={4}>
            <Grid item md={7} xs={7}>
              <Box>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={6}>
                    <DateFieldMolecule
                      exceptDates={exceptDates}
                      control={control}
                      controlName={"date"}
                      inputFormat={"dd/MM/yyyy"}
                      label={<Trans>Date</Trans>}
                      sError={sErrors ? sErrors["date"] : undefined}
                    ></DateFieldMolecule>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TimeFieldMolecule
                      control={control}
                      controlName={"start_time"}
                      inputFormat={"HH:mm"}
                      label={<Trans>Time start</Trans>}
                      sError={sErrors ? sErrors["start_time"] : undefined}
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TimeFieldMolecule
                      control={control}
                      controlName={"end_time"}
                      inputFormat={"HH:mm"}
                      label={<Trans>Time end</Trans>}
                      sError={sErrors ? sErrors["end_time"] : undefined}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid container spacing={2}>
                  {hideReportStatus !== true && (
                    <Grid item md={4} xs={4}>
                      <SelectMolecule
                        control={control}
                        controlName="report_status_id"
                        sError={
                          sErrors ? sErrors["report_status_id"] : undefined
                        }
                        optionValue={"id"}
                        storeCollection="statuses.reports"
                        variant={"outlined"}
                        label={<Trans>Report status</Trans>}
                      ></SelectMolecule>
                    </Grid>
                  )}
                  {!hideReportStatus &&
                    activity.report_status?.alias !== "sent" && (
                      <Grid
                        item
                        md={5}
                        xs={5}
                        sx={{ display: "flex" }}
                        className="align-center"
                      >
                        <ButtonAtom
                          onClick={handleSendReminder}
                          variant="outlined"
                          disabled={sendingReminder}
                          label={
                            <Box className="align-center">
                              {sendingReminder
                                ? "Sending... "
                                : "Send reminder"}{" "}
                              {sendingReminder ? (
                                <SpinnerAtom size={"11px"} />
                              ) : (
                                <ArrowForwardIosIcon fontSize="small" />
                              )}
                            </Box>
                          }
                        />
                      </Grid>
                    )}
                  <Grid
                    item
                    md={3}
                    xs={3}
                    sx={{ display: "flex" }}
                    className="align-center"
                  >
                    {activity.last_remind_at && (
                      <Box>
                        <Typography variant="caption">
                          Last reminder sent at:
                        </Typography>
                        <Box>
                          <DatePrintAtom format="DD/MM/yyyy">
                            {activity.last_remind_at}
                          </DatePrintAtom>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              {!hideReportStatus /*&& activity.status.alias === "checkout"*/ && (
                <Box>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <ReportUpload
                        inspection={inspection}
                        sharepoint_dir={activity.sharepoint_dir}
                        allow_multiple={true}
                        model_id={activity.id}
                        model_type="activities"
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Box mt={2}>
                <Grid container>
                  <Grid item md={12} xs={12}>
                    <GoogleAutocomplete
                      control={control}
                      label={<Trans>A&zwnj;ddress override</Trans>}
                      controlName={"street"}
                    />
                  </Grid>
                </Grid>
              </Box>
              {isDirty && (
                <Box className="flex-10 flex-end">
                  <Box mt={1} className="text-right">
                    <ButtonAtom
                      color="error"
                      onClick={handleReset}
                      variant="contained"
                      label={t`Cancel`}
                    />
                  </Box>
                  <Box mt={1} className="text-right">
                    <ButtonAtom
                      loading={isLoading}
                      disabled={!isValid}
                      label={t`Save`}
                      type="submit"
                    />
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item md={5} xs={5}>
              <StatusesMolecule
                status_logs={activity.status_logs}
                activity={activity}
              />
              {/*<Box mt={2}>
                                <Typography>
                                    <b><Trans>Report</Trans></b>
                                </Typography>
                            </Box>
                            <Documents model_id={activity.id} model_type='activities'></Documents>*/}
              <Box mt={2}>
                <Typography>
                  <b>
                    <Trans>Notes</Trans>
                  </b>
                </Typography>
                <Notes model_type="activity" model_id={activity.id} />
              </Box>
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </>
  );
};

export default InspActivityFormOrganism;
