import { memo } from "react";
import { UseFormReturn, FieldValues } from "react-hook-form";
import { Controller } from "react-hook-form";
import type { CustomMemoType } from "@appTypes/GlobalInterfaces";
import type ISwitchMolecule from "./interface";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from "@mui/material/Alert";
import { FormControlLabel, Switch } from "@mui/material";
import ConnectForm from "@utils/ConnectForm";

/**
 * Please note:
 * in order to make it work while using a react-hook-form
 * you must wrap this component in a <FormProvider {...formMethods}> provided by react-hook-form
 * import { FormProvider, useForm } from "react-hook-form";
 * <FormProvider {...formMethods}>
 *  <SwitchMolecule />
 * </FormProvider>
 * @param IAsyncSelectMolecule
 * @returns
 */
const SwitchMolecule = <N extends string = string>(
  props: ISwitchMolecule<N>,
) => {
  const { controlName, label, control, sError, disabled, onChangeToggle } =
    props;

  return (
    <ConnectForm>
      {({ register, setValue, formState }: UseFormReturn<FieldValues, any>) => (
        <Controller
          control={control}
          name={controlName}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <>
              <FormControlLabel
                control={
                  <Switch
                    disabled={disabled}
                    checked={value || false}
                    {...register(controlName)}
                    onChange={(a: any, status: any) => {
                      if (onChangeToggle) onChangeToggle(status);
                      onChange(status);
                    }}
                  />
                }
                label={label ? label : ""}
              />
              {!!formState.errors &&
                formState.errors[controlName] &&
                formState.errors[controlName]?.message && (
                  <FormHelperText>
                    {!!formState.errors &&
                      formState.errors[controlName] &&
                      (formState.errors[controlName]?.message as String)}
                  </FormHelperText>
                )}
              {sError && (
                <Alert severity="error" icon={false}>
                  {sError}
                </Alert>
              )}
            </>
          )}
        />
      )}
    </ConnectForm>
  );
};

SwitchMolecule.defaultProps = {
  inputFormat: "yyyy/MM/dd",
  controlName: null,
  label: "",
  control: null,
};

export default (memo as CustomMemoType)(SwitchMolecule);
