import * as React from "react";
import { Box, Typography } from "@mui/material";
import IComponentProps, { schema } from "./interface";
import { FormProvider, useForm } from "react-hook-form";
import { Trans } from "@lingui/macro";
import Grid from "@mui/material/Grid";
import TextFieldAtom from "@atoms/TextField";
import { useSelector } from "react-redux";
import { SelectMolecule } from "@molecules/AsyncSelect";
import { yupResolver } from "@hookform/resolvers/yup";

const ClienSupplierRatesForm = ({
  model,
  record,
  setValidity,
  Serrors,
  onDirtyChange,
  typeId,
  onChange,
}: IComponentProps) => {
  const [sErrors, setSErrors] = React.useState<any>(Serrors ? Serrors : {});
  const ratesTypesList = useSelector(
    (state: any) => state.filters.filters.types?.rates,
  );
  const categoriesRatesList = useSelector(
    (state: any) => state.filters.filters.categories?.rates,
  );

  const formInstance = useForm<any>({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const {
    watch,
    getValues,
    reset,
    formState: { isDirty, isValid, errors },
  } = formInstance;

  React.useEffect(() => {
    setSErrors(errors ? errors : {});
  }, [Serrors, errors]);

  React.useEffect(() => {
    const categoryIdEuro = categoriesRatesList.find(
      (category: any) => category.alias === "euro",
    )?.id;
    reset({
      category_id: record
        ? record.category_id ?? categoryIdEuro
        : categoryIdEuro,
      type_id: typeId,
      id: record ? record.id ?? "" : "",
      model_id: model?.id,
      extra: record ? (record.info?.extra ? record.info.extra : 0) : 0,
      rate: record ? record.info?.rate ?? 0 : 0,
      days: record
        ? record.info?.days
          ? record.info?.days
          : typeId === ratesTypesList[0].id && !record.info?.days
          ? 20
          : 20
        : 20,
      allowance: record ? record.info?.allowance ?? 0 : 0,
      allowance_quantity: record ? record.info?.allowance_quantity ?? 0 : 0,
    });
  }, [record, reset, typeId, model]);

  const extraValue = Number(watch("extra"));
  const daysValue = Number(watch("days"));
  const rateValue = Number(watch("rate"));
  const allowanceQuantityValue = Number(watch("allowance_quantity"));
  const allowanceValue = Number(watch("allowance"));

  React.useEffect(() => {
    if (onChange) {
      onChange(getValues());
    }
  }, []);

  React.useEffect(() => {
    const watchAll = formInstance.watch((value, { name, type }) => {
      if (onChange) {
        onChange(getValues());
      }
    });
    return () => watchAll.unsubscribe();
  }, [watch, formInstance, getValues]);

  React.useEffect(() => {
    if (setValidity) {
      setValidity(isValid);
    }
  }, [isValid, errors, setValidity]);

  React.useEffect(() => {
    if (isDirty && onDirtyChange) {
      onDirtyChange(isDirty);
    }
  }, [isDirty]);
  // console.log(sErrors);
  return (
    <Box>
      <form noValidate>
        <FormProvider {...formInstance}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <SelectMolecule
                control={formInstance.control}
                controlName="category_id"
                required={true}
                emptyValue={{ id: null, name: "--" }}
                helperText={sErrors["category_id"]}
                storeCollection={"categories.rates"}
                optionValue={"id"}
                variant={"outlined"}
                label={"Currency"}
                optionLabel={"name"}
              />
            </Grid>
            <Grid item md={6} xs={12}></Grid>
          </Grid>
          {typeId === ratesTypesList[0].id && (
            <Box mt={1}>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <TextFieldAtom
                    type={"number"}
                    controlName={"rate"}
                    variant={"outlined"}
                    helperText={sErrors["rate"]?.message}
                    label={<Trans>Client rate</Trans>}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextFieldAtom
                    type={"number"}
                    controlName={"days"}
                    variant={"outlined"}
                    helperText={sErrors["days"]?.message}
                    label={<Trans>Days</Trans>}
                  />
                </Grid>
              </Grid>
              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item md={4} xs={12}>
                    <TextFieldAtom
                      type={"number"}
                      controlName={"allowance"}
                      variant={"outlined"}
                      helperText={sErrors["client_allowance"]?.message}
                      label={<Trans>Client allowance</Trans>}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextFieldAtom
                      type={"number"}
                      controlName={"allowance_quantity"}
                      variant={"outlined"}
                      helperText={sErrors["allowance_quantity"]?.message}
                      label={<Trans>Allowance quantity</Trans>}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextFieldAtom
                      type={"number"}
                      controlName={"extra"}
                      variant={"outlined"}
                      helperText={sErrors["extra"]?.message}
                      label={<Trans>Extra</Trans>}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
          {typeId === ratesTypesList[1].id && (
            <Box mt={1}>
              <Grid container spacing={1}>
                <Grid item md={4} xs={12}>
                  <TextFieldAtom
                    type={"number"}
                    controlName={"allowance"}
                    variant={"outlined"}
                    helperText={sErrors["allowance"]?.message}
                    label={<Trans>Supplier allowance</Trans>}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextFieldAtom
                    type={"number"}
                    controlName={"allowance_quantity"}
                    variant={"outlined"}
                    helperText={sErrors["allowance_quantity"]?.message}
                    label={<Trans>Supplier quantity</Trans>}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextFieldAtom
                    type={"number"}
                    controlName={"extra"}
                    variant={"outlined"}
                    helperText={sErrors["extra"]?.message}
                    label={<Trans>Extra</Trans>}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          {
            <Box mt={1} className="text-right">
              <Typography>
                <b>
                  Total:{" "}
                  {rateValue * daysValue +
                    allowanceQuantityValue * allowanceValue +
                    extraValue}
                </b>
              </Typography>
            </Box>
          }
        </FormProvider>
      </form>
    </Box>
  );
};

export default ClienSupplierRatesForm;
