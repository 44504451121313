import styled from "styled-components/macro";
import Box from "@mui/material/Box";
import media from "@theme/MediaQueries";
import { zIndex } from "@theme/Variables";
import { styled as MuiStyled } from "@mui/system";
import Grid from "@mui/material/Grid";
import { remCalc } from "@utils/helpers";

export const StyledColumnLeft = MuiStyled(Grid)((props) => ({
  background: "#2058e7",
  padding: remCalc(30),
  clipPath: "polygon(0% 0%, 100% 0%, 80% 100%, 0% 100%)",
  height: "100vh",
  [props.theme.breakpoints.down("md")]: {
    clipPath: "none",
    height: "auto",
    textAlign: "center",
  },
}));

export const StyledColumnRight = MuiStyled(Grid)((props) => ({
  background: "#FFFFFF",
  height: "100vh",
  display: "flex",
  padding: `0rem ${remCalc(100)}`,
  alignItems: "center",
  [props.theme.breakpoints.down("md")]: {
    height: "auto",
    padding: `0rem ${remCalc(20)}`,
  },
}));

export const StyledMain = styled.main`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const CardBox = styled(Box)`
  margin: 24px 0 32px;
  padding: 32px 34px;
  width: 100%;
  max-height: 400px;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  z-index: ${zIndex.upper};

  ${media.max.md`
     margin: 16px 0;
     padding: 26px 28px;
     max-height: 390px;
  `}

  ${media.max.sm`
     width: 100%;
     padding: 26px 20px;
  `}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: ${zIndex.low};
  }
`;
