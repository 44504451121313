import { styled } from "@mui/system";
import { Box, IconButton } from "@mui/material";
import { COLORS } from "@theme/Colors";
import { zIndex } from "@theme/Variables";

export const StyledChatBubbleContainer = styled(Box)(() => ({
  position: "relative",
  zIndex: zIndex.superModal,
  textAlign: "center",
}));

export const StyledChatBubble = styled(Box)(() => ({
  borderRadius: "50%",
  padding: "10px",
  zIndex: zIndex.superModal,
  background: COLORS.blue.primary,
  border: "3px solid #DDDDDD",
  boxShadow: "0px 0px 10px #DDDDDD",
  position: "relative",
  margin: "3px",
  "& svg": {
    fill: "white",
  },
}));

export const StyledCloseButton = styled(IconButton)(() => ({
  position: "absolute",
  right: "-9px",
  top: "-9px",
  zIndex: 1,
  background: COLORS.blue.primary,
  "&:hover": {
    background: COLORS.blue.primary,
  },
  "& svg": {
    fontSize: "8px",
    fill: "white",
  },
}));
