import { useState } from "react";
import { Box, Grid, Typography, Tooltip, IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Paper from "@mui/material/Paper";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IPeopleQualificationsOrganism from "./interface";
import { Trans, t } from "@lingui/macro";
import { StyledGeneralDetailBox, StyledGenearalContGrid } from "./styled";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import EditIcon from "@mui/icons-material/Edit";
import PersonCreateOrganism from "@organisms/PersonCreate";
import { PeopleModel } from "@models/PeopleModel";
import CancelIcon from "@mui/icons-material/Cancel";

const PeopleDetailQualificationsOrganism = ({
  person,
  onUpdate,
}: IPeopleQualificationsOrganism) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  let isExpanded = true;

  const personUpdate = (person: PeopleModel) => {
    setIsEdit(false);
    if (onUpdate) {
      onUpdate(person);
    }
  };

  const accordionChangeStatus = (expanded: boolean) => {
    isExpanded = expanded;
  };

  const edit = (event: any, isEdit: boolean) => {
    if (isExpanded) {
      event?.stopPropagation();
    }
    setIsEdit(isEdit);
  };

  return (
    <>
      {person ? (
        <Box mt={3} mb={1}>
          <Paper variant="outlined" sx={{ marginBottom: "15px" }}>
            <Accordion
              onChange={(event: any, expanded: boolean) =>
                accordionChangeStatus(expanded)
              }
              defaultExpanded={true}
              elevation={0}
              sx={{ marginBottom: "10px" }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1z-content"
                id="panel1z-header"
              >
                <Typography variant="h5">
                  <b>
                    <Trans>General</Trans>
                  </b>
                </Typography>
                <Box sx={{ textAlign: "right" }}>
                  {!isEdit ? (
                    <Tooltip title={t`Edit data`} placement="top">
                      <IconButton onClick={(event: any) => edit(event, true)}>
                        <EditIcon color="success"></EditIcon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t`Cancel`} placement="top">
                      <IconButton onClick={(event: any) => edit(event, false)}>
                        <CancelIcon></CancelIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {!isEdit ? (
                  <Box>
                    <StyledGenearalContGrid container>
                      <Grid item md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            <Trans>Verified</Trans>
                          </Typography>
                          {person.verified ? (
                            <Tooltip title={t`This person is verified`}>
                              <CheckCircleOutlinedIcon
                                sx={{
                                  marginLeft: "10px",
                                }}
                                fontSize="medium"
                                color="info"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={t`This person is not verified`}>
                              <ErrorOutlinedIcon
                                sx={{
                                  marginLeft: "10px",
                                }}
                                fontSize="medium"
                                color="warning"
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            <Trans>Internal</Trans>
                          </Typography>
                          {person.internal ? (
                            <Tooltip title={t`This person is internal`}>
                              <CheckCircleOutlinedIcon
                                sx={{
                                  marginLeft: "10px",
                                }}
                                fontSize="medium"
                                color="info"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={t`This person is not internal`}>
                              <ErrorOutlinedIcon
                                sx={{
                                  marginLeft: "10px",
                                }}
                                fontSize="medium"
                                color="warning"
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                      <Grid item md={4}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="body1">
                            <Trans>BHGE Enabled</Trans>&nbsp;
                          </Typography>
                          {person.bhge ? (
                           <Tooltip title={t`Is BHGE`}>
                            <CheckCircleOutlinedIcon
                              sx={{
                                marginLeft: "10px",
                              }}
                              fontSize="medium"
                              color="info"
                            />
                          </Tooltip>
                          ) : (
                            <Tooltip title={t`Not BHGE`}>
                              <ErrorOutlinedIcon
                                sx={{
                                  marginLeft: "10px",
                                }}
                                fontSize="medium"
                                color="warning"
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </Grid>
                    </StyledGenearalContGrid>
                    <StyledGenearalContGrid container>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <b>
                              <Trans>Email</Trans>
                            </b>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {person.email ? person.email : "--"}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <b>
                              <Trans>Phone</Trans>
                            </b>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {person.phone ? person.phone : "--"}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                    </StyledGenearalContGrid>
                    <StyledGenearalContGrid container>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <b>
                              <Trans>City</Trans>
                            </b>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {person.city ? person.city : "--"}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <b>
                              <Trans>Country</Trans>
                            </b>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {person.country ? person.country.name : "--"}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                    </StyledGenearalContGrid>
                    <StyledGenearalContGrid container>
                      <Grid item md={12}>
                        <Box>
                          <b>
                            <Trans>Source</Trans>
                          </b>
                        </Box>
                        <ul>
                          {person.partners &&
                            person.partners.map((partner: any) => {
                              return (
                                <li>{`${partner.category.name}${
                                  partner.category.alias === "provider"
                                    ? ":"
                                    : ""
                                } ${
                                  partner.partner ? partner.partner.name : ""
                                }`}</li>
                              );
                            })}
                        </ul>
                      </Grid>
                    </StyledGenearalContGrid>
                  </Box>
                ) : (
                  <PersonCreateOrganism
                    onCancel={() => setIsEdit(false)}
                    onUpdate={personUpdate}
                    mode="patch"
                    person={person}
                    hideAttributes={true}
                  ></PersonCreateOrganism>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Box>
      ) : null}
    </>
  );
};

export default PeopleDetailQualificationsOrganism;
