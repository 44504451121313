import { CoreBaseModel } from "@core/models/Base.model";
import { ICategory } from "@interfaces/ICategory";
import { IType } from "@interfaces/IType";
import { InspectionModel } from "./InspectionModel";

export class JobModel extends CoreBaseModel {
  instanceOf: "Job";
  id: number;
  code_id: string;
  label: string;
  category: ICategory;
  category_id: number;
  type_id: number;
  client: any;
  client_id: number;
  client_code_id?: string;
  type: IType;
  inspections: Array<InspectionModel>;
  total_inspections: number;
  user: any;

  constructor(data: any) {
    super();
    this.instanceOf = "Job";
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "jobs";
  }
}
