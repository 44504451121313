import { styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import { COLORS } from "@theme/Colors";

export const PageHeader = styled("div")`
  display: flex;
  justify-content: space-between;
`;

export const StyledHeaderContainer = styled("div")`
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  background: white;
  border-radius: 3px;
`;

export const StyledGridItem = styled(Grid)`
  color: ${COLORS.blue.shade1};
  text-transform: uppercase;
  font-size: 0.775rem !important;
`;
