import { styled } from "@mui/system";
import { Grid } from "@mui/material";

export const StyledInspectionInfoGrid = styled(Grid)(() => ({
  "& p": {
    color: "#888888",
    fontSize: "1rem",
    fontWeight: "400",
  },
}));

export const StyledInspectionStatusesGrid = styled(Grid)(() => ({
  "& .MuiGrid-item": {
    textAlign: "right",
    paddingRight: "10px",
  },
}));
