import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { DocumentModel } from "@models/DocumentModel";

class DocumentsService extends CoreBaseService {
  constructor() {
    super(DocumentModel);
  }

  download(fileId: number, fileName: string, headers?: any) {
    return this.sub(`${fileId}/download`)
      .get(undefined, undefined, true, false, { responseType: "blob" }, headers)
      .then((res: any) => {
        res.download(fileName);
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  getFile(fileId: number, fileName: string, headers?: any) {
    return this.sub(`${fileId}/download`)
      .get(undefined, undefined, true, false, { responseType: "blob" }, headers)
      .then((res: any) => {
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  updateVisibility(id: number, visible: boolean) {
    return this.sub(`${id}/visible`).update({ visible }, -1);
  }
}

const inst = new DocumentsService();

export default inst;
