import { Fragment, memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StyledChatBubbleContainer } from "./styled";
import { RChatModel } from "@models/RChatModel";
import ChatBubble from "./ChatBubble";
import BubbleDrawer from "./BubbleChatDrawer";

const ChatBubblesOrganism = () => {
  const [currentChat, setCurrentChat] = useState<RChatModel | null>(null);

  // Instances of RChatModel
  const chats = useSelector((state: any) =>
    Object.keys(state.chat.chats).map(
      (chatKey: any) => new RChatModel(state.chat.chats[chatKey]),
    ),
  );
  const chatToShow = useSelector((state: any) => state.chat.chatToShow);

  useEffect(() => {
    if (chatToShow) {
      setCurrentChat(chatToShow);
    } else setCurrentChat(null);
  }, [chatToShow]);

  return (
    <Fragment>
      <StyledChatBubbleContainer>
        {chats &&
          chats.map((chat: RChatModel, index: number) => {
            return (
              <ChatBubble
                chat={chat}
                index={index}
                key={index}
                onChatSelect={(chat: RChatModel) => setCurrentChat(chat)}
              />
            );
          })}
      </StyledChatBubbleContainer>
      <BubbleDrawer chat={currentChat} onClose={() => setCurrentChat(null)} />
    </Fragment>
  );
};

export default memo(ChatBubblesOrganism);
