import { styled } from "@mui/system";
import { Typography } from "@mui/material";

export const StyledValueTitle = styled(Typography)(() => ({
  fontSize: ".9rem",
}));

export const StyledValueText = styled(Typography)(() => ({
  fontSize: "1.1rem",
}));
