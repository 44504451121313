import { Box, Button } from "@mui/material";
import IComponentProps from "./interface";
import { t, Trans } from "@lingui/macro";
import ButtonAtom from "@atoms/Button";
import Notes from "@organisms/Notes";

const InspectionNotesForm = ({
  inspection,
  onBack,
  onNext,
  onNextText,
  isAdd,
  exceptDates,
}: IComponentProps) => {
  return (
    <Box>
      <Notes model_id={inspection.id} model_type="inspections" />
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        {onBack && (
          <Button color="inherit" onClick={onBack} sx={{ mr: 1 }}>
            <Trans>Back</Trans>
          </Button>
        )}
        <Box sx={{ flex: "1 1 auto" }} />
        {onNext && (
          <ButtonAtom
            variant="contained"
            label={onNextText ? onNextText : t`Next`}
            onClick={() => onNext()}
            sx={{ mr: 1 }}
          />
        )}
      </Box>
    </Box>
  );
};

export default InspectionNotesForm;
