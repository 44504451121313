import { memo } from "react";
import type { IDocumentRowItemProps } from "./interface";
import { t } from "@lingui/macro";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import FolderIcon from "@mui/icons-material/Folder";
import LightTooltip from "@atoms/LightTooltip";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { useUser } from "@hooks";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IDocument } from "@interfaces/IDocument";
import DocumentsService from "@services/DocumentsService";
import { toast } from "react-toastify";
import CircleIcon from "@mui/icons-material/Circle";

const DocumentRowItem = ({
  isSelected,
  item,
  toggleSelect,
  handleDownload,
  handleDelete,
  handleView,
}: IDocumentRowItemProps) => {
  const user = useUser();
  const extension: string | undefined = item.file.split(".").pop();
  const viewAllowedExtensions = [/*"jpeg", "jpg", "png", "bmp", "gif",*/ "pdf",/* "ppt", "pptx", "xlsx", "doc", "docx", "xls"*/];

  const setVisible = (item: IDocument, state: boolean) => {
    DocumentsService.updateVisibility(item.id, state)
      .then((res: any) => {
        if (!res.hasErrors()) {
          toast(res.getMsgString(), { type: "success" });
        } else {
          toast(res.getMsgString(), { type: "error" });
        }
      })
      .catch((e: any) => {
        toast("errore", { type: "error" });
      });
  };

  return (
    <TableRow selected={isSelected} hover key={item.id}>
      <TableCell padding="checkbox">
        {user.can("documents.delete") && (
          <Checkbox
            checked={isSelected}
            onChange={(e) => toggleSelect(e, item.id)}
          />
        )}
      </TableCell>
      <TableCell sx={{ width: "72px", display: "none", textAlign: "center" }}>
        <Avatar sx={{ width: "30px", height: "30px" }}>
          <FolderIcon fontSize="small" />
        </Avatar>
      </TableCell>
      <TableCell sx={{ width: "20%", padding: 0 }}>
        <Tooltip title={item.file}>
          <Box
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              paddingBottom: "6px",
              overflow: { xs: "scroll", md: "hidden" },
              maxWidth: { xs: "150px", md: "100%" },
            }}
          >
            { 
              !item.sharepoint_url && <CircleIcon
              style={{
                width: '.8em',
                paddingTop: '5px', 
                height: '.8em',
                color: 'rgb(237, 108, 2)'
              }}
              fontSize="small"
            />
            }
            &nbsp;{item.file}
          </Box>
        </Tooltip>
      </TableCell>
      {user.user.role.name !== "inspector" && (
        <TableCell sx={{ width: "20%", padding: 0, textAlign: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Checkbox
              defaultChecked={item.visible}
              onChange={(event: any, state: boolean) => setVisible(item, state)}
              inputProps={{ "aria-label": t`Description` }}
            />
          </Box>
        </TableCell>
      )}
      <TableCell
        sx={{ pr: 1, display: "flex", justifyContent: "center" }}
        size="small"
      >
        {user.can("documents.download") && (
          <LightTooltip title={t`Download`} followCursor>
            <IconButton
              aria-label={t`download`}
              onClick={() => handleDownload(item)}
            >
              <DownloadIcon />
            </IconButton>
          </LightTooltip>
        )}
        {user.user.role.name !== "inspector" && handleView &&
          extension &&
          (viewAllowedExtensions.includes(extension) || item.sharepoint_url) && (
            <LightTooltip title={item.sharepoint_url ? t`View on Sharepoint` : t`View`} followCursor>
              <IconButton aria-label={t`view`} onClick={() => handleView(item)}>
                <RemoveRedEyeIcon />
              </IconButton>
            </LightTooltip>
          )}
        {user.can("documents.delete") && (
          <LightTooltip title={t`Delete`} followCursor>
            <IconButton
              color="error"
              aria-label={t`delete`}
              onClick={() => handleDelete(item)}
            >
              <DeleteIcon />
            </IconButton>
          </LightTooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(DocumentRowItem);
