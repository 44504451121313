import { Grid, Paper, Typography, Box } from "@mui/material";
import { StyledActivityRow } from "./styled";
import IAssetRow from "./interface";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const AssetRowMolecule = ({ asset, onActionSelected }: IAssetRow) => {
  const actionSelected = (item: any) => {
    if (onActionSelected) {
      onActionSelected(item, asset);
    }
  };

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledActivityRow>
          <Grid container>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center" }}
              md={2}
              xs={12}
            >
              <Box>
                <Typography>
                  {asset.available ? (
                    <CheckCircleOutlinedIcon color="primary" />
                  ) : (
                    <ErrorOutlinedIcon color="warning" />
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={2} xs={12}>
              {asset.name}
            </Grid>
            <Grid item md={2} xs={12}>
              {asset.category?.name}
            </Grid>
            <Grid item md={2} xs={12}>
              {asset.type?.name}
            </Grid>
            <Grid item md={2} xs={12}>
              {asset.location?.name}
            </Grid>
            <Grid sx={{ textAlign: "right" }} item md={2} xs={12}>
              <MultiButtonMolecule
                onSelected={actionSelected}
                options={[
                  {
                    label: "Details",
                    id: 1,
                    data: { assetId: asset.id },
                  },
                  {
                    label: "Delete",
                    id: 4,
                    data: { assetId: asset.id },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </StyledActivityRow>
      </Paper>
    </>
  );
};

export default AssetRowMolecule;
