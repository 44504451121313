import { remCalc } from "@utils/helpers";
import { styled } from "@mui/system";

interface ILogoProps {
  size?: "big" | "medium" | "small" | undefined;
}

export const StyledImg = styled("img")((props: ILogoProps) => ({
  maxWidth: remCalc(50),
  // marginBottom: ({ theme }: ILogoProps) => theme.spacing(3),
}));
