import { Grid, Paper, Box, Tooltip } from "@mui/material";
import IProps from "./interface";
import { StyledRow } from "./styled";
import { t } from "@lingui/macro";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import DataBalloonAtom from "@atoms/DataBalloon";

const JobRowExtendedMolecule = ({ job, onActionSelected }: IProps) => {
  const actionSelected = (item: IMBOption) => {
    if (onActionSelected) {
      onActionSelected(item);
    }
  };

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledRow>
          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item md={1} xs={12}>
              <DataBalloonAtom
                variant="chip"
                title={t`Total inspections`}
                severity={"warning"}
                data={job.total_inspections}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Box>
                <b>{job.client.name}</b>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Tooltip title={t`Division - service`} placement="top">
                <Box>{`${job.type?.name} - ${job.category?.name}`}</Box>
              </Tooltip>
            </Grid>
            <Grid item md={2} xs={12}></Grid>
            <Grid item md={2} xs={12}>
              <Box>{job.label ?? "--"}</Box>
            </Grid>
            <Grid sx={{ textAlign: "right" }} item md={2} xs={12}>
              {
                <MultiButtonMolecule
                  onSelected={actionSelected}
                  options={[
                    {
                      label: "Details",
                      id: 0,
                      data: { item: job },
                    },
                    {
                      label: "Copy",
                      id: 4,
                      data: { item: job },
                    },
                    {
                      label: "Delete",
                      id: 5,
                      data: { item: job },
                    },
                  ]}
                />
              }
            </Grid>
          </Grid>
        </StyledRow>
      </Paper>
    </>
  );
};

export default JobRowExtendedMolecule;
