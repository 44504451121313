import { useUser } from "@hooks";
import LayoutSettings from "@layouts/settings";
import { Outlet } from "react-router-dom";

const SettingsPage = () => {
  const user = useUser();
  return user.user.role.name !== "insepctor" ? (
    <LayoutSettings>
      <Outlet />
    </LayoutSettings>
  ) : (
    <></>
  );
};

export default SettingsPage;
