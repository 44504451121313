import { Fragment, useEffect, useState } from "react";
import { Box, Card, CardContent, Tab, Tabs } from "@mui/material";
import DocumentsOrganism from "@organisms/Documents";
import { useDispatch, useSelector } from "react-redux";
import { set } from "@stores/reducers/titleReducer";
import { ActivityModel } from "@models/ActivityModel";
import { IDocument } from "@interfaces/IDocument";
import { IType } from "@interfaces/IType";

interface IDocsPage {
  activity: ActivityModel;
}

const InspectorActivityDocsPage = ({ activity }: IDocsPage) => {
  const dispatch = useDispatch();
  const documentsTypes = useSelector(
    (state: any) => state.filters.filters.types?.documents,
  );
  const [activeDocumentsFilter, setActiveDocumentsFilter] = useState<number>(documentsTypes[0].id);

  const filterDocuments = (document: IDocument) => {
    const documentsTypeId = documentsTypes?.find((documentsType: IType) => documentsType.alias === 'documents').id;
    const poTypeId = documentsTypes?.find((documentsType: IType) => documentsType.alias === 'pos').id;

    return activeDocumentsFilter ===
      documentsTypeId ? document.type?.alias === 'documents'
      : activeDocumentsFilter === poTypeId ? document.type?.alias === 'pos'
      : document.type?.alias === 'reports'
  }

  useEffect(() => {
    dispatch(set("Docs"));
  }, [dispatch]);

  return (
    <>
      {
        <Fragment>
          <Card>
            <CardContent>
              <Box mb={1}>
              <Tabs value={activeDocumentsFilter} onChange={(event: React.SyntheticEvent, newValue: number) => {
                    setActiveDocumentsFilter(newValue);
                  }}>
                    {
                      documentsTypes.map((documentType: IType) => {
                        return (<Tab value={documentType.id} key={documentType.id} label={documentType.name} />)
                      })
                    }
                  </Tabs>
                    <DocumentsOrganism
                      upload_model_id={activity.id}
                      upload_model_type={'activities'}
                      filter={filterDocuments}
                      uploadDisabled={true}
                      model_id={activeDocumentsFilter === documentsTypes?.find((documentsType: IType) => documentsType.alias === 'pos').id ? activity.inspection.pos?.id : Number(activity.inspection_id)}
                      model_type={activeDocumentsFilter === documentsTypes?.find((documentsType: IType) => documentsType.alias === 'pos').id ? 'pos' : 'inspections'}
                    />
              </Box>
            </CardContent>
          </Card>
        </Fragment>
      }
    </>
  );
};

export default InspectorActivityDocsPage;
