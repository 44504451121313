import { useState } from "react";
import type INoteDetail from "./interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import TextFieldAtom from "@atoms/TextField";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import NotesService from "@services/NotesService";
import { useUser } from "@hooks";
import DatePrintAtom from "@atoms/DatePrint";
import { StyledFormControlLabel } from "./styled";

const schemaA = Yup.object().shape({
  text: Yup.string(),
});

interface IFormValues {
  text: string;
  visible: boolean;
}

const NoteDetailOrganism = ({ note, onSave, hide_crud }: INoteDetail) => {
  const user = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const formInstance = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: { text: note.text, visible: note.visible },
    reValidateMode: "onSubmit",
    resolver: yupResolver(schemaA),
  });

  const {
    formState: { isValid, isDirty },
    setValue,
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const handleSubmit = (formValues: any) => {
    setIsLoading(true);
    NotesService.put(formValues, note.id).then((res: any) => {
      setIsLoading(false);
      toast(res.getMsgString(), {
        type: res.hasErrors() ? "error" : "success",
      });
      if (!res.hasErrors()) {
        onSave?.();
      }
    });
  };

  return (
    <>
      <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
        <FormProvider {...formInstance}>
          <Box sx={{ minWidth: "100%" }}>
            <TextFieldAtom
              controlName={"text"}
              inputProps={{
                readOnly: !user.can("notes.update") || hide_crud,
              }}
              maxRows={6}
              minRows={2}
              variant={"outlined"}
              label={<Trans>Note</Trans>}
              multiline={true}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <StyledFormControlLabel
              label="Description"
              control={
                <Checkbox
                  defaultChecked={note.visible}
                  onChange={(event: any, state: boolean) =>
                    setValue("visible", state, {
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                  inputProps={{
                    "aria-label": t`Description`,
                  }}
                />
              }
            />
            <Typography variant="caption">
              <DatePrintAtom format="DD/MM/YYYY">
                {note.created_at}
              </DatePrintAtom>
            </Typography>
          </Box>
        </FormProvider>
        {isDirty && user.can("notes.update") && !hide_crud && (
          <Box mt={1} sx={{ textAlign: "right" }}>
            <Button type="submit" disabled={!isValid} variant="contained">
              {isLoading ? <CircularProgress /> : <Trans>Update</Trans>}
            </Button>
          </Box>
        )}
      </form>
    </>
  );
};

export default NoteDetailOrganism;
