import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { LabelModel } from "@models/LabelModel";

class LabelsService extends CoreBaseService {
  constructor() {
    super(LabelModel);
  }

  getAutocomplete(search: string) {
    return this.get("", { model_type: "inspections", search });
  }

  createAutocomplete(name: string, type_id: number) {
    return this.create({ model_type: "inspections", name, type_id });
  }
}

const inst = new LabelsService();

export default inst;
