import IOrganism from "./interface";
import { Box, Grid, Typography, Popover, Tooltip } from "@mui/material";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";
import DataBalloonAtom from "@atoms/DataBalloon";
import DatePrintAtom from "@atoms/DatePrint";
import { useState } from "react";
import { t } from "@lingui/macro";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import { Link } from "react-router-dom";

const InspectionRow = ({ inspection, onActionSelected }: IOrganism) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const actionSelected = (item: IMBOption) => {
    if (onActionSelected) {
      onActionSelected(item);
    }
  };

  return (
    <>
      <Box mb={1}>
        <Box sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item md={1} className="align-center">
              {inspection.next_activity && (
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box p={2}>
                    <ul className="ul-no-margins">
                      {inspection.next_activity.rel_activities?.map(
                        (relActivity: any, index: number) => {
                          return (
                            <li key={index}>
                              <Box
                                mb={1}
                                sx={{
                                  fontSize: "13px",
                                }}
                              >
                                <Box
                                  sx={{
                                    fontWeight:
                                      relActivity.id ===
                                      inspection.next_activity.id
                                        ? "bold"
                                        : "",
                                  }}
                                >
                                  <DatePrintAtom format="DD/MM/yyyy">
                                    {relActivity.date}
                                  </DatePrintAtom>
                                </Box>
                              </Box>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </Box>
                </Popover>
              )}
              {
                <DataBalloonAtom
                  variant="chip"
                  onClick={(e: any) => setAnchorEl(e.currentTarget)}
                  severity={"warning"}
                  title={t`Total activities`}
                  data={inspection.total_activities}
                />
              }
            </Grid>
            <Grid item md={2} className="align-center">
              {inspection.next_activity ? (
                <Tooltip title={t`Next activity`}>
                  <Box className="mouse-hover no-underline">
                    <Box className="date text-center">
                      <Typography>
                        <b>
                          {inspection.next_activity.date ? (
                            <DatePrintAtom format="DD/MM/yyyy">
                              {inspection.next_activity.date}
                            </DatePrintAtom>
                          ) : (
                            "--"
                          )}
                        </b>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Box>
                        <Typography>
                          {inspection.next_activity.start_time
                            ? inspection.next_activity.startTime()
                            : "--"}
                        </Typography>
                      </Box>
                      -
                      <Typography>
                        {inspection.next_activity.end_time
                          ? inspection.next_activity.endTime()
                          : "--"}
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              ) : (
                "--"
              )}
            </Grid>
            <Grid item md={3} className="align-center">
              <Tooltip title={t`Inspector`}>
                <span>{inspection.user?.full_name ?? "--"}</span>
              </Tooltip>
            </Grid>
            <Grid item md={2} className="align-center">
              <Tooltip title={t`Vendor`}>
                <span>{inspection.vendor?.name ?? "--"}</span>
              </Tooltip>
            </Grid>
            <Grid item md={2} className="align-center">
              <Tooltip title={t`Notifications`}>
                <span>
                  {inspection.labels.length
                    ? inspection.labels
                        .map((label: any) => label.name)
                        .join(", ")
                    : "--"}
                </span>
              </Tooltip>
            </Grid>
            <Grid item md={2} className="align-center">
              <MultiButtonMolecule
                onSelected={actionSelected}
                options={[
                  {
                    label: (
                      <Link
                        style={{ fontSize: "13px" }}
                        to={`/activities?insp=${inspection.id}`}
                      >
                        Go to details
                      </Link>
                    ),
                    id: 0,
                    data: { inspection },
                  },
                  {
                    label: "Copy",
                    id: 1,
                    data: { inspection },
                  },
                  {
                    label: "Delete",
                    id: 2,
                    data: { inspection },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default InspectionRow;
