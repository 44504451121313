import * as Yup from "yup";
import { t } from "@lingui/macro";
import { HCMModel } from "@models/HCMModel";

export default interface IOrganism {
  hcm: HCMModel;
  onMod?: () => void;
}

export const YupSchema = Yup.object().shape({
  months: Yup.number()
    .required(t`Necessaria`)
    .min(1),
});
