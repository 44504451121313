import { CoreBaseService } from "@core/services/Base.service";
import { ActivityModel } from "@models/ActivityModel";

class ActivitiesService extends CoreBaseService {
  constructor() {
    super(ActivityModel);
  }

  getTotals() {
    return this.sub("totals").get();
  }

  copyActivity(id: number) {
    return this.sub(`${id}/copy`, ActivityModel).create({});
  }

  jobs() {
    return this.sub("jobs").get();
  }

  changeStatus(status_id: number, activity_id: number) {
    return this.sub(`${activity_id}/change_status`).update({ status_id }, -1);
  }

  filters(inspection_id: number, headers?: any) {
    return this.customCall(`filters`).get("", {
      resources: ["activities"],
      inspection_id,
    }, false, false, undefined, headers);
  }

  download(filter: any, fileName: string) {
    return this.sub(`export`)
      .get(undefined, filter, true, false, { responseType: "blob" })
      .then((res: any) => {
        res.download(fileName);
        return res;
      })
      .catch((error) => {
        return error;
      });
  }

  sendReminder(activity_id: number) {
    return this.sub(`${activity_id}/pending_reportreminder`).update({}, -1);
  }
}

const inst = new ActivitiesService();

export default inst;
