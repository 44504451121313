import { useState, Fragment, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import ContainerAtom from "@atoms/Container";
import LayoutBase from "@layouts/base";
import { PageHeader } from "./styled";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@molecules/Modal";
import LayoutModal from "@layouts/modal";
import PaginatedListMolecule from "@molecules/PaginatedList";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import JobsFilterOrganism from "@organisms/JobsFilter";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { set } from "@stores/reducers/titleReducer";
import JobDetailOrganism from "@organisms/JobDetail";
import HCMDetailOrganism from "@organisms/HCMDetail";
import JobsService from "@services/JobsService";
import { JobModel } from "@models/JobModel";
import { IFormValues } from "@organisms/JobsFilter/interface";
import JobRowExtendedMolecule from "@molecules/JobRow/Extended";
import { JobFilterDefault } from "@organisms/JobsFilter/interface";
import JobCreateFormOrganism from "@organisms/JobCreate/index";
import HCMService from "@services/HCMService";
import HCMRowExtendedMolecule from "@molecules/HCMRow/Extended";
import { useSearchParams } from "react-router-dom";
import { HCMModel } from "@models/HCMModel";
import DrawerMolecule from "@molecules/Drawer";
import HCMCreateWizardOrganism from "@organisms/HCMCreateWizard";

const JobsPage = () => {
  const [filter, setFilter] = useState<IFormValues>(JobFilterDefault);
  const [refetchKey /*, setRefetchKey*/] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerDetail, setDrawerDetail] = useState<any>({});
  const [state, setState] = useState({ right: false });
  const [newRow, setNewRows] = useState<JobModel | HCMModel | undefined>(
    undefined,
  );
  const [updatedItem, setUpdated] = useState<JobModel | HCMModel | undefined>(
    undefined,
  );
  const [toDelete, setToDeleteFromList] = useState<number | undefined>(
    undefined,
  );
  const confirm = useConfirm();
  const [searchParams /*, setSearchParams*/] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams.get("id")) {
      setDrawerDetail(new JobModel({ id: searchParams.get("id") }));
      setState({ ...state, right: true });
    }
  }, [searchParams]);

  useEffect(() => {
    dispatch(set("Activities"));
  }, [dispatch]);
  /*
	const toggleDrawer = (anchor: any, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
		if (
			event.type === 'keydown' &&
			((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};
*/
  const itemUpdated = (item: JobModel | HCMModel | undefined) => {
    setUpdated(item);
  };

  const onNewJob = (job: JobModel) => {
    setNewRows(job);
    if(Number(filter?.job_type) === 1) { // if the job wizard is open
      setModalOpen(false)
    }
  };

  const handleFilterChange = (values: any) => {
    setFilter(values);
  };

  const handleActionSelect = (param: IMBOption) => {
    if (param.label === "Details") {
      setDrawerDetail(param.data.item);
      setState({ ...state, right: true });
    } else if (param.label === "Copy") {
      (param.data.item.instanceOf === "Job"
        ? JobsService.copyJob(param.data.item.id)
        : HCMService.copy(param.data.item.id)
      ).then((res: any) => {
        if (!res.hasErrors()) {
          setNewRows({
            ...res.getData(),
            ...{
              instanceOf: param.data.item.instanceOf === "Job" ? "Job" : "HCM",
            },
          });
          toast(res.getMsgString(), {
            type: "success",
          });
        } else {
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      });
    } else if (param.label === "Delete") {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        (param.data.item.instanceOf === "Job"
          ? JobsService.delete(param.data.item.id)
          : HCMService.delete(param.data.item.id)
        ).then((res: any) => {
          if (!res.hasErrors()) {
            setToDeleteFromList(param.data.item.id);
          }
          toast(res.getMsgString(), {
            type: res.hasErrors() ? "error" : "success",
          });
        });
      });
    }
  };

  return (
    <>
      <Fragment key={"right"}>
        <DrawerMolecule
          open={state.right}
          onClose={() => {
            setState({ right: false });
          }}
        >
          {drawerDetail.instanceOf === "Job" && (
            <JobDetailOrganism
              onDelete={(id: number) => {
                setState({ right: false });
                setToDeleteFromList(id);
              }}
              onCopy={(job: JobModel) => setNewRows(job)}
              onUpdate={itemUpdated}
              jobIn={drawerDetail}
            />
          )}
          {drawerDetail.instanceOf === "HCM" && (
            <HCMDetailOrganism
              onDelete={(id: number) => setToDeleteFromList(id)}
              onCopy={(hcm: HCMModel) => setNewRows(hcm)}
              onUpdate={itemUpdated}
              hcmIn={drawerDetail}
            />
          )}
        </DrawerMolecule>
      </Fragment>
      <LayoutBase>
        <Fragment>
          <ContainerAtom>
            <PageHeader>
              <Typography variant="h1">
                {Number(filter?.job_type) === 2 ? `HCM` : `Jobs`}{" "}
                <Trans>list</Trans>
              </Typography>
              <Button onClick={() => setModalOpen(true)} variant="outlined">
                <AddIcon></AddIcon> <Trans>New</Trans>{" "}
                {Number(filter?.job_type) === 2 ? `HCM` : `Job`}
              </Button>
            </PageHeader>
            <Modal
              open={modalOpen}
              class_name="modal-lg"
              set_open={setModalOpen}
            >
              {Number(filter?.job_type) === 2 && (
                <LayoutModal title={<Trans>Create new HCM</Trans>}>
                  <Box pb={5}>
                    <HCMCreateWizardOrganism
                      onFinalize={() => setModalOpen(false)}
                      onNewJob={onNewJob}
                    />
                  </Box>
                </LayoutModal>
              )}
              {Number(filter?.job_type) === 1 && (
                <LayoutModal title={<Trans>Create new job</Trans>}>
                  <JobCreateFormOrganism onSave={onNewJob} />
                </LayoutModal>
              )}
            </Modal>
            <Box mt={2} mb={2}>
              <JobsFilterOrganism
                isHcm={
                  !filter?.job_type || Number(filter?.job_type) === 1
                    ? false
                    : true
                }
                onFormChange={handleFilterChange}
              ></JobsFilterOrganism>
            </Box>
            <Box mt={1}>
              {filter ? (
                <PaginatedListMolecule
                  idToDelete={toDelete}
                  refetchKey={refetchKey}
                  scrollPagination={true}
                  updated={updatedItem}
                  renderFunc={(item: any) => {
                    return Number(filter?.job_type) === 1 ? (
                      <JobRowExtendedMolecule
                        onActionSelected={handleActionSelect}
                        job={item}
                      />
                    ) : (
                      <HCMRowExtendedMolecule
                        onActionSelected={handleActionSelect}
                        hcm={item}
                      />
                    );
                  }}
                  service={
                    !filter?.job_type || Number(filter?.job_type) === 1
                      ? JobsService
                      : HCMService
                  }
                  perPage={15}
                  newRow={newRow}
                  filter={filter}
                />
              ) : (
                <></>
              )}
            </Box>
          </ContainerAtom>
        </Fragment>
      </LayoutBase>
    </>
  );
};

export default JobsPage;
