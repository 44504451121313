import { Fragment, memo, useEffect } from "react";
import type { IActivityChatProps } from "./interface";
import { Box, Button, Grid } from "@mui/material";
import { StyledScreen, StyledChatForm, StyledChatContainer } from "./styled";
import ChatMessage from "./Message";
import { FormProvider, useForm } from "react-hook-form";
import TextFieldAtom from "@atoms/TextField";
import { Trans } from "@lingui/macro";
import { IFormValues, sendMessageSchema } from "./interface";
import { useMutation } from "react-query";
import ChatService from "@services/ChatService";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import SpinnerAtom from "@atoms/Spinner";
import { getChat/*, addMessage*/ } from "@stores/reducers/chatReducer";
import { useAppDispatch } from "@stores/hooks";
import { useSelector } from "react-redux";
import { RChatModel } from "@models/RChatModel";
import SendIcon from "@mui/icons-material/Send";

const ActivityChatOrganism = ({ activity }: IActivityChatProps) => {
  const dispatch = useAppDispatch();
  const chats = useSelector((state: any) =>
    Object.keys(state.chat.chats).map(
      (chatKey: any) => new RChatModel(state.chat.chats[chatKey]),
    ),
  );
  const currentChat = chats.find(
    (chat: RChatModel) => chat.activity_id === activity.id,
  );
  const isLoading = useSelector((state: any) => state.chat.isLoading);

  const formInstance = useForm<IFormValues>({
    defaultValues: {
      message: "",
      activity_id: activity.id,
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(sendMessageSchema),
  });

  useEffect(() => {
    dispatch(getChat({ per_page: 20, page: 1, activity_id: activity.id }));
  }, []);

  useEffect(() => {
    // useRef does not work
    if (document.getElementById("chat-screen")) {
      // @ts-ignore
      document.getElementById("chat-screen").scrollTop =
        // @ts-ignore
        document.getElementById("chat-screen").scrollHeight;
    }
  }, [currentChat]);

  const {
    reset,
    getValues,
    formState: { isValid },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const { mutate: sendMessageMutation, isLoading: isSending } = useMutation(
    (formValues: IFormValues) =>
      ChatService.create(formValues, true).then((res: any) => {
        if (!res.hasErrors()) {
          /*dispatch(
            addMessage({
              activity_id: activity.id,
              message: res.getData(),
            })
          );*/
          reset();
        } else {
          toast(res.getMsgString(), { type: "error" });
        }
      }),
  );

  const handleSubmit = (data: IFormValues) => {
    sendMessageMutation(data);
  };

  return (
    <Fragment>
      {isLoading ? (
        <SpinnerAtom></SpinnerAtom>
      ) : (
        <Box sx={{ paddingY: 2 }}>
          <StyledChatContainer>
            <StyledScreen id={"chat-screen"}>
              {currentChat &&
                currentChat.messages
                  .slice()
                  .reverse()
                  .map((message: any, index: number) => {
                    return (
                      <ChatMessage
                        key={index}
                        activity={activity}
                        message={message}
                      />
                    );
                  })}
            </StyledScreen>
            <StyledChatForm>
              <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                <FormProvider {...formInstance}>
                  <Grid container spacing={2}>
                    <Grid item md={10} xs={12}>
                      <TextFieldAtom
                        controlName={"message"}
                        required={true}
                        multiline={true}
                        disabled={isSending}
                        minRows={2}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13 && !e.shiftKey) {
                            e.preventDefault();
                            handleSubmit(getValues());
                          }
                        }}
                        variant={"outlined"}
                        label={<Trans>Message</Trans>}
                      />
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      item
                      md={2}
                      xs={12}
                    >
                      <Box
                        mt={1}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          fullWidth
                          type="submit"
                          disabled={!isValid || isSending}
                          variant="contained"
                        >
                          <Fragment>
                            <Trans>Send</Trans>
                            &nbsp;
                            <SendIcon />
                          </Fragment>
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </FormProvider>
              </form>
            </StyledChatForm>
          </StyledChatContainer>
        </Box>
      )}
    </Fragment>
  );
};

export default memo(ActivityChatOrganism);
