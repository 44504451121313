import { useState, memo, useEffect } from "react";
import { useQuery } from "react-query";
import { IMaintenancesOrganismProps } from "./interfaces";
import { Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import { Grid, Typography, Paper } from "@mui/material";
import { Alert } from "@mui/material";
import MaintenancesService from "@services/MaintenancesService";
import { MaintenanceModel } from "@models/MaintenanceModel";
import SpinnerAtom from "@atoms/Spinner";
import MaintenancesCreateOrganism from "@organisms/MaintenancesCreate";
import ChipAtom from "@atoms/Chip";
import { StyledTimelineContainer } from "./styled";

const MaintenancesOrganism = ({
  model_id,
  model_type,
}: IMaintenancesOrganismProps) => {
  const [events, setEvents] = useState<Array<MaintenanceModel>>([]);

  const { data, isLoading } = useQuery<unknown, unknown, MaintenanceModel[]>(
    `maintenances-${model_id}`,
    () =>
      MaintenancesService.get("", { model_id, model_type }).then(
        (res: any) => res.data,
      ),
    { refetchOnWindowFocus: false, cacheTime: 0, refetchOnMount: true },
  );

  useEffect(() => {
    if (!isLoading && data) {
      //@ts-ignore
      setEvents(data);
    }
  }, [isLoading, data]);

  const onMaintenanceCreate = (maintenance: MaintenanceModel) => {
    events.unshift(maintenance);
    setEvents([...events]);
  };

  return (
    <Grid container spacing={5}>
      <Grid item md={5}>
        <Paper variant="outlined" elevation={0} sx={{ padding: "10px" }}>
          <Typography variant="h5">
            <Trans>Add maintenanance</Trans>
          </Typography>
          <MaintenancesCreateOrganism
            onCreate={onMaintenanceCreate}
            model_id={model_id}
            model_type={model_type}
          />
        </Paper>
      </Grid>
      <Grid item md={7}>
        <Paper variant="outlined" elevation={0} sx={{ padding: "10px" }}>
          <Typography variant="h5">History</Typography>
          {!isLoading ? (
            events.length ? (
              <Box>
                <Box>
                  <StyledTimelineContainer>
                    <ul>
                      {events.map((event: MaintenanceModel) => {
                        return (
                          <li>
                            <Grid container>
                              <Grid item md={6}>
                                <Typography color={"#1565c0"} fontSize={"13px"}>
                                  {event.readableDate()}
                                </Typography>
                              </Grid>
                              <Grid item md={6}>
                                <ChipAtom
                                  variant="filled"
                                  label={event.status.name}
                                  color={event.status.class}
                                ></ChipAtom>
                              </Grid>
                            </Grid>
                            <Box>
                              <Typography variant={"body1"}>
                                {event.user ? event.user.full_name : "--"}
                              </Typography>
                            </Box>
                            <Box>{event.notes}</Box>
                          </li>
                        );
                      })}
                    </ul>
                  </StyledTimelineContainer>
                </Box>
              </Box>
            ) : (
              <Alert color="info">
                <Trans>There are no maintenances</Trans>
              </Alert>
            )
          ) : (
            <SpinnerAtom></SpinnerAtom>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default memo(MaintenancesOrganism);
