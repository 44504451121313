import { remCalc } from "@utils/helpers";
import { styled } from "@mui/system";

import { AppBar, Toolbar, Button } from "@mui/material";
import { COLORS } from "@theme/Colors";

export const StyledButton = styled(Button)``;

export const StyledHeader = styled(AppBar)((props) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  [props.theme.breakpoints.down("md")]: {
    paddingLeft: 0,
  },
  "&.active": {
    visibility: "visible",
    transition: "all 0.5s",
  },
  "&.hidden": {
    visibility: "hidden",
    transition: "all 0.5s",
    transform: "translateY(-100%)",
  },
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: "flex",
  zIndex: "0 !important",
  justifyContent: "space-between",
}));

export const StyledLGMenuWrapper = styled("div")((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledLGMenu = styled("div")({
  marginLeft: remCalc(10),
  paddingRight: "10px",
  borderRight: "1px solid #DDDDDD",
  marginRight: "10px",
});

export const StyledButtonLink = styled(Button)({
  margin: "5px",
  color: "white",
  "&:hover": {
    backgroundColor: "white",
    color: COLORS.blue.primary,
  },
  "&.active": {
    backgroundColor: "white",
    color: COLORS.blue.primary,
  },
});
