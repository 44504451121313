import { CoreBaseModel } from "@core/models/Base.model";

export class AttributeModel extends CoreBaseModel {
  attributes: Array<any>;
  is_repeater: boolean;
  name: string;
  id: number;
  alias: string;

  get slug() {
    return this.alias ? this.alias : this.name;
  }

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "attributes";
  }
}
