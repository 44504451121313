import React from "react";
import { Box, Typography } from "@mui/material";
import { t } from "@lingui/macro";
import ForumIcon from "@mui/icons-material/Forum";
import MIconAtom from "@atoms/MIcon";
import { startChat } from "@stores/reducers/chatReducer";
import { useAppDispatch } from "@stores/hooks";

const ChatNotification = ({ notification, onClick }: any) => {
  const dispatch = useAppDispatch();

  const openChat = () => {
    dispatch(
      startChat({
        activity_id: notification.notification.model.activity_id,
      }),
    );
    if (onClick) {
      onClick();
    }
  };

  return (
    <React.Fragment>
      <Box
        onClick={openChat}
        sx={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        <Box>
          <MIconAtom
            tooltip={t`Chat message`}
            icon={<ForumIcon sx={{ fontSize: "12px" }} />}
          />
        </Box>
        <Box>
          <Typography fontSize={"12px"}>
            {notification.notification.user.full_name}
          </Typography>
          <Box>
            <Typography
              className="truncate"
              variant="caption"
              sx={{ color: "#616161", fontWeight: "bold" }}
            >
              "{notification.text}"
            </Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default ChatNotification;
