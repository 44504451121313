import type { IPeopleFilterProps } from "@organisms/PeopleFilter/interface";
import { IDS } from "@utils/constants";

export const peopleFilterInitialValues: IPeopleFilterProps = {
  search: "",
  role_id: IDS.ROLES.INSPECTOR,
  country_id: "",
  city: "",
  type_id: "",
  location_id: "",
  inactive: null,
  attributes: [],
  isRadius: false,
  radius: 0,
};
