import * as React from "react";
import {
  Grid,
  Box,
  Alert,
  Divider,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectMolecule } from "@molecules/AsyncSelect";
import { t, Trans } from "@lingui/macro";
import TextFieldAtom from "@atoms/TextField";
import IPersonCreateOrganism, { IFormValues } from "./interface";
import createPersonSchema from "@validation/createPerson";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import PeopleService from "@services/PeopleService";
import { IDS } from "@utils/constants";
import { fetchFilter } from "@stores/reducers/filtersReducer";
import { useAppDispatch } from "@stores/hooks";
import SwitchMolecule from "@molecules/Switch";
import PartnersService from "@services/PartnersService";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ButtonAtom from "@atoms/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import AttributesOrganism from "@organisms/AttributesOrganism/index-new";
import { PeopleModel } from "@models/PeopleModel";
import { addForm, updateForm } from "@stores/reducers/saverReducer";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import GoogleAutocomplete from "@molecules/GoogleAutocomplete";

const steps = [t`General info`, t`Certificates`];

/**
 * QUESTO VALORE ANDRà RIADATTATO!!!!!!!!!!!
 * ANCHE IN createPersonSchema()
 */
const ID_PROVIDER = 5;
const ID_FREELANCE = 6;

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode
 * @returns
 */
const PersonCreateOrganism = ({
  mode,
  person,
  onNewPerson,
  hideAttributes,
  onUpdate,
  onCancel,
}: IPersonCreateOrganism) => {
  const [sErrors, setSErrors] = React.useState<any | null>(null);
  const [globalLoading, setGlobaLoading] = React.useState<boolean>(false);
  const [renderKey, setRenderKey] = React.useState<number>(0);
  const [msg, setMsg] = React.useState("");
  const [createdPerson, setCreatedPerson] = React.useState<
    PeopleModel | undefined
  >(undefined);
  const [success, setSuccess] = React.useState<boolean | null>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const autoSave = useSelector((state: any) => state.saver.saveAll);
  const dispatch = useAppDispatch();

  const formInstance = useForm<IFormValues>({
    defaultValues: {
      role_id: IDS.ROLES.INSPECTOR,
      name: person ? (person.name ? person.name : "") : "",
      surname: person ? (person.surname ? person.surname : "") : "",
      country_id: person ? (person.country_id ? person.country_id : "") : "",
      email: person ? (person.email ? person.email : "") : "",
      force: false,
      phone: person ? (person.phone ? person.phone : "") : "",
      city: person ? (person.city ? person.city : "") : "",
      verified: person?.verified ?? false,
      bhge: person?.bhge ?? false,
      internal: person?.internal ?? false,
      // partners - data must be mapped as: {category_id, partner_id}
      sources: person
        ? person.partners.map((partner: any) => {
            return {
              category_id: partner.category_id,
              partner_id: partner.partner ? partner.partner.id : "",
            };
          })
        : [],
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createPersonSchema(ID_PROVIDER)),
  });

  const {
    fields: sourcesFields,
    append: appendSource,
    remove: removeSource,
  } = useFieldArray({
    control: formInstance.control,
    name: "sources",
  });

  const {
    control,
    getValues,
    setValue,
    watch,
    formState: { isValid, isDirty, errors },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  useEffect(() => {
    if (autoSave.status && isDirty) {
      handleSubmit(getValues());
    }
  }, [autoSave, getValues]);

  useEffect(() => {
    if (isDirty) {
      dispatch(
        addForm({
          slug: "inspector-detail-form-data",
          formState: formInstance.formState,
        }),
      );
    } else {
      dispatch(
        updateForm({
          slug: "inspector-detail-form-data",
          dirty: false,
          saved: true,
        }),
      );
    }
  }, [formInstance, isDirty]);

  const { mutate: createAssetMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      (mode === "patch" && person
        ? PeopleService.put(formValues, person.id)
        : PeopleService.create(formValues)
      ).then((res: any) => {
        setGlobaLoading(false);
        if (!res.hasErrors()) {
          dispatch(fetchFilter("users"));
          setCreatedPerson(res.getData());
          if (!person) {
            // setMsg(res.getMsgString())
            // setSuccess(true);
          }
          /*
					toast(res.getMsgString(), {
						type: 'success'
					});
					*/
          setTimeout(() => {
            dispatch(
              updateForm({
                slug: "inspector-detail-form-data",
                dirty: false,
                saved: true,
              }),
            );
          }, 1000);
          if (onNewPerson && !person) {
            onNewPerson(res.getData());
          }
          if (person) {
            // updated
            onUpdate(res.getData());
          } else {
            setActiveStep(1);
          }
        } else {
          setMsg(res.getMsgString());
          setSErrors(res.getErrors());
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      }),
  );

  const handleSubmit = (data: IFormValues) => {
    if (globalLoading) {
      return false;
    }
    setGlobaLoading(true);
    const values = { ...data };
    setSErrors([]);
    setMsg("");
    if (getValues().sources.length) {
      const promises: Array<any> = [];
      getValues().sources.forEach((source: any) => {
        if (source.new_provider) {
          promises.push(
            PartnersService.create({
              name: source.new_provider_name,
              email: source.new_provider_email,
            }),
          );
        }
      });
      Promise.all(promises).then((results: any) => {
        // Preselect newly created partner
        dispatch(fetchFilter("partners"));
        results.forEach((response: any) => {
          values.sources.forEach((previousPartner: any, index: number) => {
            if (
              previousPartner.new_provider_email === response.getData().email
            ) {
              previousPartner.partner_id = response.getData().id;
            }
            setValue(
              `sources.${index}`,
              {
                ...previousPartner,
                ...{ new_provider: false },
              },
              { shouldValidate: true },
            );
          });
        });
        setRenderKey(renderKey + 1);
        values.partners = values.sources;
        // Clean from partner_id === 0 (refactor)
        if (values.partners) {
          values.partners.forEach((partner: any, index: number) => {
            if (values.partners && values.partners[index].partner_id === 0) {
              values.partners[index].partner_id = null;
            }
          });
        }
        values.bhge = values.bhge ? true : false;
        delete values.sources;
        createAssetMutation(values);
      });
    }
  };

  watch("sources");
  const forceEmail = watch("force");

  const freelanceFound = getValues().sources.findIndex(
    (source: any) => source.category_id === ID_FREELANCE,
  );

  const appendSourceField = () => {
    appendSource({
      category_id: "",
      partner_id: 0,
      new_provider: false,
      new_provider_name: "",
      new_provider_email: "",
    });
  };

  React.useEffect(() => {
    if (!getValues().sources.length) {
      appendSourceField();
    }
  }, []);

  const removeSourceA = (index: number) => {
    removeSource(index);
    // Force re-rendering because sources fiels are not updating correctly
    setRenderKey(renderKey + 1);
  };

  const saveInspectorAndNext = () => {
    handleSubmit(formInstance.getValues());
  };

  const finalize = () => {
    setSuccess(true);
    setMsg("Inspector has been set up successfully");
  };

  return (
    <Box sx={{ width: "100%" }}>
      {success ? (
        <React.Fragment>
          {msg ? (
            <Box mt={2}>
              <Alert severity="success">{msg}</Alert>
            </Box>
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {!person && (
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          )}
          {activeStep === 0 && (
            <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
              <FormProvider {...formInstance}>
                <Box mt={3}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} py={1}>
                      <SwitchMolecule
                        label={t`Verified`}
                        control={control}
                        controlName={"verified"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} py={1}>
                      <SwitchMolecule
                        label={t`Internal`}
                        control={control}
                        controlName={"internal"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextFieldAtom
                        controlName={"name"}
                        required={true}
                        variant={"outlined"}
                        label={<Trans>Name</Trans>}
                      />
                      {sErrors && sErrors["name"] ? (
                        <Alert severity="error" icon={false}>
                          {sErrors["name"]}
                        </Alert>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextFieldAtom
                        controlName={"surname"}
                        required={true}
                        variant={"outlined"}
                        label={<Trans>Surname</Trans>}
                      />
                      {sErrors && sErrors["surname"] ? (
                        <Alert severity="error" icon={false}>
                          {sErrors["surname"]}
                        </Alert>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item md={6}>
                        <TextFieldAtom
                          controlName={"email"}
                          required={true}
                          variant={"outlined"}
                          label={<Trans>Email</Trans>}
                        />
                        {sErrors && sErrors["email"] ? (
                          <Alert severity="error" icon={false}>
                            {sErrors["email"]}
                          </Alert>
                        ) : null}
                      </Grid>
                      <Grid item md={6}>
                        <SwitchMolecule
                          label={t`Force email`}
                          control={control}
                          controlName={"force"}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {
                    forceEmail && <Box mt={1}>
                      <Alert severity="warning">
                        <b><Trans>Force email Warning</Trans></b>
                        <p><Trans>You should use this flag ONLY in the case the email you’re adding is the one of a provider NOT an inspector</Trans></p>
                      </Alert>
                    </Box>
                  }
                  <Box mt={2}>
                    <Grid container spacing={2}>
                    <Grid item md={4}>
                        <TextFieldAtom
                          controlName={"phone"}
                          variant={"outlined"}
                          label={<Trans>Phone</Trans>}
                        />
                        {sErrors && sErrors["phone"] ? (
                          <Alert severity="error" icon={false}>
                            {sErrors["phone"]}
                          </Alert>
                        ) : null}
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <GoogleAutocomplete
                          control={control}
                          onlyCity={true}
                          label={
                            <React.Fragment>
                              <Trans>City</Trans> *
                            </React.Fragment>
                          }
                          controlName="city"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <SelectMolecule
                          control={control}
                          required={true}
                          controlName="country_id"
                          sError={sErrors ? sErrors["country_id"] : undefined}
                          optionValue={"id"}
                          storeCollection="countries"
                          variant={"outlined"}
                          label={<Trans>Country</Trans>}
                        ></SelectMolecule>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <SwitchMolecule
                        label={t`BHGE enabled`}
                        control={control}
                        controlName={"bhge"}
                      />
                  </Box>
                  <Box mt={3}>
                    <Typography>
                      <b>
                        <Trans>Sources</Trans>
                      </b>
                    </Typography>
                  </Box>
                  <Box key={renderKey}>
                    {sourcesFields.map((source: any, sourceIndex: number) => {
                      return (
                        <Box key={sourceIndex}>
                          <Box mt={2}>
                            <Divider />
                            <Box
                              sx={{
                                marginTop: "-12px",
                                marginBottom: "15px",
                              }}
                            >
                              <Typography
                                sx={{
                                  background: "white",
                                  paddingRight: "3px",
                                  color: "#333333",
                                }}
                                variant="caption"
                              >
                                <Trans>Source</Trans>
                                &nbsp;#
                                {sourceIndex + 1}
                              </Typography>
                            </Box>
                          </Box>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              md={sourcesFields.length > 1 ? 11 : 12}
                              xs={12}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                  <SelectMolecule
                                    control={control}
                                    controlName={`sources.${sourceIndex}.category_id`}
                                    block={
                                      freelanceFound !== -1 &&
                                      freelanceFound !== sourceIndex
                                        ? [ID_FREELANCE]
                                        : []
                                    }
                                    listId={"people-create-category"}
                                    storeCollection="categories.inspections"
                                    variant={"outlined"}
                                    emptyValue={null}
                                    optionValue={"id"}
                                    optionLabel={"name"}
                                    label={<Trans>Source</Trans>}
                                  ></SelectMolecule>
                                </Grid>
                              </Grid>
                              {getValues().sources[sourceIndex].category_id ===
                                ID_PROVIDER && (
                                <React.Fragment>
                                  <Grid container spacing={2}>
                                    <Grid item md={10}>
                                      {!getValues().sources[sourceIndex]
                                        .new_provider && (
                                        <Grid container mt={1} spacing={2}>
                                          <Grid item xs={12} sm={12} md={12}>
                                            <SelectMolecule
                                              control={control}
                                              //@ts-ignore
                                              formError={
                                                //@ts-ignore
                                                errors?.["sources"]?.[
                                                  sourceIndex
                                                ]?.["partner_id"]?.["message"]
                                              }
                                              controlName={`sources.${sourceIndex}.partner_id`}
                                              listId={"people-create-provider"}
                                              storeCollection="partners"
                                              emptyValue={0}
                                              variant={"outlined"}
                                              optionValue={"id"}
                                              optionLabel={"name"}
                                              label={<Trans>Provider</Trans>}
                                            ></SelectMolecule>
                                          </Grid>
                                        </Grid>
                                      )}
                                      {getValues().sources[sourceIndex]
                                        .new_provider && (
                                        <Grid container mt={1} spacing={2}>
                                          <Grid item xs={12} sm={12} md={6}>
                                            <TextFieldAtom
                                              //@ts-ignore
                                              formError={
                                                //@ts-ignore
                                                errors?.["sources"]?.[
                                                  sourceIndex
                                                ]?.["new_provider_name"]?.[
                                                  "message"
                                                ]
                                              }
                                              controlName={`sources.${sourceIndex}.new_provider_name`}
                                              variant={"outlined"}
                                              label={
                                                <Trans>New provider name</Trans>
                                              }
                                            />
                                          </Grid>
                                          <Grid item xs={12} sm={12} md={6}>
                                            {
                                              //@ts-ignore
                                              errors?.["sources"]?.[
                                                sourceIndex
                                              ]?.["required"]?.["message"]
                                            }
                                            <TextFieldAtom
                                              //@ts-ignore
                                              formError={
                                                //@ts-ignore
                                                errors?.["sources"]?.[
                                                  sourceIndex
                                                ]?.["new_provider_email"]?.[
                                                  "message"
                                                ]
                                              }
                                              controlName={`sources.${sourceIndex}.new_provider_email`}
                                              variant={"outlined"}
                                              label={
                                                <Trans>
                                                  New provider email
                                                </Trans>
                                              }
                                            />
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      md={2}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SwitchMolecule
                                        controlName={`sources.${sourceIndex}.new_provider`}
                                        control={control}
                                        label={"New provider"}
                                      />
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              )}
                            </Grid>
                            {sourcesFields.length > 1 && (
                              <Grid
                                item
                                md={1}
                                xs={12}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <IconButton
                                  color="error"
                                  className="delete-btn"
                                  onClick={() => removeSourceA(sourceIndex)}
                                >
                                  <DeleteIcon color={"error"} />
                                </IconButton>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box mt={2}>
                    <ButtonAtom
                      variant="outlined"
                      label={
                        <React.Fragment>
                          <AddIcon></AddIcon>&nbsp;
                          <Trans>Add source</Trans>
                        </React.Fragment>
                      }
                      onClick={appendSourceField}
                    />
                  </Box>
                </Box>
              </FormProvider>
            </form>
          )}
          {activeStep === 1 && createdPerson && (
            <Box mt={3}>
              <AttributesOrganism
                editModeIndex={0}
                model_type="users"
                model={createdPerson}
              ></AttributesOrganism>
            </Box>
          )}
          <Box mt={2}>
            {sErrors && msg ? <Alert severity="error">{msg}</Alert> : null}
            <Box mt={2} sx={{ textAlign: "right" }}>
              {onCancel ? (
                <ButtonAtom
                  sx={{ marginRight: "5px" }}
                  color={"error"}
                  onClick={onCancel}
                  disabled={isLoading}
                  label={<Trans>Cancel</Trans>}
                />
              ) : null}
              {(activeStep === 1 || mode === "patch") && (
                <ButtonAtom
                  label={
                    mode === "patch" ? (
                      <Trans>Update inspector</Trans>
                    ) : (
                      <Trans>Create inspector</Trans>
                    )
                  }
                  loading={globalLoading}
                  variant="contained"
                  onClick={mode === "patch" ? saveInspectorAndNext : finalize}
                  disabled={!isValid}
                />
              )}
              {activeStep === 0 && mode !== "patch" && (
                <ButtonAtom
                  label={<Trans>Next</Trans>}
                  loading={globalLoading}
                  variant="contained"
                  onClick={saveInspectorAndNext}
                  disabled={!isValid}
                />
              )}
            </Box>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default PersonCreateOrganism;
