import { CoreBaseService } from "@core/services/Base.service";
import { HCMActivityModel } from "@models/HCMActivityModel";
import { HCMModel } from "@models/HCMModel";

class HCMService extends CoreBaseService {
  constructor() {
    super(HCMModel);
  }

  copy(id: number) {
    return this.sub(`${id}/copy`).create({});
  }

  getActivities(query: any) {
    return this.customCall("hcms_activities").get("", query);
  }

  extends(id: number, data: any) {
    return this.sub(`${id}/extends`, HCMActivityModel).create(data);
  }

  setRates(hcmId: string | number, data: any) {
    return this.sub(`${hcmId}/rates`).create(data);
  }
}

const inst = new HCMService();

export default inst;
