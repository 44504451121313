import { ChangeEvent, useEffect, useRef, useState } from "react";
import IPaginatedList from "./interface";
import { Box, Pagination, Alert } from "@mui/material";
import SpinnerAtom from "@atoms/Spinner";
import { Trans } from "@lingui/macro";
import { StyledContainer, StyledLoadingOverlay } from "./styled";
import { useBottomScrollListener } from "@hooks";

const PaginatedListMolecule = (props: IPaginatedList) => {
  const {
    service,
    newRow,
    idToDelete,
    updated,
    filter,
    avoidRerender,
    handleOnPageChange,
    listId,
    header,
    renderFunc,
    perPage,
    showPaginationOnTop,
    onLoad,
    onPageClick,
    scrollPagination,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResult] = useState<Array<any>>([]);
  const [lastPage, setLastPage] = useState(undefined);
  const [isLoading, setLoading] = useState(true);
  const [overlay, setOverlay] = useState(false);
  const readyToRestart = useRef<boolean>(true);

  useBottomScrollListener(
    () => {
      if (scrollPagination !== undefined && readyToRestart.current) {
        readyToRestart.current = false;
        changePageAutomation();
      }
    },
    {
      debounce: 0,
      offset: 10,
      triggerOnNoScroll: true,
      element:
        scrollPagination !== undefined && scrollPagination !== true
          ? scrollPagination
          : null,
    },
  );

  const list = (page?: number, loading = true, append = false) => {
    if (loading) setLoading(true);
    readyToRestart.current = false;
    page = page || 1;
    setCurrentPage(page);
    service
      .get("", { ...filter, page, perpage: perPage })
      .then((res: any) => {
        setOverlay(false);
        if (loading) setLoading(false);
        if (!res.hasErrors()) {
          setResult(
            !append ? res.getData().data : results.concat(res.getData().data),
          );
          setLastPage(res.getData().last_page);
          setTimeout(() => {
            readyToRestart.current = true;
          }, 10);
          if (onLoad) {
            onLoad();
          }
        } else {
          setResult([]);
        }
      })
      .catch((e: any) => {
        if (loading) setLoading(false);
        setResult([]);
      });
  };

  useEffect(() => {
    if (handleOnPageChange) {
      handleOnPageChange(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setOverlay(true);
    list(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    // @ts-ignore
    if (props.refetchKey > 0) {
      list(scrollPagination ? 1 : currentPage, scrollPagination ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.refetchKey]);

  const changePageAutomation = () => {
    setTimeout(() => {
      if (!isLoading) {
        if (lastPage && lastPage > 1) {
          if (currentPage < lastPage) {
            list(currentPage + 1, true, true);
            if (onPageClick) {
              onPageClick();
            }
          }
        }
      }
    }, 100);
  };

  const handlePageClick = (event: ChangeEvent<unknown>, page: number) => {
    list(page);
    if (onPageClick) {
      onPageClick();
    }
  };

  useEffect(() => {
    if (newRow) {
      if (Array.isArray(newRow)) {
        newRow.forEach((row: any) => {
          results.unshift(row);
        });
      } else {
        results.unshift(newRow);
      }
      const newA = [...results];
      setResult(newA);
    }
  }, [newRow]);

  useEffect(() => {
    if (idToDelete) {
      setResult(results.filter((item: any) => item.id !== idToDelete));
    }
  }, [idToDelete]);

  useEffect(() => {
    if (updated) {
      const toUpdateIndex = results.findIndex(
        (item: any) => item.id === updated.id,
      );
      results[toUpdateIndex] = updated;
      setResult([...results]);
    }
  }, [updated]);

  return (
    <>
      <StyledContainer elevation={0} sx={{ position: "relative" }}>
        {showPaginationOnTop && results.length && (
          <Box mb={2}>
            <Pagination
              count={lastPage}
              page={currentPage}
              onChange={handlePageClick}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        )}
        <Box sx={{ padding: "2px", position: "relative" }}>
          {header}
          {/*newRows && (
                        <StyledNewRowBox>
                            {newRows.map((item: any, index: number) => {
                                return <Box mb={1} key={index}>{renderFunc(item)} </Box>
                            })}
                        </StyledNewRowBox>
                    )*/}
          {overlay || (isLoading && !scrollPagination) ? (
            <StyledLoadingOverlay>
              <Box
                mt={1}
                sx={{
                  width: "fit-content",
                  position: "absolute",
                  right: "5px",
                  top: "-20px",
                }}
              >
                <SpinnerAtom />
              </Box>
            </StyledLoadingOverlay>
          ) : (
            <></>
          )}
          {results?.map((item: any, index: number) => {
            return (
              <Box
                className="row"
                key={
                  avoidRerender
                    ? index.toString() + item.id.toString() + listId
                    : index.toString() +
                      item.id.toString() +
                      Math.floor(Math.random() * 10)
                }
              >
                {renderFunc(item, index)}
              </Box>
            );
          })}
        </Box>
        {scrollPagination && isLoading && (
          <Box mt={1}>
            <SpinnerAtom size="25px" />
          </Box>
        )}
        {results.length ? (
          <Box>
            {lastPage && lastPage > 1 && !scrollPagination && (
              <Box mt={2}>
                <Pagination
                  count={lastPage}
                  page={currentPage}
                  onChange={handlePageClick}
                  variant="outlined"
                  shape="rounded"
                />
              </Box>
            )}
          </Box>
        ) : !isLoading ? (
          <Alert severity="warning">
            <Trans>No documents were found</Trans>
          </Alert>
        ) : null}
      </StyledContainer>
    </>
  );
};

PaginatedListMolecule.defaultProps = {
  perPage: 15,
  listStateVar: "list",
  showPaginationOnTop: false,
};

export default PaginatedListMolecule;
