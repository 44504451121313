import { styled } from "@mui/system";
import Box from "@mui/material/Box";

export const StyledBody = styled(Box)(() => ({
  marginTop: "10px",
  margin: "0px",
  "& h5": {
    marginBottom: "25px",
  },
}));
