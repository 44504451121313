import { Fragment, useState } from "react";
import IOrganism, { YupSchema } from "./interface";
import { Box, Button, Card, Grid } from "@mui/material";
import { useMutation } from "react-query";
import HCMService from "@services/HCMService";
import HCMActivityRow from "./ActivityRow";
import ClearIcon from "@mui/icons-material/Clear";
import { t, Trans } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import TextField from "@atoms/TextField";
import ButtonAtom from "@atoms/Button";
import HCMActivitiesService from "@services/HCMActivitiesService";
import { HCMActivityModel } from "@models/HCMActivityModel";
import PaginatedListMolecule from "@molecules/PaginatedList";
import { yupResolver } from "@hookform/resolvers/yup";
import { ActivityModel } from "@models/ActivityModel";

const HCMDetailsActivitiesOrganism = ({ hcm, onMod }: IOrganism) => {
  const [extensorVisible, setExtensorVisible] = useState<boolean>(false);
  const [newRow, setNewRows] = useState<HCMActivityModel | undefined>(
    undefined,
  );
  const [filter /*, setFilter*/] = useState({ hcm_id: hcm.id });
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);
  const [updatedItem, setUpdated] = useState<ActivityModel | undefined>(
    undefined,
  );

  const formInstance = useForm<any>({
    defaultValues: {
      months: 1,
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(YupSchema),
  });

  const { handleSubmit: handleHookSubmit, formState } = formInstance;

  const { mutate: extendMutation, isLoading: isExtending } = useMutation(
    (data: any) =>
      HCMService.extends(hcm.id, data).then((res: any) => {
        if (!res.hasErrors()) {
          setNewRows(res.getData());
          setExtensorVisible(false);
          toast(res.getMsgString(), { type: "success" });
          if (onMod) {
            onMod();
          }
        } else {
          toast(res.getMsgString(), { type: "error" });
        }
      }),
  );

  const handleSubmit = (data: any) => {
    extendMutation(data);
  };

  const handleExtend = () => {
    setExtensorVisible(extensorVisible ? false : true);
  };

  const onDelete = (id: number) => {
    setIdToDelete(id);
    if (onMod) {
      onMod();
    }
  };

  const onRowMod = (activity: ActivityModel) => {
    setUpdated(activity);
  };

  return (
    <>
      {
        <Box>
          <Box mb={1}>
            <Button
              sx={{ margin: "0 auto" }}
              color={extensorVisible ? "error" : "info"}
              variant={"contained"}
              className="align-center"
              onClick={handleExtend}
            >
              {extensorVisible ? (
                <Fragment>
                  <ClearIcon fontSize="small" />
                  &nbsp;<Trans>Cancel</Trans>
                </Fragment>
              ) : (
                <Fragment>
                  <AddIcon fontSize="small" />
                  &nbsp;<Trans>Extend</Trans>
                </Fragment>
              )}
            </Button>
          </Box>
          {extensorVisible && (
            <Box mb={2}>
              <Card>
                <Box p={2}>
                  <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                    <FormProvider {...formInstance}>
                      <Box>
                        <Grid container spacing={1}>
                          <Grid item md={6}>
                            <TextField
                              InputProps={{
                                inputProps: {
                                  min: 1,
                                  max: 10,
                                },
                              }}
                              type={"number"}
                              label={t`Months`}
                              controlName={"months"}
                              required={true}
                            />
                          </Grid>
                          <Grid item md={6}></Grid>
                        </Grid>
                      </Box>
                    </FormProvider>
                    <Box mt={1} className="text-center">
                      <ButtonAtom
                        disabled={!formState.isValid}
                        loading={isExtending}
                        label={t`extends`}
                        type="submit"
                      />
                    </Box>
                  </form>
                </Box>
              </Card>
            </Box>
          )}
          <PaginatedListMolecule
            renderFunc={(activity: HCMActivityModel) => {
              return (
                <Box mb={1}>
                  <HCMActivityRow
                    onMod={onRowMod}
                    onDelete={() => onDelete(activity.id)}
                    hcm={hcm}
                    activity={activity}
                  />
                </Box>
              );
            }}
            service={HCMActivitiesService}
            idToDelete={idToDelete}
            scrollPagination={document.querySelector(
              ".MuiDrawer-paperAnchorRight",
            )}
            updated={updatedItem}
            perPage={15}
            newRow={newRow}
            filter={filter}
          />
        </Box>
      }
    </>
  );
};

export default HCMDetailsActivitiesOrganism;
