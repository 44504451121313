import React, { useEffect } from "react";
import { Grid, Box, Typography, Alert } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectMolecule } from "@molecules/AsyncSelect";
import { t, Trans } from "@lingui/macro";
import TextFieldAtom from "@atoms/TextField";
import IProps, { IFormValues, IFormCreateValues } from "./interface";
import createSchema from "./validation";
import { FormProvider, useForm } from "react-hook-form";
import SwitchMolecule from "@molecules/Switch";
import PaginatedListMolecule from "@molecules/PaginatedList";
import { JobModel } from "@models/JobModel";
import JobsService from "@services/JobsService";
import JobRowMolecule from "@molecules/JobRow";
import ClientsService from "@services/ClientsService";
import { IDS } from "@utils/constants";
import { CoreResponseModel } from "@core/models/Response.model";
import { fetchFilter } from "@stores/reducers/filtersReducer";
import { useAppDispatch } from "@stores/hooks";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import InspectionsService from "@services/InspectionsService";
import ButtonAtom from "@atoms/Button";
import { AutocompleteMolecule } from "@molecules/AsyncAutocomplete";

const LinkJobForm = ({
  action,
  onSelectedJobChange,
  selectedJob,
  inspection,
  onNext,
}: IProps) => {
  const [filter, setFilter] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const formMethods = useForm<IFormValues>({
    defaultValues: {
      job_type: "existing",
      client_id: null,
      type_id: "",
      category_id: "",
      search: "",
    },
    mode: "onChange",
  });

  const { getValues, watch } = formMethods;

  const formMethodsCreate = useForm<IFormCreateValues>({
    defaultValues: {
      client_id: 0,
      type_id: "",
      category_id: "",
      label: "",
      // code_id: '',
      is_new: false,
      client_name: null,
      client_email: null,
      client_country: 0,
      notes: "",
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createSchema()),
  });

  const {
    getValues: getValuesCreate,
    watch: watchCreate,
    resetField: resetFieldCreate,
    setValue: setValueCreate,
    formState: formStateCrate,
  } = formMethodsCreate;

  const isNew = watchCreate("is_new");

  useEffect(() => {
    const watchAll = formMethods.watch((value, { name, type }) => {
      setFilter(getValues());
    });
    return () => watchAll.unsubscribe();
  }, [watch, formMethods, getValues]);

  useEffect(() => {
    onSelectedJobChange(0);
  }, [filter]);

  const createJob = async () => {
    setIsLoading(true);
    let isNew = getValuesCreate().is_new;
    let newClientId = null;
    if (isNew) {
      const clientData = (await ClientsService.create({
        type_id: IDS.CUSTOMERS.CLIENT,
        country_id: getValuesCreate().client_country,
        name: getValuesCreate().client_name,
        email: getValuesCreate().client_email,
      })) as CoreResponseModel;
      newClientId = clientData.getData().id;
      if (!clientData.hasErrors()) {
        dispatch(fetchFilter("clients"));
        setValueCreate("client_id", newClientId);
        resetFieldCreate("client_country");
        resetFieldCreate("client_name");
        resetFieldCreate("client_email");
        setValueCreate("is_new", false);
      } else {
        toast(clientData.getMsgString(), { type: "error" });
      }
    }
    const jobValues = getValuesCreate();
    if (isNew) {
      jobValues.client_id = newClientId;
    }
    JobsService.create(jobValues).then((res: any) => {
      setIsLoading(false);
      if (!res.hasErrors()) {
        onSelectedJobChange(res.getData().id);
        goNext(res.getData().id);
      } else {
        toast(res.getMsgString(), { type: "error" });
      }
    });
  };

  const goNext = (jobId: number | null = null) => {
    if (inspection) {
      return associateJobId(jobId, () => {
        if (onNext) {
          onNext();
        }
      });
    } else {
      if (onNext) {
        onNext();
      }
    }
  };

  const { mutate: updateInspectionMutation } = useMutation(
    (data: { data: { job_id: number }; cb: () => void }) =>
      InspectionsService.setJob(data.data, inspection ? inspection.id : 0).then(
        (res: any) => {
          if (!res.hasErrors()) {
            if (data.cb) {
              data.cb();
            }
          } else {
            setError(true);
          }
        },
      ),
  );

  const associateJobId = (
    jobId: number | null = null,
    callback: () => void,
  ) => {
    setError(false);
    updateInspectionMutation({
      data: { job_id: jobId ?? selectedJob },
      cb: callback,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        {action === "existing" && (
          <form noValidate>
            <FormProvider {...formMethods}>
              <Box>
                <Box mt={2}>
                  <Typography>
                    <Trans>Select the parent job of this inspection</Trans>
                  </Typography>
                  <Grid container mt={1} spacing={1}>
                    <Grid item md={3} sm={12}>
                      <AutocompleteMolecule
                        control={formMethods.control}
                        controlName="client_id"
                        emptyValue={""}
                        optionValue={"id"}
                        variant={"outlined"}
                        label={"Client"}
                        getOptionLabel={(client: any) => `${client?.name}`}
                        storeCollection="clients"
                        optionLabel={"name"}
                      ></AutocompleteMolecule>
                    </Grid>
                    <Grid item md={3} sm={12}>
                      <SelectMolecule
                        control={formMethods.control}
                        controlName="type_id"
                        emptyValue={""}
                        optionValue={"id"}
                        variant={"outlined"}
                        label={"Division"}
                        storeCollection="types.jobs"
                        optionLabel={"name"}
                      ></SelectMolecule>
                    </Grid>
                    <Grid item md={3} sm={12}>
                      <SelectMolecule
                        control={formMethods.control}
                        controlName="category_id"
                        emptyValue={""}
                        optionValue={"id"}
                        variant={"outlined"}
                        label={"Service"}
                        storeCollection="categories.jobs"
                        optionLabel={"name"}
                      ></SelectMolecule>
                    </Grid>
                    <Grid item md={3} sm={12}>
                      <TextFieldAtom
                        controlName={"search"}
                        required={true}
                        minRows={2}
                        variant={"outlined"}
                        label={<Trans>Project name</Trans>}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    mt={1}
                    sx={{
                      maxHeight: "26vh",
                      overflowY: "auto",
                    }}
                  >
                    {filter ? (
                      <PaginatedListMolecule
                        renderFunc={(item: JobModel) => {
                          return (
                            <JobRowMolecule
                              selected={selectedJob === item.id}
                              job={item}
                              onSelected={onSelectedJobChange}
                            />
                          );
                        }}
                        onPageClick={() => onSelectedJobChange(0)}
                        service={JobsService}
                        perPage={15}
                        filter={filter}
                      />
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Box>
            </FormProvider>
          </form>
        )}
        {action === "new" && (
          <form noValidate>
            {/*
             * TAKE CARE:
             * If you modify this form you will have to modify the @organism/JobsCreate/index.tsx as well
             */}
            <FormProvider {...formMethodsCreate}>
              <Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    {!isNew && (
                      <Grid item md={6} sm={6}>
                        <AutocompleteMolecule
                          control={formMethodsCreate.control}
                          controlName="client_id"
                          emptyValue={""}
                          optionValue={"id"}
                          variant={"outlined"}
                          label={
                            <React.Fragment>
                              <Trans>Client</Trans> *
                            </React.Fragment>
                          }
                          storeCollection="clients"
                          getOptionLabel={(client: any) => `${client.name}`}
                          optionLabel={"name"}
                        ></AutocompleteMolecule>
                      </Grid>
                    )}
                    {isNew && (
                      <Grid item md={3} sm={3}>
                        <TextFieldAtom
                          controlName={"client_name"}
                          required={true}
                          variant={"outlined"}
                          label={<Trans>Client name</Trans>}
                        />
                      </Grid>
                    )}
                    {isNew && (
                      <Grid item md={4} sm={4}>
                        <TextFieldAtom
                          controlName={"client_email"}
                          required={true}
                          variant={"outlined"}
                          label={<Trans>Client email</Trans>}
                        />
                      </Grid>
                    )}
                    {isNew && (
                      <Grid item md={3} sm={3}>
                        <SelectMolecule
                          control={formMethodsCreate.control}
                          controlName="client_country"
                          emptyValue={""}
                          optionValue={"id"}
                          required={true}
                          variant={"outlined"}
                          label={t`Country`}
                          storeCollection="countries"
                          optionLabel={"name"}
                        ></SelectMolecule>
                      </Grid>
                    )}
                    <Grid item md={isNew ? 2 : 6} sm={isNew ? 2 : 6}>
                      <SwitchMolecule
                        controlName={"is_new"}
                        control={formMethodsCreate.control}
                        label={"Is new?"}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item md={6} sm={6}>
                          <SelectMolecule
                            control={formMethodsCreate.control}
                            controlName={"type_id"}
                            emptyValue={""}
                            optionValue={"id"}
                            required={true}
                            variant={"outlined"}
                            label={<Trans>Division</Trans>}
                            storeCollection="types.jobs"
                            optionLabel={"name"}
                          ></SelectMolecule>
                        </Grid>
                        <Grid item md={6} sm={6}>
                          <SelectMolecule
                            control={formMethodsCreate.control}
                            controlName={"category_id"}
                            emptyValue={""}
                            required={true}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={<Trans>Service</Trans>}
                            storeCollection="categories.jobs"
                            optionLabel={"name"}
                          ></SelectMolecule>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={6} sm={6}></Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={6}>
                      <TextFieldAtom
                        controlName={"label"}
                        minRows={2}
                        required={true}
                        variant={"outlined"}
                        label={<Trans>Project name</Trans>}
                      />
                    </Grid>
                    {/*<Grid item md={6} sm={6}>
											<TextFieldAtom
												controlName={"code_id"}
												required={true}
												minRows={2}
												variant={"outlined"}
												label={<Trans>Code ID</Trans>}
											/>
										</Grid>*/}
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12}>
                      <TextFieldAtom
                        controlName={"notes"}
                        multiline={true}
                        minRows={2}
                        variant={"outlined"}
                        label={<Trans>Notes</Trans>}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </FormProvider>
          </form>
        )}
        {error && (
          <Box mt={2}>
            <Alert severity="error">
              <Trans>An error occurred, please check the fields</Trans>
            </Alert>
          </Box>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Box sx={{ flex: "1 1 auto" }} />
          <ButtonAtom
            loading={isLoading}
            variant="contained"
            label={t`Next`}
            onClick={() => (action === "new" ? createJob() : goNext())}
            disabled={
              (action === "existing" && !selectedJob) ||
              (action === "new" && !formStateCrate.isValid)
            }
            sx={{ mr: 1 }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LinkJobForm;
