import { Grid, Paper, Box } from "@mui/material";
import { StyledProviderRow } from "./styled";
import IUserRow from "./interface";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";
import { useUser } from "@hooks";

const UserRowMolecule = ({ user, onActionSelected }: IUserRow) => {
  const authUser = useUser();
  const actionSelected = (item: any) => {
    if (onActionSelected) {
      onActionSelected(item, user);
    }
  };

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledProviderRow>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center" }}
              md={4}
              xs={12}
            >
              <Box mr={1}>{user.full_name}</Box>
            </Grid>
            <Grid item md={2} xs={12}>
              {user.role ? user.role?.alias : "--"}
            </Grid>
            <Grid item md={2} xs={12}>
              {user.email ? user.email : "--"}
            </Grid>

            <Grid item md={2} xs={12}>
              {user.phone ? user.phone : "--"}
            </Grid>
            <Grid sx={{ textAlign: "right" }} item md={2} xs={12}>
              <MultiButtonMolecule
                onSelected={actionSelected}
                options={[
                  { label: "Edit", id: 1, data: { user } },
                  { label: "Delete", id: 4, data: { user } },
                  { label: "Send password", id: 3, data: { user }, hidden: authUser.user.role.alias !== 'Administrator' },
                ]}
              />
            </Grid>
          </Grid>
        </StyledProviderRow>
      </Paper>
    </>
  );
};

export default UserRowMolecule;
