import * as React from "react";
import IOrganism from "./interface";
import {
  Box,
  Grid,
  Card,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import DataBalloonAtom from "@atoms/DataBalloon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import NotesOrganism from "@organisms/Notes";
import HCMActivityForm from "./Form";
import { useConfirm } from "material-ui-confirm";
import HCMActivitiesService from "@services/HCMActivitiesService";
import { HCMActivityModel } from "@models/HCMActivityModel";
import { toast } from "react-toastify";
import { t } from "@lingui/macro";
import { ActivityModel } from "@models/ActivityModel";
import { useAppDispatch } from "@stores/hooks";
import ButtonAtom from "@atoms/Button";
import { updateForm } from "@stores/reducers/saverReducer";

const HCMActivityRow = ({
  hcm,
  activity,
  onDelete,
  onMod,
  hidden,
}: IOrganism) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const [saveFormKey, setSaveFormKey] = React.useState<number>(0);
  const [formIsDirty, setFormIsDirty] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  const handleActivitySave = (activity: ActivityModel) => {
    if (onMod) {
      onMod(activity);
    }
  };

  const handleOnDirty = (formIsDirty: boolean) => {
    setFormIsDirty(formIsDirty);
    // TODO rates salva il singolo
  };

  const handleExpansion = (expanded: boolean) => {
    if (formIsDirty && activity) {
      confirm({
        title: "Attention. Some data has not been saved",
        description: "If you decide to continue, the data will not be saved",
        cancellationText: t`Close without saving`,
        confirmationText: (
          <ButtonAtom
            variant="contained"
            color="success"
            label={t`Save and close`}
          />
        ),
      })
        .then(() => {
          setSaveFormKey(saveFormKey + 1);
        })
        .catch(() => {
          dispatch(
            updateForm({
              slug: `hcm-activity-detail-${activity.id}`,
              saved: true,
            }),
          );
          setIsExpanded(expanded);
        });
    } else {
      setIsExpanded(expanded);
    }
  };

  const deleteActivity = (
    e: React.MouseEvent<HTMLInputElement>,
    activity: HCMActivityModel,
  ) => {
    e.stopPropagation();
    confirm({
      title: "Are you sure you want to delete this element?",
      description: "The action is irreversible!",
      cancellationText: "Go back",
      confirmationText: "Delete",
    }).then(() => {
      HCMActivitiesService.delete(activity.id).then((res: any) => {
        if (!res.hasErrors()) {
          toast(res.getMsgString(), { type: "success" });
          if (onDelete) {
            onDelete();
          }
        } else {
          toast(res.getMsgString(), { type: "error" });
        }
      });
    });
  };

  return (
    <>
      <Box mb={1} sx={{ display: hidden ? "none" : "block" }}>
        <Accordion
          expanded={isExpanded}
          onChange={(event: any, expanded: boolean) =>
            handleExpansion(expanded)
          }
          defaultExpanded={isExpanded ?? false}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid container spacing={1}>
                <Grid item md={1}>
                  {
                    <DataBalloonAtom
                      variant="chip"
                      title={t`Days`}
                      severity={"warning"}
                      data={activity.days}
                    />
                  }
                </Grid>
                <Grid item md={2} className="align-center">
                  {`${activity.month}/${activity.year}`}
                </Grid>
                <Grid item md={3} className="align-center">
                  {hcm.user?.name ?? "--"}
                </Grid>
                <Grid item md={2} className="align-center">
                  {activity.vendor
                    ? activity.vendor.name
                    : hcm.vendor
                    ? hcm.vendor.name
                    : "--"}
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Divider orientation="vertical" />
                <IconButton
                  color="error"
                  className="delete-btn"
                  onClick={(e: any) => deleteActivity(e, activity)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {isExpanded && (
              <Box>
                <Box mt={1} mb={1}>
                  <b>
                    <Typography variant={"h5"}>HCM activity info</Typography>
                  </b>
                </Box>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <HCMActivityForm
                      hcm={hcm}
                      onDirty={handleOnDirty}
                      saveFormKey={saveFormKey}
                      saveOnDestroy={true}
                      activity={activity}
                      onSave={handleActivitySave}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Card sx={{ p: 1 }} className="ov-visible">
                      <NotesOrganism
                        model_id={activity.id}
                        model_type={"hcms_activities"}
                      />
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default HCMActivityRow;
