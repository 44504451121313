import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const StyledNotePreview = styled(Box)((props) => ({
  display: "flex",
  alignItems: "center",
  ".desc-icon": {
    height: "20px",
  },
  svg: {
    fontSize: "16px",
  },
  "&:hover": {
    cursor: "pointer",
  },
}));
