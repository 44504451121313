import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { AnswerModel } from "@models/AnswerModel";

class AnswersService extends CoreBaseService {
  constructor() {
    super(AnswerModel);
  }
}

const inst = new AnswersService();

export default inst;
