import { Component, Fragment } from "react";
import MediaHandler from "./MediaHandler";
import Peer from "simple-peer";
import { StyledScreen, StyledWrapper } from "./styled";
import { Alert, Box, Button } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FaceRetouchingOffIcon from "@mui/icons-material/FaceRetouchingOff";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ringingPhone from "@resources/sounds/ringing_phone.mp3";
import IconButton from "@mui/material/IconButton";
import { ADMIN_ID } from "@utils/constants";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import VideocamIcon from "@mui/icons-material/Videocam";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CameraRearIcon from "@mui/icons-material/CameraRear";
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import { Trans } from "@lingui/macro";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

// TODO SE MOBILE BROWSER NON IN FOCUS LA VIDEOCAMERA NON VIENE ATTIVATA
// TODO SE MOBILE BROWSER NON IN FOCUS LA VIDEOCAMERA NON VIENE ATTIVATA
// https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/idle/onStateChanged
class VideoPeer2Peer extends Component {
  constructor(props) {
    super();

    this.receiverIsOnline = props.receiverIsOnline;
    this.onSharing = props.onSharing;
    this.onHangUp = props.onHangUp;
    this.onAcceptCall = props.onAcceptCall;
    this.device = props.device;
    this.signal = props.signal; // When someone calling us
    this.activity = props.activity;
    this.isFullScreen = props.isFullScreen;
    this.onRequestFullscreen = props.onRequestFullscreen;
    this.iAmInspector = props.iAmInspector;
    this.pusher = props.pusher;
    this.auth = props.auth;
    this.peerRef = null;
    this.presenceChannel = props.presenceChannel;

    this.state = {
      hasMedia: false,
      otherUserId: null,
      callAccepted: false,
      audioStream: true,
      chatting: false,
      videoStream: true,
      facingModeSupport: false,
      isFullscreen: props.isFullScreen ? true : false,
      facingModeSetting: "user",
      ready: this.device.os === "ios" ? true : false,
    };

    this.ringingSound = document.createElement("audio");
    this.ringingSound.volume = 0.2;
    this.ringingSound.pause();
    this.ringingSound.src = ringingPhone;
    // TODO ios can't load audio mp3
    this.ringingSound.oncanplaythrough = () => {
      this.setState({ ready: true });
    };

    this.ringingSoundLoop = null;

    this.videoRefs = {};

    this.user = JSON.parse(localStorage.getItem("user")).user;
    this.user.stream = null;
    this.peers = {};

    this.mediaHandler = new MediaHandler();
    this.setupPusher();

    this.callTo = this.callTo.bind(this);
    this.setupPusher = this.setupPusher.bind(this);
    this.callingSound = this.callingSound.bind(this);
    this.acceptCall = this.acceptCall.bind(this);
    this.toggleVideoStream = this.toggleVideoStream.bind(this);
    this.toggleAudioStream = this.toggleAudioStream.bind(this);
    this.switchFacingmode = this.switchFacingmode.bind(this);
    this.makeFullscreen = this.makeFullscreen.bind(this);
    this.startPeer = this.startPeer.bind(this);
  }

  componentWillMount() {
    var self = this;
    const mediaHandlerActivation = () => {
      this.mediaHandler.getPermissions().then((stream) => {
        this.setState({ hasMedia: true });
        this.user.stream = stream;
        try {
          this.myVideo.srcObject = this.user.stream;
        } catch (e) {
          this.myVideo.src = URL.createObjectURL(this.user.stream);
        }
        this.myVideo.setAttribute("playsinline", "");
        this.myVideo.setAttribute("autoplay", "");
        this.myVideo.play();
      });
    };
    mediaHandlerActivation();
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        mediaHandlerActivation.call(self);
      }
    });
  }

  acceptCall() {
    if (this.onAcceptCall) {
      this.onAcceptCall();
    }
    this.setState({ callAccepted: true, chatting: true, waiting: false });
    let peer = this.peers[this.signal.userId];
    if (peer === undefined) {
      this.setState({ otherUserId: this.signal.userId });
      peer = this.startPeer(this.signal.userId, false, true);
    }
    peer.signal(this.signal.data);
  }

  componentWillUnmount() {
    // Stop sound
    if (this.ringingSoundLoop) {
      clearInterval(this.ringingSoundLoop);
    }
    // Stop stream
    if (this.user.stream) {
      this.user.stream.getTracks().forEach(function (track) {
        track.enabled = false;
        track.stop();
      });
    } else {
      console.log("USER STREAM undefined admin");
    }
  }

  switchFacingmode(to = "environment") {
    let self = this;
    this.user.stream.getTracks().forEach(function (track) {
      if (!["chrome", "safari"].includes(self.device.browser)) {
        track.enabled = false;
      } else {
        track.stop();
      }
    });
    navigator.mediaDevices
      .getUserMedia({
        video: { facingMode: to },
        audio: true,
      })
      .then((stream) => {
        try {
          this.setState({ facingModeSetting: to });
          // Replace peer track audio/video
          this.user.peer.replaceTrack(
            this.user.stream.getVideoTracks()[0],
            stream.getVideoTracks()[0],
            this.user.stream,
          );
          this.user.peer.replaceTrack(
            this.user.stream.getAudioTracks()[0],
            stream.getAudioTracks()[0],
            this.user.stream,
          );
          // Remove track stream audio/video
          this.user.stream.removeTrack(this.user.stream.getVideoTracks()[0]);
          this.user.stream.removeTrack(this.user.stream.getAudioTracks()[0]);
          // Add track stream audio/video
          this.user.stream.addTrack(stream.getVideoTracks()[0]);
          this.user.stream.addTrack(stream.getAudioTracks()[0]);
          // Toggle
          this.toggleAudioStream(this.state.audioStream);
          this.toggleVideoStream(this.state.videoStream);
        } catch (e) {
          alert(e);
        }
      });
  }

  setupPusher() {
    this.presenceChannel.bind(`client-signal-${this.user.id}`, (signal) => {
      if (signal.type !== "message") {
        if (signal.isAnswer) {
          if (this.onSharing) {
            this.onSharing();
          }
          let peer = this.peers[signal.userId];
          // if peer is not already exists, we got an incoming call
          if (peer === undefined || peer.destroyed) {
            this.setState({ otherUserId: signal.userId });
            peer = this.startPeer(signal.userId, false, true);
          }
          this.setState({ chatting: true, waiting: false });
          peer.signal(signal.data);
        }
      }
    });
  }

  startPeer(userId, initiator = true, isAnswer = false) {
    const peer = new Peer({
      initiator,
      stream: this.user.stream,
      trickle: false,
    });

    peer.on("signal", (data) => {
      this.presenceChannel.trigger(`client-signal-${userId}`, {
        type: "signal",
        userId: this.user.id,
        user_info: this.user.name,
        data: data,
        isAnswer,
      });
    });

    peer.on("stream", (stream) => {
      this.callingSound(true);
      try {
        this.partnerVideo.srcObject = stream;
      } catch (e) {
        this.partnerVideo.src = URL.createObjectURL(stream);
      }
      this.partnerVideo.setAttribute("playsinline", "");
      this.partnerVideo.setAttribute("autoplay", "");
      this.partnerVideo.play();
    });

    peer.on("close", () => {
      let peer = this.peers[userId];
      if (peer !== undefined) {
        peer.destroy();
      }

      this.peers[userId] = undefined;
    });

    if (initiator || this.signal) {
      this.user.peer = peer;
    }

    return peer;
  }

  callingSound(stop = false) {
    if (this.signal) {
      return false;
    }
    if (stop && this.ringingSoundLoop) {
      return clearInterval(this.ringingSoundLoop);
    }
    function repeat() {
      this.ringingSound.play();
    }
    this.ringingSoundLoop = setInterval(repeat.bind(this), 3000);
    this.ringingSound.play();
  }

  callTo(userId) {
    this.peers[userId] = this.startPeer(userId);
    this.setState({ waiting: true });
    this.callingSound();
  }

  toggleVideoStream(forceTo = undefined) {
    let self = this;
    this.user.stream.getTracks().forEach(function (track) {
      if (track.readyState === "live" && track.kind === "video") {
        if (forceTo !== undefined) {
          track.enabled = forceTo;
        } else {
          if (self.state.videoStream) {
            track.enabled = false;
            self.setState({ videoStream: false });
          } else {
            track.enabled = true;
            self.setState({ videoStream: true });
          }
        }
      }
    });
  }

  toggleAudioStream(forceTo = undefined) {
    let self = this;
    this.user.stream.getTracks().forEach(function (track) {
      if (track.readyState === "live" && track.kind === "audio") {
        if (forceTo !== undefined) {
          track.enabled = forceTo;
        } else {
          if (self.state.audioStream) {
            track.enabled = false;
            self.setState({ audioStream: false });
          } else {
            track.enabled = true;
            self.setState({ audioStream: true });
          }
        }
      }
    });
  }

  makeFullscreen() {
    this.setState({ isFullscreen: this.state.isFullscreen ? false : true });
    this.props.onRequestFullscreen();
  }

  render() {
    return (
      <div
        ref={(ref) => {
          this.toolWrapper = ref;
        }}
        className={this.state.fullscreen ? "fullscreen" : ""}
      >
        <StyledWrapper>
          {!this.state.callAccepted && this.signal && (
            <Box mb={2} sx={{ textAlign: "center" }}>
              <Button variant="contained" onClick={this.acceptCall}>
                <Trans>Answer to </Trans>&nbsp;
                {this.signal.user_info}
              </Button>
            </Box>
          )}
          <StyledScreen sx={{ height: this.iAmInspector ? "auto" : "auto" }}>
            <div className="partner-wrapper">
              <video
                className="user-video"
                ref={(ref) => {
                  this.partnerVideo = ref;
                }}
              ></video>
            </div>
            <Box
              className={
                this.state.videoStream
                  ? "me-wrapper visible"
                  : "me-wrapper invisible"
              }
              sx={this.iAmInspector ? { maxWidth: "72px !important" } : {}}
            >
              {this.state.hasMedia ? (
                <Box>
                  <video
                    className="my-video"
                    playsInline
                    autoplay
                    muted
                    ref={(ref) => {
                      this.myVideo = ref;
                    }}
                  ></video>
                </Box>
              ) : (
                <FaceRetouchingOffIcon />
              )}
            </Box>
            <Box className="controls">
              {this.state.hasMedia ? (
                <Fragment>
                  <IconButton
                    variant="contained"
                    size="small"
                    onClick={() => this.toggleVideoStream()}
                  >
                    {!this.state.videoStream && (
                      <VideocamOffIcon color="error" />
                    )}
                    {this.state.videoStream && <VideocamIcon color="success" />}
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => this.toggleAudioStream()}
                  >
                    {!this.state.audioStream && <MicOffIcon color="error" />}
                    {this.state.audioStream && <MicIcon color="success" />}
                  </IconButton>
                  {this.state.chatting && this.device.type === "mobile" && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        this.switchFacingmode(
                          this.state.facingModeSetting === "user"
                            ? "environment"
                            : "user",
                        )
                      }
                    >
                      {this.state.facingModeSetting === "user" ? (
                        <CameraRearIcon color="success" />
                      ) : (
                        <CameraFrontIcon color="success" />
                      )}
                    </IconButton>
                  )}
                  {this.onRequestFullscreen &&
                    this.device.type !== "mobile" && (
                      <IconButton size="small" onClick={this.makeFullscreen}>
                        {this.state.isFullscreen ? (
                          <FullscreenExitIcon color="success" />
                        ) : (
                          <FullscreenIcon color="success" />
                        )}
                      </IconButton>
                    )}
                  {!this.state.chatting && this.state.ready && !this.signal && (
                    <IconButton
                      size="small"
                      className="call"
                      disabled={this.state.waiting}
                      onClick={() =>
                        this.callTo(
                          this.iAmInspector
                            ? ADMIN_ID
                            : this.activity.getUser().id,
                        )
                      }
                    >
                      {this.state.waiting ? (
                        <MoreHorizIcon />
                      ) : (
                        <LocalPhoneIcon />
                      )}
                    </IconButton>
                  )}
                  {this.state.chatting && this.state.ready && (
                    <IconButton
                      size="small"
                      className="hang-up"
                      disabled={this.state.waiting}
                      onClick={this.onHangUp}
                    >
                      <LocalPhoneIcon />
                    </IconButton>
                  )}
                </Fragment>
              ) : (
                <Alert
                  severity="warning"
                  sx={{ maxWidth: "50%", margin: "0 auto" }}
                >
                  <Trans>
                    Please make sure you enable all necessary permissions
                  </Trans>
                </Alert>
              )}
            </Box>
          </StyledScreen>
        </StyledWrapper>
      </div>
    );
  }
}

export default VideoPeer2Peer;
