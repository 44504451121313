import { useEffect } from "react";
import { Drawer } from "@mui/material";
import DrawerLayout from "@layouts/drawer";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { resetFormsWarnings, saveAll } from "@stores/reducers/saverReducer";
import ButtonAtom from "@atoms/Button";
import { t } from "@lingui/macro";

const DrawerMolecule = ({ open, children, onClose }: any) => {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const dirtyNotSavedForms = useSelector((state: any) =>
    state.saver.forms.filter((form: any) => form.dirty && !form.saved),
  );
  const forms = useSelector((state: any) => state.saver.forms);
  const autoSaveStarted = useSelector((state: any) => state.saver.saveAll);
  const savedForms = useSelector((state: any) =>
    state.saver.forms.filter((form: any) => form.saved),
  );
  const savingForms = useSelector((state: any) =>
    state.saver.forms.filter((form: any) => form.saving),
  );

  useEffect(() => {
    if (open) {
      dispatch(resetFormsWarnings());
    }
  }, [open]);

  useEffect(() => {
    if (
      savedForms.length &&
      forms.length &&
      autoSaveStarted.status &&
      autoSaveStarted.initiator === "drawer"
    ) {
      if (savedForms.length === forms.length) {
        dispatch(resetFormsWarnings());
        onClose();
      }
    }
  }, [savedForms, onClose]);

  const handleCloseDetailDrawer = () => {
    if (savingForms.length) {
      confirm({
        title: "Wait, some data need to be saved",
        description: "Wait until the data has been saved",
        cancellationText: "",
        confirmationText: "Ok",
      })
        .then(() => {})
        .catch(() => {});
    } else if (dirtyNotSavedForms.length) {
      confirm({
        title: "Attention. Some data has not been saved",
        description: "If you decide to continue, the data will not be saved",
        cancellationText: t`Close without saving`,
        confirmationText: (
          <ButtonAtom
            variant="contained"
            color="success"
            label={t`Save and close`}
          />
        ),
      })
        .then(() => {
          dispatch(saveAll({ status: true, initiator: "drawer" }));
        })
        .catch(() => {
          onClose();
        });
    } else {
      onClose();
    }
  };

  return (
    <Drawer anchor={"right"} open={open} onClose={handleCloseDetailDrawer}>
      <DrawerLayout onCloseClick={handleCloseDetailDrawer}>
        {children}
      </DrawerLayout>
    </Drawer>
  );
};

export default DrawerMolecule;
