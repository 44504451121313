import { memo, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextFieldAtom from "@atoms/TextField";
import SearchIcon from "@mui/icons-material/Search";
import type { IUserFilterProps } from "./interface";
import type { IUserFilterOrganismProps } from "./interface";

const ProviderFilterOrganism = ({
  filter,
  setFilter,
}: IUserFilterOrganismProps) => {
  const formMethods = useForm<IUserFilterProps>({
    mode: "onChange",
    defaultValues: filter,
    reValidateMode: "onSubmit",
  });

  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(filter);
  }, [filter, reset]);

  const handleFormSubmit = (data: IUserFilterProps) => {
    setFilter(data);
  };

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <FormProvider {...formMethods}>
        <Stack>
          <TextFieldAtom
            controlName="search"
            label={
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <SearchIcon></SearchIcon> Search
              </Box>
            }
            maxRows={1}
          />
        </Stack>
      </FormProvider>
    </form>
  );
};

export default memo(ProviderFilterOrganism);
