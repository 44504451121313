import { useState, useEffect } from "react";
import IOrganism, { IFormValues, formSchema } from "./interface";
import { Grid, Box, Typography } from "@mui/material";
import { Trans } from "@lingui/macro";
import { FormProvider, useForm } from "react-hook-form";
import ButtonAtom from "@atoms/Button";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import RadioGroupMoleculeA from "@molecules/RadioGroup";
import { IType } from "@interfaces/IType";
import HCMActivitiesService from "@services/HCMActivitiesService";
import RatesService from "@services/RatesService";
import { RateModel } from "@models/RateModel";
import DateFieldMolecule from "@molecules/DateField";
import moment from "moment";
import DataBalloonAtom from "@atoms/DataBalloon";
import HCMRatesForm from "../Rates/Form";
import { useAppDispatch } from "@stores/hooks";
import { addForm, updateForm } from "@stores/reducers/saverReducer";

const HCMActivityForm = ({
  activity,
  onSave,
  saveFormKey,
  onDirty,
  hcm,
  isEdit,
  onCancel,
  saveOnDestroy,
}: IOrganism) => {
  const [sErrors, setSErrors] = useState<any>({});
  const [ratesClientValid, setRatesClientValid] = useState<boolean>(false);
  const [ratesSupplierValid, setRatesSupplierValid] = useState<boolean>(false);
  const [clientRateData, setClientRateData] = useState<any>({});
  const [supplierRateData, setSupplierRateData] = useState<any>({});
  const [supplierErrors, setSupplierErrors] = useState<any>({});
  const [clientErrors, setClientErrors] = useState<any>({});
  const [formClientRateData, setFormClientRateData] = useState<any>({});
  const [formSupplierRateData, setFormSupplierRateData] = useState<any>({});
  const [clientDirty, setClientDirty] = useState<boolean>(false);
  const [supplierDirty, setSupplierDirty] = useState<boolean>(false);
  const ratesTypesList = useSelector(
    (state: any) => state.filters.filters.types?.rates,
  );
  const dispatch = useAppDispatch();
  const autoSave = useSelector((state: any) => state.saver.saveAll);

  const formInstance = useForm<IFormValues>({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(formSchema),
  });

  const {
    formState: { isValid, isDirty },
    watch,
    reset,
    getValues,
    handleSubmit: handleHookSubmit,
  } = formInstance;

  useEffect(() => {
    if ((autoSave.status && isDirty) || clientDirty || supplierDirty) {
      handleSubmit(getValues());
    }
  }, [autoSave, getValues]);

  useEffect(() => {
    if (saveFormKey && (isDirty || clientDirty || supplierDirty)) {
      handleSubmit(getValues());
    }
  }, [saveFormKey]);

  useEffect(() => {
    if (isDirty || clientDirty || supplierDirty) {
      dispatch(
        addForm({
          slug: `hcm-detail-activity-form-${activity.id}`,
          formState: formInstance.formState,
        }),
      );
      if (onDirty) {
        onDirty(true);
      }
    } else {
      dispatch(
        updateForm({
          slug: `hcm-detail-activity-form-${activity.id}`,
          dirty: false,
          saved: true,
        }),
      );
      if (onDirty) {
        onDirty(false);
      }
    }
  }, [formInstance, isDirty]);

  useEffect(() => {
    if (activity) {
      reset({
        vendor_id: activity && activity.vendor ? activity.vendor.id : null,
        month_year: activity ? `${activity.year}-${activity.month}-01` : "",
        type_id: ratesTypesList[0] ? ratesTypesList[0].id : undefined,
      });
    }
  }, [activity, reset, ratesTypesList]);

  const type = Number(watch("type_id"));

  const { mutate: activityMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      (activity
        ? HCMActivitiesService.put(formValues, activity.id)
        : HCMActivitiesService.create(formValues)
      ).then((res: any) => {
        if (!res.hasErrors()) {
          toast(res.getMsgString(), {
            type: "success",
          });
          if (onSave) {
            onSave(res.getData());
          }
          setTimeout(() => {
            dispatch(
              updateForm({
                slug: `hcm-detail-activity-form-${activity.id}`,
                dirty: false,
                saved: true,
              }),
            );
          }, 500);
        } else {
          setSErrors(res.getErrors());
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      }),
  );

  const { isLoading: loadingClientRate, data: rateData } = useQuery(
    `activity-rates${activity.id}`,
    () =>
      RatesService.get("", {
        model_id: activity.id,
        model_type: activity.model_type,
      }).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!loadingClientRate && rateData) {
      const clientRate = rateData.data.find(
        (record: RateModel) => record.type.alias === "client",
      );
      const supplierRate = rateData.data.find(
        (record: RateModel) => record.type.alias === "supplier",
      );
      setClientRateData(clientRate ?? {});
      setSupplierRateData(supplierRate ?? {});
    }
  }, [loadingClientRate, rateData]);

  const handleSubmit = (data: IFormValues) => {
    // Update activity
    setSErrors({});
    const finalData: any = { ...data };
    if (data.month_year) {
      const convDate = moment(data.month_year).format("YYYY-MM").split("-");
      finalData.month = Number(convDate[1]);
      finalData.year = convDate[0];
    }
    activityMutation(finalData);

    if (Number(type) === ratesTypesList[0].id) {
      if (formClientRateData.id) {
        RatesService.put(formClientRateData, formClientRateData.id).then(
          (res: any) => {
            if (!res.hasErrors()) {
              setClientRateData(res.getData());
            } else {
              setClientErrors(res.getErrors());
              toast(res.getMsgString(), {
                type: "error",
              });
            }
          },
        );
      } else {
        RatesService.create(formClientRateData).then((res: any) => {
          if (!res.hasErrors()) {
            setClientRateData(res.getData());
          } else {
            setClientErrors(res.getErrors());
            toast(res.getMsgString(), {
              type: "error",
            });
          }
        });
      }
    }

    if (Number(type) === ratesTypesList[1].id) {
      if (formSupplierRateData.id) {
        RatesService.put(formSupplierRateData, formSupplierRateData.id).then(
          (res: any) => {
            if (!res.hasErrors()) {
              setSupplierRateData(res.getData());
            } else {
              setSupplierErrors(res.getErrors());
              toast(res.getMsgString(), {
                type: "error",
              });
            }
          },
        );
      } else {
        RatesService.create(formSupplierRateData).then((res: any) => {
          if (!res.hasErrors()) {
            setSupplierRateData(res.getData());
          } else {
            setSupplierErrors(res.getErrors());
            toast(res.getMsgString(), {
              type: "error",
            });
          }
        });
      }
    }
  };

  const clientChanged = (data: any) => {
    const rData: any = {};
    rData.model_type = activity.model_type;
    rData.model_id = activity.id;
    rData.id = data.id;
    rData.info = JSON.stringify({
      days: data.days,
      rate: data.rate,
      allowance: data.allowance,
      allowance_quantity: data.allowance_quantity,
      extra: data.extra,
    });
    rData.category_id = data.category_id;
    rData.type_id = data.type_id;
    setFormClientRateData(rData);
  };

  const supplierChanged = (data: any) => {
    const rData: any = {};
    rData.model_type = activity.model_type;
    rData.model_id = activity.id;
    rData.id = data.id;
    rData.info = JSON.stringify({
      allowance: data.allowance,
      allowance_quantity: data.allowance_quantity,
      extra: data.extra,
    });
    rData.category_id = data.category_id;
    rData.type_id = data.type_id;
    setFormSupplierRateData(rData);
  };

  const handleOnDirtyChangeClient = (isDirty: boolean) => {
    setClientDirty(isDirty);
  };

  const handleOnDirtyChangeSupplier = (isDirty: boolean) => {
    setSupplierDirty(isDirty);
  };

  useEffect(() => {
    if (supplierDirty || clientDirty) {
      dispatch(
        addForm({
          slug: `hcm-detail-activity-form-${activity.id}`,
          formState: formInstance.formState,
        }),
      );
      if (onDirty) {
        onDirty(true);
      }
    } else {
      if (onDirty) {
        onDirty(false);
      }
    }
  }, [supplierDirty, clientDirty]);

  return (
    <>
      <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
        <FormProvider {...formInstance}>
          <Box>
            <Grid container spacing={1}>
              <Grid item md={12}>
                <Typography>
                  Vendor: <b>{hcm?.vendor?.name}</b>
                </Typography>
                {/*<AutocompleteMolecule
                                    control={formInstance.control}
                                    controlName="vendor_id"
                                    sError={sErrors ? sErrors['client_id'] : undefined}
                                    listId={'insp-edit-clients'}
                                    storeCollection="vendors"
                                    variant={'outlined'}
                                    optionValue={'id'}
                                    emptyValue={{name: ''}}
                                    getOptionLabel={(item:any) => `${item.name}`}
                                    optionLabel={'name'}
                                    label={<Fragment><Trans>Vendor</Trans></Fragment>}
                                ></AutocompleteMolecule>*/}
              </Grid>
            </Grid>
            <Box mt={3}>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <DateFieldMolecule
                    views={["year", "month"]}
                    sError={
                      sErrors
                        ? sErrors["month"]
                          ? sErrors["month"]
                          : sErrors["year"]
                          ? sErrors["year"]
                          : undefined
                        : undefined
                    }
                    control={formInstance.control}
                    controlName={"month_year"}
                    inputFormat={"MMMM yyyy"}
                    label={<Trans>Month and year</Trans>}
                  ></DateFieldMolecule>
                </Grid>
              </Grid>
            </Box>
            <Box mt={3}>
              <Grid item md={6} xs={12}>
                <RadioGroupMoleculeA
                  control={formInstance.control}
                  controlName="type_id"
                  radios={ratesTypesList.map((type: IType) => {
                    return {
                      name: (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          {type.name}&nbsp;
                          {(Object.keys(clientErrors).length &&
                            type === ratesTypesList[0].id) ||
                          (Object.keys(supplierErrors).length &&
                            type === ratesTypesList[1].id) ? (
                            <DataBalloonAtom
                              size={"small"}
                              severity="error"
                              data={
                                type.name.toLowerCase() === "client" &&
                                Object.keys(clientErrors).length
                                  ? Object.keys(clientErrors).length
                                  : type.name.toLowerCase() === "supplier" &&
                                    Object.keys(supplierErrors).length
                                  ? Object.keys(supplierErrors).length
                                  : ""
                              }
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      ),
                      id: type.id,
                    };
                  })}
                />
              </Grid>
            </Box>
          </Box>
        </FormProvider>
      </form>
      {!loadingClientRate && ratesTypesList && (
        <Box mt={2}>
          {
            <Box
              sx={{
                display: type === ratesTypesList[0].id ? "block" : "none",
              }}
            >
              <HCMRatesForm
                onDirtyChange={handleOnDirtyChangeClient}
                record={clientRateData}
                onChange={clientChanged}
                Serrors={clientErrors}
                model={activity}
                setValidity={setRatesClientValid}
                typeId={ratesTypesList[0].id}
              />
            </Box>
          }
          {
            <Box
              sx={{
                display: type === ratesTypesList[1].id ? "block" : "none",
              }}
            >
              <HCMRatesForm
                onDirtyChange={handleOnDirtyChangeSupplier}
                Serrors={supplierErrors}
                record={supplierRateData}
                onChange={supplierChanged}
                model={activity}
                setValidity={setRatesSupplierValid}
                typeId={ratesTypesList[1].id}
              />
            </Box>
          }
        </Box>
      )}
      {
        <Box mt={1} className="text-right">
          <ButtonAtom
            loading={isLoading}
            label={!activity ? <Trans>Create</Trans> : <Trans>Save</Trans>}
            disabled={
              !isValid ||
              (!ratesClientValid && type === ratesTypesList[0].id) ||
              (!ratesSupplierValid && type === ratesTypesList[1].id)
            }
            onClick={() => (isLoading ? null : handleSubmit(getValues()))}
          />
        </Box>
      }
    </>
  );
};

export default HCMActivityForm;
