import { useState, ChangeEvent, memo, useCallback } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import type { INote } from "@interfaces/INote";
import type { INotesOrganismProps } from "./interfaces";
import { t, Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import NoteRowItem from "@organisms/NoteRowItem";
import NotesService from "@services/NotesService";
import { Wrapper, GroupActionButton } from "./styled";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Alert, FormControlLabel } from "@mui/material";
import SpinnerAtom from "@atoms/Spinner";
import ArticleIcon from "@mui/icons-material/Article";
import { useConfirm } from "material-ui-confirm";
import { useUser } from "@hooks";
import ButtonAtom from "@atoms/Button";

const NotesOrganism = ({
  model_id,
  model_type,
  hide_crud,
}: INotesOrganismProps) => {
  const user = useUser();

  const { data, refetch, isLoading } = useQuery<unknown, unknown, INote[]>(
    `activity-notes${model_id}`,
    () =>
      NotesService.get("", { model_id, model_type }).then(
        (res: any) => res.data,
      ),
    { refetchOnWindowFocus: false, cacheTime: 0, refetchOnMount: true },
  );

  const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
  const [newNote, setNewNote] = useState<string>("");
  const [newNoteIsDescription, setNewNoteIsDescription] =
    useState<boolean>(true);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const items = data || [];
  const rowsCount = items.length;
  const selectedItemsLength = selectedItems.length;
  const hasSelectedItems = !!selectedItems.length;
  const confirm = useConfirm();

  const handleDelete = useCallback(
    (item: INote) => {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        NotesService.delete(item.id)
          .then((res: any) => {
            toast(res.getMsgString(), {
              type: res.hasErrors() ? "error" : "success",
            });
            refetch();
          })
          .catch((res: any) => {
            toast(t`Generic error`, { type: "error" });
          });
      });
    },
    [refetch],
  );

  const addNote = () => {
    NotesService.create({
      text: newNote,
      model_id,
      model_type,
      visible: newNoteIsDescription,
    }).then((res: any) => {
      refetch();
      setIsCreateMode(false);
      toast(res.getMsgString(), {
        type: res.hasErrors() ? "error" : "success",
      });
    });
  };

  const handleMultipleDelete = () => {
    confirm({
      title: "Are you sure you want to delete this element?",
      description: "The action is irreversible!",
      cancellationText: "Go back",
      confirmationText: "Delete",
    }).then(() => {
      if (selectedItemsLength === 0) {
        return;
      }

      if (selectedItemsLength === 1) {
        handleDelete(items.find(({ id }) => id === selectedItems[0])!);
      }

      Promise.all(selectedItems.map((id) => NotesService.delete(id)))
        .then(() => {
          toast(t`Notes deleted successfully`, { type: "success" });
          setSelectedItems([]);
        })
        .catch(() => {
          toast(t`Generic error`, { type: "error" });
        })
        .finally(() => {
          refetch();
        });
    });
  };

  const toggleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedItems(items.map(({ id }) => id));
    } else {
      setSelectedItems([]);
    }
  };

  const toggleSelectSingleItem = useCallback(
    (event: ChangeEvent<HTMLInputElement>, id: number) => {
      if (event.target.checked) {
        setSelectedItems((prevState) => [...prevState, id]);
      } else {
        setSelectedItems((prevState) =>
          prevState.filter((selectedId) => selectedId !== id),
        );
      }
    },
    [],
  );

  const handleOpenNewNote = () => {
    setIsCreateMode(true);
    setSelectedItems([]);
  };

  return (
    <Box sx={{ flexGrow: 1, maxWidth: { /*md: 752,*/ xs: "100%" } }}>
      <Box sx={{ display: "grid", gridTemplateColumns: "100%" }}>
        {isCreateMode ? (
          <Box p={1}>
            <TextareaAutosize
              maxRows={6}
              onChange={(e) => setNewNote(e.target.value)}
              minRows={3}
              placeholder="Insert note"
              style={{ width: "100%", padding: "6px" }}
            />
            <Box sx={{ textAlign: "right" }}>
              <FormControlLabel
                label="Description"
                control={
                  <Checkbox
                    defaultChecked={true}
                    inputProps={{
                      "aria-label": t`Description`,
                    }}
                    onChange={(event: any, b: boolean) =>
                      setNewNoteIsDescription(b)
                    }
                  />
                }
              />
              <ButtonAtom
                sx={{ marginRight: "5px" }}
                onClick={() => setIsCreateMode(false)}
                variant="contained"
                label={<Trans>CANCEL</Trans>}
                color="error"
              />
              {newNote ? (
                <ButtonAtom
                  onClick={addNote}
                  variant="contained"
                  label={<Trans>ADD</Trans>}
                />
              ) : null}
            </Box>
          </Box>
        ) : (
          <Wrapper>
            <Stack direction="row" spacing={2} sx={{ marginTop: "10px" }}>
              {user.can("notes.create") && !hide_crud ? (
                <GroupActionButton
                  sx={{ margin: "0 auto", maxWidth: "50%" }}
                  color="primary"
                  onClick={handleOpenNewNote}
                >
                  <ArticleIcon></ArticleIcon>&nbsp;
                  <Trans>ADD NOTE</Trans>
                </GroupActionButton>
              ) : null}
              {hasSelectedItems && user.can("notes.delete") && !hide_crud ? (
                <GroupActionButton
                  isVisible={hasSelectedItems}
                  color="error"
                  onClick={handleMultipleDelete}
                >
                  <Trans>Delete</Trans>
                </GroupActionButton>
              ) : null}
            </Stack>
            {isLoading && (
              <Box mt={1}>
                <SpinnerAtom></SpinnerAtom>
              </Box>
            )}
            {!isLoading && !items.length ? (
              <Alert sx={{ mt: 2 }} severity="info">
                <Trans>There are no notes</Trans>
              </Alert>
            ) : null}
            {items && items.length ? (
              <TableContainer
                sx={{
                  maxHeight: 440,
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                <Table stickyHeader size="small">
                  <TableHead>
                    {(user.can("notes.delete") ||
                      user.can("notes.edit") ||
                      user.can("notes.delete")) &&
                      !hide_crud && (
                        <TableRow>
                          {user.can("notes.delete") && !hide_crud && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                indeterminate={
                                  selectedItemsLength > 0 &&
                                  selectedItemsLength < rowsCount
                                }
                                checked={
                                  rowsCount > 0 &&
                                  selectedItemsLength === rowsCount
                                }
                                inputProps={{
                                  "aria-label": t`select all`,
                                }}
                                onChange={toggleSelectAll}
                              />
                            </TableCell>
                          )}
                          <TableCell align="center">
                            {/*<Trans>Note</Trans>*/}
                          </TableCell>
                          <TableCell align="center">
                            {(user.can("notes.edit") ||
                              user.can("notes.delete")) &&
                              !hide_crud && <Trans>Actions</Trans>}
                          </TableCell>
                          {/*
													user.can('notes.edit') || user.can('notes.delete') ?
														<TableCell align="center">
														<Trans>Actions</Trans> 
														</TableCell>
														: <></>
												*/}
                        </TableRow>
                      )}
                  </TableHead>
                  <TableBody sx={{ width: "inherit" }}>
                    {items.map((item) => (
                      <NoteRowItem
                        hide_crud={hide_crud}
                        onNoteModified={refetch}
                        key={item.id}
                        isSelected={selectedItems.some((id) => id === item.id)}
                        note={item}
                        toggleSelect={toggleSelectSingleItem}
                        handleDelete={handleDelete}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
          </Wrapper>
        )}
      </Box>
    </Box>
  );
};

export default memo(NotesOrganism);
