import { styled } from "@mui/material/styles";
import { yellow, red, grey } from "@mui/material/colors";

interface IProps {
  size?: "small" | undefined;
  severity: string;
}

interface IPropsChip {}

export const StyledBalloon = styled("div")((props: IProps) => ({
  background:
    props.severity === "info" || !props.severity
      ? "#1976d2"
      : props.severity === "warning"
      ? yellow[400]
      : props.severity === "error"
      ? red[600]
      : "#1976d2",
  borderRadius: "50%",
  marginRight: "25px",
  color:
    props.severity === "info" || !props.severity
      ? "#FFFFFF"
      : props.severity === "warning"
      ? grey[900]
      : props.severity === "error"
      ? "#FFFFFF"
      : "#FFFFFF",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  fontSize: props.size !== "small" ? "16px" : "10px",
  width: props.size !== "small" ? "50px" : "16px",
  height: props.size !== "small" ? "50px" : "16px",
}));

export const StyledChip = styled("div")((props: IPropsChip) => ({
  maxWidth: "100%",
  // fontFamily: 'Roboto sans-serif',
  fontSize: "0.8125rem",
  display: "-webkit-inline-box",
  //display: '-webkit-inline-flex',
  //display: '-ms-inline-flexbox',
  //display: 'inline-flex',
  "-webkit-align-items": "center",
  "-webkit-box-align": "center",
  "-ms-flex-align": "center",
  "align-items": "center",
  "-webkit-box-pack": "center",
  "-ms-flex-pack": "center",
  "-webkit-justify-content": "center",
  "justify-content": "center",
  height: "32px",
  width: "32px",
  color: "rgba(0, 0, 0, 0.87)",
  // backgroundColor: 'rgba(0, 0, 0, 0.08)',
  borderRadius: "16px",
  whiteSpace: "nowrap",
  "-webkit-transition":
    "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  transition:
    "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  cursor: "default",
  outline: 0,
  "-webkit-text-decoration": "none",
  textDecoration: "none",
  // border: 0,
  padding: 0,
  verticalAlign: "middle",
  boxSizing: "border-box",
  backgroundColor: "transparent",
  border: "1px solid #bdbdbd",
  marginRight: "20px",
  borderColor: "#2059e8",
}));
