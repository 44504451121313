import { t } from "@lingui/macro";
import { ActivityModel } from "@models/ActivityModel";
import { HCMActivityModel } from "@models/HCMActivityModel";
import { InspectionModel } from "@models/InspectionModel";
import * as Yup from "yup";

export default interface IComponentProps {
  model: InspectionModel | ActivityModel | HCMActivityModel | undefined;
  typeId: number;
  record?: any;
  setValidity?: (validity: boolean) => void;
  onChange?: (param: any) => void;
  onDirtyChange?: (param: boolean) => void;
  Serrors?: any;
}

export const schema = Yup.object().shape({
  category_id: Yup.number().min(1).required(),
  extra: Yup.number()
    .min(0)
    .typeError(t`Value must be a number`),
  days: Yup.number()
    .min(0)
    .typeError(t`Value must be a number`),
  rate: Yup.number()
    .min(0)
    .typeError(t`Value must be a number`),
  allowance: Yup.number()
    .min(0)
    .typeError(t`Value must be a number`),
  allowance_quantity: Yup.number()
    .min(0)
    .typeError(t`Value must be a number`),
});
