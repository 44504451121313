import { memo, useState } from "react";
import type { INoteRowItemProps } from "./interface";
import { t } from "@lingui/macro";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LightTooltip from "@atoms/LightTooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import Popover from "@mui/material/Popover";
import NoteDetailOrganism from "@organisms/NoteDetail";
import EditIcon from "@mui/icons-material/Edit";
import { StyledNotePreview } from "./styled";
import { useUser } from "@hooks";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const NoteRownote = ({
  isSelected,
  note,
  toggleSelect,
  handleDelete,
  onNoteModified,
  hide_crud,
}: INoteRowItemProps) => {
  const user = useUser();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleEditClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const noteModified = () => {
    setAnchorEl(null);
    if (onNoteModified) {
      onNoteModified();
    }
  };

  const open = Boolean(anchorEl);

  return (
    <TableRow selected={isSelected} hover key={note.id}>
      {user.can("notess.delete") && (
        <TableCell padding="checkbox">
          <Checkbox
            checked={isSelected}
            onChange={(e) => toggleSelect(e, note.id)}
          />
        </TableCell>
      )}
      <TableCell sx={{ width: "100%" }}>
        <Popover
          id={String(note.id)}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box p={2}>
            <NoteDetailOrganism
              hide_crud={hide_crud}
              note={note}
              onSave={noteModified}
            ></NoteDetailOrganism>
          </Box>
        </Popover>
        <StyledNotePreview
          aria-describedby={String(note.id)}
          onClick={handleEditClick}
          sx={{
            maxWidth: { md: "230px", sm: "100px" },
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {note.visible && (
            <Tooltip title={t`Description`}>
              <div className="desc-icon">
                <InsertDriveFileIcon fontSize="small" color="info" />
              </div>
            </Tooltip>
          )}
          <Tooltip title={note.text}>
            <Box>{note.text.substring(0, 100)}</Box>
          </Tooltip>
        </StyledNotePreview>
      </TableCell>
      <TableCell></TableCell>
      {(user.can("notes.edit") || user.can("notes.delete")) && !hide_crud ? (
        <TableCell
          sx={{
            pr: 1,
            width: "104px",
            display: "flex",
            minHeight: "40px",
          }}
          size="small"
        >
          {user.can("notes.edit") && (
            <LightTooltip title={t`edit`} followCursor>
              <IconButton aria-label={t`edit`} onClick={handleEditClick}>
                <EditIcon />
              </IconButton>
            </LightTooltip>
          )}
          {user.can("notes.delete") && (
            <LightTooltip title={t`Delete`} followCursor>
              <IconButton
                color="error"
                className="delete-btn"
                aria-label={t`delete`}
                onClick={() => handleDelete(note)}
              >
                <DeleteIcon />
              </IconButton>
            </LightTooltip>
          )}
        </TableCell>
      ) : null}
    </TableRow>
  );
};

export default memo(NoteRownote);
