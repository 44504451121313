import { Box } from "@mui/material";
import IPeopleQualificationsOrganism from "./interface";
import AttributesOrganism from "@organisms/AttributesOrganism/index-new";

const PeopleDetailQualificationsOrganism = ({
  person,
}: IPeopleQualificationsOrganism) => {
  return (
    <>
      {person ? (
        <Box mt={3} mb={1}>
          <AttributesOrganism
            model_type="users"
            model={person}
          ></AttributesOrganism>
        </Box>
      ) : null}
    </>
  );
};

export default PeopleDetailQualificationsOrganism;
