import { useState } from "react";
import { Box } from "@mui/material";
import IAddress from "./interface";
import { GOOGLE_MAPS_API_KEY } from "@helpers/constants";

const AddressMolecule = ({ address }: IAddress) => {
  const [showMap /*, setShowMap*/] = useState<boolean>(true);

  return (
    <>
      {address ? (
        <Box>
          <a href={`https://www.google.com/maps/place?q=${address}`}>
            {address}↪
          </a>
          {showMap ? (
            <Box mt={1}>
              <iframe
                title="map-iframe"
                width="100%"
                height="250"
                frameBorder="0"
                src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=${address}`}
              ></iframe>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <>--</>
      )}
    </>
  );
};

export default AddressMolecule;
