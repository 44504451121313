import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { t } from "@lingui/macro";
import MIconAtom from "@atoms/MIcon";
import { startChat } from "@stores/reducers/chatReducer";
import { useAppDispatch } from "@stores/hooks";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Link } from "react-router-dom";

const NotificationGeneric = ({ notification, onClick }: any) => {
  const dispatch = useAppDispatch();

  const [ notificationText ] = useState(notification.text.replace(/(<([^>]+)>)/gi, ""));

  const openChat = () => {
    dispatch(
      startChat({
        activity_id: notification.notification.model.activity_id,
      }),
    );
    if (onClick) {
      onClick();
    }
  };

  return (
    <React.Fragment>
      <Link to={'/notifications'}>
        <Box
          onClick={openChat}
          sx={{ display: "flex", alignItems: "center", gap: "5px", maxWidth: '100%', overflow: 'hidden'}}
        >
          <Box>
            <MIconAtom
              tooltip={t`Chat message`}
              icon={<NotificationsNoneIcon sx={{ fontSize: "12px" }} />}
            />
          </Box>
          <Box>
            <Typography fontSize={"12px"}>
              {notification.notification.user.full_name}
            </Typography>
            <Box>
              <Typography
                className="truncate"
                variant="caption"
                sx={{ color: "#616161", fontWeight: "bold" }}
              >
                "{notification.notification.type.name}"
              </Typography>
            </Box>
          </Box>
        </Box>
      </Link>
    </React.Fragment>
  );
};

export default NotificationGeneric;
