import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { PeopleModel } from "@models/PeopleModel";

class PeopleService extends CoreBaseService {
  constructor() {
    super(PeopleModel);
  }

  getTotals() {
    return this.sub("totals").get();
  }

  sendPassword(userId: number) {
    return this.customCall(`send-password/${userId}`).create(null);
  }
}

const inst = new PeopleService();

export default inst;
