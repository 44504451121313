import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const StyledScreen = styled(Box)(() => ({
  padding: "15px",
  background: "transparent",
  overflow: "auto",
  height: "500px",
  backgroundColor: "#ffffff",
  opacity: 0.8,
  flex: 1,
  gap: "20px",
  backgroundImage:
    "repeating-radial-gradient( circle at 0 0, transparent 0, #ffffff 10px ), repeating-linear-gradient( #e8e8e855, #e8e8e8 )",
}));

export const StyledChatForm = styled(Box)(() => ({
  minHeight: "60px",
  marginTop: "20px",
}));

export const StyledChatContainer = styled(Box)(() => ({
  display: "flex",
  flexFlow: "column",
  height: "85vh",
  width: "100%",
}));
