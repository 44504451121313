import axios, { AxiosError, AxiosResponse } from "axios";
import { LOCAL_STORAGE_KEYS } from "@utils/constants";

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const retry = (lastCall: any) => {
  if (lastCall.data) {
    lastCall.data = JSON.parse(lastCall.data);
  }
  console.log(lastCall);
  return axios.request({
    ...lastCall,
    headers: {
      Authorization: axios.defaults.headers.common["Authorization"],
    },
  });
};

const onResponseError = async (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 401) {
      try {
        // Remove user auth from localStorage
        localStorage.removeItem(LOCAL_STORAGE_KEYS.USER);
        // Force redirect
        window.location.href = "/login";
        return Promise.reject();
      } catch (_error) {
        return Promise.reject(_error);
      }
    } else if (error.response.status === 402) {
      // Refresh token
      return axios
        .post(`${process.env.REACT_APP_PUBLIC_API_ENDPOINT}auth/refresh-token`)
        .then((response) => {
          if (response.hasOwnProperty("data")) {
            const token = response.data.data.token;
            const user = JSON.parse(
              localStorage.getItem(LOCAL_STORAGE_KEYS.USER) as string,
            );
            if (user) {
              user.token = token;
              localStorage.setItem(
                LOCAL_STORAGE_KEYS.USER,
                JSON.stringify(user),
              );
            }
            axios.defaults.headers.common[
              process.env.REACT_APP_TOKEN_HEADER_NAME
                ? process.env.REACT_APP_TOKEN_HEADER_NAME
                : "token"
            ] = `${process.env.REACT_APP_TOKEN_PREFIX} ${token as string}`;
            return retry(error.config);
          }
        });
    }
    return Promise.reject(error);
    /*
    if (
      error.response.status === 402 &&
    ) {
      const storedToken = JSON.parse(localStorage.getItem("token"));

      try {
        const rs = await axios.post(`${API_URL}/auth/refresh`, {
          refresh_token: storedToken.refresh_token,
        });

        const { token, user } = rs.data;

        localStorage.setItem("token", JSON.stringify(token));
        localStorage.setItem("user", JSON.stringify(user));

        return;
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    */
  }
  return Promise.reject(error);
};

export const setupInterceptors = () => {
  axios.interceptors.response.use(onResponse, onResponseError);
};
