import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { StyledBody } from "./styled";

interface ILayoutModal {
  title: string | JSX.Element;
  children: any;
}

const LayoutModal = ({ children, title }: ILayoutModal) => {
  return (
    <>
      <StyledBody className="modal-layout-wrapper">
        <Typography variant={"h2"}>{title}</Typography>
        <Paper elevation={0}>{children}</Paper>
      </StyledBody>
    </>
  );
};

export default LayoutModal;
