import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { UseFormReturn, FieldValues, Controller } from "react-hook-form";
import ConnectForm from "@helpers/ConnectForm";
import { GOOGLE_MAPS_API_KEY } from "@helpers/constants";

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

export default function GoogleAutocomplete({
  control,
  controlName,
  label,
  onlyCity,
}: any) {
  const [value, setValue] = React.useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places&language=en`,
        document.querySelector("head"),
        "google-maps",
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string; types: Array<any> },
          callback: (results?: readonly PlaceType[]) => void,
        ) => {
          (autocompleteService.current as any).getPlacePredictions(
            request,
            callback,
          );
        },
        400,
      ),
    [],
  );

  React.useEffect(() => {
    let active = true;
    let types: any = [];
    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (onlyCity) {
      types = ["(cities)"];
    }

    // @ts-ignore
    fetch(
      { input: inputValue, types /*, language: "en"*/ },
      (results?: readonly PlaceType[]) => {
        if (active) {
          let newOptions: readonly PlaceType[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <ConnectForm>
      {({ setValue: setValueF }: UseFormReturn<FieldValues, any>) => (
        <Controller
          render={({ field: { onChange, onBlur, value, ref, ...props } }) => (
            <React.Fragment>
              <Autocomplete
                sx={{ width: "100%" }}
                getOptionLabel={(option) =>
                  typeof option === "string" ? option : option.description
                }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText="No locations"
                onChange={(event: any, newValue: PlaceType | null) => {
                  setOptions(newValue ? [newValue, ...options] : options);
                  setValue(newValue);
                  setValueF(
                    controlName,
                    newValue
                      ? onlyCity
                        ? newValue.structured_formatting.main_text
                        : newValue.description
                      : "",
                    {
                      shouldValidate: true,
                      shouldDirty: true,
                    },
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label={label} fullWidth />
                )}
                renderOption={(props, option) => {
                  const matches =
                    option.structured_formatting.main_text_matched_substrings ||
                    [];

                  const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [
                      match.offset,
                      match.offset + match.length,
                    ]),
                  );

                  return (
                    <li {...props}>
                      <Grid container alignItems="center">
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            width: 44,
                          }}
                        >
                          <LocationOnIcon
                            sx={{
                              color: "text.secondary",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          sx={{
                            width: "calc(100% - 44px)",
                            wordWrap: "break-word",
                          }}
                        >
                          {parts.map((part: any, index: number) => (
                            <Box
                              key={index}
                              component="span"
                              sx={{
                                fontWeight: part.highlight ? "bold" : "regular",
                              }}
                            >
                              {part.text}
                            </Box>
                          ))}
                          <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  );
                }}
              />
            </React.Fragment>
          )}
          name={controlName}
          control={control}
        />
      )}
    </ConnectForm>
  );
}
