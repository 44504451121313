import { ActivityModel } from "@models/ActivityModel";
import { HCMActivityModel } from "@models/HCMActivityModel";
import { InspectionModel } from "@models/InspectionModel";
import * as Yup from "yup";
import { t } from "@lingui/macro";

export default interface IComponentProps {
  model: InspectionModel | ActivityModel | HCMActivityModel | undefined;
  typeId: number;
  record?: any;
  onChange?: (param: any) => void;
  onIsZero?: (param: boolean) => void;
  onDirtyChange?: (param: boolean) => void;
  errors?: any;
}

export const YupSchema = Yup.object().shape({
  value: Yup.number()
    .required(t`Necessaria`)
    .min(0)
    .typeError(t`Value must be a number`),
  extra: Yup.number()
    .required(t`Necessaria`)
    .min(0)
    .typeError(t`Value must be a number`),
  allowance: Yup.number()
    .required(t`Necessaria`)
    .min(0)
    .typeError(t`Value must be a number`),
  allowance_quantity: Yup.number()
    .required(t`Necessaria`)
    .min(0)
    .typeError(t`Value must be a number`),
  hours: Yup.number()
    .required(t`Necessaria`)
    .min(0)
    .typeError(t`Value must be a number`),
  days: Yup.number()
    .required(t`Necessaria`)
    .min(0)
    .typeError(t`Value must be a number`),
  rate: Yup.number()
    .required(t`Necessaria`)
    .min(0)
    .typeError(t`Value must be a number`),
});
