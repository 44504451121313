import { createSlice } from "@reduxjs/toolkit";

export const appSlide = createSlice({
  name: "app",
  initialState: {
    pusherInstance: null,
    notificationChannel: null,
    presenceChannel: null,
    message: null,
    serviceWorkerRegistration: null,
  },
  reducers: {
    setPusherInstance: (state, action) => {
      state.pusherInstance = action.payload;
    },
    setPresenceChannel: (state, action) => {
      state.presenceChannel = action.payload;
    },
    setNotificationChannel: (state, action) => {
      state.notificationChannel = action.payload;
    },
    setServiceWorkerRegistration: (state, action) => {
      state.serviceWorkerRegistration = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
  },
});

export const {
  setPusherInstance,
  setServiceWorkerRegistration,
  setMessage,
  setPresenceChannel,
  setNotificationChannel,
} = appSlide.actions;

export default appSlide.reducer;
