import { styled } from "@mui/system";
import { Box } from "@mui/material";
import { Paper } from "@mui/material";

export const StyledNewRowBox = styled(Box)((props) => ({
  marginTop: "10px",
  ".MuiPaper-root": {
    marginBottom: "2px",
  },
}));

export const StyledContainer = styled(Paper)((props) => ({
  border: "1px solid #DDDDDD",
  padding: "5px",
  "& .row": {
    borderBottom: "1px solid #DDDDDD",
  },
}));

export const StyledLoadingOverlay = styled(Paper)((props) => ({
  background: "rgba(255, 255, 255, 0.9)",
  zIndex: 2,
  position: "absolute",
  top: "0px",
  left: "0px",
  width: "100%",
  height: "100%",
}));
