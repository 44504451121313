import * as Yup from "yup";
import { t } from "@lingui/macro";
import { HCMModel } from "@models/HCMModel";

export default interface IOrganism {
  hcm?: HCMModel;
  onSave?: (param: any) => void;
  onCancel?: () => void;
  saveOnDestroy?: boolean;
}

export interface IFormValues {
  from: any;
  to: any;
  type_id: number | null;
  client_id: number | null;
  user_id: number | null;
  vendor_id: number | null;
  notes: string;
}

export const formSchema = (fromTo = true) => {
  if (fromTo) {
    return Yup.object().shape({
      from: Yup.string().required(t`Required`),
      to: Yup.string().required(t`Required`),
      type_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      client_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      user_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      vendor_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      notes: Yup.string().notRequired(),
    });
  } else {
    return Yup.object().shape({
      from: Yup.string().notRequired(),
      to: Yup.string().notRequired(),
      type_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      client_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      user_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      vendor_id: Yup.number()
        .typeError(t`Required`)
        .required(t`Required`),
      notes: Yup.string().notRequired(),
    });
  }
};
