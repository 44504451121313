import * as React from "react";
import { Grid, Box, Alert } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { SelectMolecule } from "@molecules/AsyncSelect";
import { Trans } from "@lingui/macro";
import TextFieldAtom from "@atoms/TextField";
import IUserCreateOrganism, { IFormValues } from "./interface";
import createAdminSchema from "@validation/createAdmin";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import UsersService from "@services/UserService";
import ButtonAtom from "@atoms/Button";

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode
 * @returns
 */
const UserCreateOrganism = ({
  user,
  onNew,
  onUpdate,
  hideAttributes,
}: IUserCreateOrganism) => {
  const [sErrors, setSErrors] = React.useState(null);
  const [msg, setMsg] = React.useState("");
  const [success, setSuccess] = React.useState<boolean>(false);

  const formInstance = useForm<IFormValues>({
    defaultValues: {
      role_id: user ? (user.role ? user.role.id : undefined) : undefined,
      name: user ? (user.name ? user.name : undefined) : "",
      email: user ? (user.email ? user.email : undefined) : "",
      surname: user ? (user.surname ? user.surname : undefined) : "",
      phone: user ? (user.phone ? user.phone : undefined) : "",
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createAdminSchema),
  });

  const { mutate: createAssetMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      (user
        ? UsersService.put(formValues, user.id)
        : UsersService.create(formValues)
      ).then((res: any) => {
        setMsg(res.getMsgString());
        if (!res.hasErrors()) {
          setSuccess(true);
          toast(res.getMsgString(), {
            type: "success",
          });
          if (onNew && !user) {
            onNew(res.getData());
          }
          if (onUpdate && user) {
            onUpdate(res.getData());
          }
        } else {
          setSErrors(res.getErrors());
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      }),
  );

  const {
    control,
    formState: { isValid },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const handleSubmit = (data: IFormValues) => {
    const values = { ...data };
    createAssetMutation(values);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
        <FormProvider {...formInstance}>
          {success ? (
            <React.Fragment>
              {msg ? (
                <Box mt={2}>
                  <Alert severity="success">{msg}</Alert>
                </Box>
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box>
                <Box mt={1}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <TextFieldAtom
                        controlName={"name"}
                        required={true}
                        variant={"outlined"}
                        label={<Trans>Name</Trans>}
                      />
                      {sErrors && sErrors["name"] ? (
                        <Alert severity="error" icon={false}>
                          {sErrors["name"]}
                        </Alert>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextFieldAtom
                        controlName={"surname"}
                        required={true}
                        variant={"outlined"}
                        label={<Trans>Surname</Trans>}
                      />
                      {sErrors && sErrors["surname"] ? (
                        <Alert severity="error" icon={false}>
                          {sErrors["surname"]}
                        </Alert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <TextFieldAtom
                        controlName={"phone"}
                        variant={"outlined"}
                        label={<Trans>Phone</Trans>}
                      />
                      {sErrors && sErrors["phone"] ? (
                        <Alert severity="error" icon={false}>
                          {sErrors["phone"]}
                        </Alert>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <SelectMolecule
                        control={control}
                        controlName="role_id"
                        required={true}
                        sError={sErrors ? sErrors["role_id"] : undefined}
                        optionValue={"id"}
                        except={[2, 4]}
                        storeCollection="roles"
                        optionLabel={'alias'}
                        variant={"outlined"}
                        label={<Trans>Role</Trans>}
                      ></SelectMolecule>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <TextFieldAtom
                        controlName={"email"}
                        required={true}
                        variant={"outlined"}
                        label={<Trans>Email</Trans>}
                      />
                      {sErrors && sErrors["email"] ? (
                        <Alert severity="error" icon={false}>
                          {sErrors["email"]}
                        </Alert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box mt={2}>
                {sErrors ? <Alert severity="error">{msg}</Alert> : null}
                <Box mt={2} sx={{ textAlign: "right" }}>
                  <ButtonAtom
                    type="submit"
                    disabled={!isValid}
                    loading={isLoading}
                    label={
                      user ? (
                        <Trans>Update user</Trans>
                      ) : (
                        <Trans>Create user</Trans>
                      )
                    }
                  />
                </Box>
              </Box>
            </React.Fragment>
          )}
        </FormProvider>
      </form>
    </Box>
  );
};

export default UserCreateOrganism;
