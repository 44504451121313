import { CoreBaseModel } from "@core/models/Base.model";

export class QuestionModel extends CoreBaseModel {
  id: number;
  model_type: string;
  title: string;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "questions";
  }
}
