import { styled } from "@mui/system";
import { Box } from "@mui/material";

interface ILogoProps {
  selected?: boolean;
}

export const StyledRow = styled(Box)((props: ILogoProps) => ({
  padding: "5px",
  background: props.selected ? "#348ee661" : "",
  "&:hover": {
    background: "#348ee661",
    cursor: "pointer",
  },
  "& .selected-icon": {
    marginLeft: "auto",
    display: props.selected ? "block" : "none",
  },
}));
