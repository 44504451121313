import { Fragment, memo, useState } from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { StyledMessage } from "./styled";
import { useUser } from "@hooks";
import ModeIcon from "@mui/icons-material/Mode";
import TextField from "@atoms/TextField";
import { FormProvider, useForm } from "react-hook-form";
import { Trans } from "@lingui/macro";
import { IFormValues, sendMessageSchema } from "./interface";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import ChatService from "@services/ChatService";
import { useMutation } from "react-query";
import { deleteMessage, editMessage } from "@stores/reducers/chatReducer";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { ActivityModel } from "@models/ActivityModel";
import { useAppDispatch } from "@stores/hooks";
import moment from "moment";
import DatePrintAtom from "@atoms/DatePrint";
import ButtonAtom from "@atoms/Button";

interface IChatMessageProps {
  message: any;
  activity: ActivityModel;
}

const ChatMessage = ({ message, activity }: IChatMessageProps) => {
  const [modMode, setModMode] = useState<boolean>(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const auth = useUser();
  console.log(message);
  const formInstance = useForm<IFormValues>({
    defaultValues: {
      message: message.message ? message.message : message.text,
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(sendMessageSchema),
  });

  const {
    reset,
    formState: { isValid },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const { mutate: updateMessageMutation, isLoading: isUpdating } = useMutation(
    (formValues: IFormValues) =>
      ChatService.put(formValues, message.id, true).then((res: any) => {
        setModMode(false);
        if (!res.hasErrors()) {
          toast(res.getMsgString(), { type: "success" });
          dispatch(
            editMessage({
              activity_id: activity.id,
              message: {
                id: res.getData().id,
                text: res.getData().message,
              },
            }),
          );
        } else {
          toast(res.getMsgString(), { type: "error" });
        }
      }),
  );

  const { mutate: deleteMessageMutation, isLoading: isDeleting } = useMutation(
    () =>
      ChatService.delete(message.id).then((res: any) => {
        setModMode(false);
        if (!res.hasErrors()) {
          setDeleted(true);
          toast(res.getMsgString(), { type: "success" });
          dispatch(deleteMessage({ message: { id: res.getData().id } }));
        } else {
          toast(res.getMsgString(), { type: "error" });
        }
      }),
  );

  const handleSubmit = (data: IFormValues) => {
    updateMessageMutation(data);
  };

  const handleDeleteMessage = () => {
    deleteMessageMutation();
  };

  const toggleModMode = () => {
    if (modMode) {
      setModMode(false);
      reset();
    } else {
      setModMode(true);
    }
  };

  return (
    <Fragment>
      {deleted ? null : (
        <StyledMessage
          className={
            auth.user.id ===
            (message.sender_id ? message.sender_id : message.user?.id)
              ? "mine"
              : ""
          }
        >
          {isDeleting && <Box className="deleting-overlay"></Box>}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Box>
                <Typography variant="body1" className="author">
                  {message.sender_id
                    ? message.sender_name
                    : message.user?.full_name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" className="date">
                  {moment(message.created_at).isSame(moment(), "day") ? (
                    <DatePrintAtom utc={false} tz={null} format="HH:mm">
                      {message.created_at}
                    </DatePrintAtom>
                  ) : (
                    <DatePrintAtom
                      utc={false}
                      tz={null}
                      format="DD/MM/YYYY HH:mm"
                    >
                      {message.created_at}
                    </DatePrintAtom>
                  )}
                </Typography>
              </Box>
            </Box>
            {(message.sender_id ? message.sender_id : message.user?.id) ===
              auth.user.id && (
              <Box className="actions">
                <IconButton
                  onClick={toggleModMode}
                  className="mod-button"
                  size="small"
                >
                  <ModeIcon fontSize="small" />
                </IconButton>
                <IconButton
                  color="error"
                  onClick={handleDeleteMessage}
                  className="del-button delete-btn"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box>
            {modMode ? (
              <Box mt={1}>
                <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                  <FormProvider {...formInstance}>
                    <Grid container>
                      <Grid item md={10} xs={12}>
                        <TextField
                          controlName={"message"}
                          required={true}
                          multiline={true}
                          disabled={isUpdating}
                          minRows={1}
                          variant={"outlined"}
                          label={<Trans>Message</Trans>}
                        ></TextField>
                      </Grid>
                      <Grid item md={2} xs={12}>
                        <Button disabled={!isValid} type="submit">
                          {isUpdating ? (
                            <CircularProgress />
                          ) : (
                            <Trans>Save</Trans>
                          )}
                        </Button>
                        <ButtonAtom
                          label={<Trans>Cancel</Trans>}
                          onClick={toggleModMode}
                          color="error"
                        />
                      </Grid>
                    </Grid>
                  </FormProvider>
                </form>
              </Box>
            ) : (
              <Typography className="message">
                {message.message ? message.message : message.text}
              </Typography>
            )}
          </Box>
        </StyledMessage>
      )}
    </Fragment>
  );
};

export default memo(ChatMessage);
