import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const StyledWrapper = styled(Box)((props: any) => ({
  display: "flex",
  "& .active": {
    opacity: 1,
  },
  "& .off": {
    opacity: 0.5,
  },
  "& svg:hover": {
    cursor: "pointer",
  },
}));
