import { Grid, Paper, Box, Tooltip } from "@mui/material";
import IProps from "./interface";
import { StyledRow } from "./styled";
import { t, Trans } from "@lingui/macro";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import DataBalloonAtom from "@atoms/DataBalloon";

const HCMRowExtendedMolecule = ({ hcm, onActionSelected }: IProps) => {
  const actionSelected = (item: IMBOption) => {
    if (onActionSelected) {
      onActionSelected(item);
    }
  };

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledRow>
          <Grid container spacing={1} sx={{ alignItems: "center" }}>
            <Grid item md={1} xs={12}>
              <Tooltip title={t`Total inspections`} placement="top">
                <Box>
                  <DataBalloonAtom
                    variant="chip"
                    title={t`Total activities`}
                    severity={"warning"}
                    data={hcm.total_activities}
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item md={2} xs={12}>
              <Box>
                <b>{hcm.client.name}</b>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Tooltip title={t`Inspector`}>
                <Box>{`${hcm.user?.full_name ?? "--"}`}</Box>
              </Tooltip>
            </Grid>
            <Grid item md={2} xs={12}>
              <Tooltip title={t`Vendor`}>
                <Box>{`${hcm.vendor?.name ?? "--"}`}</Box>
              </Tooltip>
            </Grid>
            <Grid item md={2} xs={12}>
              {hcm.first_activity && (
                <Box>
                  <Trans>From</Trans>{" "}
                  {hcm.first_activity
                    ? `${hcm.first_activity.month}/${hcm.first_activity.year}`
                    : "--"}{" "}
                  <Trans>to</Trans>{" "}
                  {hcm.last_activity
                    ? `${hcm.last_activity.month}/${hcm.last_activity.year}`
                    : hcm.first_activity
                    ? `${hcm.first_activity.month}/${hcm.first_activity.year}`
                    : "--"}
                </Box>
              )}
            </Grid>
            <Grid sx={{ textAlign: "right" }} item md={2} xs={12}>
              {
                <MultiButtonMolecule
                  onSelected={actionSelected}
                  options={[
                    {
                      label: "Details",
                      id: 0,
                      data: { item: hcm },
                    },
                    {
                      label: "Copy",
                      id: 4,
                      data: { item: hcm },
                    },
                    {
                      label: "Delete",
                      id: 5,
                      data: { item: hcm },
                    },
                  ]}
                />
              }
            </Grid>
          </Grid>
        </StyledRow>
      </Paper>
    </>
  );
};

export default HCMRowExtendedMolecule;
