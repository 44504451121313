import { Box } from "@mui/material";
import IInspectionCreateOrganism from "./interface";

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode
 * @returns
 */
const InspectionDetailPreviewOrganism = ({
  mode,
  inspection,
  onSave,
}: IInspectionCreateOrganism) => {
  return <Box sx={{ width: "100%" }}>Info</Box>;
};

export default InspectionDetailPreviewOrganism;
