import * as Yup from "yup";
import { t } from "@lingui/macro";
import { HCMActivityModel } from "@models/HCMActivityModel";
import { HCMModel } from "@models/HCMModel";

export default interface IOrganism {
  hcm: HCMModel;
  activity: HCMActivityModel;
  saveFormKey?: number;
  saveOnDestroy?: boolean;
  onCancel?: () => void;
  onSave?: (param: any) => void;
  onDirty?: (param: boolean) => void;
  isEdit?: boolean;
}

export interface IFormValues {
  vendor_id: number | null;
  type_id: number;
  month_year: string | null;
}

export const formSchema = Yup.object().shape({
  vendor_id: Yup.number()
    .typeError(t`Required`)
    .nullable(),
  month_year: Yup.string()
    .typeError(t`Required`)
    .required(t`Required`),
});
