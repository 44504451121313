import { useState, Fragment } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Grid, Box, Typography, Paper, Button } from "@mui/material";
import Modal from "@molecules/Modal";
import UserCreateOrganism from "@organisms/UserCreate";
import LayoutModal from "@layouts/modal";
import PaginatedListMolecule from "@molecules/PaginatedList";
import {
  StyledFilterContainer,
  StyledHeaderContainer,
  StyledGridItem,
} from "./styled";
import UsersService from "@services/UserService";
import AddIcon from "@mui/icons-material/Add";
import { UserModel } from "@models/UserModel";
import UserFilterOrganism from "@organisms/UserFilter";
import UserRow from "@molecules/UserRow";
import { useConfirm } from "material-ui-confirm";
import { Navigate } from "react-router-dom";
import { useUser } from "@hooks";
import PeopleService from "@services/PeopleService";

const UsersPage = () => {
  const [refetchKey, setRefetchKey] = useState<any>(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerDetail, setDrawerDetail] = useState<any>({});
  const [filter, setFilter] = useState<any>({ search: "", role_id: [1, 3] });
  const [newRow, setNewRows] = useState<UserModel | undefined>(undefined);
  const [toDelete, setToDeleteFromList] = useState<number | undefined>(
    undefined,
  );
  const [updatedItem, setUpdated] = useState<UserModel | undefined>(undefined);
  const confirm = useConfirm();
  const user = useUser();
  
  /*const newUserCreated = (user: UserModel) => {
        setNewRows(user)
    }*/

  const onUserUpdate = (user: UserModel) => {
    setUpdated(user);
  };

  const handleActionSelect = (param: any) => {
    if (param.label === "Edit") {
      setModalOpen(true);
      setDrawerDetail(param.data.user);
    } else if (param.label === "Delete") {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        UsersService.delete(param.data.user.id).then((res: any) => {
          if (!res.hasErrors()) {
            setToDeleteFromList(param.data.user.id);
            toast(res.getMsgString(), {
              type: "success",
            });
            setRefetchKey(refetchKey + 1);
          } else {
            toast(res.getMsgString(), {
              type: "error",
            });
          }
        });
      });
    } else if (param.label === "Send password") {
      confirm({
        title: "Confirmation",
        description: "Are you sure you want to send the password to this user?",
        cancellationText: "Go back",
        confirmationText: "Send",
      }).then(() => {
        PeopleService.sendPassword(param.data.user.id).then((res: any) => {
          if (!res.hasErrors()) {
            toast(res.getMsgString(), {
              type: "success",
            });
            setRefetchKey(refetchKey + 1);
          } else {
            toast(res.getMsgString(), {
              type: "error",
            });
          }
        })
      });
    }
  };

  return (
    <>
      {user.user.role.name === "inspector" && (
        <Navigate to={"/login"}></Navigate>
      )}
      <Fragment>
        <Modal open={modalOpen} set_open={setModalOpen}>
          <LayoutModal
            title={
              !!drawerDetail ? (
                <Trans>Update user</Trans>
              ) : (
                <Trans>New user</Trans>
              )
            }
          >
            <UserCreateOrganism
              onUpdate={onUserUpdate}
              onNew={setNewRows}
              user={drawerDetail}
            />
          </LayoutModal>
        </Modal>
      </Fragment>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"h1"}>
          <Trans>Settings/Users</Trans>
        </Typography>
        <Box sx={{ textAlign: "right" }} mb={2}>
          {user.can("users.create") && 
            <Button
              variant="outlined"
              onClick={() => {
                setDrawerDetail(null);
                setModalOpen(true);
              }}>
              <AddIcon></AddIcon> Create user
            </Button>
          }
        </Box>
      </Box>
      <Box mt={2}>
        <Paper elevation={0} sx={{ padding: "10px" }}>
          <StyledFilterContainer
            mb={3}
            sx={{ maxWidth: "50%", textAlign: "right" }}
          >
            <Box>
              <UserFilterOrganism setFilter={setFilter} filter={filter} />
            </Box>
          </StyledFilterContainer>
          <PaginatedListMolecule
            filter={filter}
            newRow={newRow}
            scrollPagination={true}
            updated={updatedItem}
            idToDelete={toDelete}
            renderFunc={(item: UserModel) => {
              return (
                <UserRow user={item} onActionSelected={handleActionSelect} />
              );
            }}
            header={
              <StyledHeaderContainer sx={{ paddingY: "10px" }}>
                <Grid container>
                  <StyledGridItem item md={4} xs={12}>
                    <Trans>Name</Trans>
                  </StyledGridItem>
                  <StyledGridItem item md={2} xs={12}>
                    <Trans>Role</Trans>
                  </StyledGridItem>
                  <StyledGridItem item md={2} xs={12}>
                    <Trans>Email</Trans>
                  </StyledGridItem>
                  <StyledGridItem item md={2} xs={12}>
                    <Trans>Phone</Trans>
                  </StyledGridItem>
                  <StyledGridItem item md={2} xs={12}></StyledGridItem>
                </Grid>
              </StyledHeaderContainer>
            }
            service={UsersService}
          />
        </Paper>
      </Box>
    </>
  );
};

export default UsersPage;
