import ActivityDetailFormOrganism from "@organisms/ActivityDetailForm";
import IActivityDetailResumeOrganism from "./interface";
import { Box, Grid } from "@mui/material";
import { Trans } from "@lingui/macro";
import { StyledValueTitle, StyledValueText } from "./styled";
import { LabelModel } from "@models/LabelModel";
import AddressMolecule from "@molecules/AddressMolecule";

const ActivityDetailResumeOrganism = ({
  activity,
  onSave,
  isEdit,
  onCancel,
  hide,
  verbose,
}: IActivityDetailResumeOrganism) => {
  return (
    <>
      {activity && isEdit && (
        <ActivityDetailFormOrganism
          onCancel={onCancel}
          onSave={onSave}
          mode="patch"
          activity={activity}
        ></ActivityDetailFormOrganism>
      )}
      {activity && !isEdit && (
        <Box>
          <Grid container>
            <Grid item md={6} sm={6} xs={6}>
              <Box>
                <StyledValueTitle variant="h6">
                  <Trans>Vendor</Trans>
                </StyledValueTitle>
                <StyledValueText>
                  {activity.inspection?.vendor?.name ?? "--"}
                </StyledValueText>
              </Box>
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <Box>
                <StyledValueTitle variant="h6">
                  <Trans>Subvendor</Trans>
                </StyledValueTitle>
                <StyledValueText>
                  {activity.inspection?.subvendor?.name ?? "--"}
                </StyledValueText>
              </Box>  
            </Grid>
          </Grid>
          <Box>
            {!hide.includes("owner") && (
              <Grid container mt={1} spacing={2}>
                <Grid item md={6} sm={6} xs={6}>
                  <StyledValueTitle variant="h6">
                    <Trans>Owner</Trans>
                  </StyledValueTitle>
                  <StyledValueText>
                    {activity.user ? activity.user.full_name : "--"}
                  </StyledValueText>
                </Grid>
                <Grid item sm={12} md={6} xs={6}>
                  <Grid container>
                    <Grid item md={6}>
                      <StyledValueTitle variant="caption">
                        Role
                      </StyledValueTitle>
                      <StyledValueText>
                        {activity.user?.category
                          ? activity.user?.category.name
                          : "--"}
                      </StyledValueText>
                    </Grid>
                    <Grid item md={6}>
                      <StyledValueTitle variant="caption">
                        Seniority
                      </StyledValueTitle>
                      <StyledValueText>
                        {activity.user?.type ? activity.user.type.name : "--"}
                      </StyledValueText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid container mt={1} spacing={2}>
              <Grid item sm={6} md={6} xs={6}>
                <StyledValueTitle variant="h6">
                  <Trans>Notifications</Trans>
                </StyledValueTitle>
                <StyledValueText>
                  {activity.inspection?.labels?.reduce(
                    (a: LabelModel, b: LabelModel) =>
                      a?.name ? `${a.name} - ${b.name}` : b.name,
                    "--",
                  )}
                </StyledValueText>
              </Grid>
              <Grid item sm={6} md={6} xs={6}>
                <StyledValueTitle variant="h6">
                  <Trans>PO</Trans>
                </StyledValueTitle>
                <StyledValueText>
                  {activity.inspection?.po?.reduce(
                    (a: LabelModel, b: LabelModel) =>
                      a?.name ? `${a.name} - ${b.name}` : b.name,
                    "--",
                  )}
                </StyledValueText>
              </Grid>
            </Grid>
            <Grid container mt={1} spacing={2}>
              <Grid item sm={12} md={12} xs={12}>
                <StyledValueTitle variant="h6">
                  <Trans>Address</Trans>
                </StyledValueTitle>
                <StyledValueText variant="caption">
                  <AddressMolecule
                    address={activity.getAddress()}
                  ></AddressMolecule>
                </StyledValueText>
              </Grid>
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
};

ActivityDetailResumeOrganism.defaultProps = {
  hide: [],
  verbose: [],
};

export default ActivityDetailResumeOrganism;
