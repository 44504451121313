import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { ChatModel } from "@models/ChatModel";

class ChatService extends CoreBaseService {
  constructor() {
    super(ChatModel);
  }
}

const inst = new ChatService();

export default inst;
