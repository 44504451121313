import { Fragment, memo, useEffect, useState } from "react";
import DrawerLayout from "@layouts/drawer";
import { Trans } from "@lingui/macro";
import ActivitiesService from "@services/ActivitiesService";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { closeChat, hideActiveChat } from "@stores/reducers/chatReducer";
import { Drawer, Box } from "@mui/material";
import { ActivityModel } from "@models/ActivityModel";
import ActivityChatOrganism from "@organisms/ActivityChat";

type Anchor = "top" | "left" | "bottom" | "right";

const BubbleDrawer = ({ chat, onClose }: any) => {
  const [activity, setActivity] = useState<ActivityModel | null>(null);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      if (chat && activity) {
        onClose();
        dispatch(closeChat({ activity_id: activity.id }));
      }
      setState({ ...state, [anchor]: open });
      dispatch(hideActiveChat());
    };

  const closeDr = () => {
    if (chat && activity) {
      onClose();
      dispatch(closeChat({ activity_id: activity.id }));
    }
    setState({ ...state, ["right"]: false });
  };

  const { isLoading, data } = useQuery(
    `activity-detail-${chat ? chat.activity_id : ""}`,
    () =>
      chat
        ? ActivitiesService.get(chat.activity_id).then((res: any) => res)
        : null,
    {
      refetchOnWindowFocus: false,
      cacheTime: 25000,
      enabled: true,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!isLoading && data) {
      setActivity(data.getData());
      setState({ ...state, right: true });
    } else {
    }
  }, [data, isLoading]);

  return (
    <Fragment>
      {activity && (
        <Drawer
          sx={{ width: "100vw" }}
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          <DrawerLayout
            title={
              <Box>
                <Box>
                  <Trans>Activity</Trans>: {activity.inspection.job_id}
                </Box>
                <Box>
                  <Trans>Inspector</Trans>: {activity.getUser().full_name}
                </Box>
              </Box>
            }
            onCloseClick={() => closeDr()}
          >
            {activity && <ActivityChatOrganism activity={activity} />}
          </DrawerLayout>
        </Drawer>
      )}
    </Fragment>
  );
};

export default memo(BubbleDrawer);
