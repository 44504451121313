import * as React from "react";
import { Grid, Box, Alert } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { Trans } from "@lingui/macro";
import TextFieldAtom from "@atoms/TextField";
import IUserCreateOrganism, {
  IFormValues,
  createVendorSchema,
  createSubcontractorSchema,
} from "./interface";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ClientsService from "@services/ClientsService";
import { fetchFilter } from "@stores/reducers/filtersReducer";
import { useAppDispatch } from "@stores/hooks";
import { SelectMolecule } from "@molecules/AsyncSelect";
import ButtonAtom from "@atoms/Button";
import { IDS } from "@helpers/constants";

/**
 * Including a double-layout mode ('patch' and 'normal')
 * @param mode
 * @returns
 */
const ClientCreateOrganism = ({
  mode,
  label,
  typeId,
  user,
  onNew,
  onUpdate,
  hideAttributes,
}: IUserCreateOrganism) => {
  const [sErrors, setSErrors] = React.useState(null);
  const [msg, setMsg] = React.useState("");
  const [success, setSuccess] = React.useState<boolean>(false);

  const dispatch = useAppDispatch();

  const formInstance = useForm<IFormValues>({
    defaultValues: {
      type_id: typeId,
      name: user ? (user.name ? user.name : undefined) : "",
      email: user ? (user.email ? user.email : undefined) : "",
      phone: user ? (user.phone ? user.phone : undefined) : "",
      country_id: user
        ? user.country_id
          ? user.country_id
          : undefined
        : undefined,
      division: user ? (user.division ? user.division : undefined) : undefined,
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(
      typeId === IDS.ROLES.SUBCONTRACTOR
        ? createSubcontractorSchema
        : createVendorSchema,
    ),
  });

  const { mutate: createAssetMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      (mode === "patch" && user
        ? ClientsService.put(formValues, user.id)
        : ClientsService.create(formValues)
      ).then((res: any) => {
        setMsg(res.getMsgString());
        if (!res.hasErrors()) {
          if (typeId === IDS.ROLES.SUBCONTRACTOR) {
            dispatch(fetchFilter("subcontractors"));
          } else {
            dispatch(fetchFilter("partners"));
          }
          setSuccess(true);
          toast(res.getMsgString(), {
            type: "success",
          });
          if (onNew && !user) {
            onNew(res.getData());
          }
          if (onUpdate && user) {
            onUpdate(res.getData());
          }
        } else {
          setSErrors(res.getErrors());
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      }),
  );

  const {
    formState: { isValid },
    handleSubmit: handleHookSubmit,
  } = formInstance;

  const handleSubmit = (data: IFormValues) => {
    const values = { ...data };
    createAssetMutation(values);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
        <FormProvider {...formInstance}>
          {success ? (
            <React.Fragment>
              {msg ? (
                <Box mt={2}>
                  <Alert severity="success">{msg}</Alert>
                </Box>
              ) : null}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box mt={1}>
                <Grid container spacing={2}>
                  <Grid item md={6}>
                    <TextFieldAtom
                      controlName={"name"}
                      required={true}
                      variant={"outlined"}
                      label={<Trans>Name</Trans>}
                    />
                    {sErrors && sErrors["name"] ? (
                      <Alert severity="error" icon={false}>
                        {sErrors["name"]}
                      </Alert>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextFieldAtom
                      controlName={"email"}
                      required={true}
                      variant={"outlined"}
                      label={<Trans>Email</Trans>}
                    />
                    {sErrors && sErrors["email"] ? (
                      <Alert severity="error" icon={false}>
                        {sErrors["email"]}
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                      <SelectMolecule
                        control={formInstance.control}
                        required={true}
                        controlName="country_id"
                        sError={sErrors ? sErrors["country_id"] : undefined}
                        optionValue={"id"}
                        storeCollection="countries"
                        variant={"outlined"}
                        label={<Trans>Country</Trans>}
                      ></SelectMolecule>
                    </Grid>
                    {typeId !== IDS.ROLES.SUBCONTRACTOR && (
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldAtom
                          controlName={"division"}
                          variant={"outlined"}
                          label={<Trans>Division</Trans>}
                        />
                        {sErrors && sErrors["division"] ? (
                          <Alert severity="error" icon={false}>
                            {sErrors["division"]}
                          </Alert>
                        ) : null}
                      </Grid>
                    )}
                    {typeId === IDS.ROLES.SUBCONTRACTOR && (
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldAtom
                          controlName={"phone"}
                          variant={"outlined"}
                          label={<Trans>Phone</Trans>}
                        />
                        {sErrors && sErrors["phone"] ? (
                          <Alert severity="error" icon={false}>
                            {sErrors["phone"]}
                          </Alert>
                        ) : null}
                      </Grid>
                    )}
                  </Grid>
                </Box>
                {typeId !== IDS.ROLES.SUBCONTRACTOR && (
                  <Box mt={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={6}>
                        <TextFieldAtom
                          controlName={"phone"}
                          variant={"outlined"}
                          label={<Trans>Phone</Trans>}
                        />
                        {sErrors && sErrors["phone"] ? (
                          <Alert severity="error" icon={false}>
                            {sErrors["phone"]}
                          </Alert>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box mt={2}>
                {sErrors ? <Alert severity="error">{msg}</Alert> : null}
                <Box mt={2} sx={{ textAlign: "right" }}>
                  <ButtonAtom
                    type="submit"
                    disabled={!isValid}
                    loading={isLoading}
                    label={`${user ? "Update" : "Create"} ${label}`}
                  />
                </Box>
              </Box>
            </React.Fragment>
          )}
        </FormProvider>
      </form>
    </Box>
  );
};

export default ClientCreateOrganism;
