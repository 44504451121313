import * as Yup from "yup";

const createSchema = Yup.object().shape({
  activities: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        date: Yup.date().required(),
        start_time: Yup.date().required(),
        end_time: Yup.date().required(),
      }),
    ),
});

export default createSchema;
