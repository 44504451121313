import { Box } from "@mui/material";
import IPeopleDataOrganism from "./interface";
import InspectionsService from "@services/InspectionsService";
import PaginatedListMolecule from "@molecules/PaginatedList";
import InspectionRow from "@organisms/JobDetail/Inspections/InspectionRow";
import { InspectionModel } from "@models/InspectionModel";
import { useConfirm } from "material-ui-confirm";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import { toast } from "react-toastify";
import { useState } from "react";
import { t } from "@lingui/macro";

const PeopleDetailJobsOrganism = ({ person }: IPeopleDataOrganism) => {
  const confirm = useConfirm();
  const [newRows, setNewRows] = useState<Array<InspectionModel>>([]);
  const [paginationKey, setPaginationKey] = useState<number>(0);
  const [filter /*, setFilter*/] = useState<any>({
    user_id: person ? person.id : null,
  });

  const handleRowAction = (param: IMBOption) => {
    if (param.label === "Copy") {
      InspectionsService.copyInspection(param.data.inspection.id).then(
        (res: any) => {
          toast(t`Inspection successfully copied`, {
            type: "success",
          });
          setNewRows([...newRows, ...[res.getData()]]);
        },
      );
    } else if (param.label === "Delete") {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        InspectionsService.delete(param.data.inspection.id).then((res: any) => {
          if (!res.hasErrors()) {
            toast(t`Inspection successfully deleted`, {
              type: "success",
            });
            setPaginationKey(paginationKey + 1);
          } else {
            toast(res.getMsgString(), { type: "error" });
          }
        });
      });
    }
  };

  return (
    <>
      <Box>
        <PaginatedListMolecule
          renderFunc={(inspection: InspectionModel) => {
            return (
              <InspectionRow
                onActionSelected={handleRowAction}
                inspection={inspection}
              ></InspectionRow>
            );
          }}
          service={InspectionsService}
          refetchKey={paginationKey}
          newRow={newRows}
          scrollPagination={document.querySelector(
            ".MuiDrawer-paperAnchorRight",
          )}
          perPage={15}
          filter={filter}
        />
      </Box>
    </>
  );
};

export default PeopleDetailJobsOrganism;
