import { Box, Grid, Typography, Paper } from "@mui/material";
import { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IAssetSpecsOrganism from "./interface";
import { t, Trans } from "@lingui/macro";
import ChipAtom from "@atoms/Chip";
import { StyledGeneralDetailBox, StyledGenearalContGrid } from "./styled";
import { AssetsModel } from "@models/AssetsModel";
import AttributesOrganism from "@organisms/AttributesOrganism";
import AssetCreateOrganism from "@organisms/AssetCreate";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

const AssetDetailSpecsOrganism = ({ asset, onUpdate }: IAssetSpecsOrganism) => {
  const [assetData, setAssetData] = useState<AssetsModel>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [infoIsExpanded, setInfoIsExpanded] = useState<boolean>(true);
  /*const [ addCertification, setAddCertification ] = useState<number>(0);
	const [ infoCertsIsExpanded, setInfoCertsIsExpanded ] = useState<boolean>(true);*/

  const clickEditGeneric = (event: any, edit: boolean) => {
    setIsEdit(edit);
    if (infoIsExpanded) {
      event.stopPropagation();
    }
  };

  const assetUpdated = (asset: AssetsModel) => {
    setAssetData(asset);
    if (onUpdate) {
      onUpdate(asset);
    }
    setIsEdit(false);
  };

  const onEditCancel = (event: any) => {
    setIsEdit(false);
    event.stopPropagation();
  };

  useEffect(() => {
    if (asset) {
      setAssetData(asset);
    }
  }, [asset]);

  return (
    <>
      {assetData ? (
        <Box mt={3}>
          <Paper sx={{ marginBottom: "10px" }} variant="outlined">
            <Accordion
              onChange={(event: any, expanded: boolean) =>
                setInfoIsExpanded(expanded)
              }
              defaultExpanded={true}
              elevation={0}
              sx={{ marginBottom: "10px" }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span>
                  <Typography variant="h5">
                    <b>
                      <Trans>General</Trans>
                    </b>
                  </Typography>
                </span>
                <span>
                  {isEdit ? (
                    <Tooltip title={<Trans>Cancel</Trans>} placement="top">
                      <IconButton
                        onClick={(event: any) => clickEditGeneric(event, false)}
                      >
                        <CancelIcon></CancelIcon>
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={t`Edit asset`} placement="top">
                      <IconButton
                        onClick={(event: any) => clickEditGeneric(event, true)}
                      >
                        <EditIcon color="success"></EditIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                {!!isEdit ? (
                  <Box>
                    <AssetCreateOrganism
                      onCancel={onEditCancel}
                      onNewAsset={assetUpdated}
                      asset={assetData}
                      mode="patch"
                    />
                  </Box>
                ) : (
                  <Box>
                    <StyledGenearalContGrid container>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <Typography variant="h6">
                              <Trans>Name/id</Trans>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {assetData.name}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <Typography variant="h6">
                              <Trans>Make</Trans>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {assetData.type?.name}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                    </StyledGenearalContGrid>
                    <StyledGenearalContGrid container>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <Typography variant="h6">
                              <Trans>Category</Trans>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {assetData.category.name}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <Typography variant="h6">
                              <Trans>Department</Trans>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              {assetData.location?.name}
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                    </StyledGenearalContGrid>
                    <StyledGenearalContGrid container>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <Typography variant="h6">
                              <Trans>Availabe</Trans>
                            </Typography>
                          </Box>
                          <Box>
                            {assetData.available ? (
                              <ChipAtom
                                variant={"filled"}
                                color={"success"}
                                label={<Trans>Yes</Trans>}
                              ></ChipAtom>
                            ) : (
                              <ChipAtom
                                variant={"filled"}
                                color={"warning"}
                                label={<Trans>No</Trans>}
                              ></ChipAtom>
                            )}
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                      <Grid item md={4}>
                        <StyledGeneralDetailBox>
                          <Box>
                            <Typography variant="h6">
                              <Trans>Status</Trans>
                            </Typography>
                          </Box>
                          <Box>
                            <Typography variant="body1">
                              <ChipAtom
                                variant={"filled"}
                                color={assetData.status?.class}
                                label={assetData.status?.name}
                              ></ChipAtom>
                            </Typography>
                          </Box>
                        </StyledGeneralDetailBox>
                      </Grid>
                    </StyledGenearalContGrid>
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          </Paper>
          <AttributesOrganism
            model_type="assets"
            model={assetData}
          ></AttributesOrganism>
        </Box>
      ) : null}
      {assetData ? (
        <Paper variant="outlined">
          {/*<Accordion onChange={(event: any, expanded: boolean) => setInfoCertsIsExpanded(expanded)} defaultExpanded={true} elevation={0}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<span><Typography variant='h5'><b><Trans>Certifications</Trans></b></Typography></span>
							<Tooltip title={t`Add certification`} placement="top">
							<IconButton onClick={(event:any) => { setAddCertification(addCertification + 1); if(infoCertsIsExpanded) { event.stopPropagation();}} }>
								<AddCircleIcon color='success'/>
							</IconButton>
						</Tooltip>
						</AccordionSummary>
						<AccordionDetails>
							<Certifications model_id={assetData.id} model_type={'assets'} add_cert={addCertification}></Certifications>
						</AccordionDetails>
					</Accordion>*/}
        </Paper>
      ) : null}
    </>
  );
};

export default AssetDetailSpecsOrganism;
