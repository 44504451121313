import { styled } from "@mui/system";
import Box from "@mui/material/Box";

export const StyledScreen = styled(Box)((props) => ({
  border: "1px solid black",
  position: "relative",
  background: "white",
  "&.fullscreen": {
    position: "fixed",
    top: 0,
    width: "100vw",
    left: 0,
    height: "100vh",
  },
  "& .controls": {
    background: "transparent",
    bottom: "5px",
    right: 0,
    textAlign: "center",
    position: "absolute",
    width: "100%",
    "& button": {
      margin: "5px",
      border: "1px solid white",
      "& svg.MuiSvgIcon-colorSuccess": {
        color: "white",
      },
    },
    "& .call": {
      background: "green",
      "& svg": {
        color: "white",
      },
    },
    "& .hang-up": {
      background: "red",
      "& svg": {
        color: "white",
      },
    },
  },
  "& .me-wrapper": {
    position: "absolute",
    left: "5px",
    top: "5px",
    maxWidth: "115px",
    "& video": {
      maxWidth: "100%",
      maxHeight: "150px",
      border: "2px solid black",
    },
    [props.theme.breakpoints.down("md")]: {
      left: "8px !important",
      bottom: "8px !important",
      maxWidth: "100px",
      "& video": {
        maxHeight: "70px !important",
      },
    },
  },
  "& .partner-wrapper": {
    position: "relative",
    height: "100%",
    background: "black",
    "& video": {
      width: "100%",
      height: "auto",
      maxHeight: "500px",
    },
    "& .fullscreen-control": {
      position: "absolute",
      right: "5px",
      bottom: "5px",
      "& svg": {
        color: "white",
      },
    },
  },
}));

export const StyledWrapper = styled(Box)(() => ({
  maxWidth: "100%",
  margin: "0 auto",
  marginTop: "20px",
}));
