import { useState, useEffect } from "react";
import { Grid, Paper, Box, IconButton, Tooltip } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import AsyncSelectMolecule, { SelectMolecule } from "@molecules/AsyncSelect";
import { AutocompleteMolecule } from "@molecules/AsyncAutocomplete";
import IActivitiesFilterOrganism from "./interface";
import { yupResolver } from "@hookform/resolvers/yup";
// https://blog.logrocket.com/using-material-ui-with-react-hook-form/
import { FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";
import TabsMolecule from "@molecules/Tabs";
import DownloadIcon from "@mui/icons-material/Download";
import DateFieldMolecule from "@molecules/DateField";
import TextFieldAtom from "@atoms/TextField";
import TuneIcon from "@mui/icons-material/Tune";
import SwitchMolecule from "@molecules/Switch";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import { IDS } from "@utils/constants";
import { StyledIconsHolder } from "./styled";
import { UserModel } from "@models/UserModel";
import BoxTotalAtom from "@atoms/BoxTotal";
import { useQuery } from "react-query";
import ActivitiesService from "@services/ActivitiesService";
import { messageService } from "@utils/messagesService";
import { toast } from "react-toastify";
import DocumentsService from "@services/DocumentsService";

const schemaA = Yup.object().shape({
  user_id: Yup.number().nullable(),
  customer_id: Yup.number().nullable(),
  date_range: Yup.string().nullable(),
  client_id: Yup.number().nullable(),
  vendor_id: Yup.number().nullable(),
  po_id: Yup.number().nullable(),
  internal: Yup.number().nullable(),
  status_id: Yup.number().nullable(),
  has_claim: Yup.boolean().nullable(),
  report_status_id: Yup.mixed().nullable(),
  subcontractor_id: Yup.number().nullable(),
  search: Yup.string().nullable(),
  date_start: Yup.string().nullable(),
  date_end: Yup.string().nullable(),
});

interface IFormValues {
  user_id: number;
  report_status_id: number | null | string;
  status_id: number | null;
  internal: number | null;
  subcontractor_id: number;
  date_range: string;
  client_id: number | null;
  has_claim: boolean;
  vendor_id: number | null;
  search: string | undefined;
  date_start: string | undefined;
  date_end: string | undefined;
}

const InspectionActivitiesFilterOrganism = ({
  onFormChange,
}: IActivitiesFilterOrganism) => {
  const [showRanges, setShowRanges] = useState<boolean>(false);
  const [ downloadingXLS, setDownloadingXLS] = useState<boolean>(false);
  const [showAdvancedFilters, setShowAdvancedFilters] =
    useState<boolean>(false);
  const [totals, setTotals] = useState<any>({});

  const formMethods = useForm<IFormValues>({
    mode: "onChange",
    defaultValues: {
      client_id: null,
      user_id: undefined,
      report_status_id: "",
      status_id: null,
      internal: null,
      vendor_id: null,
      date_range: "all",
      search: "",
      has_claim: false,
    },
    reValidateMode: "onSubmit",
    resolver: yupResolver(schemaA),
  });

  useEffect(() => {
    const subS = messageService.getMessage().subscribe((message) => {
      if (message && message.text === "refetch-act-totals") {
        refetchTotals();
      }
    });
    return () => {
      subS.unsubscribe();
    };
  }, []);

  const { refetch: refetchTotals } = useQuery(
    "activities-total",
    () =>
      ActivitiesService.getTotals().then((res: any) => {
        setTotals(res.getData());
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const { handleSubmit, getValues, watch, setValue } = formMethods;

  const setFilterTab = (data: any) => {
    if (data.value !== "range") {
      setShowRanges(false);
      formMethods.setValue("date_start", "", { shouldValidate: true });
      formMethods.setValue("date_end", "", { shouldValidate: true });
      formMethods.setValue("date_range", data.value, {
        shouldValidate: true,
      }); // FIX date range 1/11/2022 - 2/11/2022
    } else {
      setShowRanges(true);
    }
  };

  let timeout: ReturnType<typeof setTimeout> | null = null;

  useEffect(() => {
    const watchAll = formMethods.watch((value, { name, type }) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        handleFormSubmit(getValues());
      }, 500);
    });
    return () => watchAll.unsubscribe();
  }, [watch, formMethods]);

  const handleFormSubmit = (data: IFormValues) => {
    onFormChange({ ...data, has_claim: data.has_claim ? 1 : 0 });
  };

  const changeReportStatusId = (value: any) => {
    setValue("report_status_id", value);
    if (value === "") {
      setValue("date_range", "today");
    } else {
      setValue("date_range", "24");
    }
  };

  const downloadResults = () => {
    const toastWarningID = toast("Downloading... please wait it could take a few minutes", {
      type: "warning",
      autoClose: false
    });    
    const values = getValues();
    if(showRanges) {
      values.date_range = "";
    }
    setDownloadingXLS(true);
    ActivitiesService.download(values, `inspection-activities-${(new Date().toJSON().slice(0,10))}.xlsx`)
      .then((res: any) => {
        if(!res.hasErrors()) {
          toast(t`Download export complete`, {
            type: "success",
          });
        } else {
          toast(t`An error occurred, the file could not be downloaded`, {
            type: "error",
          });
        }
        setDownloadingXLS(false);
        toast.dismiss(toastWarningID);
      })
      .catch(() => {
        setDownloadingXLS(false);
        toast(t`Generic error`, { type: "error" });
      });
  };

  return (
    <>
      <Grid my={2} container spacing={1}>
        <Grid item md={8} xs={8}>
          <Grid container spacing={1}>
            {Object.keys(totals).map((totalKey, index) => {
              return (
                <Grid key={index} item md={3} xs={3}>
                  <BoxTotalAtom
                    totalInteger={totals[totalKey]}
                    label={totalKey.charAt(0).toUpperCase() + totalKey.slice(1)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item md={4} xs={4} className="align-bottom">
          <Box ml={"auto"} className="d-flex">
            <StyledIconsHolder>
              <Box sx={{ marginLeft: "auto" }}>
                <Tooltip placement="top" title={t`View activities`}>
                  <IconButton
                    className={
                      getValues().report_status_id === "" ? "active" : ""
                    }
                    onClick={() => changeReportStatusId("")}
                  >
                    <FindInPageIcon className="icon-button" />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top" title={t`View reports`}>
                  <IconButton
                    className={
                      getValues().report_status_id ===
                      IDS.STATUSES.REPORTS.PENDING
                        ? "active"
                        : ""
                    }
                    onClick={() =>
                      changeReportStatusId(IDS.STATUSES.REPORTS.PENDING)
                    }
                  >
                    <AssignmentTurnedInIcon className="icon-button" />
                  </IconButton>
                </Tooltip>
              </Box>
            </StyledIconsHolder>
            <StyledIconsHolder>
              <Box sx={{ marginLeft: "auto", display: 'flex' }}>
                <Tooltip
                  placement="top"
                  className={showAdvancedFilters ? "active" : ""}
                  title={
                    showAdvancedFilters
                      ? t`Hide advanced filters`
                      : t`Show advanced filters`
                  }
                >
                  <IconButton
                    onClick={() =>
                      setShowAdvancedFilters(showAdvancedFilters ? false : true)
                    }
                  >
                    <TuneIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip placement="top" title={downloadingXLS ? t`Downloading, please wait...` : t`Download results`}>
                  <Box>
                    <IconButton disabled={downloadingXLS} onClick={downloadResults}>
                      <DownloadIcon />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            </StyledIconsHolder>
          </Box>
        </Grid>
      </Grid>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormProvider {...formMethods}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <Box>
                <Paper
                  elevation={0}
                  variant="outlined"
                  sx={{ position: "relative" }}
                >
                  <Grid container p={1} spacing={1}>
                    <Grid item xs={12} sm={12} md={2}>
                      <AutocompleteMolecule
                        control={formMethods.control}
                        controlName="client_id"
                        listId={"activities-filter-clients"}
                        storeCollection="clients"
                        emptyValue={null}
                        variant={"outlined"}
                        optionValue={"id"}
                        getOptionLabel={(item: any) => `${item.name}`}
                        label={<Trans>Client</Trans>}
                      ></AutocompleteMolecule>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <AutocompleteMolecule
                        control={formMethods.control}
                        controlName="vendor_id"
                        listId={"activities-filter-vendors"}
                        storeCollection="vendors"
                        emptyValue={null}
                        variant={"outlined"}
                        optionValue={"id"}
                        getOptionLabel={(user: any) => `${user.name}`}
                        label={<Trans>Vendor</Trans>}
                      ></AutocompleteMolecule>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <AutocompleteMolecule
                        control={formMethods.control}
                        controlName="subvendor_id"
                        listId={"activities-filter-subvendors"}
                        storeCollection="vendors"
                        emptyValue={null}
                        variant={"outlined"}
                        optionValue={"id"}
                        getOptionLabel={(user: any) => `${user.name}`}
                        label={<Trans>Subvendor</Trans>}
                      ></AutocompleteMolecule>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <AutocompleteMolecule
                        control={formMethods.control}
                        controlName="user_id"
                        listId={"activities-filter-owner"}
                        storeCollection="users"
                        emptyValue={null}
                        variant={"outlined"}
                        except={(user: UserModel) =>
                          user.role
                            ? user.role.id !== IDS.ROLES.INSPECTOR
                            : false
                        }
                        optionValue={"id"}
                        getOptionLabel={(user: any) => `${user.full_name}`}
                        label={<Trans>Inspector</Trans>}
                      ></AutocompleteMolecule>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <AutocompleteMolecule
                        control={formMethods.control}
                        controlName="po_id"
                        listId={"pos-filter"}
                        storeCollection="pos"
                        emptyValue={null}
                        variant={"outlined"}
                        optionValue={"id"}
                        getOptionLabel={(item: any) => `${item.name}_${item.client_name}`}
                        label={<Trans>PO</Trans>}
                      ></AutocompleteMolecule>
                    </Grid>
                    <Grid item xs={12} sm={12} md={2}>
                      <TextFieldAtom
                        controlName="search"
                        variant={"outlined"}
                        label={<Trans>Notification/Job ID/Project label</Trans>}
                      ></TextFieldAtom>
                    </Grid>
                  </Grid>
                  {showAdvancedFilters ? (
                    <Box>
                      <Grid container p={1} spacing={1}>
                        <Grid item xs={12} sm={12} md={2}>
                          <SelectMolecule
                            control={formMethods.control}
                            controlName="status_id"
                            emptyValue={null}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={"Inspection results"}
                            storeCollection="statuses.inspections"
                            optionLabel={"name"}
                          ></SelectMolecule>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <SelectMolecule
                            control={formMethods.control}
                            controlName="activity_category_id"
                            emptyValue={null}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={"Inspection category"}
                            storeCollection="categories.activities"
                            optionLabel={"name"}
                          ></SelectMolecule>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <AutocompleteMolecule
                            control={formMethods.control}
                            controlName="subcontractor_id"
                            storeCollection="subcontractors"
                            emptyValue={{
                              name: "",
                            }}
                            variant={"outlined"}
                            optionValue={"id"}
                            getOptionLabel={(item: any) => item.name}
                            optionLabel={"name"}
                            label={<Trans>Subcontract for</Trans>}
                          ></AutocompleteMolecule>
                        </Grid>
                        <Grid item xs={12} sm={12} md={2}>
                          <AsyncSelectMolecule
                            control={formMethods.control}
                            controlName="internal"
                            emptyValue={null}
                            filters={{
                              data: [
                                {
                                  name: "Internal",
                                  id: 1,
                                },
                                {
                                  name: "External",
                                  id: 2,
                                },
                              ],
                            }}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={"Internal/external"}
                            optionLabel={"name"}
                          ></AsyncSelectMolecule>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          sx={{
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <SelectMolecule
                            control={formMethods.control}
                            controlName="report_status_id"
                            emptyValue={null}
                            optionValue={"id"}
                            variant={"outlined"}
                            label={"Report status"}
                            storeCollection="statuses.reports"
                            optionLabel={"name"}
                          ></SelectMolecule>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={2}
                          sx={{ textAlign: "center" }}
                        >
                          <SwitchMolecule
                            controlName={"has_claim"}
                            control={formMethods.control}
                            label={"Has claim"}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Paper>
              </Box>
            </Grid>
            {getValues().report_status_id === "" && (
              <Box mt={2} sx={{ width: "100%" }}>
                <Grid container>
                  <Grid item sm={12} md={12} lg={12}>
                    <TabsMolecule
                      onChange={setFilterTab}
                      delayOnChange={true}
                      preselected={0}
                      tabsLabels={[
                        { label: "All", value: "all" },
                        {
                          label: "Today",
                          value: "today",
                        },
                        {
                          label: "Tomorrow",
                          value: "tomorrow",
                        },
                        {
                          label: "Current week",
                          value: "week",
                        },
                        {
                          label: "Current month",
                          value: "month",
                        },
                        {
                          label: "Custom range",
                          value: "range",
                        },
                      ]}
                    />
                    {showRanges ? (
                      <Box mt={1}>
                        <Grid px={1} py={0} container spacing={1}>
                          <Grid item sm={12} md={6} lg={6}>
                            <DateFieldMolecule
                              control={formMethods.control}
                              controlName={"date_start"}
                              inputFormat={"dd/MM/yyyy"}
                              label={<Trans>From</Trans>}
                            ></DateFieldMolecule>
                          </Grid>
                          <Grid item sm={12} md={6} lg={6}>
                            <DateFieldMolecule
                              minDate={getValues().date_start}
                              control={formMethods.control}
                              controlName={"date_end"}
                              inputFormat={"dd/MM/yyyy"}
                              label={<Trans>To</Trans>}
                            ></DateFieldMolecule>
                          </Grid>
                        </Grid>
                      </Box>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            )}
            {getValues().report_status_id === IDS.STATUSES.REPORTS.PENDING && (
              <Box mt={2} sx={{ width: "100%" }}>
                <Grid container>
                  <Grid item sm={12} md={12} lg={12}>
                    <TabsMolecule
                      delayOnChange={true}
                      onChange={setFilterTab}
                      tabsLabels={[
                        { label: "24 H", value: "24" },
                        { label: "36 H", value: "36" },
                        {
                          label: "3 DAYS",
                          value: "72",
                        },
                        {
                          label: "OTHERS",
                          value: "others",
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </FormProvider>
      </form>
    </>
  );
};

export default InspectionActivitiesFilterOrganism;
