import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CoreResponseModel } from "@core/models/Response.model";
import ActivitiesService from "@services/ActivitiesService";
import { ActivityModel } from "@models/ActivityModel";

interface IActivitiesState {
  isLoading: boolean;
  activities: Array<ActivityModel>;
  error: any;
  currentPage: number;
  lastPage: number;
  perpage: number;
  total: number;
  msg: string;
}

const initialState: IActivitiesState = {
  isLoading: false,
  activities: [],
  error: null,
  currentPage: 0,
  lastPage: 0,
  perpage: 0,
  total: 0,
  msg: "",
};

export const fetchActivities = createAsyncThunk(
  "activities/get",
  async (filter: object, { rejectWithValue }): Promise<any> => {
    try {
      const res = (await ActivitiesService.get(
        undefined,
        filter,
      )) as CoreResponseModel;
      if (res.hasErrors()) {
        // @ts-ignore
        throw new Error({
          msg: res.getMsgString ? res.getMsgString() : "Errore",
          fieldErrors: res.getErrors(),
        });
      }
      return res.getData();
    } catch (error: any) {
      console.log("error.message", error.msg);
      throw rejectWithValue(error);
    }
  },
);

export const activitiesReducer = createSlice({
  name: "activities",
  initialState,
  reducers: {
    fetchActivitiesB: (state) => {
      /*
        const user: IUser = new AuthModel(LocalStorageManager.getItem(LOCAL_STORAGE_KEYS.USER));

        if (user) {
          state.user = user;
          const tokenName = process.env.REACT_APP_TOKEN_HEADER_NAME ? process.env.REACT_APP_TOKEN_HEADER_NAME : 'token';
          axios.defaults.headers.common[tokenName] = `${process.env.REACT_APP_TOKEN_PREFIX} ${user.token as string}`;
        }
      */
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(fetchActivities.fulfilled, (state, { payload }) => {
        state.activities = payload.data /* paginated */
          ? payload.data
          : payload;
        if (payload.current_page) {
          state.currentPage = payload.current_page;
          state.lastPage = payload.last_page;
          state.perpage = payload.perpage;
          state.total = payload.total;
          state.isLoading = false;
        }
      })
      .addCase(fetchActivities.rejected, (state, { payload }) => {
        state.error = payload; // msg, fieldErrors
        state.isLoading = false;
      });
  },
});

export const { fetchActivitiesB } = activitiesReducer.actions;

export default activitiesReducer.reducer;
