import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IAvatarAtom from "./interface";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useUser } from "@hooks";

const AvatarAtom = ({ dropdownMenu }: IAvatarAtom) => {
  const user = useUser();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        sx={{ minWidth: "auto", padding: "0" }}
        onClick={dropdownMenu ? handleClick : (event) => {}}
      >
        <Avatar>{user.getInitials()}</Avatar>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ "aria-labelledby": "basic-button" }}
      >
        <MenuItem onClick={handleClose}>
          <Link to={"/logout"}>Logout</Link>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default AvatarAtom;
