import { CoreBaseService } from "@core/services/Base.service";
import { MaintenanceModel } from "@models/MaintenanceModel";

class MaintenancesService extends CoreBaseService {
  constructor() {
    super(MaintenanceModel);
  }
}

const inst = new MaintenancesService();

export default inst;
