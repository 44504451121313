import React from "react";
import "./index.css";
import App from "./App";

// Redux
import { Provider } from "react-redux";
import store from "@stores/store";

// Router
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { ENVIRONMENT } from "@utils/constants";
import { initDebuggerForTesting } from "@utils/helpers";

if (ENVIRONMENT.local) {
  initDebuggerForTesting();
}

const isStrictMode = false;
const Container = isStrictMode ? React.StrictMode : React.Fragment;

render(
  <Container>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </Container>,
  document.getElementById("root"),
);
