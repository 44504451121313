import { styled } from "@mui/system";
import { remCalc } from "@utils/helpers";
import { Box, Paper } from "@mui/material";

export const StyledBoxTotal = styled(Box)(() => ({
  height: remCalc(70),
  padding: remCalc(10),
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  textAlign: "center",
  "& .number": {
    fontWeight: "bold",
  },
  "& .title": {
    fontSize: "18px",
    color: "#2058E8e3",
  },
}));

export const StyledPaper = styled(Paper)(() => ({}));
