import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { PartnerModel } from "@models/PartnerModel";

class PartnersService extends CoreBaseService {
  constructor() {
    super(PartnerModel);
  }
}

const inst = new PartnersService();

export default inst;
