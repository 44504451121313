import { CoreBaseModel } from "@core/models/Base.model";

export class RateModel extends CoreBaseModel {
  id: number;
  value: number;
  model_id: number;
  model_type: string;
  type: any;
  type_id: number;
  category_id: number;
  info: any;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "rates";
  }

  isCalculated() {
    return Boolean(this.info?.rate);
  }
}
