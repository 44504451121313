import { Grid, Paper, Box } from "@mui/material";
import { StyledProviderRow } from "./styled";
import IClientRow from "./interface";
import MultiButtonMolecule from "@molecules/MultiButtonMolecule";

const SubcontractorRowMolecule = ({ client, onActionSelected }: IClientRow) => {
  const actionSelected = (item: any) => {
    if (onActionSelected) {
      onActionSelected(item, client);
    }
  };

  return (
    <>
      <Paper sx={{ border: "0px" }} elevation={0} variant="outlined">
        <StyledProviderRow>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid
              item
              sx={{ display: "flex", alignItems: "center" }}
              md={5}
              xs={12}
            >
              <Box mr={1}>{client.name ? client.name : "--"}</Box>
            </Grid>
            <Grid item md={3} xs={12}>
              {client.email ? client.email : "--"}
            </Grid>
            <Grid item md={2} xs={12}>
              {client.phone ? client.phone : "--"}
            </Grid>
            <Grid sx={{ textAlign: "right" }} item md={2} xs={12}>
              <MultiButtonMolecule
                onSelected={actionSelected}
                options={[
                  { label: "Edit", id: 1, data: { client } },
                  {
                    label: "Delete",
                    id: 4,
                    data: { client },
                  },
                ]}
              />
            </Grid>
          </Grid>
        </StyledProviderRow>
      </Paper>
    </>
  );
};

export default SubcontractorRowMolecule;
