import { memo } from "react";
import type { ISortUIMoleculeProps } from "./interface";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { StyledWrapper } from "./styled";

const SortUIMolecule = ({ order, onClick, name }: ISortUIMoleculeProps) => {
  return (
    <StyledWrapper>
      <ArrowDropUpIcon
        className={order && order === "ASC" ? "active" : "off"}
        onClick={() => (onClick ? onClick({ name, direction: "ASC" }) : false)}
        fontSize="small"
      />
      <ArrowDropDownIcon
        onClick={() => (onClick ? onClick({ name, direction: "DESC" }) : false)}
        className={order && order === "DESC" ? "active" : "off"}
        fontSize="small"
      />
    </StyledWrapper>
  );
};

export default memo(SortUIMolecule);
