import { useState } from "react";
import { Box, Button, Divider } from "@mui/material";
import IComponentProps, { IDateField, IActivity } from "./interface";
import { FormProvider, useForm, useFieldArray } from "react-hook-form";
import { IFormValues } from "@organisms/InspectionDetailForm/interface";
import { t, Trans } from "@lingui/macro";
import { useMutation } from "react-query";
import InspectionsService from "@services/InspectionsService";
import Grid from "@mui/material/Grid";
import createSchema from "./validation";
import Flatpickr from "react-flatpickr";
import DatePrintAtom from "@atoms/DatePrint";
import TimeFieldMolecule from "@molecules/TimePicker";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import ButtonAtom from "@atoms/Button";

const InspectionActivitiesForm = ({
  inspection,
  onBack,
  onNext,
  onNextText,
  isAdd,
  exceptDates,
}: IComponentProps) => {
  const [errors, setErrors] = useState({});

  const formInstance = useForm<any>({
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createSchema),
  });

  const {
    handleSubmit: handleHookSubmit,
    formState,
    setValue,
    getValues,
  } = formInstance;

  const { fields, append } = useFieldArray({
    control: formInstance.control,
    name: "activities",
  });

  const { mutate: createMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      InspectionsService.setActivities(formValues, inspection.id)
        .then((res: any) => {
          if (!res.hasErrors()) {
            setValue(
              "activities",
              getValues().activities.map((activity: IDateField) => {
                return {
                  start_time: activity.start_time,
                  end_time: activity.end_time,
                  date: activity.date,
                  id: res
                    .getData()
                    .find((activityB: IDateField) =>
                      moment(activityB.date).isSame(activity.date),
                    ).id,
                };
              }),
            );
            if (onNext) {
              onNext();
            }
          } else {
            setErrors(res.getErrors());
          }
        })
        .catch((e) => {
          console.log(e.response);
        }),
  );

  const handleSubmit = () => {
    const values = JSON.parse(JSON.stringify(getValues()));
    values.activities.forEach((activity: IActivity, index: number) => {
      values.activities[index].date = moment(
        values.activities[index].date,
      ).format("YYYY-MM-DD");
      values.activities[index].start_time = moment(
        values.activities[index].start_time,
      ).format("HH:mm");
      values.activities[index].end_time = moment(
        values.activities[index].end_time,
      ).format("HH:mm");
    });
    if (isAdd) {
      values.append = true;
    }
    createMutation(values);
  };

  const updateDates = (dates: Array<Date>) => {
    let currentFields = [...getValues().activities];
    setValue("activities", []);
    const out: Array<IDateField> = [];
    dates.forEach((incomingDate: Date) => {
      out.push(
        currentFields.find((field: any) =>
          moment(incomingDate).isSame(field.date),
        )
          ? currentFields.find((field: any) =>
              moment(incomingDate).isSame(field.date),
            )
          : {
              date: incomingDate,
              start_time: "2020-01-01T09:00:00",
              end_time: "2020-01-01T17:00:00",
            },
      );
    });
    append(out);
  };

  return (
    <Box>
      <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
        <FormProvider {...formInstance}>
          <Grid container spacing={1}>
            <Grid item md={5} xs={5}>
              <Flatpickr
                data-enable-time
                options={{
                  disable: exceptDates ? exceptDates : [],
                  locale: {
                    firstDayOfWeek: 1,
                  },
                  static: true,
                  mode: "multiple",
                  inline: true,
                  enableTime: false,
                }}
                value={[]}
                onChange={(dates: any) => {
                  updateDates(dates);
                }}
              />
            </Grid>
            <Grid item md={7} xs={7}>
              {fields.map((fieldDate: any, index: number) => {
                return (
                  <Box key={index} mt={1}>
                    <Grid container spacing={1}>
                      <Grid item md={4} xs={4} className="align-center">
                        <DatePrintAtom format="DD/MM/yyyy">
                          {fieldDate.date ? fieldDate.date.toString() : ""}
                        </DatePrintAtom>
                      </Grid>
                      <Grid item md={4} xs={4}>
                        <TimeFieldMolecule
                          // @ts-ignore
                          sError={
                            // @ts-ignore
                            errors[`activities.${index}.start_time`]
                              ? // @ts-ignore
                                errors[`activities.${index}.start_time`][0]
                              : ""
                          }
                          control={formInstance.control}
                          controlName={`activities.${index}.start_time`}
                          inputFormat={"HH:mm"}
                          label={<Trans>Time start</Trans>}
                        />
                      </Grid>
                      <Grid item md={4} xs={4}>
                        <TimeFieldMolecule
                          // @ts-ignore
                          sError={
                            // @ts-ignore
                            errors[`activities.${index}.end_time`]
                              ? // @ts-ignore
                                errors[`activities.${index}.end_time`][0]
                              : ""
                          }
                          control={formInstance.control}
                          controlName={`activities.${index}.end_time`}
                          inputFormat={"HH:mm"}
                          label={<Trans>Time end</Trans>}
                        />
                      </Grid>
                    </Grid>
                    <Box mt={1}>
                      <Divider />
                    </Box>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            {onBack && (
              <Button color="inherit" onClick={onBack} sx={{ mr: 1 }}>
                <Trans>Back</Trans>
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            {onNext && (
              <ButtonAtom
                variant="contained"
                loading={isLoading}
                label={onNextText ? onNextText : t`Next`}
                onClick={() => handleSubmit()}
                disabled={!formState.isValid}
                sx={{ mr: 1 }}
              />
            )}
          </Box>
        </FormProvider>
      </form>
    </Box>
  );
};

export default InspectionActivitiesForm;
