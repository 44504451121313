import { CoreBaseModel } from "@core/models/Base.model";
import { HCMModel } from "./HCMModel";

export class HCMActivityModel extends CoreBaseModel {
  id: number;
  month: number;
  year: number;
  created_at: string;
  updated_at: string;
  days: number;
  hcm_id: number;
  hcm?: HCMModel;
  vendor?: any;
  vendor_id?: number;
  model_type: string;

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
    this.model_type = "hcms_activities";
  }

  static getResourceName() {
    return "hcms_activities";
  }

  static getSubTypesList() {
    return [{ object: "hcm", model: HCMModel }];
  }
}
