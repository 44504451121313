import React, { useEffect, useRef } from "react";
import { Tab, Box, Tabs } from "@mui/material";
import ITabsMolecule, { ITabOptions } from "./interface";
import { useConfirm } from "material-ui-confirm";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import ButtonAtom from "@atoms/Button";
import { t } from "@lingui/macro";
import { resetFormsWarnings, saveAll } from "@stores/reducers/saverReducer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabsMolecule = ({
  tabsLabels,
  onChange,
  preselected,
  delayOnChange,
}: ITabsMolecule) => {
  const [value, setValue] = React.useState(preselected ? preselected : 0);
  const [nextTab, setNextTab] = React.useState(0);
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const dirtyNotSavedForms = useSelector((state: any) =>
    state.saver.forms.filter((form: any) => form.dirty && !form.saved),
  );
  const forms = useSelector((state: any) => state.saver.forms);
  const autoSaveStarted = useSelector((state: any) => state.saver.saveAll);
  const savedForms = useSelector((state: any) =>
    state.saver.forms.filter((form: any) => form.saved),
  );
  const savingForms = useSelector((state: any) =>
    state.saver.forms.filter((form: any) => form.saving),
  );

  const isMountRef = useRef(true);

  useEffect(() => {
    if (delayOnChange) {
      if (isMountRef.current) {
        isMountRef.current = false;
      } else {
        if (onChange) {
          onChange(tabsLabels[value]);
        }
      }
    } else {
      if (onChange) {
        onChange(tabsLabels[value]);
      }
    }
  }, [value]);

  useEffect(() => {
    if (
      savedForms.length &&
      forms.length &&
      autoSaveStarted.status &&
      autoSaveStarted.initiator === "tabs"
    ) {
      if (savedForms.length === forms.length) {
        dispatch(resetFormsWarnings());
        setValue(nextTab);
      }
    }
  }, [savedForms]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (savingForms.length) {
      confirm({
        title: "Wait, some data need to be saved",
        description: "Wait until the data has been saved",
        cancellationText: "",
        confirmationText: "Ok",
      })
        .then(() => {})
        .catch(() => {});
    } else if (dirtyNotSavedForms.length) {
      confirm({
        title: "Attention. Some data has not been saved",
        description: "If you decide to continue, the data will not be saved",
        cancellationText: t`Close without saving`,
        confirmationText: (
          <ButtonAtom
            variant="contained"
            color="success"
            label={t`Save and close`}
          />
        ),
      })
        .then(() => {
          dispatch(saveAll({ status: true, initiator: "tabs" }));
          setNextTab(newValue);
        })
        .catch(() => {
          dispatch(resetFormsWarnings());
          setValue(newValue);
        });
    } else {
      setValue(newValue);
    }
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginBottom: "15px",
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          {tabsLabels.map((tab: ITabOptions, index: number) => {
            return <Tab key={index} label={tab.label} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      {tabsLabels.map((tab: ITabOptions, index: number) => {
        if (tab.child) {
          return (
            <TabPanel key={index} value={value} index={index}>
              {tab.child}
            </TabPanel>
          );
        }
      })}
    </>
  );
};

export default TabsMolecule;
