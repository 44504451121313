import { Fragment, useEffect, useState } from "react";
import IOrganism, { IFormValues, formSchema } from "./interface";
import { Grid, Box, Alert } from "@mui/material";
import { Trans } from "@lingui/macro";
import { AutocompleteMolecule } from "@molecules/AsyncAutocomplete";
import { FormProvider, useForm } from "react-hook-form";
import TextFieldAtom from "@atoms/TextField";
import ButtonAtom from "@atoms/Button";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { yupResolver } from "@hookform/resolvers/yup";
import HCMService from "@services/HCMService";
import DateFieldMolecule from "@molecules/DateField";
import moment from "moment";
import { UserModel } from "@models/UserModel";
import { IDS } from "@helpers/constants";
import { useAppDispatch } from "@stores/hooks";
import { addForm, updateForm } from "@stores/reducers/saverReducer";
import { useSelector } from "react-redux";

const HCMCreateFormOrganism = ({
  hcm,
  onSave,
  onCancel,
  saveOnDestroy,
}: IOrganism) => {
  const [sErrors, setSErrors] = useState<any>({});
  const autoSave = useSelector((state: any) => state.saver.saveAll);
  const dispatch = useAppDispatch();

  const formInstance = useForm<IFormValues>({
    defaultValues: {
      type_id: hcm ? hcm.type?.id : null,
      client_id: hcm ? hcm.client?.id : null,
      user_id: hcm ? hcm.user?.id : null,
      vendor_id: hcm ? hcm.vendor?.id : null,
      from: hcm ? "" : `${moment().month() + 1}-01-${moment().year()}`,
      to: "",
      notes: "",
    },
    mode: "onChange",
    reValidateMode: "onSubmit",
    resolver: yupResolver(formSchema(hcm ? false : true)),
  });

  const {
    formState: { isValid, isDirty },
    getValues,
    handleSubmit: handleHookSubmit,
  } = formInstance;

  useEffect(() => {
    if (autoSave.status && isDirty) {
      handleSubmit(getValues());
    }
  }, [autoSave, getValues]);

  useEffect(() => {
    if (isDirty) {
      dispatch(
        addForm({
          slug: "hcm-detail-form",
          formState: formInstance.formState,
        }),
      );
    } else {
      dispatch(
        updateForm({
          slug: "hcm-detail-form",
          dirty: false,
          saved: true,
        }),
      );
    }
  }, [formInstance, isDirty]);

  const { mutate: hcmMutation, isLoading } = useMutation(
    (formValues: IFormValues) =>
      (hcm
        ? HCMService.put(formValues, hcm.id)
        : HCMService.create(formValues)
      ).then((res: any) => {
        if (!res.hasErrors()) {
          toast(res.getMsgString(), {
            type: "success",
          });
          if (onSave) {
            onSave(res.getData());
          }
          setTimeout(() => {
            dispatch(
              updateForm({
                slug: "hcm-detail-form",
                dirty: false,
                saved: true,
              }),
            );
          }, 500);
        } else {
          setSErrors(res.getErrors());
          toast(res.getMsgString(), {
            type: "error",
          });
        }
      }),
  );

  const handleSubmit = (data: IFormValues) => {
    const values = { ...data };
    values.to = moment(data.to).format("YYYY-MM");
    values.from = moment(data.from).format("YYYY-MM");
    hcmMutation(values);
  };

  return (
    <>
      <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
        <FormProvider {...formInstance}>
          <Box>
            <Grid container spacing={1}>
              <Grid item md={3}>
                <AutocompleteMolecule
                  control={formInstance.control}
                  controlName="client_id"
                  sError={sErrors ? sErrors["client_id"] : undefined}
                  listId={"insp-edit-clients"}
                  storeCollection="clients"
                  variant={"outlined"}
                  optionValue={"id"}
                  emptyValue={{ name: "" }}
                  getOptionLabel={(item: any) => `${item.name}`}
                  optionLabel={"name"}
                  label={
                    <Fragment>
                      <Trans>Client</Trans> *
                    </Fragment>
                  }
                ></AutocompleteMolecule>
              </Grid>
              <Grid item md={3}>
                <AutocompleteMolecule
                  control={formInstance.control}
                  controlName="user_id"
                  listId={"users-hcm"}
                  storeCollection="users"
                  sError={sErrors ? sErrors["user_id"] : undefined}
                  except={(user: UserModel) =>
                    user.role ? user.role.id !== IDS.ROLES.INSPECTOR : false
                  }
                  emptyValue={null}
                  variant={"outlined"}
                  optionValue={"id"}
                  getOptionLabel={(user: any) => `${user.full_name}`}
                  label={
                    <Fragment>
                      <Trans>Inspector</Trans> *
                    </Fragment>
                  }
                ></AutocompleteMolecule>
              </Grid>
              <Grid item md={3}>
                <AutocompleteMolecule
                  control={formInstance.control}
                  controlName="vendor_id"
                  listId={"vendors-hcm"}
                  sError={sErrors ? sErrors["vendor_id"] : undefined}
                  storeCollection="vendors"
                  emptyValue={null}
                  variant={"outlined"}
                  optionValue={"id"}
                  getOptionLabel={(vendor: any) => `${vendor.name}`}
                  label={
                    <Fragment>
                      <Trans>Vendor</Trans> *
                    </Fragment>
                  }
                ></AutocompleteMolecule>
              </Grid>
              <Grid item md={3}>
                <AutocompleteMolecule
                  control={formInstance.control}
                  controlName="type_id"
                  listId={"jobs-types"}
                  sError={sErrors ? sErrors["type_id"] : undefined}
                  storeCollection="types.jobs"
                  emptyValue={null}
                  variant={"outlined"}
                  optionValue={"id"}
                  getOptionLabel={(type: any) => `${type.name}`}
                  label={
                    <Fragment>
                      <Trans>Division</Trans> *
                    </Fragment>
                  }
                ></AutocompleteMolecule>
              </Grid>
            </Grid>
            {!hcm && (
              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <DateFieldMolecule
                      views={["year", "month"]}
                      control={formInstance.control}
                      sError={sErrors ? sErrors["from"] : undefined}
                      controlName={"from"}
                      maxDate={getValues().to}
                      inputFormat={"MMMM yyyy"}
                      label={<Trans>From</Trans>}
                    ></DateFieldMolecule>
                  </Grid>
                  <Grid item md={6}>
                    <DateFieldMolecule
                      views={["year", "month"]}
                      minDate={getValues().from}
                      control={formInstance.control}
                      sError={sErrors ? sErrors["to"] : undefined}
                      controlName={"to"}
                      inputFormat={"MMMM yyyy"}
                      label={<Trans>To</Trans>}
                    ></DateFieldMolecule>
                  </Grid>
                </Grid>
              </Box>
            )}
            {!hcm && (
              <Box mt={2}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <TextFieldAtom
                      controlName={"notes"}
                      variant={"outlined"}
                      minRows={3}
                      multiline={true}
                      label={<Trans>Notes</Trans>}
                    />
                    {sErrors && sErrors["notes"] ? (
                      <Alert severity="error" icon={false}>
                        {sErrors["notes"]}
                      </Alert>
                    ) : null}
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box className="flex-10 flex-end">
              {onCancel && (
                <Box mt={1} className="text-right">
                  <ButtonAtom
                    color={"error"}
                    onClick={onCancel}
                    label={<Trans>Cancel</Trans>}
                  />
                </Box>
              )}
              {!hcm ? (
                <Box mt={1} className="text-right">
                  <ButtonAtom
                    loading={isLoading}
                    label={<Trans>Create</Trans>}
                    disabled={!isValid}
                    type="submit"
                  />
                </Box>
              ) : (
                isDirty && (
                  <Box mt={1} className="text-right">
                    <ButtonAtom
                      loading={isLoading}
                      label={<Trans>Save</Trans>}
                      disabled={!isValid}
                      type="submit"
                    />
                  </Box>
                )
              )}
            </Box>
          </Box>
        </FormProvider>
      </form>
    </>
  );
};

export default HCMCreateFormOrganism;
