import { styled } from "@mui/system";
import Box from "@mui/material/Box";

export const StyledBody = styled(Box)(() => ({
  padding: "1.52rem",
  width: "100vw",
  maxWidth: "1200px",
  minHeight: "100%",
  height: "fit-content",
  "& i": {
    fontSize: "40px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
