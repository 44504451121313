import { Navigate } from "react-router-dom";
import { useUser } from "@hooks";

const IndexPage = () => {
  const user = useUser();

  return (
    <Navigate
      to={user.isInspector() ? "/inspector/activities" : "/activities"}
      replace
    />
  );
};

export default IndexPage;
