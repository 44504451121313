import * as Yup from "yup";

export default interface IJobsFilterOrganism {
  onFormChange: (data: object) => void;
  isHcm?: boolean;
}

export const schemaA = Yup.object().shape({
  job_type: Yup.number().nullable(),
  po_id: Yup.number().nullable(),
  client_id: Yup.number().nullable(),
  search: Yup.string().nullable(),
  category_id: Yup.number().nullable(),
  user_id: Yup.number().nullable(),
  vendor_id: Yup.number().nullable(),
});

export interface IFormValues {
  user_id: number | string;
  po_id: number | string;
  vendor_id: number | string;
  job_type: number | string;
  client_id: number | string;
  category_id: number | string;
  search: string;
}

export const JobFilterDefault = {
  user_id: "",
  po_id: "",
  vendor_id: "",
  job_type: 1,
  client_id: "",
  category_id: "",
  search: "",
};
