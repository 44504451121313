import { styled } from "@mui/system";

interface ILogoProps {}

export const StyledActivityRow = styled("div")((props: ILogoProps) => ({
  padding: "8px",
  fontSize: "13px",
  "& svg": {
    // color: 'rgb(204, 204, 204)'
  },
  "& p": {
    fontSize: "13px",
  },
  "& button": {
    fontSize: "13px",
  },
  "& .date *": {
    fontWeight: "bold",
    fontSize: "15px",
  },
  // marginBottom: ({ theme }: ILogoProps) => theme.spacing(3),
}));
