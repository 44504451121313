import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import AttributesService from "@services/AttributesService";
import SpinnerAtom from "@atoms/Spinner";
import IAssetAttributesGroupFormOrganism from "./interface";
import { FormProvider, useForm } from "react-hook-form";
import AttributesFormGroupMolecule from "@molecules/AttributesFormGroup";
//import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from "react-query";
import { AttributeModel } from "@models/AttributeModel";
import { addForm, updateForm } from "@stores/reducers/saverReducer";
import { useAppDispatch } from "@stores/hooks";
import { useSelector } from "react-redux";
import { PeopleModel } from "@models/PeopleModel";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const AttributesGroupFormOrganism = ({
  model,
  group,
  model_id,
  model_type,
  showTitle,
  onSave,
  personAssignedGroup,
}: IAssetAttributesGroupFormOrganism) => {
  const formInstance = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    // resolver: yupResolver(MakeResolver(group, group.attributes))
  });

  const {
    getValues,
    formState: { isDirty },
    handleSubmit: handleHookSubmit,
    watch,
  } = formInstance;
  const [success, setSuccess] = useState<boolean | null>(null);
  const dispatch = useAppDispatch();
  const autoSave = useSelector((state: any) => state.saver.saveAll);

  const appendFormValues = (formValues: any) => {
    const array = model.attributes.filter(
      (group: AttributeModel) => group.id !== formValues[0].id,
    );
    array.push(formValues[0]);
    return array;
  };

  useEffect(() => {
    if (isDirty) {
      dispatch(
        addForm({
          slug: `inspection-detail-${group.name}`,
          formState: formInstance.formState,
        }),
      );
    }
  }, [formInstance, isDirty]);

  useEffect(() => {
    if (autoSave.status && isDirty) {
      handleSubmit();
    }
  }, [autoSave]);

  let timeout: ReturnType<typeof setTimeout> | null = null;
  useEffect(() => {
    const watchAll = formInstance.watch((value, o) => {
      if (o.type) {
        // if there is a type then its coming from user action
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          handleSubmit();
        }, 100);
      }
    });
    return () => watchAll.unsubscribe();
  }, [watch, formInstance]);

  const { mutate: saveAttributesMutation, isLoading } = useMutation(
    (values: any) =>
      AttributesService.save(
        model_id,
        model_type,
        appendFormValues(values) /*values*/,
      )
        .then((res: any) => {
          dispatch(
            updateForm({
              slug: `inspection-detail-${group.name}`,
              dirty: false,
              saved: true,
              saving: false,
            }),
          );
          if (!res.hasErrors()) {
            // toast(res.getMsgString(), { type: res.hasErrors() ? 'error' : 'success' });
            setSuccess(true);
            if (onSave && !res.hasErrors()) {
              onSave(res.getData());
            }
          } else {
            setSuccess(false);
          }
        })
        .catch((e: any) => {
          setSuccess(false);
        }),
  );

  const handleSubmit = () => {
    setSuccess(null);
    let values: any = null;
    /**
     * TODO SPOSTA IN UN FOGLIO CENTRALIZZATO
     */
    if (Object.keys(getValues()).length) {
      values = [];
      let attributes: any = getValues()[group.alias].reduce(
        (prevVal: any, currVal: any) => {
          let array: any = [];
          // Get the fields array
          Object.keys(currVal).forEach((id: any) => {
            array.push({
              attribute_id: Number(id),
              value: currVal[id],
            });
          });
          prevVal.push(array);
          return prevVal;
        },
        [],
      );
      values.push({ id: group.id, attributes: attributes });
    }
    dispatch(
      updateForm({
        slug: `inspection-detail-${group.name}`,
        saving: true,
      }),
    );

    // Anticipate saving procedure to update the main attributes object of the model
    // in order to keep the form status always updated (the groups are saved all together, thus each group must be aware of the status of the other one)
    const a = { ...values };
    a[0].alias = group.alias;
    a[0].name = group.name;
    a[0].attributes[0].forEach((attr: any, index: number) => {
      let found = null;
      found = group.attributes.find(
        (att2: any) => att2.id === a[0].attributes[0][index].attribute_id,
      );
      a[0].attributes[0][index].type = found.type;
      a[0].attributes[0][index].label = found.label;
    });
    if (onSave) {
      onSave(new PeopleModel({ attributes: appendFormValues(a) }));
    }
    saveAttributesMutation(values);
  };

  return (
    <>
      {group ? (
        <React.Fragment>
          <Box mb={0}>
            <FormProvider {...formInstance}>
              <form noValidate onSubmit={handleHookSubmit(handleSubmit)}>
                <Grid container spacing={1}>
                  <Grid item md={10} xs={10}>
                    <Box>
                      <AttributesFormGroupMolecule
                        formInstance={formInstance}
                        formControl={formInstance.control}
                        personAssignedGroup={personAssignedGroup}
                        patch={true}
                        showTitle={showTitle}
                        group={group}
                      />
                    </Box>
                  </Grid>
                  <Grid item md={2} xs={2} className="align-bottom">
                    <Box
                      mt={0}
                      mb={2}
                      sx={{
                        textAlign: "right",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                    >
                      {isLoading ? <SpinnerAtom size={"18px"} /> : <></>}
                      {success === true && (
                        <CheckIcon fontSize="small" color="success" />
                      )}
                      {success === false && (
                        <ErrorOutlineIcon fontSize="small" color="error" />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </React.Fragment>
      ) : null}
    </>
  );
};

export default AttributesGroupFormOrganism;
