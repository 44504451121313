import { useState, useEffect } from "react";
import IOrganism from "./interface";
import {
  Box,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Tooltip,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChipAtom from "@atoms/Chip";
import { Trans, t } from "@lingui/macro";
import InspActivityFormOrganism from "./../ActivityForm";
import { ActivityModel } from "@models/ActivityModel";
import ActivitiesService from "@services/ActivitiesService";
import DatePrintAtom from "@atoms/DatePrint";
import CallIcon from "@mui/icons-material/Call";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Modal from "@molecules/Modal";
import LayoutModal from "@layouts/modal";
import ActivityChat from "@organisms/ActivityChat";
import VideoCallOrganism from "@organisms/VideoCall";
import SpinnerAtom from "@atoms/Spinner";
import DeleteIcon from "@mui/icons-material/Delete";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import { removeForm, updateForm } from "@stores/reducers/saverReducer";
import { useAppDispatch } from "@stores/hooks";
import ButtonAtom from "@atoms/Button";
import React from "react";

const ActivityAccordion = ({
  isExpanded,
  inspection,
  activityMinimal,
  onDelete,
  exceptDates,
  onSave,
  hideReportStatus,
}: IOrganism) => {
  const [detailActivity, setDetailActivity] = useState<
    ActivityModel | undefined
  >(undefined);
  const [selectedActivity, setSelectedActivity] =
    useState<ActivityModel | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [saveFormKey, setSaveFormKey] = useState<number>(0);
  const [expanded, setExpanded] = useState<boolean>(isExpanded ?? false);
  const [videoCall, setVideoCall] = useState<any | undefined>(undefined);
  const [videoCallFullscreen, setVideoCallFullscreen] = useState(false);
  const dispatch = useAppDispatch();
  const confirm = useConfirm();

  useEffect(() => {
    if (expanded) {
      ActivitiesService.get(activityMinimal.id).then((res: any) => {
        setDetailActivity(res.getData());
      });
    } else {
      setDetailActivity(undefined);
    }
  }, [expanded]);

  const openChat = (activity: ActivityModel, event: any) => {
    setSelectedActivity(activity);
    setModalOpen(true);
    event?.stopPropagation();
  };

  const openVideoChat = (activity: ActivityModel, event: any) => {
    setSelectedActivity(activity);
    // setVideoCall(true);
    event?.stopPropagation();
  };

  const closeVideChatDrawer = () => {
    setSelectedActivity(null);
    setVideoCall(false);
  };

  const deleteActivity = (event: any) => {
    event.stopPropagation();
    confirm({
      title: "Are you sure you want to delete this element?",
      description: "The action is irreversible!",
      cancellationText: "Go back",
      confirmationText: "Delete",
    }).then(() => {
      ActivitiesService.delete(activityMinimal.id).then((res: any) => {
        if (!res.hasErrors()) {
          if (onDelete) {
            onDelete();
          }
        }
        toast(res.getMsgString(), {
          type: res.hasErrors() ? "error" : "success",
        });
      });
    });
  };

  useEffect(() => {
    if (!expanded) {
      dispatch(
        removeForm({
          slug: `inspection-activity-detail-${activityMinimal.id}`,
        }),
      );
    }
  }, [expanded]);

  const handleOnDirty = (formIsDirty: boolean) => {
    setFormIsDirty(formIsDirty);
    // TODO rates salva il singolo
  };

  const handleOnSave = (param: any) => {
    if (saveFormKey) {
      setExpanded(false);
    }
    if (onSave) {
      onSave(param);
    }
  };

  const handleExpansion = (expanded: boolean) => {
    if (formIsDirty && detailActivity) {
      confirm({
        title: "Attention. Some data has not been saved",
        description: "If you decide to continue, the data will not be saved",
        cancellationText: t`Close without saving`,
        confirmationText: (
          <ButtonAtom
            variant="contained"
            color="success"
            label={t`Save and close`}
          />
        ),
      })
        .then(() => {
          setSaveFormKey(saveFormKey + 1);
        })
        .catch(() => {
          dispatch(
            updateForm({
              slug: `inspection-activity-detail-${detailActivity.id}`,
              saved: true,
            }),
          );
          setExpanded(expanded);
        });
    } else {
      setExpanded(expanded);
    }
  };

  return (
    <>
      <Modal open={modalOpen} set_open={setModalOpen}>
        <LayoutModal title={<Trans>Chat</Trans>}>
          {selectedActivity && (
            <ActivityChat activity={selectedActivity}></ActivityChat>
          )}
        </LayoutModal>
      </Modal>
      <Modal
        fullscreen={videoCallFullscreen}
        open={videoCall && selectedActivity ? true : false}
        set_open={closeVideChatDrawer}
      >
        <LayoutModal
          title={
            <Box>
              Call {selectedActivity ? selectedActivity.user?.full_name : ""}
            </Box>
          }
        >
          {videoCall && (
            <Box>
              <VideoCallOrganism
                onHangUp={closeVideChatDrawer}
                isFullScreen={videoCallFullscreen}
                onRequestFullscreen={() =>
                  setVideoCallFullscreen(videoCallFullscreen ? false : true)
                }
                activity={selectedActivity}
              />
            </Box>
          )}
        </LayoutModal>
      </Modal>
      <Box id={`${activityMinimal.id}`}></Box>
      <Accordion
        disableGutters
        expanded={expanded}
        onChange={(event: any, expanded: boolean) => handleExpansion(expanded)}
        defaultExpanded={expanded ?? false}
        elevation={0}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box className="align-center">
              <span>
                <Typography variant="h6">
                  <b>
                    <DatePrintAtom format="DD/MM/yyyy">
                      {activityMinimal.date}
                    </DatePrintAtom>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {activityMinimal.start_time
                      ? activityMinimal.startTime()
                      : "--"}
                    &nbsp;-&nbsp;
                    {activityMinimal.end_time
                      ? activityMinimal.endTime()
                      : "--"}
                  </b>
                </Typography>
              </span>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box className="align-center" sx={{ paddingRight: "10px" }}>
                <ChipAtom
                  variant="filled"
                  label={activityMinimal.report_status.name}
                  color={activityMinimal.report_status.class}
                ></ChipAtom>
              </Box>
              <Divider orientation="vertical" />
              <Box sx={{ paddingLeft: "10px" }}>
                <Tooltip title="Chat" placement="top">
                  <IconButton
                    onClick={(event: any) => openChat(activityMinimal, event)}
                  >
                    <ChatBubbleOutlineIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Call" placement="top">
                  <IconButton
                    onClick={(event: any) =>
                      openVideoChat(activityMinimal, event)
                    }
                  >
                    <CallIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement="top">
                  <IconButton
                    color="error"
                    onClick={(event: any) => deleteActivity(event)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {detailActivity ? (
            <InspActivityFormOrganism
              inspection={inspection}
              hideReportStatus={hideReportStatus}
              onSave={handleOnSave}
              saveFormKey={saveFormKey}
              onDirty={handleOnDirty}
              exceptDates={exceptDates}
              activity={detailActivity}
            />
          ) : (
            <SpinnerAtom />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default React.memo(ActivityAccordion);
