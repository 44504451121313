import { useRef, useEffect } from "react";
import { StyledBoxTotal, StyledPaper } from "./styled";
import { Typography } from "@mui/material";
import IBoxTotalAtom from "./interface";
import { gsap } from "gsap";

const BoxTotalAtom = ({ label, totalInteger }: IBoxTotalAtom) => {
  const boxRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      boxRef.current,
      {
        scale: 0,
        opacity: 0,
      },
      {
        scale: 1,
        opacity: 1,
        duration: 1,
        delay: 0.1,
        ease: "expo",
      },
    );
  }, [boxRef]);

  return (
    <>
      <StyledPaper ref={boxRef} variant="outlined">
        <StyledBoxTotal>
          <Typography color="info" className="title">
            {label}
          </Typography>
          <Typography fontSize={20} className="number">
            <b>{totalInteger}</b>
          </Typography>
        </StyledBoxTotal>
      </StyledPaper>
    </>
  );
};

export default BoxTotalAtom;
