import { useState, Fragment, useCallback, useMemo } from "react";
import { Trans, t } from "@lingui/macro";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import isEqual from "lodash/isEqual";
import type { ICategoryOption } from "@interfaces/ICategory";
import type { ITypeOption } from "@interfaces/IType";
import type { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import type { ISelectedFilterItem } from "@molecules/SelectedFilter/interface";
import type { IAssetsFilterValues } from "./interface";
import { AssetsModel } from "@models/AssetsModel";
import { useAppSelector } from "@stores/hooks";
import {
  filtersCategoriesSelector,
  filtersTypeSelector,
} from "@stores/selectors/filtersSelector";
import { assetsFilterInitialValues } from "./data";
import ContainerAtom from "@atoms/Container";
import { Grid, Stack, Button, Typography, Drawer } from "@mui/material";
import LayoutBase from "@layouts/base";
import BoxTotalAtom from "@atoms/BoxTotal";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@molecules/Modal";
import AssetCreateOrganisms from "@organisms/AssetCreate";
import LayoutModal from "@layouts/modal";
import PaginatedListMolecule from "@molecules/PaginatedList";
import AssetRowMolecule from "@molecules/AssetRow";
import AssetDetailOrganism from "@organisms/AssetDetail";
import AssetsService from "@services/AssetsService";
import DrawerLayout from "@layouts/drawer";
import AssetsFilterOrganism from "@organisms/AssetsFilter";
import SelectedFilterMolecule from "@molecules/SelectedFilter";
import { PageHeader, StyledGridItem, StyledHeaderContainer } from "./styled";
import { useConfirm } from "material-ui-confirm";
import { deleteItem } from "@stores/reducers/filtersReducer";
import { useAppDispatch } from "@stores/hooks";

const getTotalsLabel = (
  totalKey: "all" | "available" | "expiring" | string,
) => {
  switch (totalKey) {
    case "all":
      return t`All`;

    case "available":
      return t`Available`;

    case "expiring":
      return t`Expiring`;

    default:
      return totalKey;
  }
};

const AssetsPage = () => {
  const dispatch = useAppDispatch();
  const categoriesData = useAppSelector(filtersCategoriesSelector("assets"));
  const typesData = useAppSelector(filtersTypeSelector("assets"));
  const [filter, setFilter] = useState<IAssetsFilterValues>(
    assetsFilterInitialValues,
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [drawerDetailID, setDrawerDetailID] = useState<any>({});
  const [state, setState] = useState({ right: false });
  const [totals, setTotals] = useState<any>({});
  const [updatedItem, setUpdated] = useState<AssetsModel | undefined>(
    undefined,
  );
  const [newRow, setNewRows] = useState<AssetsModel | undefined>(undefined);
  const confirm = useConfirm();
  const isNameChanged = !isEqual(
    filter.search,
    assetsFilterInitialValues.search,
  );
  const isCategoryChanged = !isEqual(
    filter.category_id,
    assetsFilterInitialValues.category_id,
  );
  const isTypeChanged = !isEqual(
    filter.type_id,
    assetsFilterInitialValues.type_id,
  );
  const isAvailableChanged = !isEqual(
    filter.available,
    assetsFilterInitialValues.available,
  );
  const [toDelete, setToDeleteFromList] = useState<number | undefined>(
    undefined,
  );

  const categories = useMemo((): ICategoryOption[] => {
    return categoriesData.map((category) => ({
      ...category,
      value: category.id,
      label: category.name,
    }));
  }, [categoriesData]);

  const types = useMemo((): ITypeOption[] => {
    return typesData.map((type) => ({
      ...type,
      value: type.id,
      label: type.name,
    }));
  }, [typesData]);

  const toggleDrawer =
    (anchor: any, open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  useQuery(
    "assets-total",
    () =>
      AssetsService.getTotals().then((res: any) => {
        setTotals(res.getData());
      }),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  const handleActionSelect = (param: IMBOption, asset: AssetsModel) => {
    if (param.label === "Details") {
      setDrawerDetailID(param.data.assetId);
      setState({ ...state, right: true });
    } else if (param.label === "Delete") {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        AssetsService.delete(param.data.assetId).then((res: any) => {
          toast(res.getMsgString(), {
            type: res.hasErrors() ? "error" : "success",
          });
          if (!res.hasErrors()) {
            setToDeleteFromList(param.data.assetId);
            // remove from store
            dispatch(
              deleteItem({
                what: "assets",
                id: param.data.assetId,
              }),
            );
          }
        });
      });
    }
  };

  const handleDeleteFilter = useCallback((key: string | "all") => {
    switch (key) {
      case "all":
        setFilter(assetsFilterInitialValues);
        break;

      default:
        setFilter((prevState) => ({
          ...prevState,
          [key]: assetsFilterInitialValues[key as keyof IAssetsFilterValues],
        }));
    }
  }, []);

  const filterSelections = useMemo((): ISelectedFilterItem[] => {
    return [
      {
        label: `${t`Name`}: ${filter.search}`,
        value: "search",
        isChanged: isNameChanged,
      },
      {
        label: `${t`Category`}: ${
          (
            categories.find(
              (category) => category.id === filter.category_id,
            ) || { name: "" }
          ).name
        }`,
        value: "category_id",
        isChanged: isCategoryChanged,
      },
      {
        label: `${t`Make`}: ${
          (
            types.find((type) => type.id === filter.type_id) || {
              name: "",
            }
          ).name
        }`,
        value: "type_id",
        isChanged: isTypeChanged,
      },
      {
        label: `${t`Available`}: ${
          filter.available ? t`available` : t`not available`
        }`,
        value: "available",
        isChanged: isAvailableChanged,
      },
    ];
  }, [
    filter,
    categories,
    types,
    isAvailableChanged,
    isCategoryChanged,
    isNameChanged,
    isTypeChanged,
  ]);

  return (
    <>
      <Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          <DrawerLayout onCloseClick={() => setState({ right: false })}>
            <AssetDetailOrganism onUpdate={setUpdated} id={drawerDetailID} />
          </DrawerLayout>
        </Drawer>
      </Fragment>
      <LayoutBase>
        <ContainerAtom>
          <PageHeader>
            <Typography variant="h1">
              <Trans>Assets</Trans>
            </Typography>
            <Button onClick={() => setModalOpen(true)} variant="outlined">
              <AddIcon></AddIcon> <Trans>New asset</Trans>
            </Button>
          </PageHeader>
          <Modal open={modalOpen} set_open={setModalOpen}>
            <LayoutModal title={<Trans>New asset</Trans>}>
              <AssetCreateOrganisms
                onNewAsset={(asset: AssetsModel) => setNewRows(asset)}
              />
            </LayoutModal>
          </Modal>
          <Grid my={2} container spacing={1}>
            {Object.keys(totals).map((totalKey, index) => (
              <Grid key={index} item md={totalKey !== "total" ? 4 : 4} xs={6}>
                <BoxTotalAtom
                  totalInteger={totals[totalKey]}
                  label={getTotalsLabel(totalKey)}
                />
              </Grid>
            ))}
          </Grid>
          <Stack mb={2} direction="row" spacing={2} alignItems="center">
            <SelectedFilterMolecule
              items={filterSelections}
              handleDelete={handleDeleteFilter}
            />
          </Stack>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <AssetsFilterOrganism filter={filter} setFilter={setFilter} />
            </Grid>
            <Grid item md={9} xs={12}>
              {filter && (
                <PaginatedListMolecule
                  service={AssetsService}
                  newRow={newRow}
                  updated={updatedItem}
                  idToDelete={toDelete}
                  perPage={15}
                  filter={filter}
                  renderFunc={(item: AssetsModel) => {
                    return (
                      <AssetRowMolecule
                        asset={item}
                        onActionSelected={handleActionSelect}
                      />
                    );
                  }}
                  header={
                    <StyledHeaderContainer sx={{ paddingY: "10px" }}>
                      <Grid container>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Available</Trans>
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Name</Trans>
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Category</Trans>
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Make</Trans>
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}>
                          <Trans>Department</Trans>
                        </StyledGridItem>
                        <StyledGridItem item md={2} xs={12}></StyledGridItem>
                      </Grid>
                    </StyledHeaderContainer>
                  }
                />
              )}
            </Grid>
          </Grid>
        </ContainerAtom>
      </LayoutBase>
    </>
  );
};

export default AssetsPage;
