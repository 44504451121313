import { InspectionModel } from "@models/InspectionModel";
import { t } from "@lingui/macro";
import { JobModel } from "@models/JobModel";

export default interface IInspectionActivityCreateOrganism {
  mode?: string;
  job?: JobModel;
  onNewInspection?: (param: InspectionModel) => void;
}

export const Steps = [
  t`Job`,
  t`Inspection`,
  t`Notes/description`,
  t`Activities`,
  t`Rates`,
];
