import IMessage from "@interfaces/IMessage";

export class RChatModel {
  messages: Array<IMessage>;
  isOpen: boolean;
  toRead: number;
  activity: any;
  activity_id: number;

  constructor(data: any) {
    if (data) {
      Object.assign(this, data);
    }
  }
}
