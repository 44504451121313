import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import RatesService from "@services/RatesService";
import { RateModel } from "@models/RateModel";
import InspectionRatesForm from "@organisms/InspectionCreate/Rates";
import { toast } from "react-toastify";
import { t } from "@lingui/macro";

const InspectionDetailsRates = ({ inspection, ifZero }: any) => {
  const [clientRateData, setClientRateData] = useState<any>({});
  const [supplierRateData, setSupplierRateData] = useState<any>({});

  const { isLoading: loadingClientRate, data: rateData } = useQuery(
    `activity-rates-${inspection.id}`,
    () =>
      RatesService.get("", {
        model_id: inspection.id,
        model_type: "inspections",
      }).then((res: any) => res),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (!loadingClientRate && rateData) {
      const clientRate = rateData.data.find(
        (record: RateModel) => record.type.alias === "client",
      );
      const supplierRate = rateData.data.find(
        (record: RateModel) => record.type.alias === "supplier",
      );
      setClientRateData(clientRate ?? {});
      setSupplierRateData(supplierRate ?? {});
      const checkIsZero = !clientRate && !supplierRate ? true : false;
      if (ifZero) {
        ifZero(checkIsZero);
      }
    }
  }, [loadingClientRate, rateData]);

  const handleSuccess = () => {
    toast(t`Rate updated successfully`, { type: "success" });
  };

  const handleError = () => {
    toast(
      t`An error occurred while saving the data. Check the fields and retry`,
      { type: "error" },
    );
  };

  return (
    <>
      {!loadingClientRate && (
        <InspectionRatesForm
          clientRecord={clientRateData}
          isInd={true}
          onNext={handleSuccess}
          onError={handleError}
          supplierRecord={supplierRateData}
          inspection={inspection}
        />
      )}
    </>
  );
};

export default InspectionDetailsRates;
