import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import Grid from "@mui/material/Grid";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IAccordionGroupInterface from "./interface";
import { Trans } from "@lingui/macro";
import { StyledAttributesBox } from "./styled";
import AttributesGroupFormOrganism from "@organisms/AttributesGroupForm";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { PeopleModel } from "@models/PeopleModel";
import { AssetsModel } from "@models/AssetsModel";
import DatePrintAtom from "@atoms/DatePrint";
import { InspectionModel } from "@models/InspectionModel";
import { updateForm } from "@stores/reducers/saverReducer";
import { useAppDispatch } from "@stores/hooks";

const AccordionOrganism = ({
  model_type,
  group,
  groupIndex,
  personAssignedGroup,
  modelData,
  onGroupModified,
  onGroupChange,
}: IAccordionGroupInterface) => {
  const [attrToEdit, setAttrToEdit] = useState<
    PeopleModel | AssetsModel | InspectionModel | null
  >(null);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [data, setData] = useState<PeopleModel | null>(null);
  const dispatch = useAppDispatch();

  const accordionChangeStatus = (expanded: boolean) => {
    setExpanded(expanded);
  };

  const editGroup = (e: any, groupInput: any) => {
    if (!groupInput) {
      dispatch(
        updateForm({
          slug: `inspection-detail-${group.name}`,
          saved: true,
        }),
      );
      if (onGroupModified) {
        if (data) {
          onGroupModified(data);
        }
      }
    }
    setAttrToEdit(groupInput);
    if (groupInput && !expanded) {
      return 0;
    } else {
      // any case stop propagation
      return e.stopPropagation();
    }
  };

  const getSelectLabelByValue = (
    personAttribute: any,
    groupConfiguration: any,
  ) => {
    const attributesFound = groupConfiguration.attributes.find(
      (attribute: any) => attribute.id === personAttribute.attribute_id,
    );
    if (attributesFound) {
      const selectedOption = attributesFound.options.find(
        (option: any) => Number(option.id) === Number(personAttribute.value),
      );
      if (selectedOption) {
        return selectedOption.label;
      } else return "--";
    }
    return "--";
  };

  const handleOnSave = (dataIn: any) => {
    if (onGroupChange) {
      onGroupChange(dataIn);
    }
    /*
		if(onGroupModified) {
			onGroupModified(dataIn);
		}
		*/
    setData(dataIn);
    // setAttrToEdit(null);
  };

  return (
    <>
      <StyledAttributesBox key={groupIndex}>
        <Accordion disableGutters={true} expanded={expanded} elevation={0}>
          <AccordionSummary
            onClick={() => accordionChangeStatus(expanded ? false : true)}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${groupIndex}a-content`}
            id={`panel${groupIndex}a-header`}
          >
            <Typography variant="h5">
              <b>{group.name}</b>
            </Typography>
            <Box sx={{ textAlign: "right", fontSize: "12px" }}>
              {attrToEdit && attrToEdit.id === group.id ? (
                <Tooltip title={<Trans>Cancel</Trans>} placement="top">
                  <IconButton onClick={(e: any) => editGroup(e, null)}>
                    <CancelIcon></CancelIcon>
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    <React.Fragment>
                      <Trans>Modify the</Trans> {group.name}
                    </React.Fragment>
                  }
                  placement="top"
                >
                  <IconButton onClick={(e: any) => editGroup(e, group)}>
                    <EditIcon color="success"></EditIcon>
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              {!attrToEdit || attrToEdit.id !== group.id ? (
                personAssignedGroup.length ? (
                  personAssignedGroup.map(
                    (attributesGroup: any, indexG: number) => {
                      return (
                        <Grid container key={"cc-" + indexG}>
                          {attributesGroup.map(
                            (attribute: any, itemIndex: number) => {
                              return (
                                <Grid item mt={2} md={3} xs={3} key={itemIndex}>
                                  <Typography>
                                    <b>{attribute.label}</b>
                                  </Typography>
                                  {attribute.type.alias === "select" && (
                                    <Typography>
                                      {getSelectLabelByValue(attribute, group)}
                                    </Typography>
                                  )}
                                  {attribute.type.alias === "text" && (
                                    <Typography>
                                      {attribute.value ?? "--"}
                                    </Typography>
                                  )}
                                  {attribute.type.alias === "date" && (
                                    <Box>
                                      {attribute.value ? (
                                        <DatePrintAtom format="DD/MM/yyyy">
                                          {attribute.value}
                                        </DatePrintAtom>
                                      ) : (
                                        "--"
                                      )}
                                    </Box>
                                  )}
                                </Grid>
                              );
                            },
                          )}
                        </Grid>
                      );
                    },
                  )
                ) : (
                  <Box mb={1}>
                    <Alert severity="warning">
                      <Trans>There are no attributes set for this group</Trans>
                    </Alert>
                  </Box>
                )
              ) : null}
            </Box>
            {attrToEdit && attrToEdit.id === group.id ? (
              <AttributesGroupFormOrganism
                showTitle={false}
                model_id={modelData.id}
                model_type={model_type}
                onSave={handleOnSave}
                model={modelData}
                personAssignedGroup={personAssignedGroup}
                group={group}
              />
            ) : null}
          </AccordionDetails>
        </Accordion>
      </StyledAttributesBox>
    </>
  );
};

export default AccordionOrganism;
