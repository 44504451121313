/**
 * credits in CREDITS.txt
 */

import { StyledContainer } from "./styled";

const ContainerAtom = ({ children }: any) => {
  return <StyledContainer>{children}</StyledContainer>;
};

export default ContainerAtom;
