import * as Yup from "yup";
import { t } from "@lingui/macro";

const createSchema = () =>
  Yup.object().shape({
    type_id: Yup.number().required(t`Necessaria`),
    category_id: Yup.number().required(t`Necessaria`),
    label: Yup.string().required(t`Necessaria`),
    // code_id: Yup.string().required(t`Necessaria`),
    notes: Yup.string().nullable().notRequired(),
    client_id: Yup.number()
      .min(1, t`Required`)
      .required()
      .when("is_new", {
        is: true,
        then: Yup.number().min(0).nullable().notRequired(),
      }),
    is_new: Yup.boolean(),
    client_name: Yup.mixed()
      .nullable()
      .notRequired()
      .when("is_new", {
        is: true,
        then: Yup.string().min(1, "Required").required(),
      }),
    client_email: Yup.mixed()
      .nullable()
      .notRequired()
      .when("is_new", {
        is: true,
        then: Yup.string().email().min(1, "Required").required(),
      }),
    client_country: Yup.mixed()
      .nullable()
      .notRequired()
      .when("is_new", {
        is: true,
        then: Yup.number().min(1, "Required").required(),
      }),
  });

export default createSchema;
