import { forwardRef } from "react";
import { styled, keyframes } from "@mui/material/styles";
import Box, { BoxProps } from "@mui/material/Box";

const progress = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -70px 0;
  }
`;

interface IContainerProps extends BoxProps {
  isDragActive: boolean;
}

export const Container = styled(
  forwardRef<HTMLDivElement, IContainerProps>(
    ({ isDragActive, ...restProps }, ref) => <Box {...restProps} ref={ref} />,
  ),
)<IContainerProps>(({ isDragActive }) => {
  const dragActiveStyles = {
    border: "solid",
    animation: `${progress} 2s linear infinite !important`,
    backgroundSize: "150% 100%",
    backgroundImage:
      "repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)",
  };

  return {
    width: "100%",
    minHeight: "250px",
    border: "dashed",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#C8C8C8",
    backgroundColor: "#F0F0F0",
    boxSizing: "border-box",
    cursor: "pointer",
    ...(isDragActive ? dragActiveStyles : {}),
  };
});

export const TextContainer = styled(Box)(() => ({
  textAlign: "center",

  p: {
    fontSize: "1.2rem",
  },

  svg: {
    fontSize: "3rem",
  },
}));
