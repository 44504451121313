import { CoreBaseService } from "@core/services/Base.service";

// Reference model
import { NotificationModel } from "@models/NotificationModel";

class NotesService extends CoreBaseService {
  constructor() {
    super(NotificationModel);
  }

  getToRead(filter: any = {}) {
    return this.sub("to_read").get("", filter);
  }

  readAll() {
    return this.sub("read_index").update(null, -1);
  }
}

const inst = new NotesService();

export default inst;
