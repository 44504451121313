import { useState, Fragment } from "react";
import IOrganism from "./interface";
import { Box, Button, Card } from "@mui/material";
import { InspectionModel } from "@models/InspectionModel";
import InspectionRow from "./InspectionRow";
import InspectionsService from "@services/InspectionsService";
import { t, Trans } from "@lingui/macro";
import NewInspectionActivityOrganism from "@organisms/InspectionCreate";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import { IMBOption } from "@molecules/MultiButtonMolecule/interface";
import PaginatedListMolecule from "@molecules/PaginatedList";
import { useConfirm } from "material-ui-confirm";

const JobDetailsInspectionsOrganism = ({ job, onMod }: IOrganism) => {
  const [wizardVisible, setWizardVisible] = useState<boolean>(false);
  const [newRows, setNewRows] = useState<Array<InspectionModel>>([]);
  const [filter /*, setFilter*/] = useState<any>({ job_id: job.id });
  // const [ paginationKey, setPaginationKey ] = useState<number>(0);
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);
  const confirm = useConfirm();

  const handleNewInspection = () => {
    setWizardVisible(wizardVisible ? false : true);
  };

  const onInspectionCreated = (inspection: InspectionModel) => {
    InspectionsService.get(inspection.id).then((res: any) => {
      setNewRows(res.getData());
    });
    if (onMod) {
      onMod();
    }
    toast(t`Inspection successfully created`, { type: "success" });
    setWizardVisible(false);
  };

  const handleRowAction = (param: IMBOption) => {
    if (param.label === "Copy") {
      InspectionsService.copyInspection(param.data.inspection.id).then(
        (res: any) => {
          toast(t`Inspection successfully copied`, {
            type: "success",
          });
          setNewRows(res.getData());
          if (onMod) {
            onMod();
          }
        },
      );
    } else if (param.label === "Delete") {
      confirm({
        title: "Are you sure you want to delete this element?",
        description: "The action is irreversible!",
        cancellationText: "Go back",
        confirmationText: "Delete",
      }).then(() => {
        InspectionsService.delete(param.data.inspection.id).then((res: any) => {
          if (!res.hasErrors()) {
            toast(t`Inspection successfully deleted`, {
              type: "success",
            });
            // setPaginationKey(paginationKey + 1);
            setIdToDelete(param.data.inspection.id);
            if (onMod) {
              onMod();
            }
          } else {
            toast(res.getMsgString(), { type: "error" });
          }
        });
      });
    }
  };

  return (
    <>
      <Box className="text-center" mb={1}>
        <Button
          sx={{ margin: "0 auto" }}
          color={wizardVisible ? "error" : "info"}
          variant={"contained"}
          className="align-center"
          onClick={handleNewInspection}
        >
          {wizardVisible ? (
            <Fragment>
              <ClearIcon fontSize="small" />
              &nbsp;<Trans>Cancel</Trans>
            </Fragment>
          ) : (
            <Fragment>
              <AddIcon fontSize="small" />
              &nbsp;<Trans>Add inspection</Trans>
            </Fragment>
          )}
        </Button>
      </Box>
      {wizardVisible && (
        <Card className="ov-visible">
          <Box p={1}>
            <NewInspectionActivityOrganism
              job={job}
              mode="fast"
              onNewInspection={onInspectionCreated}
            />
          </Box>
        </Card>
      )}
      <Box mt={3}>
        <PaginatedListMolecule
          renderFunc={(inspection: InspectionModel) => {
            return (
              <Box mb={1}>
                <InspectionRow
                  onActionSelected={handleRowAction}
                  inspection={inspection}
                />
              </Box>
            );
          }}
          idToDelete={idToDelete}
          service={InspectionsService}
          scrollPagination={document.querySelector(
            ".MuiDrawer-paperAnchorRight",
          )}
          perPage={15}
          newRow={newRows}
          filter={filter}
        />
      </Box>
    </>
  );
};

export default JobDetailsInspectionsOrganism;
