import { memo } from "react";
import type { IDocumentRowItemProps } from "./interface";
import { t } from "@lingui/macro";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import LightTooltip from "@atoms/LightTooltip";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import { useUser } from "@hooks";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const ReportRowItem = ({
  isSelected,
  item,
  toggleSelect,
  cant_delete,
  handleDownload,
  handleDelete,
  handleView,
}: IDocumentRowItemProps) => {
  const user = useUser();
  const extension: string | undefined = item.file.split(".").pop();
  const viewAllowedExtensions = ["jpeg", "jpg", "png", "bmp", "gif", "pdf", "ppt", "pptx", "xlsx", "doc", "docx", "xls"];

  return (
    <TableRow selected={isSelected} hover key={item.id}>
      <TableCell sx={{ width: "100%", padding: "0 18px" }}>
        <Tooltip title={item.file}>
          <Box
            sx={{
              whiteSpace: "nowrap",
              maxWidth: { xs: "200px", md: "300px" },
              textOverflow: "ellipsis",
              paddingBottom: "6px",
              overflow: { xs: "hidden", md: "hidden" },
            }}
          >
            {item.file}
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell sx={{ pr: 1, display: "flex" }} size="small">
        {user.can("documents.download") && (
          <LightTooltip title={t`Download`} followCursor>
            <IconButton
              aria-label={t`download`}
              onClick={() => handleDownload(item)}
            >
              <DownloadIcon />
            </IconButton>
          </LightTooltip>
        )}
        {handleView &&
          extension &&
          viewAllowedExtensions.includes(extension) && (
            <LightTooltip title={item.sharepoint_url ? t`View on Sharepoint` : t`View`} followCursor>
              <IconButton aria-label={t`view`} onClick={() => handleView(item)}>
                <RemoveRedEyeIcon />
              </IconButton>
            </LightTooltip>
          )}
        {user.can("documents.delete") && !cant_delete && (
          <LightTooltip title={t`Delete`} followCursor>
            <IconButton
              color="error"
              aria-label={t`delete`}
              onClick={() => handleDelete(item)}
            >
              <DeleteIcon />
            </IconButton>
          </LightTooltip>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(ReportRowItem);
