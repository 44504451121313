import { CoreBaseModel } from "@core/models/Base.model";
import { IType } from "@interfaces/IType";
import StatusLogModel from "@models/StatusLogModel";
import { ActivityModel } from "./ActivityModel";
import { VendorModel } from "./VendorModel";

export class InspectionModel extends CoreBaseModel {
  date: string;
  asset: any;
  asset_id: number;
  attributes: Array<any>;
  client_job_id: string;
  created_at: string;
  project_label: string;
  sharepoint_dir?: string;
  customer: any;
  category: any;
  activity_category_id: number;
  category_id: number;
  customer_id: number;
  start_time: string;
  pos?: any;
  has_pos_dir: string;
  country: IType;
  user_invoice: any;
  end_time: string;
  status_logs: Array<StatusLogModel>;
  id: number;
  is_purchase: boolean;
  total_activities: number;
  subcontractor: any;
  next_activity: ActivityModel;
  labels: Array<any>;
  po: Array<any>;
  inspection: any;
  job_id: number;
  job: any;
  confirmation: any;
  user: any;
  partner: any;
  partner_id: number;
  type: any;
  type_id: number;
  status: any;
  status_id: number;
  updated_at: string;
  user_id: number;
  vendor: VendorModel;
  subvendor_id: number;
  subvendor: VendorModel;
  address?: any;
  full_address?: string;
  activity_category?: any;
  next_status: {
    class:
      | "inherit"
      | "primary"
      | "secondary"
      | "success"
      | "error"
      | "info"
      | "warning"
      | undefined;
    id: number;
    name: string;
    evaluation: boolean;
    alias: string;
  };

  constructor(data: any) {
    super();
    if (data) {
      Object.assign(this, data);
    }
  }

  static getResourceName() {
    return "inspections";
  }

  static getSubTypesList() {
    return [
      { object: "status_logs", model: StatusLogModel },
      { object: "next_activity", model: ActivityModel },
    ];
  }

  readableDate(format = "dd/mm/yy") {
    const date = new Date(this.date);
    format = format.replace("dd", String(date.getDate()));
    format = format.replace("mm", String(date.getMonth()));
    format = format.replace("yy", String(date.getFullYear()));
    return format;
  }

  checkedIn() {
    return this.status.alias === "checkin";
  }

  lastCheckin(what: "created_at" | "id"): string | number | undefined {
    const log = this.status_logs.find(
      (log: StatusLogModel) => log.status.alias === "checkin",
    );
    if (what && log) {
      return log[what];
    } else {
      return undefined;
    }
  }

  nextStatus() {
    return this.next_status;
  }

  totalRelActivities() {
    return this.total_activities;
  }

  startTime() {
    return this.start_time.split(":", 2).join(":");
  }

  endTime() {
    return this.end_time.split(":", 2).join(":");
  }

  notifications(as: string = "", truncate: number = 0) {
    if (as === "string") {
      if (this.inspection && this.inspection.labels) {
        const output = this.inspection.labels
          .map((label: any) => label.name)
          .join(", ");
        if (truncate) {
          return output.length > truncate
            ? output.substring(0, truncate) + "..."
            : output;
        } else {
          return output;
        }
      } else {
        return "";
      }
    } else {
      return this.inspection
        ? this.inspection.labels
          ? this.inspection.labels
          : []
        : [];
    }
  }

  getAttributes(alias?: string) {
    if (alias && Array.isArray(this.attributes)) {
      const group = this.attributes.find((group: any) => group.alias === alias);
      if (group) return group.attributes;
      return [];
    } else {
      return this.attributes ? this.attributes : [];
    }
  }

  isUnique() {
    return this.type.alias === "unique";
  }

  isDaily() {
    return this.type.alias === "daily";
  }
}
