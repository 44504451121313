import IOrganism from "./interface";
import AttributesOrganism from "@organisms/AttributesOrganism";

const InspectionDetailQualityTabOrganism = ({
  inspection,
  onSave,
  isEdit,
  onCancel,
}: IOrganism) => {
  return (
    <>
      <AttributesOrganism
        model_type="inspections"
        model={inspection}
      ></AttributesOrganism>
    </>
  );
};

InspectionDetailQualityTabOrganism.defaultProps = {
  hide: [],
  verbose: [],
};

export default InspectionDetailQualityTabOrganism;
