import { Tooltip } from "@mui/material";
import "moment-timezone";
import React from "react";
import { StyledBalloon, StyledChip } from "./styled";

interface IAtom {
  variant?: string;
  data: any;
  title?: string;
  severity: string;
  size?: "small" | undefined;
  onClick?: (e: React.MouseEvent) => void;
}

const DataBalloonAtom = ({
  variant,
  data,
  title,
  severity,
  size,
  onClick,
}: IAtom) => {
  return (
    <>
      {variant === "chip" ? (
        <Tooltip title={title} placement="top">
          <StyledChip>{data}</StyledChip>
        </Tooltip>
      ) : (
        <StyledBalloon
          size={size}
          onClick={onClick ?? undefined}
          severity={severity}
        >
          {data}
        </StyledBalloon>
      )}
    </>
  );
};

export default DataBalloonAtom;
