import { useState } from "react";
import IOrganism from "./interface";
import {
  Card,
  Grid,
  Typography,
  Box,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Trans } from "@lingui/macro";
import CreateIcon from "@mui/icons-material/Create";
import HCMCreateFormOrganism from "@organisms/HCMCreate";
import NotesOrganism from "@organisms/Notes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";

const HCMDetailsFormOrganism = ({ hcm, onSave }: IOrganism) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const editMode = () => {
    setIsEditMode(isEditMode ? false : true);
  };

  const formSubmitted = () => {
    editMode();
    if (onSave) {
      onSave();
    }
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={7}>
          <Card sx={{ p: 1 }} className="ov-visible">
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box mr={1}>
                <Typography variant="h5">
                  <Trans>HCM info</Trans>
                </Typography>
              </Box>
              <Box>
                {!isEditMode ? (
                  <IconButton onClick={editMode}>
                    <CreateIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={editMode}>
                    <CloseIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
            {!isEditMode && (
              <Box>
                <Box mt={2}>
                  <Grid container>
                    <Grid item md={3}>
                      <FormLabel>
                        <b>
                          <Trans>Client</Trans>
                        </b>
                        <Box>{hcm.client ? hcm.client.name : "--"}</Box>
                      </FormLabel>
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <FormLabel>
                        <b>
                          <Trans>Inspector</Trans>
                        </b>
                        <Box>{hcm.user ? hcm.user.name : "--"}</Box>
                      </FormLabel>
                    </Grid>
                    <Grid item md={4}>
                      <FormLabel>
                        <b>
                          <Trans>Vendor</Trans>
                        </b>
                        <Box>{hcm.vendor ? hcm.vendor.name : "--"}</Box>
                      </FormLabel>
                    </Grid>
                    <Grid item md={4}>
                      <FormLabel>
                        <b>
                          <Trans>Division</Trans>
                        </b>
                        <Box>{hcm.type ? hcm.type.name : "--"}</Box>
                      </FormLabel>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
            {isEditMode && (
              <Box mt={1}>
                <Grid container spacing={1}>
                  <Grid item md={12}>
                    <HCMCreateFormOrganism
                      saveOnDestroy={true}
                      onCancel={() => setIsEditMode(false)}
                      hcm={hcm}
                      onSave={formSubmitted}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Card>
        </Grid>
        <Grid item md={5}>
          <Accordion defaultExpanded={true} elevation={1}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
            >
              <span>
                <Typography variant="h5">
                  <b>
                    <Trans>Notes</Trans>
                  </b>
                </Typography>
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <NotesOrganism
                model_id={hcm.id}
                model_type={"hcms"}
              ></NotesOrganism>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
};

export default HCMDetailsFormOrganism;
